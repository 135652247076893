import React, { useContext, useState } from "react";
import { AppContext } from "../../../utils/context";
import { colors } from "../../../components/utils/theme";
import Header from "../lib/header";
import OrderFulfillmentReport from "./order-fulfillment-report";
import Otif from "./otif-report";
import Primitives from "../../../components/primitives";
import useApiRequest from "../../../hooks/useApiRequest";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";
import Navigation from "../../../lib/navigation";
import PurchaseOrderOtifReport from "./purchase-order-fulfilment-report";
import PillChartHeader from "./pillchart-header";
import { FulfillmentContext } from "../../../utils/fulfillment-report-context";
import splitDateRange from "../../../utils/split-date-range";
import { subDays } from "date-fns";
import formatDate from "../../../utils/format-date";
import { FilterContext } from "../../../utils/filter-context";

const headerFn = data => (
  <Primitives.Flex color={colors.text[0]} pb={4} pl={2} pt={2}>
    {data}
  </Primitives.Flex>
);

const OtifColumn = header => {
  return [
    { heading: headerFn(header), type: "string", width: 0.7 },
    { heading: headerFn("UNIQUE SKUs ORDERED"), type: "number", width: 0.5 },
    { heading: headerFn("QUANTITY ORDERERD"), type: "number", width: 0.5 },
    {
      heading: (
        <PillChartHeader
          mainHeading="OTIF BREAKDOWN"
          padding="122px"
          subHeading={["EARLY(24 HRS)", "ON TIME(1-2 DAYS)", "LATE(2+ DAYS)"]}
        />
      )
    }
  ];
};

const FulFillmentReport = () => {
  const { BASE_URL } = useContext(AppContext);
  const fulfillmentContext = useContext(FulfillmentContext);
  const [query, updateQuery] = useState(
    `order_date_gteq=${fulfillmentContext.startDate}&order_date_lteq=${fulfillmentContext.endDate}`
  );
  const filterContext = useContext(FilterContext);

  const path = `${BASE_URL}/reports`;
  const props = {
    path: path,
    query: query,
    startDate: fulfillmentContext.startDate,
    endDate: fulfillmentContext.endDate
  };
  const { data, isLoaded } = useApiRequest(
    `${BASE_URL}/reports/order_fulfilment_report?${query}`
  );
  const filterUpdate = queryObj => {
    const dateRange = splitDateRange(queryObj.date);

    const startDate =
      (dateRange && dateRange.startDate) ||
      formatDate(subDays(subDays(new Date(), 3), 29));
    const endDate =
      (dateRange && dateRange.endDate) ||
      formatDate(subDays(new Date(), 3), 29);
    fulfillmentContext.updateStartDate(startDate);
    fulfillmentContext.updateEndDate(endDate);
    const hospitalEntity = queryObj?.hospitalEntity?.replace(
      "&hospital_entity_id=",
      ""
    );
    filterContext.updateFilterState({
      ...filterContext.filterState,
      hospitalEntity: hospitalEntity
    });
    updateQuery(
      `order_date_gteq=${startDate}&order_date_lteq=${endDate}${
        hospitalEntity ? `&universal_hospital_ids=${hospitalEntity}` : ""
      }`
    );
  };
  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 3]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
        pb={4}
      >
        <Header
          filterUpdate={filterUpdate}
          label="Fulfillment Report"
          config={{
            hospitalEntity: true,
            date: { buffer: 3, maxSpan: 31 },
            defaultDateRange: {
              startDate: fulfillmentContext.startDate,
              endDate: fulfillmentContext.endDate
            }
          }}
        />
        {isLoaded ? (
          data && data.response && Object.keys(data.response).length ? (
            <Primitives.Flex flexDirection="column" mb={4}>
              <OrderFulfillmentReport
                data={data}
                isLoaded={isLoaded}
                query={query}
              />
              <Otif
                column={OtifColumn("CATEGORY")}
                title="Category"
                {...props}
              />
              <PurchaseOrderOtifReport query={query} />
              <Otif
                column={OtifColumn("HOSPITAL")}
                title="Hospital"
                {...props}
              />
            </Primitives.Flex>
          ) : (
            <Primitives.Flex flexDirection="column" my={5} align="center">
              <NoResults />
            </Primitives.Flex>
          )
        ) : (
          <Loading />
        )}
      </Primitives.Flex>
    </>
  );
};

export default FulFillmentReport;
