/** It renders pillchart-header. Last modified on 27/01/23 for testing */
import React from "react";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import { array, number, oneOfType, string } from "prop-types";

const propTypes = {
  mainHeading: string,
  subHeading: oneOfType([array, string]),
  padding: oneOfType([number, string])
};

const subHeaderStyle = {
  0: {
    color: "#036516",
    textAlign: "start"
  },
  1: {
    color: colors.success[1],
    textAlign: "center"
  },
  2: {
    color: "#F4B513",
    textAlign: "end"
  }
};

const PillChartHeader = ({ mainHeading, subHeading, padding }) => (
  <Primitives.Flex
    flexDirection="column"
    key={1}
    width={1}
    pl={2}
    pr={padding}
    py={1}
  >
    <Primitives.Flex
      color="black"
      fontSize="12px"
      fontWeight={2}
      justifyContent="start"
      mb={2}
    >
      {mainHeading}
    </Primitives.Flex>
    <Primitives.Flex>
      {subHeading &&
        subHeading.map((item, index) => (
          <Primitives.Text
            color={subHeaderStyle[index].color}
            key={index}
            textAlign={subHeaderStyle[index].textAlign}
            width={1 / subHeading.length}
          >
            {item}
          </Primitives.Text>
        ))}
    </Primitives.Flex>
  </Primitives.Flex>
);

PillChartHeader.propTypes = propTypes;
export default PillChartHeader;
