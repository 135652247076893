import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../components/primitives";
import { array, bool, number, oneOfType, string, func } from "prop-types";
import { colors } from "../../components/utils/theme";
import { AppContext } from "../../utils/context";
import { useHistory } from "react-router";
import NavigationContainer from "../../components/navigation";
import logoutUser from "../logout-user";
import DropCart from "../../pages/create-new-purchase-order/drop-cart";
import SmartbuyTrademark from "../../pages/login/sb-trademark";
import NavRightComponent from "./nav-right-component";
import { startDate, endDate } from "../../utils/one-week-date-range";
import useApiRequest from "../../hooks/useApiRequest";

const propTypes = {
  selected: number,
  subNavIndex: oneOfType([array, string]),
  orderPage: bool,
  setOrderSearch: func,
  hideCart: bool,
  hideNavData: bool
};
const defaultProps = { hideCart: false };

const ProductsAndPricingOptions = [
  {
    label: "Product Master",
    link: "/hospital/product_masters"
  },
  {
    label: "Rate Contracts",
    link: "/hospital/hospital_rate_contracts"
  },
  {
    label: "Product Codes",
    link: "/hospital/product_masters/product_codes"
  },
  {
    label: "Product Order Frequency",
    link: "/hospital/product_order_frequency"
  }
];

const getNavItems = (draftsCount, item) => {
  const list = {
    Dashboard: {
      label: "Dashboard",
      link: "/hospital/dashboards"
    },
    Orders: {
      label: "Orders",
      options: [
        {
          label: "Track order",
          link: "/hospital/orders"
        },
        {
          label: "Create New Purchase Order",
          link: "/hospital/purchase_orders"
        },
        {
          label: "Draft Purchase Order",
          link: "/hospital/purchase_orders/draft_orders",
          glyph:
            draftsCount > 0 ? (
              <Primitives.Text
                background={colors.primary[3]}
                borderRadius="50%"
                color="white"
                fontSize="13px"
                fontWeight={2}
                height="17px"
                pl="1px"
                pr="1px"
                pt="1px"
                textAlign="center"
                width="18px"
              >
                {draftsCount}
              </Primitives.Text>
            ) : (
              ""
            )
        },
        {
          label: `Order Fulfillment Report`,
          link: `/hospital/order_fulfilment_details/${startDate}/${endDate}`
        }
      ]
    },
    "Products & Pricing": {
      label: "Products & Pricing",
      options: ProductsAndPricingOptions
    },
    Aknalytics: {
      label: "Aknalytics",
      options: [
        {
          label: (
            <Primitives.Text
              color={colors.primary[3]}
              fontSize={1}
              fontWeight={2}
            >
              SUPPLY CHAIN
            </Primitives.Text>
          )
        },
        {
          label: "Overview",
          link: "/hospital/analytics"
        },
        {
          label: "Procurement Summary",
          link: "/hospital/procurement_analytics"
        },
        {
          label: (
            <Primitives.Flex alignItems="center" display="inlineFlex">
              <Primitives.Text>Fulfillment & OTIF</Primitives.Text>
            </Primitives.Flex>
          ),
          link: "/hospital/fulfillment_reports"
        },
        {
          label: (
            <Primitives.Text
              color={colors.primary[3]}
              fontSize={1}
              fontWeight={2}
            >
              COMMERCIAL
            </Primitives.Text>
          )
        },
        {
          label: "Margin",
          link: "/hospital/purchase_margin_report"
        },
        {
          label: "Savings",
          link: "/hospital/analytics/savings_report"
        },
        {
          label: "Top Manufacturers",
          link: "/hospital/top_manufacturers"
        },
        {
          label: "Top Products",
          link: "/hospital/top_products"
        },
        {
          label: "Top Molecules",
          link: "/hospital/top_molecules"
        },
        {
          label: (
            <Primitives.Text
              color={colors.primary[3]}
              fontSize={1}
              fontWeight={2}
            >
              COMPLIANCE
            </Primitives.Text>
          )
        },
        {
          label: "Cross Product Procurement Report",
          link: "/hospital/cross_center_procurement_report"
        },
        {
          label: "FastGRN Compliance",
          link: "/hospital/analytics/new_grn_report"
        },
        {
          label: "Expiry Report",
          link: "/hospital/analytics/expiry_check"
        },
        {
          label: "RC Compliance Report",
          link: "/hospital/rc_compliance"
        },
        {
          label: (
            <Primitives.Text
              color={colors.primary[3]}
              fontSize={1}
              fontWeight={2}
            >
              FINANCE
            </Primitives.Text>
          )
        },
        {
          label: "All Invoices",
          link: "/hospital/analytics/invoice_reports"
        },
        {
          label: "All GRNs",
          link: "/hospital/analytics/grn_reports"
        },
        {
          label: "Product Returns",
          link: "/hospital/product_returns"
        }
      ]
    },
    Payments: {
      label: "Payments"
    }
  };
  return list[item];
};
const roleAccess = {
  hospital_warehouse: {
    Orders: {
      options: ["Track order", "Order Fulfillment Report"]
    }
  },
  default: {
    Dashboard: true,
    Orders: true,
    "Products & Pricing": true,
    Aknalytics: true,
    Payments: true
  }
};
const filterOptions = (options, items) =>
  options.filter(item => items.includes(item.label));

const navData = (draftsCount, role) =>
  Object.keys(roleAccess[role] || roleAccess["default"]).map(item => {
    const dropdownData = getNavItems(draftsCount, item);
    dropdownData.options = roleAccess?.[role]?.[item]?.options
      ? filterOptions(dropdownData.options, roleAccess[role][item].options)
      : dropdownData.options;
    return dropdownData;
  });

const profile = [
  ...(localStorage.getItem("newUser") !== "true"
    ? [{ text: "My profile" }]
    : []),
  {
    text: "Logout"
  }
];

const Navigation = ({
  selected,
  subNavIndex,
  orderPage,
  setOrderSearch,
  hideCart,
  hideNavData
}) => {
  const { draftsCount, BASE_URL, loadUser, user } = useContext(AppContext);
  const { data: updatedUser } = useApiRequest(`${BASE_URL}/is_logged_in`);
  const [loading, setLoading] = useState(false);
  const product = "SmartBuy";
  const root =
    localStorage.getItem("newUser") === "true"
      ? "/hospital/purchase_orders"
      : "/hospital/orders";
  const history = useHistory();
  const [cartCollapse, setCartCollapse] = useState(false);
  const count = localStorage.getItem("summary");
  const [searchInput, setSearchInput] = useState("");
  const [cartCount, setCartCount] = useState(
    JSON.parse(count) ? JSON.parse(count).length : 0
  );
  useEffect(() => {
    JSON.parse(count) &&
      setCartCount(
        JSON.parse(count).filter(item => item.quantity !== null).length
      );
  }, [count]);

  useEffect(() => {
    const userEmail = user && user.user && user.user.email;
    const updatedUserEmail =
      updatedUser && updatedUser.user && updatedUser.user.email;
    if (updatedUserEmail && userEmail !== updatedUserEmail) {
      loadUser(updatedUser);
    }
  }, [updatedUser]);

  const userlogout = async () => {
    setLoading(true);
    const response =
      BASE_URL && (await logoutUser(BASE_URL.replace("/smartbuy", "")));
    if (response.success) {
      setLoading(false);
      loadUser({});
      localStorage.clear();
      history.push("/");
    }
  };

  const selectHandler = option => {
    switch (option.text) {
      case "My profile":
        history.push(
          `/hospital/users/${user && user.user && user.user.id}/my_profile`
        );
        break;
      case "Logout":
        userlogout();
        break;
    }
  };

  const cartFn = () => {
    if (!count || (count && JSON.parse(count).length === 0)) {
      history.push("/hospital/purchase_orders");
    }
    setCartCollapse(!cartCollapse);
  };

  return (
    <Primitives.Flex position="sticky" top={0} zIndex={100}>
      <NavigationContainer
        data={navData(draftsCount, user?.user?.role)}
        hideNavData={hideNavData}
        id={subNavIndex}
        loading={loading}
        navRightComponent={NavRightComponent(
          orderPage,
          searchInput,
          setSearchInput,
          setOrderSearch,
          hideCart,
          cartFn,
          cartCount
        )}
        product={product}
        productLogo={<SmartbuyTrademark />}
        profileOptions={profile}
        root={root}
        selected={selected}
        selectHandler={selectHandler}
        setCartCollpase={cartFn}
        user={user}
      />
      {cartCollapse && (
        <DropCart
          closeCart={data => {
            data &&
              history.location.pathname === "/hospital/purchase_orders" &&
              history.go(0);
            setCartCollapse(false);
          }}
        />
      )}
    </Primitives.Flex>
  );
};
Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;
export default Navigation;
