import React, { useContext, useState } from "react";
import Stack from "../../../components/glyphs/elements/stack";
import Joystick from "../../../components/glyphs/elements/joystick";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import SummaryBadge from "../../../components/transaction-summary";
import Graphs from "./graphs";
import useApiRequest from "../../../hooks/useApiRequest";
import { AppContext } from "../../../utils/context";
import Loading from "../../../components/loading";
import Filters from "../../../lib/filters";
import Navigation from "../../../lib/navigation";
import Tooltip from "../../../components/tool-tip";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import { indianNumberFormat } from "../../../components/utils";

const SummaryBadgeData = data => [
  {
    backgroundColor: colors.accent[1],
    content: "Total Orders",
    glyph: <Joystick fill={colors.accent[4]} />,
    id: "priceDeviation",
    title: data["total_orders"] && indianNumberFormat(data["total_orders"])
  },
  {
    backgroundColor: colors.accent[1],
    content: "Total Order Amount",
    glyph: <Stack fill={colors.accent[4]} />,
    id: "excessPayment",
    title:
      data["total_order_amount"] &&
      `₹ ${ConvertMoneyToLakhsCrores(data["total_order_amount"])}`
  },
  {
    content: (
      <>
        <Primitives.Text>On - Time Deliveries</Primitives.Text>
        <Tooltip
          content="Orders fulfilled within 48 hours after confirmation"
          fontSize="12px"
          height={15}
          width={30}
        />
      </>
    ),
    glyph: <Joystick fill={colors["background-dark-green"]} />,
    id: "totalExcessPaid",
    title: data["fulfillment_rate"] && data["fulfillment_rate"] + " %"
  }
];

const Overview = () => {
  const { BASE_URL } = useContext(AppContext);
  const [request] = useState(
    `${BASE_URL}/overview
    `
  );
  const [quickSearch, setQuickSearch] = useState("quick_filter=monthly");
  const [hospital, setHospital] = useState("");
  const { data } = useApiRequest(request);

  const filterUpdate = queryObj => {
    setHospital(queryObj.hospitalEntitySingleSelect);
    setQuickSearch(queryObj.quickSearch);
  };

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 1]} />
      <Primitives.Flex flexDirection="column">
        <Primitives.Flex
          backgroundColor="background-blue"
          flexDirection="column"
          px={5}
          py={4}
        >
          <Primitives.Text fontSize={5} fontWeight={2}>
            All Orders Report
          </Primitives.Text>
          <Primitives.Box py={2}>
            <Filters
              filterUpdate={filterUpdate}
              config={{ hospitalEntitySingleSelect: true }}
              tagProps={{
                backgroundColor: "background-light-blue",
                color: colors.text[1],
                items: [
                  { label: "Weekly", key: 1 },
                  { label: "Monthly", key: 2, selected: true },
                  { label: "Quarterly", key: 3 }
                ],
                mr: 1
              }}
              tagSearchHeading="Quick Search"
            />
          </Primitives.Box>
          <Primitives.Flex pt={3}>
            {SummaryBadgeData(data).map((item, index) => (
              <Primitives.Flex
                borderRight="1px solid"
                borderRightColor="athens-gray"
                key={index}
                mr={4}
                pr={4}
              >
                <SummaryBadge
                  {...(item.backgroundColor && {
                    backgroundColor: item.backgroundColor
                  })}
                  content={item.content}
                  glyph={item.glyph}
                  title={
                    item.title ? (
                      item.title
                    ) : (
                      <Primitives.Flex height={25} width={40} mb={1}>
                        <Loading onlySpinner />
                      </Primitives.Flex>
                    )
                  }
                />
              </Primitives.Flex>
            ))}
          </Primitives.Flex>
        </Primitives.Flex>
        <Graphs quickSearch={quickSearch} hospital={hospital} />
      </Primitives.Flex>
    </>
  );
};
export default Overview;
