import React from "react";

const Clock = ({ ...props }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.995 0C2.235 0 0 2.24 0 5C0 7.76 2.235 10 4.995 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 4.995 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9ZM5.25 2.875C5.25 2.66789 5.08211 2.5 4.875 2.5C4.66789 2.5 4.5 2.66789 4.5 2.875V5.21999C4.5 5.39371 4.59114 5.55469 4.7401 5.64406L6.81913 6.89148C6.98841 6.99305 7.20793 6.939 7.3107 6.77045C7.41488 6.5996 7.35938 6.37657 7.18729 6.27446L5.4588 5.24889C5.32936 5.17209 5.25 5.03272 5.25 4.88221V2.875Z"
      fill="#010101"
    />
  </svg>
);

export default Clock;
