import React from "react";

const uploadedFile = ({ ...props }) => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.24984 1H2.24997C1.91846 1 1.60052 1.13169 1.36611 1.36611C1.13169 1.60052 1 1.91846 1 2.24997V12.2497C1 12.5812 1.13169 12.8992 1.36611 13.1336C1.60052 13.368 1.91846 13.4997 2.24997 13.4997H9.74978C10.0813 13.4997 10.3992 13.368 10.6336 13.1336C10.8681 12.8992 10.9997 12.5812 10.9997 12.2497V4.7499L7.24984 1Z"
      strokeWidth="1.18418"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 1V4.7499H10.9999"
      strokeWidth="1.18418"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10.9999V7.25"
      strokeWidth="1.18418"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.125 9.125H7.8749"
      strokeWidth="1.18418"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default uploadedFile;
