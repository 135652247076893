import React, { useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import ProductTable from "./product-table";
import Navigation from "../../../lib/navigation";
import Filters from "../../../lib/filters";
import { convertDate } from "../../../components/utils";

const ProductMaster = () => {
  const [hospitalEntity, setHospitalEntity] = useState("");
  const [date, setDate] = useState("");
  const currentDate = new Date();
  const defaultDate = `&start_date=${convertDate(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    "-"
  )}&end_date=${convertDate(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ),
    "-"
  )}`;

  const createHospitalParams = hospitalEntityId => {
    const hospitalIds = hospitalEntityId
      .split("&hospital_entity_id=")[1]
      .split(",");
    const params = hospitalIds
      .filter(item => item)
      .map(id => `&hospital_ids[]=${id}`)
      .join("");
    return params;
  };

  const filterUpdate = ({ hospitalEntity, date }) => {
    hospitalEntity
      ? setHospitalEntity(createHospitalParams(hospitalEntity))
      : setHospitalEntity("");

    const parsedDate = date
      ? `&end_date=${date
          .split("&")[1]
          .split("=")[1]
          .split("/")
          .join("-")}&start_date=${date
          .split("&")[0]
          .split("=")[1]
          .split("/")
          .join("-")}`
      : defaultDate;
    setDate(parsedDate);
  };

  return (
    <>
      <Navigation selected={2} subNavIndex={[2, 0]} />
      <Primitives.Flex flexDirection="column" margin={4}>
        <Primitives.Text
          color={colors.text[0]}
          fontSize={5}
          fontWeight={2}
          lineHeight={2}
        >
          Product Master
        </Primitives.Text>
        <Primitives.Flex my={3}>
          <Filters
            config={{
              hospitalEntity: true,
              date: true,
              defaultDateRange: true
            }}
            filterUpdate={filterUpdate}
          />
        </Primitives.Flex>
        <ProductTable
          hospitalEntity={hospitalEntity}
          date={date || defaultDate}
        />
      </Primitives.Flex>
    </>
  );
};
export default ProductMaster;
