import { BASE_URL, REQ_OPT } from "../config";
import "regenerator-runtime/runtime";

export default {
  uploadRc: async body => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    var requestOptions = {
      headers: myHeaders,
      body: body,
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/hospital_rate_contracts/upload_rc`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  approveCodes: async approveData => {
    var json = {};
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    if (Object.keys(approveData).length === 2) {
      var requestOptions = {
        headers: myHeaders,
        ...REQ_OPT
      };
      try {
        const response = await fetch(
          `${BASE_URL}/product_masters/update_product_code?product_code=${approveData[0]}&product_key_id=${approveData[1]}`,
          requestOptions
        );
        json = await response.json();
      } catch (error) {
        console.log(error);
      }
    } else {
      var raw = JSON.stringify({ product_codes: approveData });
      var reqOptions = {
        headers: myHeaders,
        body: raw,
        ...REQ_OPT
      };

      try {
        const response = await fetch(
          `${BASE_URL}/product_masters/update_all_product_code`,
          reqOptions
        );
        json = await response.json();
      } catch (error) {
        console.log(error);
      }
    }
    return json;
  }
};
