import React, { useState } from "react";
import { array, string } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import TooltipOnClick from "../../../../lib/tooltip-onclick";
import Table from "../../../../components/table-two";
import Close from "../../../../components/glyphs/elements/close";
import FormularyTag from "../../../../components/glyphs/elements/formulary";

const propTypes = {
  data: array,
  header: string
};

/**  Each element in the array represents a column  in ViewPO table */
const column = [
  {
    heading: "Hospital",
    type: "string",
    align: "flex-start",
    width: 2
  },
  {
    heading: "Ordered Qty",
    type: "number",
    align: "flex-end",
    width: 1
  },
  {
    heading: "Ordered Value",
    type: "number",
    align: "flex-end",
    width: 1.2
  },
  {
    heading: "PO Number",
    type: "string",
    align: "flex-start",
    width: 2
  },
  {
    heading: "Order Date",
    type: "string",
    align: "flex-start",
    width: 1.5
  }
];

/** Columns details of PO table */

const modifyData = data =>
  data &&
  data.map(
    ({
      hospital_name,
      po_number,
      order_value,
      order_date,
      quantity,
      is_formulary
    }) => ({
      hospital: (
        <Primitives.Flex>
          <Primitives.Flex mx={1}> {hospital_name} </Primitives.Flex>
          {is_formulary && <FormularyTag />}
        </Primitives.Flex>
      ),
      quantity,
      value: "₹".concat(order_value?.toFixed(2) ?? 0),
      po_number,
      order_date
    })
  );
const ViewPO = ({ data }) => {
  const [active, setActive] = useState(false);
  return (
    <TooltipOnClick
      backgroundColor="white"
      boxShadow={9}
      close={active}
      padding="16px"
      position="absolute"
      content={
        <Primitives.Flex
          flexDirection="column"
          maxHeight="35vh"
          overflow="scroll"
          width={1}
        >
          <Primitives.Flex pb="16px" justifyContent="space-between">
            <Primitives.Text color="#1E2B51" fontSize={2} fontWeight={2}>
              Purchase Orders
            </Primitives.Text>
            <Close
              fill={colors.text[0]}
              onClick={() => setActive(!active)}
              cursor="pointer"
              height="10px"
              width="10px"
            />
          </Primitives.Flex>
          <Primitives.Flex>
            <Table
              alternateRowColor="whitesmoke"
              backgroundColor={{ head: colors["background-light-blue"] }}
              border={`0.5px solid ${colors.text[3]}`}
              column={column}
              color={{
                head: colors.text[0],
                row: "#525983",
                footer: colors.text[0],
                selected: "#EDF4FF"
              }}
              data={modifyData(data)}
              striped={true}
              borderBottom={`0.5px solid ${colors.text[3]}`}
            />
          </Primitives.Flex>
        </Primitives.Flex>
      }
      contentWidth="600px"
      containerTextAlign="center"
      left="-400%"
      toolTipTriangle={{
        left: "90%",
        transform: "translateX(-50%)"
      }}
      borderRadius="9px"
      value={<Primitives.Text cursor="pointer">View PO</Primitives.Text>}
    />
  );
};

ViewPO.propTypes = propTypes;
export default ViewPO;
