const validation = (setErrors, setValid) => {
  const namePattern = /^[a-zA-Z0-9\s&.'-]{2,50}$/;
  const phonePattern = /^\d{10}$/;
  const sixDigitPattern = /^[0-9]{6,6}$/;
  const deliveryData = JSON.parse(localStorage.getItem("delivery_data")) || {
    orgName: "",
    fullName: "",
    phoneNumber: "",
    dl_file: null,
    gstNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    stateId: "",
    cityId: "",
    pincode: ""
  };

  const validateField = (field, message, pattern = null) => {
    const fieldValue = deliveryData[field] && deliveryData[field];
    if (
      !fieldValue ||
      (pattern && !pattern.test(fieldValue.trim())) ||
      (field === "dl_file" && Object.is(fieldValue, {}))
    ) {
      if (setErrors) {
        setErrors(prev => ({ ...prev, [field]: message }));
      }
      return false;
    }
    if (setErrors) {
      setErrors(prev => ({ ...prev, [field]: "" }));
    }
    return true;
  };

  const isOrgNameValid = validateField(
    "orgName",
    "Please enter a valid organization name",
    namePattern
  );
  const isFullNameValid = validateField(
    "fullName",
    "Please enter a valid full name",
    namePattern
  );
  const isPhoneNumberValid = validateField(
    "phoneNumber",
    "Please enter a valid phone number",
    phonePattern
  );
  const isDlFileValid = validateField("dl_file", "Please upload drug license");
  const isAddressLine1Valid = validateField(
    "addressLine1",
    "Please enter a valid address line 1"
  );
  const isAddressLine2Valid = validateField(
    "addressLine2",
    "Please enter a valid address line 2"
  );
  const isCityValid = validateField("cityId", "Please select city");
  const isPincodeValid = validateField(
    "pincode",
    "Please enter a valid pincode",
    sixDigitPattern
  );
  const isValid =
    isOrgNameValid &&
    isFullNameValid &&
    isPhoneNumberValid &&
    isDlFileValid &&
    isAddressLine1Valid &&
    isAddressLine2Valid &&
    isCityValid &&
    isPincodeValid;

  if (setValid) {
    setValid(isValid);
  }
  return isValid;
};

export default validation;
