// It renders report to show category wise discount
import React from "react";
import { array, string } from "prop-types";
import GenericSavingsMarginsReport from "../../../../lib/generic-savings-margins-report";

const propTypes = {
  data: array,
  download: string,
  column: array
};

const modifyData = data =>
  data.map(item => ({
    categoryName: item.name,
    totalDiscountvalue: `₹ ${item.total_discount_value.toFixed(2)}`,
    averageDiscount: `${item.average_discount_rate} %`
  }));
const title = "Category Discount Report";

const DiscountReport = ({ data, download, column }) => (
  <GenericSavingsMarginsReport
    data={data && modifyData(data)}
    downloadLink={`${download}?category_discount_download=true&`}
    column={column}
    title={title}
    report="savings"
  />
);

DiscountReport.propTypes = propTypes;
export default DiscountReport;
