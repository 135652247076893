import React, { useState } from "react";
import { array, number, string } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import TooltipOnClick from "../../../../lib/tooltip-onclick";
import Table from "../../../../components/table-two";
import Close from "../../../../components/glyphs/elements/close";

const propTypes = {
  column: array,
  data: array,
  header: string,
  value: number
};
const ViewInvoice = ({ column, data, value }) => {
  const [active, setActive] = useState(false);
  return (
    <TooltipOnClick
      backgroundColor="white"
      boxShadow={9}
      close={active}
      padding="16px"
      position="absolute"
      content={
        <Primitives.Flex
          flexDirection="column"
          maxHeight="35vh"
          overflow="scroll"
          width={1}
        >
          <Primitives.Flex pb="16px" justifyContent="space-between">
            <Primitives.Text color="#1E2B51" fontSize={2} fontWeight={2}>
              Invoice Details
            </Primitives.Text>
            <Close
              fill={colors.text[0]}
              onClick={() => setActive(!active)}
              cursor="pointer"
              height="10px"
              width="10px"
            />
          </Primitives.Flex>
          <Primitives.Flex>
            <Table
              alternateRowColor="whitesmoke"
              backgroundColor={{ head: colors["background-light-blue"] }}
              border={`0.5px solid ${colors.text[3]}`}
              column={column}
              color={{
                head: colors.text[0],
                row: "#525983",
                footer: colors.text[0],
                selected: "#EDF4FF"
              }}
              data={data}
              striped={true}
              borderBottom={`0.5px solid ${colors.text[3]}`}
            />
          </Primitives.Flex>
        </Primitives.Flex>
      }
      contentWidth="600px"
      containerTextAlign="center"
      left="-200%"
      toolTipTriangle={{
        left: "50%",
        transform: "translateX(-50%)"
      }}
      borderRadius="9px"
      value={
        <Primitives.Text
          color={colors.accent[4]}
          cursor="pointer"
          textDecoration="underline"
        >
          {value}
        </Primitives.Text>
      }
    />
  );
};

ViewInvoice.propTypes = propTypes;
export default ViewInvoice;
