import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import Primitives from "../../../components/primitives";
import GenericReport from "../lib/generic-report";
import { useParams } from "react-router";
import DisplayALlItems from "../lib/display-all-items";
import Loading from "../../../components/loading";
import { reportStyles } from "../../../lib/report-styles";
import paginationHandler from "../../../components/utils/pagination-handler";
import ViewCenter from "./view-center-tooltip";
import RCIndicator from "../../../components/glyphs/elements/rc-icon";

const modifyData = data =>
  data &&
  data["ordered_skus"] &&
  data["ordered_skus"].map(item => ({
    SKUNAME: (
      <Primitives.Flex>
        <Primitives.Text mx={1}>{item.name}</Primitives.Text>
        {item.rc_status && <RCIndicator />}
      </Primitives.Flex>
    ),
    CATEGORY: item.category,
    MANUFACTURER: item.manufacturer,
    NUMBEROFCENTRES: (
      <ViewCenter noOfCenters={item.no_of_centers} skuId={item.id} />
    )
  }));

const column = [
  {
    heading: "SKU NAME",
    type: "string",
    width: 1
  },
  {
    heading: "CATEGORY",
    type: "string",
    width: 0.5
  },
  {
    heading: "MANUFACTURER",
    type: "string",
    width: 1
  },
  {
    heading: "NUMBER OF CENTRES",
    type: "number",
    width: 0.5,
    align: "center"
  }
];
const OrderedSku = () => {
  const { BASE_URL } = useContext(AppContext);
  const hospitalId = useParams().orderedSkusId;
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/ordered_skus?id=${hospitalId}`
  );
  const [query, setQuery] = useState(1);
  const { data, isLoaded } = useApiRequest(request);
  const [currentPage, setCurrentPage] = useState(1);
  const filterUpdate = queryObj => {
    setQuery(`${queryObj.crossProcurementCategory}`);
    updateRequest(
      `${BASE_URL}/cross_procurement_reports/ordered_skus?id=${hospitalId}&page=${currentPage}&${queryObj.crossProcurementCategory}${queryObj.skus}`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/cross_procurement_reports/ordered_skus?id=${hospitalId}&page=${currentPage}&${query}`
    );
  }, [currentPage, BASE_URL]);
  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const headerProps = {
    backLink: "/hospital/cross_center_procurement_report",
    config: { crossProcurementCategory: true, skus: true },
    filterUpdate: filterUpdate,
    label:
      data && data["hospital"] ? (
        data["hospital"]
      ) : (
        <Primitives.Flex height="2.5vh">
          <Loading onlySpinner />
        </Primitives.Flex>
      )
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );

  const tableProps = {
    data: modifyData(data),
    column: column,
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      headerProps={headerProps}
      subNavIndex={[3, 11]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
export default OrderedSku;
