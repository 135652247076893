// It renders hospital-wise margin improvement report
import React from "react";
import { array, func, string } from "prop-types";
import GenericSavingsMarginsReport from "../../../../lib/generic-savings-margins-report";

const propTypes = {
  data: array,
  download: string,
  modifyData: func,
  column: array
};

const modifyData = data =>
  data.map(item => ({
    hospitalName: item[0],
    previousMonth: `${item["1"].last_month_avg_margin_perc} %`,
    currentMonth: `${item["1"].current_month_avg_margin_perc} %`,
    marginDiff: `${item["1"].avg_margin_perc} %`
  }));

const title = "Hospital Margin Improvements Report";

const HospitalManagementReport = ({ data, download, column }) => (
  <GenericSavingsMarginsReport
    data={data && modifyData(data)}
    downloadLink={`${download}?hospital_margin_download=true&`}
    column={column}
    title={title}
    report="savings"
  />
);
HospitalManagementReport.propTypes = propTypes;
export default HospitalManagementReport;
