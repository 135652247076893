/** It renders Fast GRN Compliance Report. Last Modified on 20/01/23 for testing */

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import { colors } from "../../../components/utils/theme";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import Primitives from "../../../components/primitives";
import paginationHandler from "../../../components/utils/pagination-handler";
import { indianNumberFormat } from "../../../components/utils";

const modifyData = data =>
  data &&
  data["fast_grn"] &&
  data["fast_grn"].map(item => ({
    HospitalName: item.name,
    NoOfOrders: item.no_of_orders,
    NoOfInvoices: item.no_of_invoices,
    NoOfGRN:
      item.grn_count > 0 ? (
        <Primitives.Flex>
          {item.grn_count}({item.grn_percentage})
        </Primitives.Flex>
      ) : (
        item.grn_count
      )
  }));

const column = data => [
  {
    heading: "Hospital Name",
    type: "string",
    width: 2
  },
  {
    heading: `No. Of Orders(${data["orders_count"] &&
      indianNumberFormat(data["orders_count"])})`,
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    heading: `No. Of Invoices(${data["invoices_count"] &&
      indianNumberFormat(data["invoices_count"])})`,
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    heading: `No. of GRN(${data["grn_count"] &&
      indianNumberFormat(data["grn_count"])})`,
    type: "string",
    width: 1,
    align: "flex-end"
  }
];

const FastGrnCompliance = () => {
  const { BASE_URL } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(1);
  const [request, updateRequest] = useState(
    `${BASE_URL}/analytics/new_grn_report?`
  );
  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    setQuery(`${queryObj.hospitalEntity}&${queryObj.date}`);
    updateRequest(
      `${BASE_URL}/analytics/new_grn_report?${queryObj.quickSearch}&page=${currentPage}&${queryObj.hospitalEntity}&${queryObj.date}`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/analytics/new_grn_report?page=${currentPage}&${currentPage}&${query}`
    );
  }, [currentPage]);

  const headerProps = {
    config: { hospitalEntity: true, date: true },
    filterUpdate: filterUpdate,
    label: "Fast GRN Compliance Report",
    tagProps: {
      backgroundColor: "background-light-blue",
      color: colors.text[1],
      items: [
        { label: "Today's GRN", key: 7 },
        { label: "Yesterday's GRN", key: 30 }
      ],
      mr: 1
    },
    tagSearchHeading: "Quick Search"
  };

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");

  const tableProps = {
    data: modifyData(data),
    column: column(data),
    justify: "center",
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      headerProps={headerProps}
      subNavIndex={[3, 12]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
export default FastGrnCompliance;
