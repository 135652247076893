export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const REQ_OPT = {
  method: "POST",
  redirect: "follow"
};
export const GET_REQ_OPT = {
  method: "GET",
  redirect: "follow"
};
