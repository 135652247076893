import React from "react";
import Table from "../../../components/table-two/TableComponent";
import { colors } from "../../../components/utils/theme";
import { array } from "prop-types";
import Primitives from "../../../components/primitives";
import Tooltip from "../../../components/tool-tip";
import { convertDate } from "../../../components/utils";
import Clock from "../../../components/glyphs/elements/clock";
import { indianNumberFormat } from "../../../components/utils";
import { HorizontalScroll } from "../../../lib/horizontal-scroll";

const propTypes = {
  tableData: array
};

const toolTipObject = {
  pending_mapping: {
    backgroundColor: colors.text[2],
    text: "To be mapped"
  },
  unavailable: {
    backgroundColor: colors.error[2],
    text: "Quantity not available will be procured soon"
  },
  partial: {
    backgroundColor: colors.warning,
    text: "Partial Qty. Available"
  },
  complete: {
    backgroundColor: colors["background-green"],
    text: "Full Qty. Available"
  }
};

const statusObject = {
  Delivered: colors["background-dark-green"],
  Invoiced: colors["background-dark-green"],
  "Partially Invoiced": colors["background-yellow"],
  "In Progress": colors["background-error"]
};

const modifyData = data =>
  data.map(item => ({
    PRODUCT: item.product_name,
    MANUFACTURER: item.company_name,
    RATE: `₹ ${item.rate}`,
    MRP: `₹ ${item.mrp}`,
    DISCOUNT: (
      <Primitives.Flex>
        ₹ {item.discount_amount || 0} ({item.discount_rate || 0} %)
      </Primitives.Flex>
    ),
    QUANTITY: item.quantity,
    "FREE QTY": item.free_quantity,
    UOM: item.unit_of_measurement,
    "NET AMT": `₹ ${item.net_amount}`,
    GST: `${item.gst_rate + item.cgst_rate}%`,
    "TOTAL TAX": `₹ ${item.total_tax_amount}`,
    "TOTAL AMT": `₹ ${item.total_amount}`,
    "PENDING QTY": (
      <Primitives.Flex>
        {item.pending_quantity > 0 ? (
          <Tooltip
            content={
              toolTipObject[item.soh_status] &&
              toolTipObject[item.soh_status].text
            }
            fontSize="12px"
            height={17}
            value={
              <Primitives.Flex
                backgroundColor={
                  toolTipObject[item.soh_status] &&
                  toolTipObject[item.soh_status].backgroundColor
                }
                borderRadius={30}
                color="white"
                justifyContent="center"
                p="4px"
                minWidth="15px"
              >
                {indianNumberFormat(item.pending_quantity) || 0}
              </Primitives.Flex>
            }
            width={30}
          />
        ) : (
          indianNumberFormat(item.pending_quantity)
        )}
      </Primitives.Flex>
    ),
    STATUS: (
      <Primitives.Flex flexDirection="column" width={1}>
        <Primitives.Text color={statusObject[item.status]}>
          {item.status}
        </Primitives.Text>

        <Primitives.Flex>
          {item.expected_delivery_date && (
            <Primitives.Flex alignItems="baseline" color="#010101" mr={1}>
              <Clock />
              <Primitives.Text ml="5px" textDecoration="underline">
                {convertDate(item.expected_delivery_date, " ", "M").substring(
                  0,
                  convertDate(
                    item.expected_delivery_date,
                    " ",
                    "M"
                  ).lastIndexOf(" ")
                )}
              </Primitives.Text>
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    )
  }));

const modifyReport = tableData => [
  ...modifyData(tableData),
  {
    product_name: `Total products: ${tableData.length}`,
    company_name: <Primitives.Text />,
    rate: <Primitives.Text />,
    mrp: <Primitives.Text />,
    discount_rate: (
      <Primitives.Text>
        ₹ {arrSum(tableData, "discount_amount")} (
        {arrSum(tableData, "discount_rate")}%)
      </Primitives.Text>
    ),
    quantity: indianNumberFormat(arrSum(tableData, "quantity")),
    free_quantity: arrSum(tableData, "free_quantity"),
    unit_of_measurement: <Primitives.Text />,
    net_amount: "₹".concat(arrSum(tableData, "net_amount")),
    gst_rate: <Primitives.Text />,
    total_tax_amount: "₹".concat(arrSum(tableData, "total_tax_amount")),
    total_amount: "₹".concat(arrSum(tableData, "total_amount")),
    pending_quantity: <Primitives.Text />,
    status: <Primitives.Text />
  }
];

const column = [
  { heading: "PRODUCT", type: "string", width: 2.5 },
  { heading: "MANUFACTURER", type: "string", width: 2.5 },
  { heading: "RATE", type: "number", width: 1, align: "flex-end" },
  { heading: "MRP", type: "string", align: "flex-end" },
  { heading: "DISCOUNT", type: "number", align: "flex-end", width: 1.5 },
  { heading: "QUANTITY", type: "string", align: "flex-end" },
  { heading: "FREE QTY", type: "number", align: "flex-end" },
  { heading: "UOM", type: "string" },
  { heading: "NET AMT", type: "string", align: "flex-end" },
  { heading: "GST", type: "number", width: 0.5 },
  { heading: "TOTAL TAX", type: "string", align: "flex-end" },
  { heading: "TOTAL AMT", type: "string", align: "flex-end" },
  {
    heading: "PENDING QTY",
    type: "number",
    align: "flex-end"
  },
  { heading: "STATUS", type: "object", width: 1 }
];

const arrSum = (arr, key) =>
  arr
    .map(item => item[key])
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

const PO = ({ tableData }) => (
  <Primitives.Flex flexDirection="column" width="100%" overflowX="auto" pb={3}>
    <HorizontalScroll>
      <Primitives.Flex width="120%" pr={2}>
        <Table
          boldRow={true}
          color={{ head: colors.text[1], row: colors.text[0] }}
          data={modifyReport(tableData)}
          column={column}
          page="detail"
        />
      </Primitives.Flex>
    </HorizontalScroll>
  </Primitives.Flex>
);

PO.propTypes = propTypes;
export default PO;
