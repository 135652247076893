import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import CategoryWiseMargin from "./category-wise-margin";
import HospitalWiseMargin from "./hospital-wise-margin";
import OverallMargins from "./overall-margins";
import Primitives from "../../../../components/primitives";
import Header from "../../lib/header";
import UpwardArrow from "../../../../components/glyphs/elements/upwardArrow";
import DownwardArrow from "../../../../components/glyphs/elements/downwardArrow";
import { colors } from "../../../../components/utils/theme";
import ViewDetailsBtn from "../../lib/view-details-btn";
import Loading from "../../../../components/loading";
import Navigation from "../../../../lib/navigation";
import { useParams } from "react-router";
import { FilterContext } from "../../../../utils/filter-context";

const rupee = [
  "mrp",
  "rate",
  "margin_value",
  "purchase_val",
  "order_val",
  "curr_mon_margin_perc"
];

const percentage = ["last_mon_margin_perc"];
const arrowItem = {
  low: <DownwardArrow fill="red" />,
  high: <UpwardArrow fill={colors["background-green"]} />
};

const PurchaseMarginReport = () => {
  const filterContext = useContext(FilterContext);
  const params = useParams();
  const { BASE_URL } = useContext(AppContext);

  const [query, updateQuery] = useState(
    `${
      params.month ? `months=${params.month}-${new Date().getFullYear()}` : ""
    }&${
      params.category
        ? `universal_category_category_type_cont=${params.category}`
        : ""
    }`
  );
  const [hospotalFilter, setHospitalFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const path = `${BASE_URL}/reports/margin_report`;
  const { data, isLoaded } = useApiRequest(`${path}?${query}`);

  useEffect(() => {
    if (params.month) {
      filterContext.updateFilterState({
        months: [`${params.month}-${new Date().getFullYear()}`]
      });
      setDateFilter(`months=${params.month}-${new Date().getFullYear()}`);
    }
  }, [params]);

  const filterUpdate = queryObj => {
    queryObj.months && setDateFilter(queryObj.months.split(",")[0] || "");
    filterContext.updateFilterState({
      months: [queryObj.months.split("=")[1].split(",")[0]]
    });
    setHospitalFilter(queryObj.entity);
    updateQuery(
      `${queryObj.entity}&${(queryObj.months &&
        queryObj.months.split(",")[0]) ||
        ""}`
    );
  };
  const modifyData = (data, arrows) => {
    return data.map(item => {
      let obj = { ...item };
      for (var i in obj) {
        obj[i] =
          rupee.indexOf(i) > -1
            ? `₹${Math.round(item[i])}`
            : percentage.indexOf(i) > -1
            ? `${item[i]}%`
            : item[i];
      }
      if (arrows) {
        obj.curr_mon_margin_perc = (
          <Primitives.Flex>
            <Primitives.Flex alignSelf="flex-start">
              {item.margin_diff !== 0 && (
                <Primitives.Flex flex={1} alignItems="center">
                  <Primitives.Text
                    color={
                      item.position === "low"
                        ? "red"
                        : colors["background-green"]
                    }
                    pr="3px"
                  >
                    {item.margin_diff}
                  </Primitives.Text>
                  {arrowItem[item.position]}
                </Primitives.Flex>
              )}
            </Primitives.Flex>

            <Primitives.Text ml={1} minWidth="42px">
              {item.curr_mon_margin_perc}%
            </Primitives.Text>

            {item.category_id && (
              <Primitives.Flex alignSelf="center">
                <ViewDetailsBtn
                  top={1}
                  leftAligned={true}
                  link={{
                    pathname: `/hospital/purchase_margin_category/${item.category_id}`,
                    state: {
                      date: dateFilter,
                      hospital: hospotalFilter
                    }
                  }}
                />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        );
      }

      delete obj["position"];
      delete obj["category_id"];
      delete obj["margin_diff"];
      delete obj["hospital_id"];

      return obj;
    });
  };

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 5]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Header
          config={{ entity: true, months: true }}
          filterUpdate={filterUpdate}
          label="Purchase Margin Report"
        />
        {isLoaded ? (
          <>
            <Primitives.Flex pl={4} py={4}>
              <OverallMargins
                data={data.overall_margin}
                title="Overall average margin"
              />
            </Primitives.Flex>
            <Primitives.Flex mt={0} pl={4} py={4}>
              <CategoryWiseMargin
                avgMarginPercentage={data.avg_category_margin}
                modifyData={modifyData}
                data={data.category_margin}
                download={`${data.category_download_url}&${query}`}
              />
            </Primitives.Flex>
            <Primitives.Flex mt={0} pl={4} py={4}>
              <HospitalWiseMargin
                modifyData={modifyData}
                data={data.hospital_margin}
                download={`${data.hospital_download_url}&${query}`}
              />
            </Primitives.Flex>
          </>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
      </Primitives.Flex>
    </>
  );
};
export default PurchaseMarginReport;
