import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import Primitives from "../../../components/primitives";
import { reportStyles } from "../../../lib/report-styles";
import { useHistory } from "react-router";
import { FilterContext } from "../../../utils/filter-context";
import paginationHandler from "../../../components/utils/pagination-handler";

const modifyData = (data, currentPage) =>
  data &&
  data["top_molecules"] &&
  data["top_molecules"].map((item, index) => ({
    srNo: (currentPage - 1) * 20 + index + 1,
    moleculeName:
      item.molecule_names.length > 60
        ? `${item.molecule_names.substring(0, 57)}  . . .`
        : item.molecule_names,
    moleculeGroup: item.molecule_group,
    totalPurchaseValue: `₹ ${item.total_amount}`,
    totalPurchaseQuantity: item.total_quantity,
    uniqueBrandsPurchased: item.uniq_skus
  }));

const column = [
  {
    heading: "Sr. No.",
    sort: false,
    type: "number",
    width: 0.5
  },
  {
    heading: "Molecule Name",
    type: "string",
    width: 2
  },
  {
    heading: "Molecule Group",
    type: "string",
    width: 2
  },
  {
    heading: "Total Purchase Value",
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "Total Purchase Quantity",
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "Unique Brands Purchased",
    type: "number",
    width: 1,
    align: "flex-end"
  }
];

const headerProps = filterUpdate => ({
  filterUpdate: filterUpdate,
  config: {
    hospitalEntity: true,
    moleculeSearch: true,
    moleculeGroup: true,
    subcategory: true,
    date: true
  },
  label: "Top Molecules"
});

const index = {
  1: "molecule_names",
  2: "molecule_group",
  3: "total_amount",
  4: "total_quantity",
  5: "uniq_skus"
};

const paginationProps = (data, currentPage, paginationChangeHandler) => ({
  changeHandler: paginationChangeHandler,
  forcePage: currentPage - 1,
  marginPagesDisplayed: 1,
  pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
  pageRangeDisplayed: 5
});

const TopMolecule = () => {
  const history = useHistory();
  const { BASE_URL } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, updateSort] = useState("");
  const [order, toggleOrder] = useState("asc");
  const filterContext = useContext(FilterContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/top_molecules?`
  );
  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    let hospitals =
      queryObj.hospitalEntity && queryObj.hospitalEntity.split("=")[1];
    let subcategories =
      queryObj.subcategory && queryObj.subcategory.split("=")[1];
    filterContext.updateFilterState({
      dateRange: queryObj.date,
      hospitals: hospitals,
      molecule: queryObj.moleculeSearch,
      moleculeGroups: queryObj.moleculeGroup,
      subcategory: subcategories
    });
  };

  useEffect(() => {
    let filterState = filterContext.filterState;
    updateRequest(
      `${BASE_URL}/reports/top_molecules?page=${currentPage}&q[name_cont]=${filterState.molecule ||
        ""}&${filterState.moleculeGroups || ""}&${filterState.dateRange || ""}${
        filterState.subcategory
          ? `&sub_category_id=${filterState.subcategory}`
          : ""
      }&universal_hospital_ids=${filterState.hospitals || ""}&${sort}`
    );
  }, [currentPage, filterContext, sort]);

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );
  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");

  const getHeaderId = id => {
    toggleOrder(order === "asc" ? "desc" : "asc");
    id in index
      ? updateSort(`sort_by=${index[id]}&sort_type=${order}`)
      : updateSort(null);
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const moleculeDetails = async (item, index) => {
    history.push({
      pathname: `/hospital/top_molecules/${data["top_molecules"][index].sku_ids}/${data["top_molecules"][index].molecule_names}`
    });
  };

  const tableProps = {
    cursor: "pointer",
    clickHandler: (item, index) => moleculeDetails(item, index),
    data: modifyData(data, currentPage),
    column: column,
    isLakhsCroresNeeded: true,
    ...reportStyles["allReports"],
    sorting: true
  };
  return (
    <Primitives.Flex flexDirection="column">
      <GenericReport
        displayItems={displayItems()}
        downloadCtaProps={downloadCtaProps}
        getHeaderId={getHeaderId}
        subNavIndex={[3, 9]}
        isLoaded={isLoaded}
        headerProps={headerProps(filterUpdate)}
        paginationProps={paginationProps(
          data,
          currentPage,
          paginationChangeHandler
        )}
        tableProps={tableProps}
      />
    </Primitives.Flex>
  );
};
export default TopMolecule;
