// It renders GRN Time Savings Report
import React from "react";
import { array, func, string } from "prop-types";
import GenericSavingsMarginsReport from "../../../../lib/generic-savings-margins-report";

const propTypes = {
  data: array,
  download: string,
  column: array,
  modifyData: func
};

const modifyData = data =>
  data.map(item => ({
    hospitalName: item[0],
    numOfConfirmedInvoices: item["1"].invoice_count,
    total: item["1"].avg_items,
    grnEstimateTime: item["1"].avg_time_per_grn
  }));
const title = "GRN Time Savings Report";

const GrnTimeSavings = ({ data, download, column }) => (
  <GenericSavingsMarginsReport
    data={data && modifyData(data)}
    downloadLink={`${download}?grn_download=true&`}
    column={column}
    title={title}
    report="savings"
  />
);

GrnTimeSavings.propTypes = propTypes;
export default GrnTimeSavings;
