import React, { useState, useContext } from "react";
import Button from "../../components/button";
import Primitives from "../../components/primitives";
import Table from "../../components/table-two/TableComponent";
import { colors } from "../../components/utils/theme";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import useApiRequest from "../../hooks/useApiRequest";
import { AppContext } from "../../utils/context";
import Filters from "../../lib/filters";
import NoResults from "../../components/no-results";
import Navigation from "../../lib/navigation";
import PurchaseOrderService from "../../services/create-new-purchase-order";

const column = [
  {
    heading: "PO NUMBER",
    type: "string",
    width: 0.7
  },
  {
    heading: "CENTRE",
    type: "string",
    width: 1
  },
  {
    heading: "DATE CREATED",
    type: "string",
    width: 0.6
  },
  {
    heading: "NO. OF ITEMS",
    type: "number",
    align: "flex-end",
    width: 0.5
  },
  {
    heading: "TOTAL AMOUNT",
    type: "number",
    align: "flex-end",
    width: 0.5
  },
  {
    heading: " ",
    type: "object",
    align: "flex-end",
    width: 0.8
  },
  {
    heading: " ",
    type: "object",
    width: 0.5
  }
];

const modifyData = (data, viewDraft, deleteDraft) =>
  data.map(item => ({
    "PO NUMBER": item["po_number"],
    CENTRE: item["center"],
    "DATE CREATED": item["date_created"],
    "NO. OF ITEMS": item["no_of_items"],
    "TOTAL AMOUNT": `₹ ${item["total_amount"]}`,
    "": (
      <Button
        active={{ backgroundColor: colors.primary[3], color: "white" }}
        border="1px solid"
        borderColor={colors.primary[3]}
        borderRadius="5px"
        hover={{ backgroundColor: colors.primary[3], color: "white" }}
        clickHandler={() => viewDraft(item)}
        label="View & Complete Order"
        px={2}
        py={1}
      />
    ),
    " ": (
      <Button
        active={{ color: colors.primary[3] }}
        backgroundColor="none!important"
        clickHandler={() => deleteDraft(item)}
        fontWeight={2}
        hover={{ color: colors.primary[3] }}
        label="Delete Draft"
        px={2}
        py={1}
      />
    )
  }));

const DraftPurchaseOrder = () => {
  const { BASE_URL } = useContext(AppContext);
  const [pageNumber] = useState(1);
  const [request, updateRequest] = useState(
    `${BASE_URL}/purchase_orders/draft_orders`
  );
  const { data, isLoaded } = useApiRequest(request);
  const history = useHistory();

  const viewDraft = async item => {
    try {
      const response = await fetch(
        `${BASE_URL}/purchase_orders/edit_order?id=${item.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      const result = await response.json();
      if (result.po_number) {
        const localStorageObj = {
          summary: result.draft_order_items,
          po_number: result.po_number,
          hospital: [result.hospital[0], result.hospital[1].id],
          dated: result.so_date,
          sales_order_id: item.id
        };
        Object.keys(localStorageObj).map(key =>
          localStorage.setItem(key, JSON.stringify(localStorageObj[key]))
        );
        history.push({
          pathname: `/hospital/purchase_orders/purchase_order_summary/${
            result.hospital[1].id
          }/${result.po_number.split("/").join("-")}`
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDraft = async item => {
    const res = await PurchaseOrderService.deleteDraft(item.id);
    if (res["status"] === "success") {
      const token = localStorage.getItem("token");
      localStorage.setItem("token", token);
      history.go(0);
    }
  };

  const filterUpdate = (hospitalStr, hospitalEntityStr) => {
    if (typeof hospitalEntityStr !== "string") return;
    updateRequest(
      `${BASE_URL}/purchase_orders/draft_orders?hospital_ids=${
        hospitalEntityStr.split("=")[1]
      }`
    );
  };
  return (
    <>
      <Navigation selected={1} subNavIndex={[1, 2]} />
      <Primitives.Flex flexDirection="column" px={5} py={3}>
        <Primitives.Text fontSize={5} fontWeight={2}>
          Draft Purchase Orders
        </Primitives.Text>
        <Primitives.Flex py={2}>
          <Filters
            config={{ hospitalEntity: true }}
            filterUpdate={filterUpdate}
          />
        </Primitives.Flex>
        {isLoaded && data && data["total_items"] > 0 && (
          <Primitives.Text color={colors.text[1]} fontSize={2} py={2}>
            Showing {(pageNumber - 1) * 20 + 1}-
            {data["total_items"] > 20
              ? pageNumber * 20 > data["total_items"]
                ? data["total_items"]
                : pageNumber * 20
              : data["total_items"]}{" "}
            of {data["total_items"]}
            {" SKUs"}
          </Primitives.Text>
        )}
        <Primitives.Flex py={4}>
          {isLoaded && data && data["draft_orders"] ? (
            <Primitives.Flex flexDirection="column" py={3} width="100vw">
              {data["draft_orders"].length > 0 ? (
                <Table
                  column={column}
                  data={modifyData(
                    data["draft_orders"],
                    viewDraft,
                    deleteDraft
                  )}
                />
              ) : (
                <NoResults />
              )}
            </Primitives.Flex>
          ) : (
            <Primitives.Flex margin="auto">
              <Loading />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default DraftPurchaseOrder;
