import React from "react";
import { colors } from "../../../../components/utils/theme";
import Cta from "../../../../components/cta";
import Primitives from "../../../../components/primitives";
import Modal from "../../../../components/modal";
import UploadedFileButton from "../uploaded-file-tag";
import CtaForDownload from "./cta-for-download";
import { array, func, string } from "prop-types";

const propTypes = {
  downloadUrl: string,
  rateContract: string,
  supportingDocs: array,
  toggleDownloadModal: func
};

const downloadModal = ({
  downloadUrl,
  rateContract,
  supportingDocs,
  toggleDownloadModal
}) => {
  return (
    <>
      <Modal
        border="1px solid ${colors.text[3]}"
        borderRadius={8}
        closeHandler={() => toggleDownloadModal(false)}
        content={
          <Primitives.Flex
            flex={1}
            flexDirection="column"
            maxHeight="80vh"
            overflow={supportingDocs.length > 0 ? "scroll" : "none"}
          >
            <Primitives.Flex
              flexDirection="column"
              fontFamily="Lato"
              fontStyle="normal"
              fontWeight={1}
            >
              <Primitives.Text fontSize={5} fontWeight={2}>
                This particular item consists two files.
              </Primitives.Text>
              <Primitives.Text color={colors.text[1]} fontSize={3} mt={1}>
                Which one do you want to download?
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={4}>
              <Primitives.Text fontSize={3}>Rate Contract</Primitives.Text>
              <Primitives.Flex mt={2} justifyContent="space-between">
                <UploadedFileButton value={rateContract} />
                <Primitives.Flex alignItems="center">
                  <Cta
                    color="inherit"
                    label={
                      <CtaForDownload
                        color={colors.primary[3]}
                        hover={{
                          backgroundColor: "white",
                          color: colors.primary[3]
                        }}
                        label="Download Rate Contract"
                      />
                    }
                    link={downloadUrl}
                    target="_blank"
                    textdecorationline={false}
                    type="anchor"
                  />
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={4}>
              <Primitives.Text fontSize={3}>
                Supporting Documents
              </Primitives.Text>
              {supportingDocs.length ? (
                supportingDocs.map((item, index) => (
                  <Primitives.Flex
                    key={index}
                    justifyContent="space-between"
                    mt={2}
                  >
                    <UploadedFileButton value={item["name"]} />
                    <Primitives.Flex alignItems="center" pr={4}>
                      <Cta
                        color="inherit"
                        label={
                          <CtaForDownload
                            color={colors.text[1]}
                            hover={{
                              backgroundColor: "white",
                              color: colors.text[1]
                            }}
                            label="Download"
                          />
                        }
                        link={item["png"]}
                        target="_blank"
                        textdecorationline={false}
                        type="anchor"
                      />
                    </Primitives.Flex>
                  </Primitives.Flex>
                ))
              ) : (
                <Primitives.Flex mt={2}>
                  <Primitives.Text>No supporting documents</Primitives.Text>
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        }
        open={true}
        pb={0}
        pt={4}
        px={4}
        width={580}
      />
    </>
  );
};

downloadModal.propTypes = propTypes;
export default downloadModal;
