import "regenerator-runtime/runtime";

const logoutUser = async BASE_URL => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append(
    "Cookie",
    "_aknamed_admin_session=KNT91jPIl2iY0q52%2BbgDAdmTo9SEP3CP92q3Sh3iM1r7A%2F6VcESDgdk5IFBfSbjuzg%3D%3D--kK9wtn8Cf%2FFV0BVy--RLvNl2gaR7OL9A6dvntJ9g%3D%3D"
  );
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const response = await fetch(
      `${BASE_URL.replace("/smartbuy", "")}/api/logout`,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export default logoutUser;
