import React, { useState } from "react";
import { colors } from "../../../../components/utils/theme";
import Modal from "../../../../components/modal";
import Primitives from "../../../../components/primitives";
import UploadRateContract from "./upload-rate-contract";
import SupportingDocuments from "./supporting-documents";
import Checked from "../../../../components/glyphs/elements/checked";
import { func } from "prop-types";
import RateContractServices from "../../../../services/product-masters";

const propTypes = {
  toggleUploadModal: func
};

const UploadNewModal = ({ toggleUploadModal }) => {
  const [docTypeId, setDocTypeId] = useState(1);
  const [docFormSubmit, toggleDocFormSubmit] = useState(true);
  const [listOfDocuments, setListOfDocuments] = useState([]);
  const [arrayOfSupportDocs, setArrayOfSupportDocs] = useState([]);

  const handleSubmit = async () => {
    const newListOfDocuments = [...listOfDocuments, ...arrayOfSupportDocs];

    var formdata = new FormData();
    formdata.append(
      "rc_file",
      newListOfDocuments[0]["file"],
      newListOfDocuments[0]["value"]
    );

    arrayOfSupportDocs &&
      arrayOfSupportDocs.map((item, index) => {
        formdata.append("file" + index.toString(), item["file"], item["value"]);
      });
    const res = await RateContractServices.uploadRc(formdata);
    if (res && res.status === "success") {
      toggleDocFormSubmit(!docFormSubmit);
    }
  };

  const handleDone = () => {
    window.location.reload();
    toggleDocFormSubmit(!docFormSubmit);
    setListOfDocuments([]);
    setArrayOfSupportDocs([]);
    setDocTypeId(1);
    toggleUploadModal(false);
  };
  return (
    <>
      <Modal
        borderRadius={8}
        closeHandler={() => toggleUploadModal(false)}
        content={
          <Primitives.Flex flex={1}>
            {docFormSubmit ? (
              <Primitives.Flex flexDirection="column">
                <Primitives.Text fontSize={5} fontWeight={2}>
                  Steps to upload rate contract
                </Primitives.Text>
                <Primitives.Text color={colors.text[1]} mt={0}>
                  Complete these steps to upload it
                </Primitives.Text>
                <Primitives.Flex mt={4}>
                  <Primitives.Flex flexDirection="column" width={300}>
                    <Primitives.Flex
                      alignItems="center"
                      backgroundColor={
                        docTypeId === 1 ? "background-blue" : "white"
                      }
                      padding={2}
                    >
                      <Primitives.Flex
                        alignItems="center"
                        backgroundColor="white"
                        borderRadius={50}
                        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
                        color={colors.primary[3]}
                        fontWeight={2}
                        height={29}
                        justifyContent="center"
                        width={29}
                      >
                        1
                      </Primitives.Flex>
                      <Primitives.Text ml={2}>
                        Upload Rate contract
                      </Primitives.Text>
                      {docTypeId === 2 && (
                        <Primitives.Flex ml={4}>
                          <Checked fill={colors.success[0]} />
                        </Primitives.Flex>
                      )}
                    </Primitives.Flex>
                    <Primitives.Flex
                      alignItems="center"
                      backgroundColor={
                        docTypeId === 2 ? "background-blue" : "white"
                      }
                      opacity={docTypeId === 2 ? 1 : 0.5}
                      padding={2}
                    >
                      <Primitives.Flex
                        alignItems="center"
                        backgroundColor="white"
                        borderRadius={50}
                        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
                        color={colors.primary[3]}
                        fontWeight={2}
                        height={29}
                        justifyContent="center"
                        width={29}
                      >
                        2
                      </Primitives.Flex>
                      <Primitives.Text ml={2}>
                        Supporting Documents
                      </Primitives.Text>
                    </Primitives.Flex>
                  </Primitives.Flex>
                  {docTypeId === 1 && (
                    <UploadRateContract
                      listOfDocuments={listOfDocuments}
                      setListOfDocuments={setListOfDocuments}
                      setDocTypeId={setDocTypeId}
                    />
                  )}
                  {docTypeId === 2 && (
                    <SupportingDocuments
                      arrayOfSupportDocs={arrayOfSupportDocs}
                      setArrayOfSupportDocs={setArrayOfSupportDocs}
                    />
                  )}
                </Primitives.Flex>
                {docTypeId === 2 && (
                  <Primitives.Flex
                    alignItems="center"
                    backgroundColor={colors.primary[3]}
                    borderRadius={4}
                    color="white"
                    cursor="pointer"
                    fontSize={1}
                    fontWeight={2}
                    height={36}
                    justifyContent="center"
                    mt={2}
                    ml="auto"
                    onClick={handleSubmit}
                    width={128}
                  >
                    Submit
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            ) : (
              <Primitives.Flex flexDirection="column" flex={1}>
                <Primitives.Flex>
                  <Checked fill={colors.success[0]} />
                  <Primitives.Flex flexDirection="column" ml={2}>
                    <Primitives.Text fontSize={5} fontWeight={2}>
                      Great! Your rate contract has been uploaded
                    </Primitives.Text>
                    <Primitives.Text color={colors.text[1]} fontSize={3} mt={1}>
                      Press the button below to complete the process
                    </Primitives.Text>
                  </Primitives.Flex>
                </Primitives.Flex>
                <Primitives.Flex
                  alignItems="center"
                  backgroundColor={colors.primary[3]}
                  borderRadius={4}
                  color="white"
                  cursor="pointer"
                  fontSize={1}
                  fontWeight={2}
                  height={36}
                  justifyContent="center"
                  mt={2}
                  ml="auto"
                  onClick={handleDone}
                  width={128}
                >
                  Done
                </Primitives.Flex>
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        }
        open={true}
        pb={1}
        pt={4}
        px={4}
        width={682}
      />
    </>
  );
};
UploadNewModal.propTypes = propTypes;
export default UploadNewModal;
