import React, { useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import TruckWithBackground from "../../../components/glyphs/elements/truck-with-background";
import Closed from "../../../components/glyphs/elements/closed";
import Tick from "../../../components/glyphs/elements/tick";
import Cube from "../../../components/glyphs/elements/cube";
import { string, func } from "prop-types";
import { colors } from "../../../components/utils/theme";
import useApiRequest from "../../../hooks/useApiRequest";
import { BASE_URL } from "../../../services/config";
import Loading from "../../../components/loading";
import TrackItem from "./track-item";

const propTypes = {
  search: string,
  status: string,
  id: string,
  setDropdownData: func,
  setTotalCount: func
};

const capitalize = sentence =>
  sentence &&
  (sentence.charAt(0).toUpperCase() + sentence.slice(1)).split("_").join(" ");

const icons = {
  order_invoiced: <Cube />,
  order_confirmed: <Cube />,
  order_dispatched: <TruckWithBackground />,
  short_closed: <Closed />,
  order_delivered: <Tick />,
  order_pending: <Cube />
};
const modifyData = (data, search = "", status = "") =>
  data &&
  data.filter(
    item =>
      item.product_name
        .toLocaleUpperCase()
        .includes(search.toLocaleUpperCase()) && item.status.includes(status)
  );

const dateStatus = (orderStatus, date) => {
  const status = {
    order_confirmed: `Being Packed`,
    order_invoiced: `Will be delivered in 24 hours`,
    order_dispatched: `Will be delivered in 24 hours`,
    order_delivered: `Delivered on ${date}`
  };
  return status[orderStatus];
};
const StatusCard = ({ search, status, id, setDropdownData, setTotalCount }) => {
  const [filteredData, setFilteredData] = useState(null);
  const { data: response } = useApiRequest(
    `${BASE_URL}/orders/${id}/order_items`
  );
  useEffect(() => {
    setDropdownData(response.item_status);
    setTotalCount(response.total_count);
    const modifiedDate = modifyData(response.data, search, status);
    setFilteredData(modifiedDate);
  }, [search, status, response, setDropdownData, setTotalCount]);

  return (
    <Primitives.Flex flex={1} flexDirection="column">
      {filteredData ? (
        filteredData.map((item, index) => (
          <Primitives.Flex
            key={index}
            backgroundColor="#FFFFFF"
            my={1}
            mx={0}
            px={1}
            flex={1}
            flexDirection="column"
          >
            <Primitives.Flex
              flex={1}
              flexDirection="row"
              px={1}
              py={2}
              justifyContent="space-between"
            >
              <Primitives.Flex>
                {icons[item.status]}
                <Primitives.Text
                  alignSelf="center"
                  fontSize={1}
                  fontWeight={3}
                  px={2}
                >
                  {capitalize(item?.status)}
                </Primitives.Text>
              </Primitives.Flex>
              {item?.pod ? (
                <Primitives.Flex
                  fontSize={1}
                  flex={0.3}
                  pr={1}
                  textAlign="center"
                  color="#5C6FCE"
                  cursor="pointer"
                  onClick={() => {
                    window.open(item.pod, "_blank");
                  }}
                >
                  View POD
                </Primitives.Flex>
              ) : (
                <Primitives.Text alignSelf="center" fontSize={1} pr={2}>
                  {item.invoice_number}
                </Primitives.Text>
              )}
            </Primitives.Flex>
            <Primitives.Flex flex={1} flexDirection="column" px={1}>
              <Primitives.Text fontSize={1} color={colors["dusty-grey"]} pb={1}>
                {item.action}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flex={1} flexDirection="column" px={1} py={1}>
              <Primitives.Flex flex={1} justifyContent="space-between">
                <Primitives.Flex flex={0.8} flexDirection="column">
                  <Primitives.Text fontSize={1} fontWeight={3} pr={1}>
                    {item.product_name}
                  </Primitives.Text>
                  <Primitives.Flex flex={1} flexDirection="row" pt={1}>
                    <Primitives.Text fontSize={1} color={colors["dusty-grey"]}>
                      PO Quantity: {item.po_quantity}
                    </Primitives.Text>
                    {item.quantity.name && item.quantity.value ? (
                      <Primitives.Text
                        fontSize={1}
                        color={colors["dusty-grey"]}
                        px={2}
                      >
                        {capitalize(item.quantity.name)}: {item.quantity.value}
                      </Primitives.Text>
                    ) : (
                      ""
                    )}
                  </Primitives.Flex>
                </Primitives.Flex>
                {item.invoice_id && (
                  <Primitives.Flex
                    fontSize={1}
                    flex={0.2}
                    color="#5C6FCE"
                    cursor="pointer"
                  >
                    <TrackItem
                      soItemid={item.id}
                      salesInvoiceId={item.invoice_id}
                    />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </Primitives.Flex>
            {item.reason && (
              <Primitives.Text
                mx={1}
                my={2}
                textAlign="start"
                fontSize={1}
                color="#F34040"
              >
                {item.reason}
              </Primitives.Text>
            )}
            {item.expected_delivery_date && (
              <Primitives.Text
                mx={2}
                my={1}
                p={2}
                textAlign="center"
                fontSize={1}
                fontWeight={2}
                backgroundColor="#F3FFF8"
                color="#27AE60"
              >
                {dateStatus(item.status, item.expected_delivery_date)}
              </Primitives.Text>
            )}
          </Primitives.Flex>
        ))
      ) : (
        <Loading onlySpinner={true} />
      )}
    </Primitives.Flex>
  );
};

StatusCard.propTypes = propTypes;
export default StatusCard;
