import React from "react";
import Primitives from "../../../../components/primitives";
import { string } from "prop-types";
import { colors } from "../../../../components/utils/theme";
import UploadedFile from "../../../../components/glyphs/elements/uploadedFile";

const propTypes = {
  value: string
};

const UploadedFileTag = ({ value }) => {
  return (
    <>
      <Primitives.Flex
        alignItems="center"
        backgroundColor="light-blue"
        border={`1px dashed ${colors.text[3]}`}
        borderRadius={3}
        height={36}
        justifyContent="center"
        mb={2}
        px={1}
      >
        <UploadedFile height={13} stroke={colors.text[1]} width={10} />
        <Primitives.Text fontSize={1} ml={1}>
          {value}
        </Primitives.Text>
      </Primitives.Flex>
    </>
  );
};

UploadedFileTag.propTypes = propTypes;
export default UploadedFileTag;
