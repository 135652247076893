import React from "react";

const FormularyTag = ({ ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FFECE6" />
    <path
      d="M8.164 15V9.96L7.63 9.876C7.514 9.852 7.42 9.812 7.348 9.756C7.28 9.696 7.246 9.612 7.246 9.504V8.898H8.164V8.442C8.164 8.09 8.216 7.774 8.32 7.494C8.428 7.214 8.58 6.976 8.776 6.78C8.976 6.584 9.218 6.434 9.502 6.33C9.786 6.226 10.106 6.174 10.462 6.174C10.746 6.174 11.01 6.212 11.254 6.288L11.224 7.032C11.22 7.088 11.204 7.134 11.176 7.17C11.148 7.202 11.11 7.228 11.062 7.248C11.018 7.264 10.966 7.276 10.906 7.284C10.846 7.288 10.782 7.29 10.714 7.29C10.538 7.29 10.38 7.31 10.24 7.35C10.104 7.386 9.988 7.452 9.892 7.548C9.796 7.64 9.722 7.764 9.67 7.92C9.622 8.072 9.598 8.262 9.598 8.49V8.898H11.2V9.954H9.646V15H8.164Z"
      fill="#F37041"
    />
  </svg>
);

export default FormularyTag;
