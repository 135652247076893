import React from "react";
import { string } from "prop-types";
import Primitives from "../../../components/primitives";
import SectionHeader from "../section-header";
import SummaryBox from "./summary-box";
import getMonthName from "../../../components/utils/get-month-name";

const propTypes = {
  header: string,
  dateRangeStart: string,
  dateRangeEnd: string
};
const rcAdherenceColumn = [
  {
    heading: "Month",
    type: "string",
    width: 1.5,
    align: "flex-start"
  },
  {
    heading: "With RC",
    type: "number",
    width: 1,
    isCurrency: true,
    align: "flex-end"
  },
  {
    heading: "Without RC",
    type: "object",
    width: 1.5,
    isCurrency: true,
    align: "flex-end"
  },
  {
    heading: "Without RC Share",
    type: "string",
    width: 1,
    isPercentage: true,
    align: "flex-end"
  }
];
const totalPurchaseColumn = [
  {
    heading: "Month",
    type: "string",
    width: 1,
    isMonth: true,
    align: "flex-start"
  },
  {
    heading: "Value Purchased",
    type: "number",
    isCurrency: true,
    align: "flex-end",
    width: 1
  }
];
const purchaseReturnsColumn = [
  { heading: "Month", type: "string", width: 1, align: "flex-start" },
  {
    heading: "Value Returned",
    type: "number",
    width: 0.5,
    isCurrency: true,
    align: "flex-end"
  },
  {
    heading: "Qty Returned",
    type: "number",
    width: 0.5,
    isCurrency: false,
    align: "flex-end"
  }
];

const productOrderFrequencyColumn = [
  { heading: "Month", type: "string", width: 1, align: "flex-start" },
  { heading: "Ordered 1-2 Times", type: string, width: 0.5, align: "flex-end" },
  { heading: "Ordered 3-4 Times", type: string, width: 0.5, align: "flex-end" },
  { heading: "Ordered 5+ Times", type: string, width: 0.5, align: "flex-end" }
];

const ProcurementSummary = flex => {
  const summaryBoxArray = [
    {
      apiKeys: ["curr_mon_purchase_details", "purchase_value_trend"],
      header: "Total Purchase",
      request: "total_purchase_value",
      viewTrendColumn: totalPurchaseColumn,
      flex: 1
    },
    {
      apiKeys: ["curr_mon_rc_details", "rc_adherence_trend"],
      header: "RC Adherence",
      request: "rc_adherence",
      viewTrendColumn: rcAdherenceColumn,
      flex: 1
    },
    {
      apiKeys: ["curr_mon_purchase_return_details", "purchase_return_trend"],
      header: "Purchase Returns",
      request: "purchase_return",
      viewTrendColumn: purchaseReturnsColumn,
      flex: 1
    },
    {
      apiKeys: ["curr_month_po_freq_stats", "po_freq_trend"],
      header: "Product Order Frequency",
      request: "po_frequency",
      viewTrendColumn: productOrderFrequencyColumn,
      flex: 1.3
    }
  ];

  return (
    <Primitives.Flex flex={flex} flexDirection="column" mt={2}>
      <SectionHeader
        text={`Procurement Summary for ${getMonthName(new Date().getMonth())}`}
      />
      <Primitives.Flex
        background="white"
        pl={3}
        py="20px"
        borderRadius="10px"
        boxShadow="rgb(0 0 0 / 10%) 0px 0px 4px"
      >
        {summaryBoxArray.map((item, index) => (
          <SummaryBox
            apiKeys={item.apiKeys}
            flex={item.flex}
            header={item.header}
            key={index}
            request={item.request}
            viewTrendColumn={item.viewTrendColumn}
            index={index}
          />
        ))}
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
ProcurementSummary.propTypes = propTypes;
export default ProcurementSummary;
