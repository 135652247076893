import React, { useContext, useState } from "react";
import { colors } from "../../../../components/utils/theme";
import { Link, useLocation, useParams } from "react-router-dom";
import Back from "../../../../components/glyphs/elements/back";
import DownloadBtn from "../../../../lib/download";
import Filter from "../../../../lib/filters";
import Primitives from "../../../../components/primitives";
import Table from "../../../../components/table-two";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import UpwardArrow from "../../../../components/glyphs/elements/upwardArrow";
import DownwardArrow from "../../../../components/glyphs/elements/downwardArrow";
import NoResults from "../../../../components/no-results";
import Loading from "../../../../components/loading";
import Navigation from "../../../../lib/navigation";
import downloadHandlerFn from "../../../../components/utils/download-handler";

const rupee = [
  "mrp",
  "rate",
  "curr_mon_margin_value",
  "purchase_val",
  "order_val",
  "last_mon_margin_perc"
];

const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);
const arrow = {
  low: <DownwardArrow fill="red" />,
  high: <UpwardArrow fill={colors["background-green"]} />
};

const modifyData = data => {
  return data.map(item => {
    let obj = { ...item };
    for (var i in obj) {
      obj[i] = rupee.indexOf(i) > -1 ? `₹ ${item[i]}` : item[i];
    }
    obj["last_mon_margin_perc"] = `${item["last_mon_margin_perc"]} %`;
    obj.curr_mon_margin_perc = (
      <Primitives.Flex>
        <Primitives.Flex alignSelf="flex-start">
          {item.margin_diff !== 0 && (
            <Primitives.Flex flex={1} alignItems="center">
              <Primitives.Text
                color={
                  item.position === "low" ? "red" : colors["background-green"]
                }
                pr="3px"
              >
                {item.margin_diff}
              </Primitives.Text>
              {arrow[item.position]}
            </Primitives.Flex>
          )}
        </Primitives.Flex>

        <Primitives.Text ml={1} minWidth="42px">
          {item.curr_mon_margin_perc}%
        </Primitives.Text>
      </Primitives.Flex>
    );
    delete obj["position"];
    delete obj["company_id"];
    delete obj["margin_diff"];
    return obj;
  });
};

const column = [
  { heading: "Product Name", type: "string" },
  { heading: "MRP Value", type: "number", align: "flex-end" },
  { heading: "Purchase Value", type: "number", align: "flex-end" },
  { heading: "Margin Value", type: "number", align: "flex-end" },
  { heading: "Margin%", type: "object", align: "flex-end" },
  { heading: "Last Month Margin %", type: "number", align: "flex-end" }
];

const PurchaseMarginDetail = () => {
  let location = useLocation();
  const id = useParams().id;
  const company = useParams().companyId;
  const date = location.state && location.state.date;
  const hospital = location.state && location.state.hospital;
  const [query, updateQuery] = useState(`${date}&${hospital}`);
  const { BASE_URL } = useContext(AppContext);
  const path = `${BASE_URL}/reports/margin_company?category_id=${id}&company_id=${company}`;
  const { data, isLoaded } = useApiRequest(`${path}&${query}`);
  const [isDownload, setIsDownload] = useState(false);
  const filterUpdate = queryObj => {
    updateQuery(
      `${queryObj.entity}&${(queryObj.months &&
        queryObj.months.split(",")[0]) ||
        ""}`
    );
  };

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 5]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          py={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex mr={2}>
              <Link
                exact="true"
                to={`/hospital/purchase_margin_category/${id}`}
              >
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Flex color={colors.text[0]} fontSize={5} fontWeight={2}>
              <Primitives.Text>{data.company_name}</Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Box mt={2} width={1 / 2}>
            <Filter
              config={{ entity: true, months: true }}
              filterUpdate={filterUpdate}
            />
          </Primitives.Box>
          <Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>MRP Value</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.actual_order_value &&
                    `₹${Math.round(data.actual_order_value)}`}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>Purchase Value(Rate)</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.purchase_value && `₹${Math.round(data.purchase_value)}`}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>Margin Value</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.margin_value && `₹${Math.round(data.margin_value)}`}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>Avg Margin %</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>{data.avg_margin_perc}%</Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
        {isLoaded ? (
          <Primitives.Flex px={5} flexDirection="column">
            <Primitives.Flex alignItems="center" mb={20} mt={3}>
              {data.products && data.products.length > 0 && (
                <Primitives.Flex fontSize={1} color={colors.text[1]}>
                  <Primitives.Text>
                    Showing 1-{data.products.length} of {data.products.length}{" "}
                    Products
                  </Primitives.Text>
                </Primitives.Flex>
              )}
              <Primitives.Flex flex={1} justifyContent="flex-end">
                {data.products && data.products.length > 0 && (
                  <Primitives.Flex flex={1} justifyContent="flex-end">
                    {isDownload ? (
                      <Loading onlySpinner />
                    ) : (
                      <DownloadBtn
                        clickHandler={() => {
                          downloadHandler(
                            setIsDownload,
                            `${data.download_link}&category_id=${id}&company_id=${company}&${query}`
                          );
                        }}
                      />
                    )}
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex mb={5}>
              {data.products && data.products.length > 0 ? (
                <Table
                  backgroundColor={{ head: colors.accent[1] }}
                  border={`1px solid ${colors.text[3]}`}
                  color={{ head: colors.text[0], row: colors.text[0] }}
                  data={modifyData(data.products)}
                  column={column}
                  pb={{ headContainer: 2, row: 2 }}
                  pt={{ headContainer: 2, row: 2 }}
                  sorting={true}
                />
              ) : (
                <NoResults />
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
      </Primitives.Flex>
    </>
  );
};

export default PurchaseMarginDetail;
