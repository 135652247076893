import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../utils/context";
import Primitives from "../../../../components/primitives";
import Pagination from "../../../../components/pagination";
import Table from "../../../../components/table-two/TableComponent";
import { colors } from "../../../../components/utils/theme";
import { object, string } from "prop-types";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";

const propTypes = {
  /** univerdal hospital ids */
  hospitalEntity: string,
  /** categories */
  category: string,
  /** Filtered date */
  filteredDate: string,
  /** Selected hospital details */
  hospitalDetails: object
};

const modifyData = data =>
  data &&
  data.response &&
  data.response.report.map(item => ({
    PRODUCT_NAME: item.name,
    TOTALPURCHASEVALUE: `₹ ${item.total_amount}`,
    TOTALPURCHASEDQTY: item.total_quantity
  }));

const column = [
  {
    heading: "PRODUCT NAME",
    type: "string"
  },
  {
    heading: "TOTAL PURCHASE VALUE",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "TOTAL PURCHASED QTY",
    type: "number",
    align: "flex-end"
  }
];

const SkuDetail = ({
  hospitalEntity,
  category,
  filteredDate,
  hospitalDetails = {}
}) => {
  const { BASE_URL } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, updateQuery] = useState(`${filteredDate}&page=${currentPage}`);
  const path = `${BASE_URL}/reports/procurement_report_for_company`;
  const { data, isLoaded } = useApiRequest(
    `${path}?company_id=${hospitalDetails.id}${hospitalEntity ||
      ""}${category || ""}&${query}`
  );

  useEffect(() => {
    setCurrentPage(1);
    updateQuery(`${filteredDate}&page=1`);
  }, [filteredDate, hospitalDetails]);

  const pageClickHandler = pageNumber => {
    !isNaN(pageNumber.selected) &&
      updateQuery(`${filteredDate}&page=${pageNumber.selected + 1}`);
    !isNaN(pageNumber.selected) && setCurrentPage(pageNumber.selected + 1);
  };

  return (
    <Primitives.Flex
      backgroundColor="light-blue"
      flexDirection="column"
      flex={1}
      pt={3}
      pl={4}
      overflow="scroll"
    >
      {isLoaded ? (
        <>
          {Object.entries(hospitalDetails).length > 0 && (
            <Primitives.Flex flexDirection="column" mb={3}>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
                mb={1}
              >
                <Primitives.Text>
                  {` ${hospitalDetails.hospital_name} - Unique SKU Purchased`}
                </Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[1]}
                fontSize={1}
                fontWeight={1}
              >
                <Primitives.Text>
                  Showing {hospitalDetails.uniq_skus} SKUS
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
          )}
          <Primitives.Flex pr={2}>
            <Table
              data={modifyData(data)}
              column={column}
              color={{ head: colors.text[1], row: colors.text[0] }}
              page="procurement-summary-detail"
            />
          </Primitives.Flex>
          {hospitalDetails.uniq_skus > 15 && (
            <Pagination
              changeHandler={pageClickHandler}
              forcePage={currentPage - 1}
              marginPagesDisplayed={1}
              pageCount={hospitalDetails.uniq_skus / 15}
              pageRangeDisplayed={5}
            />
          )}
        </>
      ) : (
        <Loading onlySpinner />
      )}
    </Primitives.Flex>
  );
};
SkuDetail.propTypes = propTypes;
export default SkuDetail;
