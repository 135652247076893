import React, { useState } from "react";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import { array, func } from "prop-types";
import ApproveButton from "../approve-button";

const propTypes = {
  getApproveAllData: func,
  inputData: array
};

const InputButton = ({ getApproveAllData, inputData }) => {
  const [productCode, setProductCode] = useState(null);
  return (
    <Primitives.Flex>
      <Primitives.Input
        border={`1px solid ${colors.text[2]}`}
        borderRadius={5}
        height={30}
        onChange={e => {
          setProductCode(e.target.value);
          getApproveAllData([e.target.value, inputData[1]]);
        }}
        onKeyDown={() => {
          productCode && setProductCode(productCode.replace(/\s/g, ""));
        }}
        pl={1}
        value={
          !productCode
            ? inputData[0] == null
              ? ""
              : inputData[0]
            : productCode
        }
      />
      <Primitives.Flex
        position="absolute"
        zIndex={1}
        left="88vw"
        width="max-content"
      >
        <ApproveButton
          approveData={[
            !productCode ? inputData[0] : productCode,
            inputData[1]
          ]}
          isConfirmed={data => {
            if (data) {
              window.location.reload();
            }
          }}
        />
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

InputButton.propTypes = propTypes;
export default InputButton;
