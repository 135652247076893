import React from "react";
import Primitives from "../../../../components/primitives";
import Button from "../../../../components/button";
import Cta from "../../../../components/cta";
import { colors } from "../../../../components/utils/theme";
import { string, object, oneOfType, bool, number } from "prop-types";
const propTypes = {
  link: oneOfType([string, object]),
  leftAligned: bool,
  top: number
};

const ViewDetailsBtn = ({ link, leftAligned, top }) => (
  <Primitives.Flex
    className="displayOnHover"
    ml={2}
    whiteSpace="nowrap"
    display="none"
    position="absolute"
    {...(leftAligned ? { left: 1 } : { right: 1 })}
    alignItems="center"
    top={top || "5px"}
  >
    <Cta
      hover={{ textdecorationline: "none" }}
      label={
        <Button
          active={{
            backgroundColor: colors.primary[3],
            color: "white"
          }}
          backgroundColor={colors.primary[3]}
          borderRadius={4}
          color={"white"}
          fontWeight={2}
          fontSize={0}
          hover={{
            backgroundColor: colors.primary[3],
            color: "white"
          }}
          label="View Details"
          lineHeight="12px"
          px={10}
          py="6px"
        />
      }
      textdecorationline={false}
      type="link"
      link={link}
    />
  </Primitives.Flex>
);
ViewDetailsBtn.propTypes = propTypes;
export default ViewDetailsBtn;
