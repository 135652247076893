import React from "react";
import { number, oneOfType, string } from "prop-types";
import Primitives from "../../../../../components/primitives";
import { colors } from "../../../../../components/utils/theme";
import styled from "styled-components";
import ViewDetails from "../../../../../components/glyphs/elements/view-details";
import { useHistory } from "react-router";

const propTypes = {
  header: string,
  objectKey: string,
  objectKeyIndex: number,
  timeQuery: string,
  value: oneOfType([number, string])
};

export const StatusContainer = styled(Primitives.Box)`
  &:hover {
    background: #e9f2fe;
    border-radius: 10px;
  }
  &:hover path {
    stroke: ${props => (props.value > 0 ? "#317ff7" : "#e9f2fe")};
  }
`;

const statusParam = {
  "Purchase Order": {
    0: "draft",
    1: "completed",
    2: "in_progress",
    3: "short_closed"
  },
  Invoices: {
    0: "draft",
    1: "confirmed",
    2: "dispatched",
    3: "delivered"
  }
};

const StatusBox = ({ header, objectKey, objectKeyIndex, timeQuery, value }) => {
  const history = useHistory();
  const date = new Date();
  const status = statusParam[objectKey][objectKeyIndex];
  const dateToday =
    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  return (
    <StatusContainer value={value}>
      <Primitives.Flex
        onClick={() =>
          value > 0 &&
          history.push(
            `${
              objectKey === "Invoices"
                ? `/hospital/product_wise_invoices/q[invoice_status][]=${status}&q[start_date]=${dateToday}&q[end_date]=${dateToday}&${timeQuery}`
                : `/hospital/filtered_orders/status=${status}&q[order_date_lteq]=${dateToday}&q[order_date_gteq]=${dateToday}&${timeQuery}`
            }`
          )
        }
        cursor={value > 0 && "pointer"}
        flex={1}
        flexDirection="column"
        px={1}
        py={1}
      >
        <Primitives.Text color={colors.text[0]} fontSize="14px">
          {header}
        </Primitives.Text>
        <Primitives.Text
          color="#317FF7"
          fontSize="18px"
          fontWeight={2}
          ml={1}
          mt={1}
        >
          {value} &nbsp; <ViewDetails stroke="white" />
        </Primitives.Text>
      </Primitives.Flex>
    </StatusContainer>
  );
};
StatusBox.propTypes = propTypes;
export default StatusBox;
