import React from "react";
import { bool, func, number, oneOfType, string } from "prop-types";
import Input from "../input/";
import Button from "../button";
import Primitives from "../primitives/index";
import { colors } from "../utils/theme/index";
import Minus from "../glyphs/elements/minus";
import Plus from "../glyphs/elements/plus";
const propTypes = {
  /** If input is active or not */
  active: bool,
  /** Change handler function */
  changeHandler: func,
  /** Decrement handler function */
  decrementHandler: func,
  /** If input or button is disabled or not */
  disabled: bool,
  /** Handler function for focus */
  clickHandler: func,
  /** Increment handler function */
  incrementHandler: func,
  /** Placeholder for input */
  placeholder: string,
  /** If input is readonly or not */
  readOnly: bool,
  /** Input value */
  value: oneOfType([string, number])
};

const AddToOrder = ({
  active,
  changeHandler,
  decrementHandler,
  disabled,
  clickHandler,
  incrementHandler,
  placeholder,
  readOnly,
  value
}) => (
  <Primitives.Flex
    backgroundColor={active && colors.primary[3]}
    borderRadius={4}
    border={"1px solid"}
    borderColor={colors.text[1]}
    justifyContent={"center"}
    lineHeight={2.6}
    width={106}
  >
    {active ? (
      <>
        <Button
          active={{ backgroundColor: colors.primary[3], color: "white" }}
          alignItems="center"
          backgroundColor={colors.primary[3]}
          borderBottomRightRadius={0}
          borderTopRightRadius={0}
          color="white"
          clickHandler={decrementHandler}
          fontWeight={2}
          hover={{ backgroundColor: colors.primary[6], color: "white" }}
          glyph={<Minus fill={colors["background-blue"]} stroke="white" />}
          px={8}
        />

        <Input
          active={active}
          borderColor={active ? colors.primary[3] : colors.text[1]}
          borderRadius={active ? 0 : 4}
          changeHandler={changeHandler}
          isDisabled={disabled}
          lineHeight="32px"
          readOnly={readOnly}
          type="number"
          value={value}
        />

        <Button
          active={{ backgroundColor: colors.primary[3], color: "white" }}
          alignItems="center"
          backgroundColor={colors.primary[3]}
          borderBottomLeftRadius={0}
          borderTopLeftRadius={0}
          clickHandler={incrementHandler}
          color="white"
          fontWeight={2}
          hover={{ backgroundColor: colors.primary[6], color: "white" }}
          glyph={<Plus fill={colors["background-blue"]} />}
          px={1}
        />
      </>
    ) : (
      <Button
        backgroundColor="white"
        color={colors.text[1]}
        fontWeight={2}
        disabled={disabled}
        hover={{
          backgroundColor: colors.text[3],
          color: colors.text[1],
          borderColor: "white"
        }}
        label={placeholder}
        lineHeight="36px"
        px="12px"
        clickHandler={clickHandler}
      />
    )}
  </Primitives.Flex>
);

AddToOrder.propTypes = propTypes;
export default AddToOrder;
