import React, { useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { array, bool, func, object, oneOfType, number } from "prop-types";
import { useHistory } from "react-router";
import { indianNumberFormat } from "../../../components/utils";
import DownloadBtn from "../../../lib/download";
import Loading from "../../../components/loading";
import Frozen from "../../../components/glyphs/elements/frozen";

const propTypes = {
  clickHandler: func,
  currentTab: number,
  data: oneOfType([array, object]),
  disableTab: bool,
  newRequest: func,
  statusTabs: array,
  setCurrentPage: func,
  updateCurrentTab: func
};

const defaultProps = {
  disableTab: false
};

const Tabs = ({
  clickHandler,
  currentTab,
  data,
  disableTab,
  setCurrentPage,
  statusTabs,
  updateCurrentTab
}) => {
  const history = useHistory();
  const [isDownload, setIsDownload] = useState(false);

  return (
    <Primitives.Flex px={30} mt={2}>
      {statusTabs.map((statusTab, index) => (
        <Primitives.Button
          active={{ backgroundColor: colors["light-blue"], color: "inherit" }}
          backgroundColor={colors["light-blue"]}
          borderRight={
            index !== statusTabs.length - 1 &&
            `1px solid ${colors["athens-gray"]}`
          }
          borderBottomLeftRadius={index === 0 && 5}
          borderBottomRightRadius={index === statusTabs.length - 1 && 5}
          borderTopLeftRadius={index === 0 && 5}
          borderTopRightRadius={index === statusTabs.length - 1 && 5}
          cursor={disableTab ? "auto" : "pointer"}
          flexDirection="column"
          hover={{
            backgroundColor: colors["light-blue"],
            borderColor: colors["athens-gray"]
          }}
          px={3}
          pt={2}
          key={index}
          onClick={() => {
            if (!disableTab) {
              setCurrentPage(1);
              updateCurrentTab(index);
            }
          }}
        >
          <Primitives.Flex
            alignItems="center"
            fontSize={1}
            fontWeight={2}
            lineHeight={1}
            pb={2}
          >
            <Primitives.Text
              color={index === currentTab ? colors.text[0] : colors.text[1]}
            >
              {statusTab === "pending"
                ? "In Progress"
                : statusTab[0].toUpperCase() + statusTab.substring(1)}
            </Primitives.Text>
            <Primitives.Text
              backgroundColor={
                index === currentTab ? colors.accent[3] : colors.accent[1]
              }
              borderRadius={7}
              color={index === currentTab ? "white" : colors.accent[3]}
              minWidth="27px"
              minHeight="13px"
              ml={1}
              px={1}
              py="4px"
            >
              {data && data[statusTab] && indianNumberFormat(data[statusTab])}
            </Primitives.Text>
          </Primitives.Flex>
          {index === currentTab && (
            <Primitives.Flex
              backgroundColor={colors.accent[3]}
              borderTopLeftRadius={7}
              borderTopRightRadius={7}
              width={1}
              height={2}
            />
          )}
        </Primitives.Button>
      ))}
      <Primitives.Flex ml="auto" flex={0.7} py={1}>
        <Frozen />
        <Primitives.Text px={1} fontSize={1}>
          Order contains cold storage products
        </Primitives.Text>
      </Primitives.Flex>
      <Primitives.Flex
        cursor="pointer"
        ml={1}
        onClick={() => history.push("/hospital/product_wise_invoices/")}
      >
        <Primitives.Text
          borderRadius={4}
          borderWidth={1}
          borderStyle="solid"
          color={colors.primary[3]}
          fontSize="12px"
          fontWeight={2}
          margin="auto"
          padding="10px"
        >
          View All Invoices
        </Primitives.Text>
      </Primitives.Flex>
      <Primitives.Flex ml={2} my="auto">
        {isDownload ? (
          <Loading onlySpinner />
        ) : (
          <DownloadBtn
            clickHandler={() => clickHandler(setIsDownload)}
            py={1}
          />
        )}
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;
export default Tabs;
