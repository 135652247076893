import React from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { array, func, object, oneOfType, number } from "prop-types";
import { indianNumberFormat } from "../../../components/utils";

const propTypes = {
  currentTab: number,
  data: oneOfType([array, object]),
  newRequest: func,
  statusTabs: array,
  setCurrentPage: func,
  updateCurrentTab: func
};
const Tabs = ({
  currentTab,
  data,
  setCurrentPage,
  statusTabs,
  updateCurrentTab
}) => {
  return (
    <Primitives.Flex
      px={30}
      borderTop={`1px solid ${colors.text[2]}`}
      borderBottom={`1px solid ${colors.text[2]}`}
    >
      {statusTabs &&
        statusTabs.map((statusTab, index) => (
          <Primitives.Flex
            key={statusTab.id}
            flexDirection="column"
            position="relative"
          >
            <Primitives.Button
              backgroundColor={
                index === currentTab ? colors["light-blue"] : "inherit"
              }
              active={{
                backgroundColor: colors["light-blue"]
              }}
              borderRight={
                index !== statusTabs.length - 1 &&
                `1px solid ${colors["athens-gray"]}`
              }
              borderBottomLeftRadius={index === 0 && 5}
              borderBottomRightRadius={index === statusTabs.length - 1 && 5}
              borderTopLeftRadius={index === 0 && 5}
              borderTopRightRadius={index === statusTabs.length - 1 && 5}
              cursor="pointer"
              flexDirection="column"
              hover={{
                backgroundColor: colors["light-blue"],
                borderColor: colors["athens-gray"]
              }}
              px={2}
              pt={2}
              key={index}
              onClick={() => {
                setCurrentPage(1);
                updateCurrentTab(index);
              }}
            >
              <Primitives.Flex
                alignItems="center"
                fontSize={1}
                fontWeight={2}
                lineHeight={1}
                pb={2}
              >
                <Primitives.Text
                  color={index === currentTab ? colors.text[0] : colors.text[1]}
                >
                  {statusTab.label}
                </Primitives.Text>
                <Primitives.Text
                  backgroundColor={
                    index === currentTab ? colors.accent[3] : colors.accent[1]
                  }
                  borderRadius={7}
                  color={index === currentTab ? "white" : colors.accent[3]}
                  minWidth="27px"
                  minHeight="13px"
                  ml={1}
                  py="4px"
                >
                  {data && data[index] && indianNumberFormat(data[index])}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Button>
            {index === currentTab && (
              <Primitives.Flex
                backgroundColor={"#495AC6"}
                borderTopLeftRadius={7}
                borderTopRightRadius={7}
                position="absolute"
                bottom={0}
                width="100%"
                height="5px"
              />
            )}
          </Primitives.Flex>
        ))}
    </Primitives.Flex>
  );
};

Tabs.propTypes = propTypes;
export default Tabs;
