import React from "react";

const CheckedCircleWithBackground = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="15" fill="#EAFFF3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 24C10.0294 24 6 19.9706 6 15C6 10.0294 10.0294 6 15 6C19.9706 6 24 10.0294 24 15C24 19.9706 19.9706 24 15 24ZM15 22.3636C19.0668 22.3636 22.3636 19.0668 22.3636 15C22.3636 10.9332 19.0668 7.63636 15 7.63636C10.9332 7.63636 7.63636 10.9332 7.63636 15C7.63636 19.0668 10.9332 22.3636 15 22.3636ZM17.6941 11.967L13.3636 16.2975L11.4876 14.4215L10.3305 15.5786L13.3636 18.6117L18.8512 13.1241L17.6941 11.967Z"
      fill="#57AD68"
    />
  </svg>
);

export default CheckedCircleWithBackground;
