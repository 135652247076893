import React, { useContext, useEffect, useRef, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { func } from "prop-types";
import Table from "../../../components/table-two/TableComponent";
import Button from "../../../components/button";
import { useHistory } from "react-router-dom";
import Delete from "../../../components/glyphs/elements/delete";
import InputButton from "../input-button";
import Dropdown from "../../../components/dropdown";
import { AppContext } from "../../../utils/context";
import Close from "../../../components/glyphs/elements/close";
import Loading from "../../../components/loading";
import PurchaseOrderService from "../../../services/create-new-purchase-order";
import PoPanel from "../drop-cart/po-panel";
import { indianNumberFormat } from "../../../components/utils";

const propTypes = {
  closeCart: func
};

const column = [
  {
    heading: "Product",
    type: "string",
    width: 2
  },
  {
    heading: "Rate",
    type: "number",
    align: "flex-end",
    width: 1
  },
  {
    heading: "QTY",
    type: "object",
    align: "flex-end",
    width: 1
  },
  {
    heading: "TOTAL",
    type: "string",
    align: "flex-end",
    width: 1.5
  },
  {
    heading: " ",
    type: "object",
    align: "flex-end",
    width: 1
  }
];

const DropCart = ({ closeCart }) => {
  const history = useHistory();
  const localData = localStorage.getItem("hospital");
  const localTenant = localStorage.getItem("distributor");
  const [selectedHospital, setSelectedHospital] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const localSumaryData = localStorage.getItem("summary");
  const localPoNumber = localStorage.getItem("po_number");
  const [summary, setSummary] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [poNumber, setPoNumber] = useState([]);
  const [changeHospital, setChangeHospital] = useState(false);
  const [hospitalCollapse, setHospitalCollapse] = useState(false);
  const [saveDraft, setSaveDraft] = useState(false);
  const [itemsUpdated, setItemsUpdated] = useState(false);
  const { hospitals, BASE_URL } = useContext(AppContext);
  const isNewUser = localStorage.getItem("newUser") === "true";
  const node = useRef();

  useEffect(() => {
    localSumaryData &&
      setSummary(
        JSON.parse(localSumaryData).filter(item => item.quantity !== null)
      );
    let total_amount = 0;
    let total_quantity = 0;
    localSumaryData &&
      JSON.parse(localSumaryData)
        .filter(item => item.quantity !== null)
        .map(item => {
          total_amount +=
            item["quantity"] && item["discount_percentage"] && item["rate"] > 0
              ? item["quantity"] *
                (
                  item["rate"] -
                  (item["discount_percentage"] / 100) * item["rate"]
                ).toFixed(2)
              : item["quantity"]
              ? item["rate"] * item["quantity"]
              : 0;
          total_quantity += item["quantity"] ? item["quantity"] : 0;
        });
    setTotalAmount(total_amount);
    setTotalQuantity(total_quantity);
  }, [localSumaryData]);

  useEffect(() => {
    localData && setSelectedHospital(JSON.parse(localData));
  }, [localData]);

  useEffect(() => {
    localTenant && setSelectedTenant(JSON.parse(localTenant));
  }, [localTenant]);

  useEffect(() => {
    localPoNumber && setPoNumber(JSON.parse(localPoNumber));
  }, [localPoNumber]);

  const removeLineItem = (item, index) => {
    const array = JSON.parse(localSumaryData);
    array.splice(index, 1);
    localStorage.setItem("summary", JSON.stringify(array));
    const newSummaryData = [...array];
    setSummary(newSummaryData);
    setItemsUpdated(true);
  };

  const changeHandler = (item, e) => {
    const updatedValue =
      parseInt(e.target.value) >= 1 && parseInt(e.target.value);
    const newSummaryData = summary.map(object =>
      object.id === item.id
        ? { ...object, qty: updatedValue, quantity: updatedValue }
        : object
    );
    const newTotal =
      totalAmount -
      parseInt(item["quantity"]) * parseInt(item["rate"]) +
      updatedValue * parseInt(item["rate"]);
    const newQuantity =
      totalQuantity - parseInt(item["quantity"]) + updatedValue;
    setTotalQuantity(newQuantity);
    setTotalAmount(newTotal);
    setSummary(newSummaryData);
    localStorage.setItem("summary", JSON.stringify(newSummaryData));
    setItemsUpdated(true);
  };

  const modifyData = data =>
    data.map((item, index) => ({
      Product: item["product_name"],
      Rate:
        item["discount_percentage"] && item["rate"] > 0
          ? `₹ ${(
              item["rate"] -
              (item["discount_percentage"] / 100) * item["rate"]
            ).toFixed(2)}`
          : item["rate"]
          ? `₹ ${item["rate"]}`
          : `₹ 0`,
      QTY: <InputButton inputData={item} changeHandler={changeHandler} />,
      TOTAL: item["quantity"]
        ? `₹${item["quantity"] *
            (
              item["rate"] -
              (item["discount_percentage"]
                ? (item["discount_percentage"] / 100) * item["rate"]
                : 0)
            ).toFixed(2)}`
        : 0,
      "": (
        <Button
          active={{
            color: colors.primary[3]
          }}
          backgroundColor="none!important"
          clickHandler={() => removeLineItem(item, index)}
          color={colors.primary[3]}
          fontWeight={2}
          hover={{
            color: colors.primary[3]
          }}
          label={<Delete />}
          lineHeight={4}
          px={2}
        />
      )
    }));

  const handleCheckout = () => {
    if (summary.length > 0 && poNumber) {
      history.push({
        pathname: `/hospital/purchase_orders/purchase_order_summary/${selectedHospital[1] ||
          0}/${
          localStorage.getItem("po_number")
            ? JSON.parse(localStorage.getItem("po_number"))
                .split("/")
                .join("-")
            : poNumber.split("/").join("-")
        }`
      });
      if (localStorage.getItem("hospital")) {
        setSelectedHospital(JSON.parse(localStorage.getItem("hospital")));
      }

      localStorage.setItem("hospital", JSON.stringify(selectedHospital));
    }
    closeCart();
  };

  const hospitalSelectHandler = option => {
    if (option.id !== selectedHospital[1]) {
      !selectedHospital.includes(option.text)
        ? setSelectedHospital([option.text, option.id])
        : setSelectedHospital(
            selectedHospital.filter(data => data !== option.text),
            option.id
          );
    }
    setHospitalCollapse(false);
  };

  const clearData = () => {
    setSummary([]);
    localStorage.setItem("summary", "[]");
    setItemsUpdated(true);
  };

  const saveAsDraft = async status => {
    setSaveDraft(true);
    const sales_order_items = {};
    summary.map((item, index) => {
      sales_order_items[item["id"]] = summary[index];
      summary[index]["qty"] = summary[index]["quantity"];
      summary[index]["master_sku_id"] = summary[index]["id"];
      delete sales_order_items[item["id"]]["id"];
    });
    const req = `${BASE_URL}/purchase_orders/create_draft`;

    const object = {
      hospital_id: selectedHospital[1],
      tenant_id: selectedTenant[1],
      sales_order: {
        buyer_party_id: selectedHospital[1],
        buyer_party_type: "Hospital",
        po_number: poNumber.split("-").join("/"),
        status: status,
        sales_order_items_attributes: sales_order_items
      }
    };
    const res = await PurchaseOrderService.saveAsDraft(req, object);
    if (res["status"] === "success") {
      history.go(0);
      const token = localStorage.getItem("token");
      localStorage.setItem("token", token);
      setSaveDraft(false);
    }
  };

  return (
    <>
      <Primitives.Flex
        alignItems="flex-end"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        flexDirection="column"
        position="fixed"
        right={0}
        ref={node}
        top={0}
        width="100vw"
        zIndex={5}
      >
        <Primitives.Flex
          height="100vh"
          left={0}
          onClick={() => closeCart(itemsUpdated)}
          position="absolute"
          width="56vw"
        ></Primitives.Flex>
        <Primitives.Box
          background={colors["background-blue"]}
          borderRadius="9px"
          cursor="pointer"
          mr={1}
          onClick={() => closeCart(itemsUpdated)}
          py={11}
          px={2}
          position="absolute"
          right="43vw"
          top="5vh"
        >
          <Close />
        </Primitives.Box>
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          className="add-to-cart"
          flexDirection="column"
          maxHeight="100vh"
          minHeight="100vh"
          width="44vw"
          overflowY="scroll"
        >
          <Primitives.Flex
            borderBottom={`1px solid ${colors["pattens-blue"]}`}
            justifyContent="space-between"
            pt={3}
            px={4}
            pb={3}
          >
            <Primitives.Flex flexDirection="column" flex={1}>
              <PoPanel poNumber={poNumber} />
              {!isNewUser && (
                <Primitives.Flex fontSize="12px" mt="4px">
                  <Primitives.Flex flex={9}>
                    <Primitives.Text color={colors.text[1]} mr="3px">
                      Ordering for
                    </Primitives.Text>
                    {!changeHospital ? (
                      <Primitives.Flex>
                        <Primitives.Text
                          color={colors.text[1]}
                          fontWeight={2}
                          mr="6px"
                        >
                          {selectedHospital && selectedHospital[0]}
                        </Primitives.Text>
                      </Primitives.Flex>
                    ) : (
                      <Primitives.Flex>
                        <Primitives.Flex mr="12px">
                          <Dropdown
                            className="dropdown-filter"
                            collapsed={hospitalCollapse}
                            defaultValue={
                              selectedHospital.length > 0
                                ? selectedHospital[0]
                                : "Select Hospital"
                            }
                            focusHandler={() => {
                              setHospitalCollapse(true);
                            }}
                            id="hospitals"
                            listMaxHeight={195}
                            listWidth={320}
                            name="hospitals"
                            options={
                              hospitals.response &&
                              hospitals.response.map(hospital => {
                                return {
                                  text: hospital.display_name,
                                  id: hospital.id
                                };
                              })
                            }
                            searchable={true}
                            selectHandler={hospitalSelectHandler}
                            selectType="singleSelect"
                          />
                        </Primitives.Flex>
                      </Primitives.Flex>
                    )}
                    <Primitives.Text
                      color={colors["highlight-blue"]}
                      cursor="pointer"
                      fontWeight={2}
                      onClick={() => setChangeHospital(!changeHospital)}
                    >
                      {!changeHospital ? "Change" : "Save Change"}
                    </Primitives.Text>
                  </Primitives.Flex>
                  {summary.length > 0 && !isNewUser && (
                    <Primitives.Flex height="3vh">
                      {!saveDraft ? (
                        <Primitives.Flex flex={3} justifyContent="center">
                          <Primitives.Text
                            color={colors.text[1]}
                            cursor="pointer"
                            fontWeight={2}
                            onClick={() => saveAsDraft("pre_draft")}
                          >
                            Save as draft
                          </Primitives.Text>
                        </Primitives.Flex>
                      ) : (
                        <Loading onlySpinner />
                      )}
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
          {summary.length > 0 ? (
            <Primitives.Flex
              flexDirection="column"
              position="relative"
              pb={5}
              pt={3}
              px={4}
              mb={2}
            >
              <Table
                color={{ head: colors.text[1], row: colors.text[0] }}
                data={modifyData(summary)}
                column={column}
              />
              <Primitives.Text
                color={colors.text[1]}
                cursor="pointer"
                onClick={clearData}
                margin={3}
                textAlign="center"
              >
                Clear All
              </Primitives.Text>
            </Primitives.Flex>
          ) : (
            <Primitives.Flex
              alignItems="center"
              flexDirection="column"
              ml={5}
              py={7}
            >
              <Primitives.Text
                color={colors.text[1]}
                fontSize={3}
                fontWeight={3}
              >
                No items added
              </Primitives.Text>
              <Primitives.Text color={colors.text[2]} fontSize={2} mt={1}>
                Search products to add to the purchase order
              </Primitives.Text>
            </Primitives.Flex>
          )}
          <Primitives.Flex
            alignItems="center"
            background="white"
            bottom={0}
            justifyContent="space-between"
            px={4}
            py={2}
            position="fixed"
            width="40vw"
          >
            <Primitives.Flex color={colors.text[0]}>
              <Primitives.Text>
                Subtotal ({summary && summary.length} items,{" "}
                {indianNumberFormat(totalQuantity)} quantity):
              </Primitives.Text>
              <Primitives.Text fontWeight={2} ml={1}>
                {totalAmount &&
                  "₹" + indianNumberFormat(totalAmount.toFixed(2))}
              </Primitives.Text>
            </Primitives.Flex>
            <Button
              border={`1px solid ${colors.text[3]}`}
              clickHandler={handleCheckout}
              hover={{ backgroundColor: "inherit", color: "inherit" }}
              label={
                <Primitives.Flex
                  alignItems="center"
                  backgroundColor={colors.primary[3]}
                  borderRadius={4}
                  cursor={
                    summary.length && selectedHospital.length && poNumber
                      ? "pointer"
                      : "default"
                  }
                  opacity={
                    summary.length && selectedHospital.length && poNumber
                      ? 1
                      : 0.5
                  }
                  px={3}
                  py={1}
                >
                  <Primitives.Text color="white" fontSize={2} fontWeight={2}>
                    Proceed To Checkout
                  </Primitives.Text>
                </Primitives.Flex>
              }
            />
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

DropCart.propTypes = propTypes;
export default DropCart;
