import React, { useEffect, useState } from "react";
import { func, number, object, oneOfType } from "prop-types";
import Input from "../../../components/input";
import Primitives from "../../../components/primitives";

const propTypes = {
  changeHandler: func,
  inputData: oneOfType([number, object])
};

const InputButton = ({ changeHandler, inputData }) => {
  const [quantity, setQuantity] = useState(inputData["quantity"]);

  useEffect(() => {
    setQuantity(inputData["quantity"]);
  }, [inputData]);

  return (
    <Primitives.Flex height="4vh">
      <Input
        changeHandler={e => {
          if (!/^[1-9][0-9]*$/.test(e.target.value) && e.target.value !== "") {
            setQuantity(1);
            changeHandler(inputData, { target: { value: 1 } });
          } else {
            setQuantity(e.target.value);
            changeHandler(inputData, e);
          }
        }}
        blurHandler={() => {
          if (!quantity) {
            setQuantity(1);
            changeHandler(inputData, { target: { value: 1 } });
          }
        }}
        lineHeight={0}
        textAlign="center"
        pattern="[0-9]*"
        width={30}
        value={quantity}
      />
    </Primitives.Flex>
  );
};

InputButton.propTypes = propTypes;
export default InputButton;
