import React from "react";

const CreditCard = ({ ...props }) => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.81818 0H20.1818C21.186 0 22 0.89543 22 2V14C22 15.1046 21.186 16 20.1818 16H10V14H20V7H2V9H0V2C0 0.89543 0.814028 0 1.81818 0ZM20 2V5H2V2H20ZM0 14H4.58579L3.29289 15.2929L4.70711 16.7071L8.41421 13L4.70711 9.29289L3.29289 10.7071L4.58579 12H0V14Z"
      fill="inherit"
    />
  </svg>
);
export default CreditCard;
