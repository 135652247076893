const ConvertMinutes = num => {
  let d = Math.floor(num / 1440); // 60*24
  let h = Math.floor((num - d * 1440) / 60);
  let m = Math.round(num % 60);

  if (d > 0 && d < 2) {
    return d * 24 + h + " hr, " + m + " min";
  } else if (d >= 2) {
    return d + " days, " + h + " hr, " + m + " min";
  } else if (h > 1) {
    return h + " hr, " + m + " min";
  } else {
    return m + " min";
  }
};
export default ConvertMinutes;
