import React from "react";
import Circle from "../glyphs/elements/circle";
import { bool, func, number, string } from "prop-types";
import Primitives from "../primitives";
import styled from "styled-components";
import { colors } from "../utils/theme";

const propTypes = {
  /** Background Color for toggle */
  backgroundColor: string,
  /** Border around toggle */
  border: string,
  /** Border Radius of toggle */
  borderRadius: number,
  /** Click function */
  clickHandler: func,
  /** Height of toggle button */
  height: number,
  /** Toggle is selected or not */
  selected: bool,
  /** Width of toggle button */
  width: number
};
const defaultProps = {
  border: "1px solid",
  borderRadius: 16,
  width: 55
};
export const ToggleContainer = styled(Primitives.Flex)`
  &:hover {
    background-color: ${props =>
      !props.selected && colors["background-dull-red"]};
    border-color: ${props => !props.selected && colors.primary[0]};
  }
  &:hover svg {
    fill: ${props => !props.selected && colors["dull-orange"]};
  }
  cursor: pointer;
`;
const StyledCircle = styled(Primitives.Flex)`
  transition: 0.1s;
`;
const Toggle = ({ border, borderRadius, clickHandler, selected, width }) => (
  <ToggleContainer
    backgroundColor={selected ? colors.primary[3] : "white"}
    border={border}
    borderColor={selected ? colors.primary[3] : colors.text[3]}
    borderRadius={borderRadius}
    height={24}
    onClick={clickHandler}
    width={width}
    selected={selected}
  >
    <StyledCircle
      left={selected ? width - 24 : 0}
      ml="1px"
      alignSelf="center"
      position="relative"
    >
      <Circle fill={selected ? "white" : colors.text[1]} />
    </StyledCircle>
  </ToggleContainer>
);
Toggle.propTypes = propTypes;
Toggle.defaultProps = defaultProps;
export default Toggle;
