import React, { useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import Button from "../../../../components/button";
import { colors } from "../../../../components/utils/theme";
import ApproveModal from "../approve-modal";
import { array, func } from "prop-types";
import Alert from "../../../../components/alert";
import Error from "../../../../components/glyphs/elements/error";

const propTypes = {
  approveData: array,
  isConfirmed: func
};

const ApproveButton = ({ approveData, isConfirmed }) => {
  const [showAlert, toggleAlert] = useState(false);
  const [showApproveModal, toggleApproveModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      toggleAlert(false);
    }, 1000);
  });

  return (
    <Primitives.Flex>
      <Button
        active={{
          color: colors.primary[3],
          borderColor: "inherit"
        }}
        backgroundColor="none!important"
        clickHandler={() => {
          if (
            approveData &&
            approveData[0] &&
            approveData[0].length > 0 &&
            approveData[0].trim()
          ) {
            toggleApproveModal(true);
          } else {
            toggleAlert(true);
          }
        }}
        color={colors.primary[3]}
        fontWeight={2}
        hover={{
          color: colors.primary[3]
        }}
        label="Approve"
        lineHeight={3}
      />
      {showAlert && (
        <Primitives.Flex
          height={3}
          left="40vw"
          position="fixed"
          top={70}
          zIndex={1}
        >
          <Alert
            backgroundColor="white"
            borderColor={colors.error[1]}
            color={colors.error[1]}
            fontSize={1}
            glyph={<Error fill={colors.error[1]} />}
            label={
              <Primitives.Text alignItems="center" display="inline-flex">
                Please Enter The Product Code
              </Primitives.Text>
            }
            lineHeight={3}
            mx={15}
            px={2}
            py={20}
          />
        </Primitives.Flex>
      )}
      {showApproveModal && (
        <ApproveModal
          approveData={approveData}
          toggleApproveModal={toggleApproveModal}
          isConfirmed={data => {
            isConfirmed(data);
          }}
        />
      )}
    </Primitives.Flex>
  );
};

ApproveButton.propTypes = propTypes;
export default ApproveButton;
