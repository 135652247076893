import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AppContext } from "../../utils/context";
import DisabledRoute from "./disabled-routes";
const ProtectedRoute = () => {
  const { user } = useContext(AppContext);
  const isNewUser = localStorage.getItem("newUser") === "true";

  return (
    <>
      {user["logged_in"] !== undefined && (
        <Route
          render={() =>
            user["success"] ||
            user["logged_in"] ||
            window.location.pathname.includes("/reset_password") ? (
              <Redirect
                to={{
                  pathname: `${
                    window.location.pathname === "/"
                      ? isNewUser
                        ? "hospital/purchase_orders"
                        : "/hospital/orders"
                      : DisabledRoute(user["user"])
                  }`
                }}
              />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )
          }
        />
      )}
    </>
  );
};
export default ProtectedRoute;
