import React, { useState, useContext, useEffect } from "react";
import Modal from "../../../../components/modal";
import Primitives from "../../../../components/primitives";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import { colors } from "../../../../components/utils/theme";
import { bool, func } from "prop-types";
import useApiRequest from "../../../../hooks/useApiRequest";
import Dropdown from "../../../../components/dropdown";
import { AppContext } from "../../../../utils/context";
import styled from "styled-components";
import validation from "./validations";
import UploadDocsModal from "../upload-documents-modal";
import PurchaseOrderService from "../../../../services/create-new-purchase-order";
import imageStorage from "../../../../utils/image-storage";

const propTypes = {
  showModal: bool,
  closeModal: func,
  setData: func,
  setModal: func,
  isValid: bool,
  setIsValid: func,
  setDlFile: func,
  dlFile: bool
};
const ErrorMessage = styled.small`
  color: red;
  margin-top: 4px;
`;

const TotalFiles = styled.small`
  color: green;
  margin-top: 4px;
`;

const commonBtnprops = {
  lineHeight: 3,
  px: 2,
  py: 1
};

const AddDeliveryCenter = ({
  showModal,
  closeModal,
  setData,
  setModal,
  setIsValid
}) => {
  const [deliveryData, setDeliveryData] = useState(
    localStorage.getItem("delivery_data")
      ? JSON.parse(localStorage.getItem("delivery_data"))
      : {
          orgName: "",
          fullName: "",
          phoneNumber: "",
          dl_file: null,
          gstNumber: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          stateId: "",
          cityId: "",
          pincode: ""
        }
  );
  const { BASE_URL } = useContext(AppContext);
  const { data: cityOption } = useApiRequest(
    `${BASE_URL}/universal_cities_states`
  );
  const [citySearch, setCitySearch] = useState("");
  const [cityCollapse, setCityCollapse] = useState(false);
  const [selectedState, setSelectedState] = useState("Select State");
  const [errors, setErrors] = useState({});
  const [supportingDocsModal, setSupportingDocsModal] = useState("none");
  const [totalFiles, setTotalFiles] = useState([]);
  const [autoFillAddress, setAutoFillAddress] = useState(null);

  const inputFields = [
    {
      header: "Organization Name*",
      placeholder: "Organisation name",
      name: "orgName"
    },
    {
      header: "Full Name*",
      placeholder: "Full Name",
      name: "fullName"
    },
    {
      header: "Phone Number*",
      placeholder: "Phone Number",
      name: "phoneNumber"
    },
    {
      header: "Address Line 1*",
      placeholder: "Address Line 1",
      name: "addressLine1"
    },
    {
      header: "Address Line 2",
      placeholder: "Address Line 2",
      name: "addressLine2"
    },
    {
      header: "Pincode*",
      placeholder: "Pincode",
      name: "pincode"
    }
  ];

  useEffect(() => {
    setCitySearch(deliveryData.city);
    setSelectedState(deliveryData.state);
    validation(null, setIsValid);
  }, [deliveryData]);

  const handleChange = data => {
    setDeliveryData({
      ...deliveryData,
      ...data
    });
  };

  const citySelectHandler = option => {
    setCitySearch(option.text);
    setDeliveryData({
      ...deliveryData,
      city: option.text,
      cityId: parseInt(option.id),
      state: option.state,
      stateId: parseInt(option.state_Id || 0)
    });
    setSelectedState(option.state || "--");
    setCityCollapse(false);
  };

  const inputChangeHandler = async (e, field) => {
    setDeliveryData({
      ...deliveryData,
      [field.name]: e.target.value
    });

    if (field.name === "pincode" && e?.target?.value?.length === 6) {
      const res = await PurchaseOrderService.pincodeSearch(e.target.value);
      setAutoFillAddress(res);
    }
  };

  useEffect(() => {
    if (autoFillAddress) {
      setDeliveryData({
        ...deliveryData,
        city: autoFillAddress.city,
        cityId: autoFillAddress.universal_city_id,
        state: autoFillAddress.state,
        stateId: autoFillAddress.universal_state_id
      });
    }
  }, [autoFillAddress]);

  const saveHandler = () => {
    const dlFile = imageStorage.retrieveAndDecodeFile("dl_file");
    const supportingFileNames = JSON.parse(
      localStorage.getItem("supporting_files[]")
    );
    let files = {};
    if (deliveryData["dl_file"] instanceof Object && dlFile) {
      const dlFile = imageStorage.retrieveAndDecodeFile("dl_file");
      const gstFile = imageStorage.retrieveAndDecodeFile("gst_file");
      const supportingFiles =
        supportingFileNames &&
        supportingFileNames.map((_, index) =>
          imageStorage.retrieveAndDecodeFile("supporting_files[]" + index)
        );
      files = {
        ...(dlFile && { dl_file: dlFile }),
        ...(gstFile && { gst_file: gstFile }),
        ...(supportingFiles && { "supporting_files[]": supportingFiles })
      };
    }
    localStorage.setItem("delivery_data", JSON.stringify(deliveryData));
    if (
      errors.file_upload === undefined ||
      (errors.file_upload === "" && validation(setErrors, setIsValid))
    ) {
      setData({ ...deliveryData, ...files });
      setModal(false);
    }
  };

  return (
    <Modal
      width={400}
      px={2}
      py={2}
      justifyContent="flex-end"
      open={showModal}
      closeHandler={closeModal}
      content={
        <Primitives.Flex flexDirection="column" flex={1}>
          <Primitives.Text
            fontSize="20px"
            fontWeight="bold"
            pb={1}
            color="#5B5A5A"
          >
            Add Delivery Center
          </Primitives.Text>
          {inputFields.map((field, index) => (
            <Primitives.Flex key={index} flex={1} flexDirection="column">
              <Primitives.Text color="#1E2B51" fontSize={1} pt={2} pb={1}>
                {field.header}
              </Primitives.Text>
              <Input
                border="1px solid #E8ECEF"
                borderRadius={4}
                p={1}
                lineHeight={4}
                fontSize={1}
                placeholder={field.placeholder}
                value={deliveryData[field.name]}
                changeHandler={e => inputChangeHandler(e, field)}
              />
              {errors[field.name] && (
                <ErrorMessage>{errors[field.name]}</ErrorMessage>
              )}
            </Primitives.Flex>
          ))}
          <Primitives.Flex flexDirection="row">
            <Primitives.Flex flex={1} flexDirection="column" pr={1}>
              <Primitives.Text color="#1E2B51" fontSize={1} pt={2} pb={1}>
                city*
              </Primitives.Text>
              <Dropdown
                className="city-dropdown"
                search={citySearch}
                placeholder="select City"
                changeHandler={e => {
                  setCitySearch(e.target.value);
                }}
                collapsed={cityCollapse}
                focusHandler={() => {
                  setCityCollapse(true);
                }}
                id="city"
                listMaxHeight={195}
                listWidth={250}
                name="city"
                options={
                  (cityOption &&
                    cityOption
                      .map(item => {
                        return {
                          text: item.city,
                          id: item.id,
                          state: item.state,
                          stateId: item.state_Id
                        };
                      })
                      .filter(item => item.text !== null)) || [
                    {
                      text: "",
                      id: "",
                      state: "",
                      stateId: ""
                    }
                  ]
                }
                searchable={true}
                selectHandler={citySelectHandler}
                selectType="singleSelect"
              />
              {errors.cityId && (
                <Primitives.Flex pt={2}>
                  <ErrorMessage>{errors.cityId}</ErrorMessage>
                </Primitives.Flex>
              )}
            </Primitives.Flex>
            <Primitives.Flex flex={1} flexDirection="column">
              <Primitives.Text color="#1E2B51" fontSize={1} pt={2} pb={1}>
                state*
              </Primitives.Text>
              <Input
                border="1px solid #E8ECEF"
                borderRadius={4}
                p={1}
                lineHeight={4}
                fontSize={1}
                placeholder="state"
                value={selectedState}
                isDisabled={true}
              ></Input>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex flex={1} flexDirection="column" pb={4}>
            <Primitives.Text color="#1E2B51" fontSize={1} pt={2} pb={1}>
              Upload Supporting Documents*
            </Primitives.Text>
            <Button
              active={{
                backgroundColor: "white",
                borderColor: colors.primary[3],
                color: colors.primary[3]
              }}
              backgroundColor="white"
              border="1px solid"
              clickHandler={() => setSupportingDocsModal("flex")}
              color={colors.text[0]}
              fontWeight={2}
              hover={{
                backgroundColor: colors.primary[3],
                color: "white"
              }}
              label="Upload Documents"
              width="11vw"
              {...commonBtnprops}
            />

            <Primitives.Flex display={supportingDocsModal}>
              <UploadDocsModal
                toggleUploadModal={val => setSupportingDocsModal(val)}
                getDeliveryData={handleChange}
                getTotalFiles={files => setTotalFiles(files.filter(n => n))}
                setErrors={setErrors}
                setIsValid={setIsValid}
              />
            </Primitives.Flex>

            {errors.file_upload && (
              <ErrorMessage>{errors.file_upload}</ErrorMessage>
            )}
            {totalFiles.length > 0 && (
              <TotalFiles>{totalFiles.length} documents uploaded</TotalFiles>
            )}
          </Primitives.Flex>
          <Button
            active={{
              backgroundColor: colors.primary[6]
            }}
            justifyContent="center"
            backgroundColor={colors.primary[3]}
            color="white"
            hover={{ backgroundColor: colors.primary[6] }}
            label="Save Delivery Center"
            clickHandler={saveHandler}
            {...commonBtnprops}
          />
        </Primitives.Flex>
      }
    ></Modal>
  );
};

AddDeliveryCenter.propTypes = propTypes;

export default AddDeliveryCenter;
