import { BASE_URL, GET_REQ_OPT } from "../../config";

export default {
  orderFulfilmentDetails: async query => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/reports/sku_wise_order_fulfillment_report?${query}`,
        requestOptions
      );
      const json = await response.json();
      return json.response;
    } catch (error) {
      console.log(error);
    }
  },
  purchaseOrderOtif: async query => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/reports/purchase_order_otif_report?${query}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  categoryOrderDeatils: async query => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/reports/sku_wise_otif_report?${query}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};
