import { BASE_URL, GET_REQ_OPT } from "../../config";

export default {
  productWiseInvoiceDetails: async query => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/invoices/items?${query}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};
