import React from "react";
import { array, string } from "prop-types";
import Primitives from "../../../../../components/primitives";
import { colors } from "../../../../../components/utils/theme";
import UpwardArrow from "../../../../../components/glyphs/elements/upwardArrow";
import DownwardArrow from "../../../../../components/glyphs/elements/downwardArrow";
import { indianNumberFormat } from "../../../../../components/utils";

const propTypes = {
  data: array,
  title: string
};

const marginDiff = data => (
  <Primitives.Flex>
    <Primitives.Flex>{data.curr_mon_margin_perc}%</Primitives.Flex>
    {data.margin_diff !== 0 && (
      <Primitives.Flex
        fontSize={2}
        m="auto"
        ml="60px"
        mt="7px"
        pl="22px"
        position="absolute"
        alignItems="center"
      >
        (&nbsp;
        {data.position === "high" && (
          <UpwardArrow fill={colors["background-green"]} />
        )}
        {data.position === "low" && <DownwardArrow fill="red" />}
        <Primitives.Text
          color={data.position === "high" ? colors["background-green"] : "red"}
        >
          &nbsp;{data.margin_diff}%&nbsp;
        </Primitives.Text>
        )&nbsp;
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);

const headerData = data => [
  {
    heading: "Products Invoiced",
    value: indianNumberFormat(Math.round(data.uniq_products))
  },
  {
    heading: "MRP Value ",
    value: "₹" + indianNumberFormat(Math.round(data.mrp))
  },
  {
    heading: "Purchase Value",
    value: "₹" + indianNumberFormat(Math.round(data.value))
  },
  {
    heading: "Margin Value",
    value: "₹" + indianNumberFormat(Math.round(data.margin_value))
  },
  { heading: "Margin %", value: marginDiff(data) },
  { heading: "Last month Margin %", value: data.last_mon_margin_perc + "%" }
];

const headerFn = data =>
  data.map((item, index) => (
    <Primitives.Flex flexDirection="column" key={index} mr={6}>
      <Primitives.Text
        color={colors.text[0]}
        fontSize="24px"
        fontWeight={2}
        textAlign="center"
      >
        {item.value}
      </Primitives.Text>
      <Primitives.Text
        color={colors.text[1]}
        fontSize="13px"
        fontWeight={2}
        mt="4px"
        textAlign="center"
      >
        {item.heading}
      </Primitives.Text>
    </Primitives.Flex>
  ));

const OverallMargins = ({ data, title }) => (
  <>
    <Primitives.Flex flexDirection="column">
      <Primitives.Text
        color={colors.text[0]}
        fontSize={5}
        fontWeight={2}
        mb={4}
      >
        {title}
      </Primitives.Text>
      <Primitives.Flex flexDirection="row">
        {data && data[0] && headerFn(headerData(data[0]))}
      </Primitives.Flex>
    </Primitives.Flex>
  </>
);

OverallMargins.propTypes = propTypes;
export default OverallMargins;
