import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../../../components/utils/theme";
import { Link, useLocation, useParams } from "react-router-dom";
import Back from "../../../../components/glyphs/elements/back";
import DownloadBtn from "../../../../lib/download";
import Primitives from "../../../../components/primitives";
import Table from "../../../../components/table-two";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import UpwardArrow from "../../../../components/glyphs/elements/upwardArrow";
import DownwardArrow from "../../../../components/glyphs/elements/downwardArrow";
import NoResults from "../../../../components/no-results";
import Loading from "../../../../components/loading";
import Filters from "../../../../lib/filters";
import ViewDetailsBtn from "../../lib/view-details-btn";
import Navigation from "../../../../lib/navigation";
import downloadHandlerFn from "../../../../components/utils/download-handler";
import { indianNumberFormat } from "../../../../components/utils";

const rupee = [
  "mrp",
  "rate",
  "curr_mon_margin_value",
  "purchase_val",
  "order_val"
];

const percentage = ["last_mon_margin_perc"];

const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);

const column = [
  { heading: "Company Name", type: "string", sort: false },
  { heading: "Total Unique Products", type: "number", align: "flex-end" },
  { heading: "MRP Value", type: "number", align: "flex-end" },
  { heading: "Purchase Value", type: "number", align: "flex-end" },
  { heading: "Margin Value", type: "number", align: "flex-end" },
  { heading: "Margin %", width: 1.3, type: "object", align: "flex-end" },
  { heading: "Last Month Margin %", type: "number", align: "flex-end" }
];

const PurchaseMarginDetail = () => {
  let location = useLocation();
  const id = useParams().purchaseMarginCategoryId;
  const date = location.state && location.state.date;
  const hospital = location.state && location.state.hospital;
  const [isDownload, setIsDownload] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [hospitalFilter, setHospitalFilter] = useState("");
  const { BASE_URL } = useContext(AppContext);
  const [query, setQuery] = useState();
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/margin_category?category_id=${id}&${date}&${hospital}`
  );
  const { data, isLoaded } = useApiRequest(request);
  const filterUpdate = queryObj => {
    queryObj.months && setDateFilter(queryObj.months.split(",")[0] || "");
    setHospitalFilter(queryObj.entity);
    setQuery(`&company_id=${queryObj.marginCategoryManufacturer || ""}`);
    updateRequest(
      `${BASE_URL}/reports/margin_category?category_id=${id}&${
        queryObj.entity
      }&${(queryObj.months && queryObj.months.split(",")[0]) ||
        ""}&company_id=${queryObj.marginCategoryManufacturer || ""}`
    );
  };

  useEffect(() => {
    location.state && location.state.date && setDateFilter(location.state.date);
    location.state &&
      location.state.hospital &&
      setHospitalFilter(location.state.hospital);
  }, [location]);
  const arrow = {
    low: <DownwardArrow fill="red" />,
    high: <UpwardArrow fill={colors["background-green"]} />
  };
  const modifyData = (data, id) => {
    return data.map(item => {
      let obj = { ...item };
      for (var i in obj) {
        obj[i] =
          rupee.indexOf(i) > -1
            ? `₹${Math.round(item[i])}`
            : percentage.indexOf(i) > -1
            ? `${item[i]}%`
            : item[i];
      }
      obj.curr_mon_margin_perc = (
        <Primitives.Flex>
          <Primitives.Flex alignSelf="flex-start">
            {item.margin_diff !== 0 && (
              <Primitives.Flex flex={1} alignItems="center">
                <Primitives.Text
                  color={
                    item.position === "low" ? "red" : colors["background-green"]
                  }
                  pr="3px"
                >
                  {item.margin_diff}
                </Primitives.Text>
                {arrow[item.position]}
              </Primitives.Flex>
            )}
          </Primitives.Flex>

          <Primitives.Text ml={1} minWidth="42px">
            {item.curr_mon_margin_perc}%
          </Primitives.Text>

          {item.company_id && (
            <Primitives.Flex>
              <ViewDetailsBtn
                top={1}
                leftAligned={true}
                link={{
                  pathname: `/hospital/purchase_margin_company/${id}/${item.company_id}`,
                  state: {
                    date: dateFilter,
                    hospital: hospitalFilter
                  }
                }}
              />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      );
      delete obj["position"];
      delete obj["company_id"];
      delete obj["margin_diff"];
      return obj;
    });
  };
  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 5]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
        mb={5}
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          py={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex mr={2}>
              <Link exact="true" to="/hospital/purchase_margin_report">
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Flex color={colors.text[0]} fontSize={5} fontWeight={2}>
              <Primitives.Text>{data.category_name}</Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Box mt={2}>
            <Filters
              config={{
                entity: true,
                months: true,
                marginCategoryManufacturer: true
              }}
              filterUpdate={filterUpdate}
            />
          </Primitives.Box>
          <Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>MRP Value</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.actual_order_value &&
                    "₹" +
                      indianNumberFormat(Math.round(data.actual_order_value))}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>Purchase Value(Rate)</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.purchase_value &&
                    "₹" + indianNumberFormat(Math.round(data.purchase_value))}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3} mr={5}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>Margin Value</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.margin_value &&
                    "₹" + indianNumberFormat(Math.round(data.margin_value))}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mt={3}>
              <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
                <Primitives.Text>Avg Margin %</Primitives.Text>
              </Primitives.Flex>
              <Primitives.Flex
                color={colors.text[0]}
                fontSize={3}
                fontWeight={2}
              >
                <Primitives.Text>
                  {data.avg_margin_perc &&
                    indianNumberFormat(data.avg_margin_perc) + "%"}
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
        {isLoaded ? (
          <Primitives.Flex px={5} flexDirection="column">
            <Primitives.Flex alignItems="center" mb={20} mt={3}>
              {data.manufacturers && data.manufacturers.length > 0 && (
                <Primitives.Flex fontSize={1} color={colors.text[1]}>
                  <Primitives.Text>
                    Showing 1-{data.manufacturers.length} of{" "}
                    {data.manufacturers.length} Manufacturers
                  </Primitives.Text>
                </Primitives.Flex>
              )}
              <Primitives.Flex flex={1} justifyContent="flex-end">
                {data.manufacturers && data.manufacturers.length > 0 && (
                  <Primitives.Flex flex={1} justifyContent="flex-end">
                    {isDownload ? (
                      <Loading onlySpinner />
                    ) : (
                      <DownloadBtn
                        clickHandler={() => {
                          downloadHandler(
                            setIsDownload,
                            `${BASE_URL}/reports/margin_category?category_id=${id}&${dateFilter ||
                              date}&${query}&is_download=true`
                          );
                        }}
                      />
                    )}
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex>
              {data.manufacturers && data.manufacturers.length > 0 ? (
                <Table
                  backgroundColor={{ head: colors.accent[1] }}
                  border={`1px solid ${colors.text[3]}`}
                  color={{ head: colors.text[0], row: colors.text[0] }}
                  data={modifyData(data.manufacturers, id)}
                  column={column}
                  pb={{ headContainer: 2, row: 2 }}
                  pt={{ headContainer: 2, row: 2 }}
                  sorting={true}
                />
              ) : (
                <NoResults />
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
      </Primitives.Flex>
    </>
  );
};

export default PurchaseMarginDetail;
