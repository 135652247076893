import React, { useState, useContext } from "react";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import { AppContext } from "../../../utils/context";
import { func } from "prop-types";
import EmailComponent from "./email-component";
import OtpComponent from "./otp-component";
import OnboardingComponent from "./onboarding-component";

const propTypes = {
  loadUserData: func
};

const leftComponent = [
  {
    src: "./emailModal.png",
    alt: "email",
    heading: "Partnering for a healthier tomorrow.",
    subheading:
      "Connecting manufacturers and hospitals to bridge the gap and improve access to essential medical supplies.",
    dots: ["#F58158", "#D9D9D9", "#D9D9D9"]
  },
  {
    src: "./smartbuy.gif",
    alt: "smartbuy gif",
    heading: "From our warehouse to your hospital pharmacy.",
    subheading: "Get real-time updates from PO to POD",
    dots: ["#D9D9D9", "#F58158", "#D9D9D9"]
  },
  {
    src: "./smartbuy.gif",
    alt: "smartbuy gif",
    heading: "From our warehouse to your hospital pharmacy.",
    subheading: "Get real-time updates from PO to POD",
    dots: ["#D9D9D9", "#D9D9D9", "#F58158"]
  }
];

const renderLeftComponent = index => (
  <Primitives.Flex
    flex={1}
    alignItems="center"
    flexDirection="column"
    backgroundColor="#F8F8F8"
    justifyContent="center"
  >
    <Primitives.Flex flex={0.4} px={5}>
      <img
        src={require(`${leftComponent[index].src}`)}
        alt={leftComponent[index].alt}
        style={{ maxWidth: "90%", height: "auto" }}
      />
    </Primitives.Flex>
    <Primitives.Text fontSize={5} fontWeight={600} pt={3} textAlign="center">
      {leftComponent[index].heading}
    </Primitives.Text>
    <Primitives.Text
      fontSize={3}
      fontWeight={300}
      pt={1}
      px={5}
      lineHeight={3}
      textAlign="center"
    >
      {leftComponent[index].subheading}
    </Primitives.Text>
    <Primitives.Flex justifyContent="center">
      {leftComponent[index].dots.map((color, index) => (
        <Primitives.Text key={index} style={{ fontSize: "80px" }} color={color}>
          .
        </Primitives.Text>
      ))}
    </Primitives.Flex>
  </Primitives.Flex>
);

const OtpLoginPopups = ({ loadUserData }) => {
  const { BASE_URL } = useContext(AppContext);
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const commonProps = {
    BASE_URL: BASE_URL,
    errors: errors,
    setErrors: setErrors,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    setIndex: setIndex,
    loadUserData: loadUserData
  };

  const renderRightComponent = index => {
    const components = [
      <EmailComponent
        key={index}
        {...commonProps}
        email={email}
        setEmail={setEmail}
      />,
      <OtpComponent
        key={index}
        {...commonProps}
        email={email}
        otp={otp}
        setOtp={setOtp}
      />,
      <OnboardingComponent key={index} {...commonProps} />
    ];

    return components[index];
  };
  return (
    <Modal
      width={1150}
      height={650}
      px={0}
      pb={0}
      pt={0}
      contentHeight={1}
      open={true}
      haveCloseButton={false}
      content={
        <Primitives.Flex flex={1} height="100%" flexDirection="row">
          {renderLeftComponent(index)}
          <Primitives.Flex flex={1}>
            {renderRightComponent(index)}
          </Primitives.Flex>
        </Primitives.Flex>
      }
    ></Modal>
  );
};

OtpLoginPopups.propTypes = propTypes;
export default OtpLoginPopups;
