import React from "react";
import Primitives from "../../../../components/primitives";
import AverageMargin from "../average-margin";
import FulFillmentRate from "../fulfillment-rate";
import PurchaseCategory from "../purchase-category";
import PurchaseTrends from "../purchase-trends";
import { string } from "prop-types";

const propTypes = {
  quickSearch: string,
  hospital: string
};

const Graphs = ({ quickSearch, hospital }) => (
  <Primitives.Flex flexDirection="column">
    <Primitives.Flex pl={3}>
      <PurchaseTrends quickSearch={quickSearch} hospital={hospital} />
      <AverageMargin quickSearch={quickSearch} hospital={hospital} />
    </Primitives.Flex>

    <Primitives.Flex pl={3} mt={5}>
      <PurchaseCategory quickSearch={quickSearch} hospital={hospital} />
      <FulFillmentRate quickSearch={quickSearch} hospital={hospital} />
    </Primitives.Flex>
  </Primitives.Flex>
);

Graphs.propTypes = propTypes;
export default Graphs;
