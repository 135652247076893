/** It renders Order Fulfilment Details Report. Last modified on 27/01/23 for testing */
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../../../../../components/glyphs/elements/back";
import Loading from "../../../../../components/loading";
import NoResults from "../../../../../components/no-results";
import Pagination from "../../../../../components/pagination";
import Primitives from "../../../../../components/primitives";
import Table from "../../../../../components/table-two";
import { colors } from "../../../../../components/utils/theme";
import DownloadBtn from "../../../../../lib/download";
import Filters from "../../../../../lib/filters";
import Navigation from "../../../../../lib/navigation";
import Header from "./header";
import OrderFulfimentService from "../../../../../services/reports/order-fulfilment";
import DisplayAllItems from "../../../lib/display-all-items";
import downloadHandlerFn from "../../../../../components/utils/download-handler";
import { BASE_URL } from "../../../../../services/config";
import paginationHandler from "../../../../../components/utils/pagination-handler";
import { convertDate } from "../../../../../components/utils";
import splitDateRange from "../../../../../utils/split-date-range";
import { FilterContext } from "../../../../../utils/filter-context";
import { HorizontalScroll } from "../../../../../lib/horizontal-scroll";

const downloadHandler = async (setIsDownload, query) =>
  downloadHandlerFn(
    `${BASE_URL}/reports/sku_wise_order_fulfillment_report?${query}&is_download=true`,
    setIsDownload
  );

const cellFn = data => (
  <Primitives.Flex flexDirection="column">
    {data ? <Primitives.Text>{data}</Primitives.Text> : "--"}
  </Primitives.Flex>
);

const modifyData = data =>
  data.map(item => ({
    "Hospital Center": item["hospital_name"],
    "Product Name": item["internal_product_name"],
    "Product Code": item["product_code"],
    "Qty Ordered": item["ordered_quantity"],
    "Invoice Quantity": item["invoice_quantity"],
    "Pending Qty": item["pending_quantity"],
    "% Fulfillment": `${item["qty_delivered_percent"]}%`,
    "Fulfilment Status": item["fulfillment_status"],
    "ETA/Remarks": item.expected_delivery_date
      ? convertDate(item.expected_delivery_date, "/", "")
      : "--",
    "Customer Remarks": cellFn(item["customer_remarks"]),
    "PO Number": item["po_number"],
    "Order Date": item["order_date"],
    "Hospital Product Name": item["hospital_product_name"],
    Manufacturer: item["manufacturer"],
    "SO Number": item["so_number"],
    "SO Creation Date": item["creation_date"],
    "Order Amount": `₹${item["order_amount"]}`,
    "Supplied Amount": `₹${item["supplied_amount"]}`,
    "Pending Amount": `₹${item["pending_amount"]}`,
    "Invoice Number": item.invoice_numbers || "--"
  }));

const column = [
  {
    heading: "Hospital Center",
    type: "string",
    width: 2
  },
  {
    heading: "Product Name",
    type: "string",
    width: 2
  },
  {
    heading: "Product Code",
    type: "string",
    width: 1,
    sort: false
  },
  {
    heading: "Ordered Qty",
    type: "number",
    width: 0.5,
    align: "flex-end",
    sort: false
  },
  {
    heading: "Fulfilled Qty",
    type: "number",
    width: 0.8,
    align: "flex-end",
    sort: false
  },
  {
    heading: "Pending Qty",
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "Fulfillment %",
    type: "number",
    width: 1,
    align: "flex-end",
    sort: false
  },
  {
    heading: "Fulfilment Status",
    type: "string",
    width: 1.5,
    sort: false
  },
  {
    heading: "ETA",
    type: "string",
    width: 1,
    sort: false
  },
  {
    heading: "Customer Remarks",
    type: "string",
    width: 2,
    sort: false
  },
  {
    heading: "PO Number",
    type: "string",
    width: 1.8,
    sort: false
  },

  {
    heading: "Order Date",
    type: "string",
    width: 1
  },
  {
    heading: "Distributor Product Name",
    type: "string",
    width: 2,
    sort: false
  },
  {
    heading: "Manufacturer",
    type: "string",
    width: 2
  },
  {
    heading: "SO Number",
    type: "string",
    width: 1.8,
    sort: false
  },
  {
    heading: "SO Creation Date",
    type: "string",
    width: 1,
    sort: false
  },
  {
    heading: "Ordered Amount",
    type: "number",
    width: 1.2,
    align: "flex-end",
    sort: false
  },
  {
    heading: "Supplied Amount",
    type: "number",
    width: 1.2,
    align: "flex-end",
    sort: false
  },
  {
    heading: "Pending Amount",
    type: "number",
    width: 1.2,
    align: "flex-end",
    sort: false
  },
  {
    heading: "Invoice Number",
    type: "string",
    width: 1.5,
    sort: false
  }
];

const fulfilmentStatusObj = {
  0: "Pending Confirmation",
  1: "Order Confirmed",
  2: "Partially Invoiced",
  3: "Invoiced",
  4: "Partially Dispatched",
  5: "Dispatched",
  6: "Partially Delivered",
  7: "Delivered",
  8: "Short Closed"
};

const OrderFulfilmentDetail = () => {
  const params = useParams();
  const [startDate, setStartDate] = useState(params.startDate);
  const [endDate, setEndDate] = useState(params.endDate);
  const [data, setData] = useState([]);
  const [manufacturerData, setManufacturerdata] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const filterContext = useContext(FilterContext);
  const hospitalEntity = filterContext?.filterState?.hospitalEntity ?? "";
  const [query, setQuery] = useState(
    `&start_date=${startDate}&end_date=${endDate}${
      hospitalEntity ? `&universal_hospital_ids=${hospitalEntity}` : ""
    }`
  );
  const startDateStringFormat = new Date(startDate).toDateString().split(" ");
  const endDateStringFormat = new Date(endDate).toDateString().split(" ");
  const [pageNumber, setPageNumber] = useState(1);
  const [sortingType, setSortingType] = useState("desc");
  const [sortingIndex, setSortingIndex] = useState(null);

  const processParams = queryObj => {
    const skuNameSearchParam = queryObj.skuNameSearch
      ? `&q[master_sku_name_cont]=${queryObj.skuNameSearch}`
      : "";
    const fulfilmentManufacturerParam = queryObj.fulfilmentManufacturer
      ? `&q[master_sku_master_company_id_eq]=${queryObj.fulfilmentManufacturer}`
      : "";
    const poNumberSearchParam = queryObj.poNumberSearch
      ? `&q[sales_order_po_number_cont]=${queryObj.poNumberSearch}`
      : "";
    const hospitalEntityParam = queryObj.hospitalEntity
      ? queryObj.hospitalEntity.replace(
          "hospital_entity_id",
          "universal_hospital_ids"
        )
      : "";
    const fulfilmentStatusParam = `&fulfillment_status=${fulfilmentStatusObj[
      queryObj.fulfilmentStatus
    ] || ""}`;
    const invoiceSearchParam = `&q[sales_invoice_items_sales_invoice_invoice_number_cont]=${queryObj.invoiceSearch ||
      ""}`;
    return {
      skuNameSearchParam,
      fulfilmentManufacturerParam,
      poNumberSearchParam,
      hospitalEntityParam,
      fulfilmentStatusParam,
      invoiceSearchParam
    };
  };

  const filterUpdate = queryObj => {
    setSortingIndex(null);
    const dateRange = splitDateRange(queryObj.date);
    const start_date =
      (dateRange.startDate &&
        dateRange.startDate
          .split("-")
          .reverse()
          .join("-")) ||
      params.startDate;
    const end_date =
      (dateRange.endDate &&
        dateRange.endDate
          .split("-")
          .reverse()
          .join("-")) ||
      params.endDate;
    setStartDate(start_date);
    setEndDate(end_date);
    const startDateParam = `start_date=${start_date}`;
    const endDateParam = `&end_date=${end_date}`;
    const sortQuery = sortingIndex
      ? `&q[s]=${index[sortingIndex]} ${sortingType}`
      : "";

    const {
      skuNameSearchParam,
      fulfilmentManufacturerParam,
      poNumberSearchParam,
      hospitalEntityParam,
      fulfilmentStatusParam,
      invoiceSearchParam
    } = processParams(queryObj);

    const queryStr = `${startDateParam}${endDateParam}${skuNameSearchParam}${fulfilmentManufacturerParam}${poNumberSearchParam}${hospitalEntityParam}${fulfilmentStatusParam}${invoiceSearchParam}${sortQuery}`;
    setQuery(queryStr);
  };

  const index = {
    0: "hospital_name",
    1: "internal_product_name",
    5: "pending_quantity",
    11: "order_date",
    13: "manufacturer"
  };

  const headClickHandler = id => {
    id === sortingIndex
      ? setSortingType(sortingType === "asc" ? "desc" : "asc")
      : setSortingType("desc");
    setSortingIndex(id);
  };
  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setPageNumber(page);
  };

  const fetchFulfilmentDetails = async (query, setData) => {
    setData([]);
    try {
      const response = await OrderFulfimentService.orderFulfilmentDetails(
        query
      );
      if (response) {
        setData(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    setData([]);
    fetchFulfilmentDetails(
      `page=${pageNumber}&q[s]=${
        sortingIndex !== null ? `${index[sortingIndex]} ${sortingType}` : ""
      }&${
        query
          ? query
          : `start_date=${startDate}&end_date=${endDate}${
              hospitalEntity ? `&universal_hospital_ids=${hospitalEntity}` : ""
            } `
      }`,
      setData
    );
  }, [pageNumber, sortingIndex, sortingType, query]);

  useEffect(() => {
    fetchFulfilmentDetails(
      `start_date=${startDate}&end_date=${endDate}`,
      setManufacturerdata
    );
  }, [startDate, endDate]);

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 3]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          py={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex
              mr={2}
              onClick={() => {
                filterContext.updateFilterState({
                  ...filterContext.filterState,
                  hospitalEntity: ""
                });
              }}
            >
              <Link exact="true" to="/hospital/fulfillment_reports">
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Flex color={colors.text[0]} fontSize={5} fontWeight={2}>
              <Primitives.Text>
                Order Fulfillment Report for {startDateStringFormat[2]}{" "}
                {startDateStringFormat[1]}
                {startDate !== endDate && (
                  <Primitives.Text>
                    {" "}
                    - {endDateStringFormat[2]} {endDateStringFormat[1]}
                  </Primitives.Text>
                )}
              </Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
          <Header data={data && data.total_fulfilment_data} />
        </Primitives.Flex>
        <Primitives.Box mt={2} pl={2}>
          <Filters
            config={{
              skuNameSearch: true,
              poNumberSearch: true,
              invoiceSearch: true,
              fulfilmentStatus: true,
              fulfilmentManufacturer: true,
              manufacturerData: manufacturerData,
              hospitalEntity: true,
              date: { buffer: 0 },
              defaultDateRange: {
                startDate: startDate
                  .split("-")
                  .reverse()
                  .join("/"),
                endDate: endDate
                  .split("-")
                  .reverse()
                  .join("/")
              }
            }}
            filterUpdate={filterUpdate}
            flexWrap="wrap"
          />
        </Primitives.Box>
        {data && data.skus_fulfillment_data && (
          <Primitives.Flex alignItems="center" mb={20} mt={3} px={2}>
            {data && data.total_item_count > 0 && (
              <Primitives.Flex fontSize={1} color={colors.text[1]}>
                <DisplayAllItems
                  currentPage={pageNumber}
                  itemsPerPage={20}
                  totalItems={data.total_item_count}
                  title="Orders"
                />
              </Primitives.Flex>
            )}
            <Primitives.Flex flex={1} justifyContent="flex-end" pr={60}>
              {data && data.skus_fulfillment_data.length > 0 && (
                <Primitives.Flex flex={1} justifyContent="flex-end">
                  {isDownload ? (
                    <Loading onlySpinner />
                  ) : (
                    <DownloadBtn
                      clickHandler={() => {
                        downloadHandler(setIsDownload, query);
                      }}
                    />
                  )}
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        )}
        {data && data.skus_fulfillment_data ? (
          <Primitives.Flex
            flexDirection="column"
            overflow="scroll"
            mb={6}
            px={2}
          >
            <HorizontalScroll>
              {data && data.skus_fulfillment_data.length > 0 ? (
                <Primitives.Flex width="170vw" pr={2}>
                  <Table
                    color={{ head: colors.text[1], row: colors.text[0] }}
                    data={modifyData(data.skus_fulfillment_data)}
                    border={`1px solid ${colors.text[3]}`}
                    column={column}
                    pb={{ headContainer: 2, row: 2 }}
                    pt={{ headContainer: 2, row: 2 }}
                    headClickHandler={headClickHandler}
                    sorting={true}
                    sortingType={sortingType}
                    sortingIndex={sortingIndex}
                  />
                </Primitives.Flex>
              ) : (
                <Primitives.Flex m="auto" mt={5} justifyContent="center">
                  <NoResults />
                </Primitives.Flex>
              )}
            </HorizontalScroll>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
        {data && data.total_item_count > 20 && (
          <Primitives.Flex
            backgroundColor={colors.accent[0]}
            bottom={0}
            right={0}
            width="100vw"
            position="fixed"
            justifyContent="flex-end"
            zIndex={2}
          >
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              marginPagesDisplayed={1}
              pageCount={data.total_item_count / 20}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

export default OrderFulfilmentDetail;
