import React, { useState, useEffect, useContext, useRef } from "react";
import Primitives from "../../../components/primitives";
import Filter from "../../../components/glyphs/elements/filter";
import { colors } from "../../../components/utils/theme";
import Table from "../../../components/table-two/TableComponent";
import Button from "../../../components/button";
import Pagination from "../../../components/pagination";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import { useParams } from "react-router";
import styled from "styled-components";
import DownloadBtn from "../../../lib/download";
import downloadHandlerFn from "../../../components/utils/download-handler";
import FilterDateRange from "../../../lib/filters/date-range";

const StyledText = styled(Primitives.Text)`
  text-decoration: underline;
`;

const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);

const column = [
  {
    heading: "Date",
    type: "string"
  },
  {
    heading: "Details",
    type: "string"
  },
  {
    heading: "Debit",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "Credit",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "Balance Pending",
    type: "string",
    align: "flex-end"
  }
];

/** Here, response itself contains Rupee symbol, Lakhs and Crores */
const modifyTableData = data =>
  data.map(item => ({
    Date: item["date"],
    Details: item["details"],
    Debit: item["debit"],
    Credit: item["credit"],
    "Balance Pending": item["balance"]
  }));

const PaymentLedger = () => {
  const { BASE_URL } = useContext(AppContext);
  const hospitalId = useParams().id;
  const [request, updateRequest] = useState(
    `${BASE_URL}/payments/${hospitalId}`
  );
  const { data, isLoaded } = useApiRequest(request);
  const [dateRangeCollapse, setDateRangeCollapse] = useState(false);
  const [paymentModal] = useState(false);
  //const [modeOfPayment, setModeOfPayment] = useState("");
  //const [amount, setAmount] = useState("");
  //const [transactionNumber, setTransactionNumber] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  //const [singleDateCollapse, setSingleDateCollapse] = useState(true);
  //const [selectedDate, setSelectedDate] = useState("");
  const [error, setError] = useState("");
  const [isDownload, setIsDownload] = useState(false);
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: colors.primary[0],
      new: true
    }
  ]);

  const isClearFilter =
    dateRanges[0].startDate && dateRanges[0].endDate && !dateRanges[0].new;

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (!pageNumber.selected) {
      setPageNumber(1);
    }
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/payments/${hospitalId}?ledger_page=${pageNumber}`
    );
  }, [pageNumber]);

  const clearFilter = () => {
    setDateRanges([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: colors.primary[0],
        new: true
      }
    ]);
    updateRequest(`${BASE_URL}/payments/${hospitalId}`);
  };

  const dateChangeHandler = (item, collapseTheDateOptions = false) => {
    item.selection.new = false;
    setDateRanges([item.selection]);
    collapseTheDateOptions &&
      item.selection.startDate !== item.selection.endDate &&
      setDateRangeCollapse(!dateRangeCollapse);
  };

  const node = useRef();
  const handleClick = e => {
    if (paymentModal && node.current.contains(e.target)) {
      // inside click
      return;
    }
  };

  const dateRangeMapper = dateRanges => {
    if (!dateRanges[0].new)
      return (
        `&ledger[start_date]=${dateRanges[0].startDate
          .toLocaleDateString()
          .split("/")
          .join("-")}` +
        `&ledger[end_date]=${dateRanges[0].endDate
          .toLocaleDateString()
          .split("/")
          .join("-")}`
      );
  };

  const applyFilter = () => {
    updateRequest(
      `${BASE_URL}/payments/${hospitalId}?ledger_page=1${dateRangeMapper(
        dateRanges
      )}`
    );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 2000);
  }, [error]);

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/payments/${hospitalId}?ledger_page=${pageNumber}`
    );
  }, [pageNumber]);

  return (
    <>
      <Primitives.Flex flexDirection="column" flex={1} pl={3} pr={7}>
        <Primitives.Text fontSize={4}>Payment Ledger</Primitives.Text>
        <Primitives.Flex flex={1} justifyContent="space-between" py={2}>
          <Primitives.Flex>
            <Primitives.Flex width={200}>
              <FilterDateRange
                dateChangeHandler={dateChangeHandler}
                dateRangeCollapse={dateRangeCollapse}
                color={colors.text[2]}
                defaultValue={
                  dateRanges && dateRanges.selection
                    ? dateRanges.selection.startDate.toString()
                    : "Select Date"
                }
                dateRanges={dateRanges}
              />
            </Primitives.Flex>
            <Primitives.Flex px={1}>
              <Button
                active={{ backgroundColor: "inherit", color: "inherit" }}
                backgroundColor="white"
                border="1px solid"
                borderColor={colors.text[3]}
                clickHandler={applyFilter}
                glyph={<Filter height={20} width={20} fill={colors.text[1]} />}
                hover={{ backgroundColor: "inherit", color: "inherit" }}
                opacity={0.7}
                px={2}
                py={1}
              />
            </Primitives.Flex>
            <StyledText
              color={colors.text[1]}
              cursor={isClearFilter ? "pointer" : "default"}
              fontSize={1}
              lineHeight={4}
              onClick={() => {
                if (isClearFilter) {
                  clearFilter();
                }
              }}
              width={60}
            >
              {isClearFilter ? "Clear Filter" : ""}
            </StyledText>
          </Primitives.Flex>
          {data && data["ledger"] && data["ledger"].length > 0 && (
            <Primitives.Flex>
              <Primitives.Flex flex={1} justifyContent="flex-end" mr={1}>
                {isDownload ? (
                  <Loading onlySpinner />
                ) : (
                  <DownloadBtn
                    clickHandler={() => {
                      downloadHandler(
                        setIsDownload,
                        `${BASE_URL}/payments/${hospitalId}.xlsx?ledger_download=true${
                          dateRangeMapper(dateRanges) === undefined
                            ? ""
                            : dateRangeMapper(dateRanges)
                        }`
                      );
                    }}
                  />
                )}
              </Primitives.Flex>
            </Primitives.Flex>
          )}
        </Primitives.Flex>
        {isLoaded ? (
          <Primitives.Flex mt={4}>
            {data && data["ledger"] && data["ledger"].length ? (
              <Table
                clickHandler={() => null}
                color={{ head: colors.text[1], row: colors.text[0] }}
                data={modifyTableData(data["ledger"])}
                column={column}
              />
            ) : (
              <Primitives.Flex m="auto">
                <NoResults />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        ) : (
          <Primitives.Flex flex={1} justifyContent="center" mt={4}>
            <Loading />
          </Primitives.Flex>
        )}

        {data && data["ledger"] && data["total_ledger"] > 20 && (
          <Primitives.Flex justifyContent="flex-end">
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              pageCount={parseInt(data["total_ledger"]) / 20}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};
export default PaymentLedger;
