import { BASE_URL, GET_REQ_OPT } from "../../config";
import "regenerator-runtime/runtime";

export default {
  moleculeDeatils: async ids => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/reports/individual_sku_purchased?sku_ids=${ids}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};
