import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import Loading from "../../../components/loading";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Back from "../../../components/glyphs/elements/back";
import GenericReport from "../../reports/lib/generic-report";
import DisplayALlItems from "../../reports/lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import Navigation from "../../../lib/navigation";
import paginationHandler from "../../../components/utils/pagination-handler";
import { indianNumberFormat } from "../../../components/utils";

const tabsHeading = {
  pos: "TRANSACTIONS(POS)",
  similar_products: "SIMILAR PRODUCTS",
  mrp_changes: "CHANGES TO MRP"
};

const titleData = data => [
  {
    name: "Code",
    value: data && data["product_code"]
  },
  {
    name: "Manufacturer",
    value: data && data["manufacturer"]
  },
  {
    name: "Molecule",
    value: data && data["molecule"]
  },
  {
    name: "Schemes",
    value: data && data["scheme"]
  },
  {
    name: "Rate",
    value: data && data["rate"]
  },
  {
    name: "Average Order Value",
    value: data && data["avg_value"] && indianNumberFormat(data["avg_value"])
  },
  {
    name: "Average Order Quantity",
    value:
      data && data["avg_quantity"] && indianNumberFormat(data["avg_quantity"])
  }
];

const title = (isLoaded, name, value) => (
  <Primitives.Flex key={name} flex="auto" flexDirection="column" mt={2}>
    <Primitives.Text color={colors.text[1]}>{name}</Primitives.Text>
    {isLoaded ? (
      <Primitives.Text fontSize={2} mt={1}>
        {value && value}
      </Primitives.Text>
    ) : (
      <Primitives.Flex height={20} width={20} mt="5px" mr={4}>
        <Loading onlySpinner />
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);
const column = selectedTab => {
  switch (selectedTab) {
    case "pos":
      return [
        { heading: "Date", type: "string", width: 1 },
        { heading: "PO", type: "string", width: 1 },
        { heading: "Centre", type: "string", width: 2 },
        { heading: "Qty Ordered", type: "number", width: 1, align: "flex-end" }
      ];
    case "mrp_changes":
      return [
        { heading: "Date", type: "string" },
        { heading: "MRP", type: "string", align: "flex-end" },
        {
          heading: "MRP Difference (From Previous Code Price)",
          type: "string",
          align: "flex-end"
        }
      ];
    case "similar_products":
      return [
        { heading: "Product Code", type: "string", width: 1 },
        { heading: "Hospital SKU Name", type: "string", width: 3 },
        { heading: "Category", type: "string", width: 1 },
        { heading: "Manufacturer", type: "string", width: 3 },
        { heading: "UOM", type: "string", width: 1 },
        { heading: "Rate", type: "number", width: 0.5, align: "flex-end" },
        { heading: "Tax", type: "number", width: 0.5, align: "flex-end" },
        { heading: "MRP", type: "number", width: 0.5, align: "flex-end" },
        { heading: "Schemes", type: "string", width: 1 },
        { heading: "Mapping Status", type: "string", width: 1 }
      ];
  }
};

const modifyData = (data, selectedTab) => {
  switch (selectedTab) {
    case "pos":
      return (
        data &&
        data.purchase_orders &&
        data.purchase_orders.map(item => ({
          Date: item.order_date,
          PO: item.po_number,
          Centre: item.centre,
          "Qty Ordered": item.quantity
        }))
      );
    case "mrp_changes":
      return (
        data &&
        data.purchase_invoices &&
        data.purchase_invoices.map(item => ({
          Date: item.invoice_date,
          MRP: `₹  ${item.mrp}`,
          "MRP Difference (From Previous Code Price)": item.mrp_diff
        }))
      );
    case "similar_products":
      return (
        data &&
        data.similar_skus &&
        data.similar_skus.map(item => ({
          "Product Code": item.product_code,
          "Hospital SKU Name": item.name,
          Category: item.category,
          Manufacturer: item.manufacturer,
          UOM: item.uom,
          Rate: item.rate,
          Tax: item.tax,
          MRP: item.mrp,
          Schemes: item.scheme,
          "Mapping Status": item.mapping_status
        }))
      );
  }
};

const Products = () => {
  const [selectedTab, setSelectedTab] = useState("pos");
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const hospitalId = useParams().productMasterId;
  const { BASE_URL } = useContext(AppContext);
  let history = useHistory();
  const [request, updateRequest] = useState(
    `${BASE_URL}/product_masters/${hospitalId}?tab="pos"`
  );
  const { data, isLoaded } = useApiRequest(request);
  const filterUpdate = ({ date }) => {
    setQuery(`${date}`);
    updateRequest(
      `${BASE_URL}/product_masters/${hospitalId}?tab=${selectedTab}&${date}`
    );
  };

  const tabSelectHandler = tab => {
    setCurrentPage(1);
    setSelectedTab(tab);
  };

  const headerProps = {
    config: { date: true, molecules: true },
    filterUpdate: filterUpdate,
    label: false
  };

  const tableProps = {
    ...reportStyles["allReports"],
    data: modifyData(data, selectedTab),
    column: column(selectedTab),
    fontSize: { head: 1, row: 2 },
    fontWeight: { head: 2, row: 1 }
  };

  const tabProps = {
    selectedTab: selectedTab,
    tabsHeading: tabsHeading,
    tabSelectHandler: tabSelectHandler
  };

  const downloadCtaProps = () =>
    selectedTab !== "similar_products"
      ? request.split("?").join(".xlsx?is_download=true&")
      : "";

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 10),
    pageRangeDisplayed: 5
  };

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      itemsPerPage={10}
      totalItems={data["total_items"]}
    />
  );

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/product_masters/${hospitalId}?tab=${selectedTab}&${query}&sku_page=${currentPage}&mrp_page=${currentPage}&pos_page=${currentPage}`
    );
  }, [selectedTab, currentPage]);

  return (
    <>
      <Navigation selected={2} subNavIndex={[2, 0]} />
      <Primitives.Box backgroundColor="background-blue" flex={1}>
        <Primitives.Flex flexDirection="column" mb={-17} pt={4} px={3}>
          <Primitives.Flex alignItems="baseline" flexDirection="row">
            <Primitives.Flex mr={10}>
              <Link
                exact="true"
                to=""
                onClick={() => {
                  history.goBack();
                }}
              >
                <Back fill="black" />
              </Link>
            </Primitives.Flex>
            <Primitives.Flex height={20}>
              {isLoaded ? (
                <Primitives.Text fontSize={5}>
                  {data && data["name"]}
                </Primitives.Text>
              ) : (
                <Loading onlySpinner />
              )}
            </Primitives.Flex>
          </Primitives.Flex>

          <Primitives.Flex flexDirection="row" fontSize={2} ml={4} py={2}>
            {titleData(data).map(item =>
              title(isLoaded, item.name, item.value)
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Box>

      <GenericReport
        displayItems={displayItems()}
        downloadCtaProps={downloadCtaProps()}
        headerProps={headerProps}
        isLoaded={isLoaded}
        paginationProps={paginationProps}
        tableProps={tableProps}
        tabProps={tabProps}
      />
    </>
  );
};

export default Products;
