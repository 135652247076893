import React, { useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import Panel from "../../lib/panel";
import OtifService from "../../../../services/reports/order-fulfilment/index";
import Loading from "../../../../components/loading";
import PillChart from "../../../../components/pill-chart";
import { useHistory } from "react-router";
import { string } from "prop-types";

const propTypes = {
  /** Updated parameters */
  query: string
};

const chartData = (otifs, count) => [
  {
    backgroundColor: "#036516",
    bottomLabel: otifs["early"] + " PO's",
    color: "#036516",
    textAlign: "start",
    topLabel: "Early(24 HRS)",
    width: (otifs["early"] / count) * 100 + "%"
  },
  {
    backgroundColor: colors.success[1],
    bottomLabel: otifs["on_time"] + " PO's",
    color: colors.success[1],
    textAlign: "center",
    topLabel: "ON TIME(1-2 DAYS)",
    width: (otifs["on_time"] / count) * 100 + "%"
  },
  {
    backgroundColor: "#F4B513",
    bottomLabel: otifs["late"] + " PO's",
    color: "#F4B513",
    textAlign: "end",
    topLabel: "LATE(2+ DAYS)",
    width: (otifs["late"] / count) * 100 + "%"
  }
];

const statusObj = {
  0: "early",
  1: "on_time",
  2: "late"
};

const PurchaseOrderOtifReport = ({ query }) => {
  const [data, setData] = useState([]);
  const history = useHistory();

  const fetchPurchaseOrderOtif = async query => {
    try {
      const response = await OtifService.purchaseOrderOtif(query);
      if (response) {
        setData(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchPurchaseOrderOtif(query);
  }, []);

  return (
    <>
      {data && data.total_po_count && data.total_po_count !== 0 ? (
        <Primitives.Flex flexDirection="column" mx={4} mt={4} minHeight={40}>
          <Panel title="Delivered Orders OTIF Report" date={data.date_range} />
          <Primitives.Flex
            backgroundColor="white"
            borderRadius={10}
            border="1px solid"
            borderColor={colors["athens-gray"]}
            boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.1)"
            flexDirection="row"
            mt={2}
          >
            <Primitives.Flex
              backgroundColor={colors.purple}
              flex={2.6}
              flexDirection="column"
              py={5}
              textAlign="center"
            >
              <Primitives.Text color={colors.text[1]} fontSize="12px">
                Total PO&#39;s Delivered
              </Primitives.Text>
              <Primitives.Text fontSize="32px" fontWeight={2}>
                {data.total_po_count} PO&#39;s
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flex={9.4} flexDirection="column" px={4}>
              <Primitives.Text fontSize="14px" fontWeight={2} my={2}>
                OTIF Breakdown
              </Primitives.Text>
              {data && data.otifs && (
                <PillChart
                  data={chartData(data.otifs, data.total_po_count)}
                  clickHandler={index => {
                    history.push({
                      pathname: `/hospital/purchase_order_details/${statusObj[index]}`
                    });
                  }}
                  viewDeatils={true}
                />
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
      ) : (
        <>
          <Primitives.Flex minHeight={300} mt={7} mx="auto">
            <Loading onlySpinner />
          </Primitives.Flex>
        </>
      )}
    </>
  );
};

PurchaseOrderOtifReport.propTypes = propTypes;
export default PurchaseOrderOtifReport;
