/**
  last modified: 23 jan
  modified by: shubham
 */
import React, { useState, useContext, useEffect } from "react";
import useApiRequest from "../../../hooks/useApiRequest";
import { AppContext } from "../../../utils/context";
import GenericReport from "../lib/generic-report";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import paginationHandler from "../../../components/utils/pagination-handler";
import Primitives from "../../../components/primitives";

const tabsHeading = {
  tab1: "1-30days",
  tab2: "30-90days",
  tab3: "90-180days",
  tab4: "180-365days",
  tab5: "365days"
};

const column = [
  { heading: "Category", type: "string", width: 1 },
  { heading: "Manufacturer", type: "string", width: 2 },
  { heading: "Batch", type: "string", width: 1 },
  { heading: "SKU Name", type: "string", width: 3 },
  { heading: "Expiry Date", type: "string", width: 1 },
  { heading: "Days To Expiry", type: "string", width: 1 },
  { heading: "Qty", type: "number", width: 0.5, align: "flex-end" }
];
const reverseDate = date =>
  date
    ?.split("-")
    .reverse()
    .join("-") ?? "";
const modifyData = data =>
  data &&
  data.order_items &&
  data.order_items.map(item => ({
    category: item.category,
    manufacturer: item.manufacturer,
    batchNumber: item.batch_number,
    skuName: item.sku_name,
    batchExpiry: reverseDate(item.batch_expiry),
    daysToExpiry: `${item.days_to_expiry} days`,
    quantity: <Primitives.Text m="auto"> {item.quantity} </Primitives.Text>
  }));

const ExpiryCheckReport = () => {
  const { BASE_URL } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [sort, updateSort] = useState("");
  const [order, toggleOrder] = useState("asc");
  const [request, updateRequest] = useState(
    `${BASE_URL}/analytics/expiry_check?page=${currentPage}&expiry_check_date_range=${tabsHeading[selectedTab]}&tabname=${selectedTab}`
  );

  const tabSelectHandler = tab => {
    setCurrentPage(1);
    setSelectedTab(tab);
  };

  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    setQuery(`${queryObj.hospitalEntitySingleSelect}`);
    updateRequest(
      `${BASE_URL}/analytics/expiry_check?page=${currentPage}&expiry_check_date_range=${
        tabsHeading[selectedTab]
      }&hospitals[hospital_id]=${queryObj.hospitalEntitySingleSelect || ""}`
    );
  };

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      itemsPerPage={10}
      totalItems={data["total_items"]}
    />
  );
  const downloadCtaProps = "";
  const headerProps = {
    config: { hospitalEntitySingleSelect: true },
    filterUpdate: filterUpdate,
    label: "Expiry Check Report"
  };

  const filterObject = {
    0: "category+",
    1: "manufacturer+",
    2: "batch_number+",
    3: "sku_name+",
    4: "batch_expiry+",
    5: "batch_expiry+",
    6: "quantity+"
  };

  const getHeaderId = id => {
    toggleOrder(order === "asc" ? "desc" : "asc");
    updateSort(filterObject[id] + order);
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 10),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    data: modifyData(data),
    fontSize: { head: 1, row: 2 },
    fontWeight: { head: 2, row: 1 },
    column: column,
    sorting: true,
    ...reportStyles["allReports"]
  };

  const tabProps = {
    selectedTab: selectedTab,
    tabsHeading: tabsHeading,
    tabSelectHandler: tabSelectHandler
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/analytics/expiry_check?page=${currentPage}&expiry_check_date_range=${tabsHeading[selectedTab]}&tabname=${selectedTab}&${query}&q[s]=${sort}`
    );
  }, [currentPage, selectedTab, sort]);

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      getHeaderId={getHeaderId}
      headerProps={headerProps}
      subNavIndex={[3, 13]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
      tabProps={tabProps}
    />
  );
};
export default ExpiryCheckReport;
