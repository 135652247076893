/** It renders Product wise invoice details report. Last Modified on 24/01/23 for testing */
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Back from "../../../components/glyphs/elements/back";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";
import Pagination from "../../../components/pagination";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two";
import { convertDate } from "../../../components/utils";
import { colors } from "../../../components/utils/theme";
import DownloadBtn from "../../../lib/download";
import Filters from "../../../lib/filters";
import DisplayAllItems from "../lib/display-all-items";
import ProductWiseInvoiceService from "../../../services/reports/product-wise-invoice-report";
import Navigation from "../../../lib/navigation";
import Cta from "../../../components/cta";
import Button from "../../../components/button";
import downloadHandlerFn from "../../../components/utils/download-handler";
import { BASE_URL } from "../../../services/config";
import paginationHandler from "../../../components/utils/pagination-handler";
import splitDateRange from "../../../utils/split-date-range";
import formatDate from "../../../utils/format-date";
import { HorizontalScroll } from "../../../lib/horizontal-scroll";

/** Handles Download */
const downloadHandler = async (setIsDownload, query) =>
  downloadHandlerFn(
    `${BASE_URL}/invoices/items?${query}&is_download=true`,
    setIsDownload
  );

/** Defines colors for various order statuses */
const statusColorObj = {
  delivered: {
    text: colors.success[1],
    background: "#57ad681a"
  },
  confirmed: {
    text: colors.warning[1],
    background: "#e898061a"
  },
  dispatched: {
    text: "#3BB6D1",
    background: "#ebf8fa"
  },
  cancelled: {
    text: colors.text[1],
    background: colors.text[3]
  }
};

const tableValue = data => <Primitives.Flex>{data}</Primitives.Flex>;

/** Transform the input data into a new format */
const modifyData = data =>
  data.map(item => {
    const attributes = item["attributes"];

    return {
      "Hospital Name": tableValue(attributes["buyer_party"]),
      "Hospital Order NO. & Date": tableValue(
        <Primitives.Flex flexDirection="column">
          <Primitives.Text>{attributes["po_number"]}</Primitives.Text>
          <Primitives.Text pt="3px">
            {convertDate(attributes["order_date"])}
          </Primitives.Text>
        </Primitives.Flex>
      ),
      "Invoice Number": tableValue(attributes["invoice_number"]),
      "Invoice Date": attributes["confirmation_date"],
      "PRODUCT NAME": tableValue(attributes["product_name"]),
      MANUFACTURER: tableValue(attributes["manufacturer"]),
      "ORDERED QTY": tableValue(attributes["ordered_quantity"]),
      "INVOICE QTY": tableValue(attributes["quantity"]),
      "FREE QTY": tableValue(attributes["free_quantity"]),
      MRP: tableValue(attributes["mrp"]),
      RATE: tableValue(attributes["rate"]),
      DISCOUNT: tableValue(`${attributes["discount_rate"]} %`),
      "GST%": tableValue(`${attributes["gst_rate"]} %`),
      "CGST%": tableValue(`${attributes["cgst_rate"]} %`),
      "MARGIN%": tableValue(`${attributes["margin_perc"]} %`),
      "INVOICE STATUS": (
        <Primitives.Text
          borderRadius={8}
          borderStyle="solid"
          borderWidth="1px"
          color={statusColorObj?.[attributes["invoice_status"]]?.text}
          background={
            statusColorObj?.[attributes["invoice_status"]]?.background
          }
          fontSize="11px"
          fontWeight={1}
          height="15px"
          px={1}
          pt="3px"
        >
          {attributes["invoice_status"].toUpperCase()}
        </Primitives.Text>
      ),
      POD:
        attributes["pod"]?.length > 0 ? (
          <Cta
            color="inherit"
            label={
              <Button
                color={colors.primary[3]}
                fontWeight={1}
                hover={{
                  backgroundColor: colors.text[2],
                  color: colors.text[0]
                }}
                label="View"
                lineHeight={3}
                px="8px"
                py="4px"
              />
            }
            link={attributes["pod"][0].url}
            hover={{ color: "inherit", textdecorationline: false }}
            target="_blank"
            textdecorationline={false}
            type="anchor"
          />
        ) : (
          <Primitives.Text px="8px">NA</Primitives.Text>
        )
    };
  });

/** Table column detail */
const column = [
  { heading: "HOSPITAL NAME", type: "string", width: 0.7 },
  { heading: "HOSPITAL ORDER NO. & DATE", type: "object", width: 0.5 },
  { heading: "INVOICE NUMBER", type: "string", width: 0.4 },
  { heading: "INVOICE DATE", type: "string", width: 0.4 },
  { heading: "PRODUCT NAME", type: "string", width: 0.4 },
  { heading: "MANUFACTURER", type: "string", width: 0.4 },
  { heading: "ORDERED QTY", type: "number", width: 0.3, align: "flex-end" },
  { heading: "INVOICE QTY", type: "number", width: 0.3, align: "flex-end" },
  { heading: "FREE QTY", type: "number", width: 0.3, align: "flex-end" },
  { heading: "MRP", type: "number", width: 0.3, align: "flex-end" },
  { heading: "RATE", type: "number", width: 0.3, align: "flex-end" },
  { heading: "DISCOUNT", type: "string", width: 0.3 },
  { heading: "GST%", type: "string", width: 0.3 },
  { heading: "CGST%", type: "string", width: 0.3 },
  { heading: "MARGIN%", type: "string", width: 0.3 },
  { heading: "INVOICE STATUS", type: "object", width: 0.3 },
  { heading: "POD", type: "object", width: 0.3 }
];
const createDateObjects = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const currentDateClone = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const defaultStartdate = convertDate(firstDayOfMonth, "-");
  const defaultEnddate = convertDate(currentDateClone, "-");

  return {
    firstDayOfMonth,
    currentDateClone,
    defaultStartdate,
    defaultEnddate
  };
};

/** Component function */
const ProductWiseInvoiceDetail = () => {
  const status = useParams().status;
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [quickSearch, setQuickSearch] = useState(1);
  const {
    firstDayOfMonth,
    currentDateClone,
    defaultStartdate,
    defaultEnddate
  } = createDateObjects();

  const [query, setQuery] = useState(
    `&q[start_date]=${defaultStartdate}&q[end_date]=${defaultEnddate}`
  );
  const defaultDateRange = {
    startDate: formatDate(firstDayOfMonth),
    endDate: formatDate(currentDateClone)
  };

  /** This function updates the query of the request URL based on selected filters. */
  const filterUpdate = queryObj => {
    const mappedHospitals = queryObj.hospitalEntity
      ? queryObj.hospitalEntity
          .split("=")[1]
          .split(",")
          .map(item => `q[hospital_id][]=${item}`)
          .join("&")
      : "";
    const mappedInvoiceStatus = queryObj.invoiceStatus
      ? queryObj.invoiceStatus
          .split("=")[1]
          .split(",")
          .map(item => `q[invoice_status][]=${item}`)
          .join("&")
      : [];
    setData([]);
    setPageNumber(1);
    queryObj.quickSearch && setQuickSearch(queryObj.quickSearch);
    const { startDate, endDate } = splitDateRange(queryObj.date);
    setQuery(
      `${
        queryObj.quickSearch === "" ? quickSearch : queryObj.quickSearch
      }&q[product_name]=${
        queryObj.productSearch ? queryObj.productSearch : ""
      }&q[po_number]=${
        queryObj.orderSearch ? queryObj.orderSearch : ""
      }&q[invoice_number]=${
        queryObj.invoiceSearch ? queryObj.invoiceSearch : ""
      }&q[company_name]=${
        queryObj.manufacturerSearch ? queryObj.manufacturerSearch : ""
      }${`&q[start_date]=${startDate ??
        defaultStartdate}`}${`&q[end_date]=${endDate ??
        defaultEnddate}`}&${mappedHospitals}&${mappedInvoiceStatus}`
    );
  };
  /** handling pagination */
  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setPageNumber(page);
  };

  /** Fetch invoice details based on the given query */
  const fetchInvoiceDetails = async query => {
    try {
      const response = await ProductWiseInvoiceService.productWiseInvoiceDetails(
        query
      );
      if (response) {
        setData(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchInvoiceDetails(status || query);
  }, []);

  useEffect(() => {
    setData([]);
    fetchInvoiceDetails(`page=${pageNumber}&${query}${status || ""}`);
  }, [pageNumber, query, status]);

  return (
    <>
      <Navigation selected={1} subNavIndex={[1, 0]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          py={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex mr={2}>
              <Link
                exact="true"
                to=""
                onClick={() => {
                  history.go(-1);
                }}
              >
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Flex color={colors.text[0]} fontSize={5} fontWeight={2}>
              <Primitives.Text>
                Product Wise Invoice Details Report
              </Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Box mt={2}>
            <Filters
              config={{
                date: true,
                hospitalEntity: true,
                invoiceSearch: true,
                invoiceStatus: true,
                manufacturerSearch: true,
                productSearch: true,
                orderSearch: true,
                defaultDateRange: { ...defaultDateRange }
              }}
              filterUpdate={filterUpdate}
              flexWrap="wrap"
            />
          </Primitives.Box>
        </Primitives.Flex>
        {data?.total_items > 0 && (
          <Primitives.Flex alignItems="center" mb={20} mt={3} px={5}>
            <Primitives.Flex fontSize={1} color={colors.text[1]}>
              <DisplayAllItems
                currentPage={pageNumber}
                itemsPerPage={20}
                totalItems={data.total_items}
                title="Orders"
              />
            </Primitives.Flex>

            <Primitives.Flex flex={1} justifyContent="flex-end">
              {data?.items?.length > 0 && (
                <Primitives.Flex flex={1} justifyContent="flex-end">
                  {isDownload ? (
                    <Loading onlySpinner />
                  ) : (
                    <DownloadBtn
                      clickHandler={() => {
                        downloadHandler(setIsDownload, query);
                      }}
                    />
                  )}
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        )}
        {data?.items ? (
          <Primitives.Flex flexDirection="column" overflow="scroll" px={5}>
            <HorizontalScroll>
              <Primitives.Flex>
                {data?.items?.length > 0 ? (
                  <Primitives.Flex>
                    <Primitives.Flex width="200vw">
                      <Table
                        color={{ head: colors.text[1], row: colors.text[0] }}
                        data={modifyData(data.items)}
                        column={column}
                        pb={{ headContainer: 2, row: 2 }}
                        pt={{ headContainer: 2, row: 2 }}
                      />
                    </Primitives.Flex>
                  </Primitives.Flex>
                ) : (
                  <Primitives.Flex m="auto" mt={5}>
                    <NoResults />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </HorizontalScroll>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
        {data?.total_items > 20 && (
          <Primitives.Flex
            backgroundColor={colors.accent[0]}
            bottom={0}
            right={0}
            justifyContent="flex-end"
            zIndex={2}
          >
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              marginPagesDisplayed={1}
              pageCount={data.total_items / 20}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

export default ProductWiseInvoiceDetail;
