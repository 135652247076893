import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Primitives from "../../../components/primitives";
import { colors, themeSettings } from "../../../components/utils/theme";
import SearchService from "../../../services/search-service";
import { bool, func, string } from "prop-types";
import Loading from "../../../components/loading";

const ListContainer = styled(Primitives.Flex)`
cursor: pointer;
&:hover {
  background: ${colors["background-light-blue"]};
  color: ${colors.text[0]};
  font-weight: ${props => themeSettings.fontWeights[props.hoverweight]};
`;

const propTypes = {
  clearFilter: bool,
  config: string,
  placeholder: string,
  selectedValues: func
};

const searchConfig = {
  orderListingProductSearch: {
    name: "name_cont",
    path: "sku_search",
    result: "search_results",
    requireName: true
  },
  manufacturerThrottleSearch: {
    name: "name",
    path: "manufacturers",
    result: "manufacturers"
  }
};

const ThrottleSearch = ({
  clearFilter,
  config,
  placeholder,
  selectedValues
}) => {
  const inputRef = useRef();
  const [data, setData] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const throttling = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const serachSku = async query => {
    try {
      setIsLoading(true);
      const response = await SearchService.search(
        query,
        searchConfig[config].path
      );
      if (response) {
        setData(response[searchConfig[config].result]);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  const selectHandler = option => {
    const id = data.filter(item => item.name === option.name)[0].id;
    if (searchConfig[config].requireName) {
      setSelectedEntities({ id: id, name: option.name });
    } else {
      setSelectedEntities(id);
    }
    setData([]);
    inputRef.current.value = option.name;
  };

  useEffect(() => {
    selectedValues(selectedEntities);
  }, [selectedEntities]);

  useEffect(() => {
    if (inputRef.current && inputRef.current.value === "") {
      setSelectedEntities([]);
    }
  }, [inputRef?.current?.value]);
  useEffect(() => {
    if (!clearFilter) {
      setSelectedEntities([]);
      inputRef.current.value = "";
    }
  }, [clearFilter]);

  const handleThrottleSearch = () => {
    if (throttling.current) {
      return;
    }
    // If there is no search term, do not make API call
    if (!inputRef.current.value.trim()) {
      setSelectedEntities([]);
      return;
    }
    throttling.current = true;
    setTimeout(() => {
      throttling.current = false;
      serachSku(`${searchConfig[config].name}=${inputRef.current.value}`);
    }, 600);
  };

  const handleClick = e => {
    if (!e.target.closest(".dropdown-filter")) {
      setIsLoading(false);
      setData([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Primitives.Flex flexDirection="column" width="12vw" zIndex={3}>
      <Primitives.Input
        // active={search.length > 0}
        // changeHandler={e => setSearch(e.target.value)}
        // clickHandler={() => getClickEvent()}
        border="1px solid"
        borderColor={colors.text[2]}
        borderRadius={4}
        fontFamily="lato"
        fontSize="12px"
        height="35px"
        pl="10px"
        onChange={handleThrottleSearch}
        placeholder={placeholder}
        ref={inputRef}
        type="text"
        // value={search}
      />
      {inputRef.current?.value && (
        <Primitives.Flex
          backgroundColor="white"
          boxShadow={9}
          flexDirection="column"
          maxWidth="32vw"
          maxHeight="23vh"
          mt="40px"
          overflow="scroll"
          position="absolute"
          zIndex={1}
        >
          {isLoading ? (
            <Primitives.Flex
              justifyContent="center"
              width={200}
              className="dropdown-filter"
              cursor="pointer"
              fontSize="12px"
            >
              <Loading onlySpinner={true} />
            </Primitives.Flex>
          ) : (
            data.length !== 0 &&
            data.map((entity, index) => (
              <Primitives.Box
                className="dropdown-filter"
                cursor="pointer"
                fontSize="12px"
                hoverColor={colors.primary[6]}
                key={entity.id + `${index}`}
                hoverWidth={1}
                lineHeight={1.5}
                onClickCapture={() => selectHandler(entity)}
              >
                <ListContainer px={2} pb={1} pt={1}>
                  <Primitives.Text lineHeight={3}>
                    {entity.name}
                  </Primitives.Text>
                </ListContainer>
              </Primitives.Box>
            ))
          )}
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

ThrottleSearch.propTypes = propTypes;
export default ThrottleSearch;
