import styled from "styled-components";

export const HorizontalScroll = styled.div`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid white;
    background-color: rgba(0, 1, 0, 0.3);
  }
`;
