import React, { useContext, useState } from "react";

import { func } from "prop-types";
import { colors } from "../../../components/utils/theme";
import Error from "../../../components/glyphs/elements/error";
import Checked from "../../../components/glyphs/elements/checked";
import { AppContext } from "../../../utils/context";
import Primitives from "../../../components/primitives";
import Alert from "../../../components/alert";
import Button from "../button";

const propTypes = {
  forgotPasswordHandler: func
};

const alertData = [
  {
    text:
      "This email ID does not exist in our database. Please try with a different email.",
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  },
  {
    text:
      "Your reset password Instructions have been sent to registered email.Please Check",
    backgroundColor: colors["background-hint-green"],
    borderColor: colors.success[0],
    color: colors.success[1],
    glyph: <Checked fill={colors.success[0]} />
  },
  {
    text: "Too many requests, please try after sometime.",
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  }
];
const ForgotPassword = ({ forgotPasswordHandler }) => {
  const { BASE_URL } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [alert, toggleAlert] = useState([false, 0]);
  const [isLoaded, toggleLoading] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    toggleLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_aknamed_admin_session=SUu%2BeZ7Pd9l2dw%2BvtntNsfcu3XNY%2F4IBcknDoxRYXPaPEB7BmV13fIUrSVRHS4FWR7JmNkHi0FeXQ7A5Ec8VunSo7BOl3x5hR%2BgrP7ODwIQO26H35TLE6A%2FEJzc%3D--BP9PSGDd8dNk2JZ9--ZPiOMLvtqK%2FvQZQQqbBqtg%3D%3D"
    );

    var raw = JSON.stringify({
      email: email
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BASE_URL}/send_token`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.valid) {
          toggleLoading(false);
          forgotPasswordHandler([false, alertData[1]]);
        } else if (result.success === false) {
          toggleLoading(false);
          toggleAlert([true, 2]);
        } else {
          toggleLoading(false);
          toggleAlert([true, 0]);
        }
      })
      .catch(error => console.log("error", error));
  };

  return (
    <Primitives.Flex flexDirection="column" mx="auto" mt={4}>
      <Primitives.Text color={colors.text[0]} fontSize={4} fontWeight={2}>
        Forgot your Password ?
      </Primitives.Text>
      <Primitives.Text color={colors.text[1]} fontSize={2} mb={1} mt="10px">
        Enter your Email to get Reset Instructions in your Inbox
      </Primitives.Text>

      {alert[0] && (
        <Primitives.Flex mt={1}>
          <Alert
            backgroundColor={alertData[alert[1]].backgroundColor}
            borderColor={alertData[alert[1]].borderColor}
            color={alertData[alert[1]].color}
            fontSize={1}
            glyph={alertData[alert[1]].glyph}
            label={
              <Primitives.Text alignItems="center" display="inline-flex">
                {alertData[alert[1]].text}
              </Primitives.Text>
            }
            lineHeight={3}
            mx={2}
            pl={0}
            pr={[0, 1]}
            py="5px"
          />
        </Primitives.Flex>
      )}
      <Primitives.Input
        border={1}
        borderColor={colors.text[2]}
        borderRadius={4}
        borderStyle="solid"
        height={33}
        mt={3}
        onChange={e => {
          setEmail(e.target.value);
        }}
        placeholder="Email"
        pl={10}
        width="29vw"
      ></Primitives.Input>
      <Button isLoaded={isLoaded} handleSubmit={handleSubmit}>
        Send Reset Link
      </Button>
      <Primitives.Flex ml="auto" mt={2} pr={1}>
        <Primitives.Button
          color={colors.primary[3]}
          fontFamily="lato"
          fontSize={3}
          onClick={() => {
            forgotPasswordHandler([false, {}]);
          }}
        >
          Login
        </Primitives.Button>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
ForgotPassword.propTypes = propTypes;
export default ForgotPassword;
