import React, { useContext } from "react";
import { array, number, string } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import CircleWithUpwardOrDownwardArrow from "../../../../components/glyphs/elements/circle_with_upward_or_downward_arrow";
import Loading from "../../../../components/loading";
import ViewTrend from "../view-trend";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import ConvertMoneyToLakhsCrores from "../../../../components/utils/convert-money-to-lakh-crores";
import { useHistory } from "react-router";
import { FilterContext } from "../../../../utils/filter-context";

const propTypes = {
  apiKeys: array,
  currMonthkey: string,
  flex: number,
  header: string,
  request: string,
  viewTrendColumn: array,
  viewTrendKey: string,
  index: number
};
const defaultProps = {
  flex: 0.7,
  showSummary: true
};

const getValues = currMonthData => ({
  total_purchase_value: [
    {
      heading: "Value Purchased",
      value: `₹${ConvertMoneyToLakhsCrores(
        currMonthData.total_purchase_value
      )}`,
      icon: {
        background: currMonthData.growth_indicator,
        arrowType:
          currMonthData.growth_indicator === "red" ? "downward" : "upward"
      },
      route: "/hospital/filtered_orders/"
    }
  ],
  rc_adherence: [
    {
      heading: "With RC",
      value:
        "₹" + ConvertMoneyToLakhsCrores(currMonthData.total_purchase_with_rc),
      filterData: {
        rcAvailability: ["Product With RC", "product_with_rc=true"]
      },
      route: "/hospital/product_specific_compliance"
    },
    {
      heading: "Without RC",
      showIcon: true,
      value:
        "₹" +
        ConvertMoneyToLakhsCrores(currMonthData.total_purchase_without_rc),
      icon: currMonthData.percentage_growth
        ? {
            background: currMonthData.growth_indicator,
            arrowType:
              currMonthData.growth_indicator === "green" ? "downward" : "upward"
          }
        : {},
      filterData: {
        rcAvailability: ["Product Without RC", "product_without_rc=true"]
      },
      route: "/hospital/product_specific_compliance"
    }
  ],
  purchase_return: [
    {
      heading: "Value & Qty Returned",
      showIcon: true,
      value: `₹${ConvertMoneyToLakhsCrores(
        currMonthData.total_return_value
      )} / ${currMonthData.total_return_quantity} Qty`,
      icon: {
        background: currMonthData.growth_indicator,
        arrowType:
          currMonthData.growth_indicator === "green" ? "downward" : "upward"
      },

      filterData: () => {
        const today = new Date();
        return {
          dateRange: `start_date=1/${today.getMonth() +
            1}/${today.getFullYear()}&end_date=${today.getDate()}/${today.getMonth() +
            1}/${today.getFullYear()}`
        };
      },
      route: "/hospital/product_returns"
    }
  ],
  po_frequency: [
    {
      heading: "Ordered 1-2 Times",
      value: `${currMonthData.one_to_two_count} products`,
      filterData: { tab: 0 },
      route: "product_order_frequency"
    },
    {
      heading: "Ordered 3-4 Times",
      value: `${currMonthData.three_to_four_count} products`,
      filterData: { tab: 1 },
      route: "product_order_frequency"
    },
    {
      heading: "Ordered 5+ Times",
      value: `${currMonthData.five_plus_count} products`,
      filterData: { tab: 2 },
      route: "product_order_frequency"
    }
  ]
});
const getFilteredData = (
  data,
  isLoaded,
  apiKeys,
  header,
  request,
  viewTrendColumn
) => {
  const currMonthkey = apiKeys[0];
  const viewTrendKey = apiKeys[1];
  const currMonthData = data && data[currMonthkey];
  return {
    header: header,
    showSummary: isLoaded,
    summary: data && currMonthData,
    values: data && currMonthData && getValues(currMonthData)[request],
    viewTrendColumn: viewTrendColumn,
    viewTrendData: data[viewTrendKey]
  };
};
const dateSuffix = n =>
  n + ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10];

const getSummary = (heading, data, growth) => {
  const isTotalPurchase = heading === "Total Purchase";
  const isPoFrequency = heading === "Product Order Frequency";
  heading = heading === "RC Adherence" ? "non-RC purchase" : heading;
  growth = isTotalPurchase ? growth : !growth;
  const date = data.date_range.split(" -");
  return isPoFrequency ? (
    <Primitives.Text>
      <Primitives.Text
        color={data.growth_indicator === "red" ? "#199B46" : "#FF2F2F"}
      >
        {data.five_plus_count && data.five_plus_count} {" SKU "}
      </Primitives.Text>
      <>
        were ordered more than 5 times compared to {dateSuffix(date[0])} -
        {dateSuffix(date[1])} last month
      </>
    </Primitives.Text>
  ) : (
    <Primitives.Text>
      {isTotalPurchase && (
        <Primitives.Text>
          Your purchase has seen a {growth ? "rise" : "drop"} in{" "}
        </Primitives.Text>
      )}
      <Primitives.Text
        color={data.growth_indicator === "red" ? "#FF2F2F" : "#199B46"}
      >
        {data.percentage_growth != undefined &&
          Math.abs(data.percentage_growth)}
        {"%"}
      </Primitives.Text>{" "}
      <Primitives.Text>
        {!isTotalPurchase && (growth ? "increase " : "decrease ")}
        {!isTotalPurchase && `in ${heading}`} compared to {dateSuffix(date[0])}{" "}
        - {dateSuffix(date[1])} last month
      </Primitives.Text>
    </Primitives.Text>
  );
};
const clickHandler = (filterdata, route, filterContext, history) => {
  filterContext.updateFilterState(prevFilterState => ({
    ...prevFilterState,
    ...filterdata
  }));
  history.push(route);
};
const valueMapper = (data, filterContext, history) =>
  data &&
  data.map((item, index) => (
    <React.Fragment key={index}>
      <Primitives.Flex flexDirection="column" key={index} cursor="pointer">
        <Primitives.Text fontSize="12px">{item.heading}</Primitives.Text>
        <Primitives.Flex
          color="#317FF7"
          fontSize="18px"
          py={1}
          alignItems="center"
          onClick={() =>
            clickHandler(item.filterData, item.route, filterContext, history)
          }
          cursor="pointer"
        >
          {item.value}
          {item && item.icon ? (
            <Primitives.Flex
              background={
                item.icon.background
                  ? item.icon.background === "red"
                    ? "#FF2F2F"
                    : "#199B46"
                  : ""
              }
              marginLeft="5px"
              width="16px"
              height="16px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
            >
              <CircleWithUpwardOrDownwardArrow
                arrowType={item.icon.arrowType && item.icon.arrowType}
              />
            </Primitives.Flex>
          ) : (
            ""
          )}
        </Primitives.Flex>
      </Primitives.Flex>
      {data.length > 1 && index < data.length - 1 && (
        <Primitives.Flex border="0.5px solid #E5E5E5" mx={2}></Primitives.Flex>
      )}
    </React.Fragment>
  ));
const SummaryBox = ({
  apiKeys,
  header,
  flex,
  request,
  viewTrendColumn,
  index
}) => {
  const { BASE_URL } = useContext(AppContext);
  const { data, isLoaded } = useApiRequest(`${BASE_URL}/dashboards/${request}`);
  const history = useHistory();
  const filterContext = useContext(FilterContext);
  const filteredData = getFilteredData(
    data,
    isLoaded,
    apiKeys,
    header,
    request,
    viewTrendColumn
  );
  return (
    <Primitives.Flex
      border="1px solid #E5E5E5"
      borderRadius="10px"
      flex={flex}
      flexDirection="column"
      mr={3}
    >
      <Primitives.Flex
        borderBottom="1px solid #E5E5E5"
        color={colors.text[0]}
        fontSize="14px"
        fontWeight={2}
        p={2}
      >
        {filteredData.header}
      </Primitives.Flex>
      {filteredData.showSummary ? (
        <>
          <Primitives.Flex flexDirection="column" p={2}>
            <Primitives.Flex>
              {valueMapper(filteredData.values, filterContext, history)}
            </Primitives.Flex>
            <Primitives.Flex color="#768698" fontSize="13px" pt={1}>
              {filteredData.summary &&
                getSummary(
                  filteredData.header,
                  filteredData.summary,
                  filteredData.summary.growth_indicator === "green"
                )}
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex
            background="#fff9f7"
            borderBottomLeftRadius="10px"
            borderBottomRightRadius="10px"
            color={colors.primary[3]}
            fontSize="12px"
            fontWeight={2}
            justifyContent="center"
            py={1}
            marginTop="auto"
          >
            <ViewTrend
              column={filteredData.viewTrendColumn}
              data={filteredData.viewTrendData}
              header={filteredData.header}
              index={index}
            />
          </Primitives.Flex>
        </>
      ) : (
        <Primitives.Flex m="30px auto">
          <Loading onlySpinner={true} />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};
SummaryBox.propTypes = propTypes;
SummaryBox.defaultProps = defaultProps;
export default SummaryBox;
