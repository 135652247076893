import React from "react";
import { array, number, string } from "prop-types";
import OrderStatus from "./OrderStatus";
import Primitives from "../../components/primitives";
import TooltipOnClick from "../tooltip-onclick";

const propTypes = {
  /** Text color of order status and date-time */
  color: array,
  /** Font-family of text */
  fontFamily: string,
  /** Line Height of text of order status and date-time */
  lineHeight: number,
  /** Font size of text of order status and date-time */
  fontSize: number,
  /** Font style of text */
  fontStyle: string,
  /** Font weight of text */
  fontWeight: number,
  /** Order status */
  orders: array
};

const defaultProps = {
  fontFamily: "Lato",
  lineHeight: 1.4,
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: 1
};
const TrackOrder = ({
  color,
  fontFamily,
  lineHeight,
  fontSize,
  fontStyle,
  fontWeight,
  orders
}) => {
  return (
    <TooltipOnClick
      backgroundColor="white"
      boxShadow="0px 0px 12px rgba(0, 0, 0, 0.2)"
      content={
        <Primitives.Flex flexDirection="column">
          <Primitives.Text color="black" fontWeight={3} fontSize={2} mb={1}>
            Track Item
          </Primitives.Text>
          {orders.map((order, index, element) => (
            <OrderStatus
              color={color}
              lineHeight={lineHeight}
              fontStyle={fontStyle}
              fontWeight={fontWeight}
              fontSize={fontSize}
              fontFamily={fontFamily}
              order={order}
              element={element}
              index={index}
              key={index}
            />
          ))}
        </Primitives.Flex>
      }
      value={<Primitives.Text>Track Item</Primitives.Text>}
      contentWidth="20vw"
      top="50%"
      left="-140%"
      transform="translateX(100%)"
      toolTipTriangle={{
        left: "90%",
        transform: "translateX(-50%)"
      }}
    />
  );
};

TrackOrder.propTypes = propTypes;
TrackOrder.defaultProps = defaultProps;
export default TrackOrder;
