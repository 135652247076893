import React from "react";

const SbLogo = ({ ...props }) => (
  <svg
    width="774"
    height="365"
    viewBox="0 0 774 365"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="sb-logo"
    {...props}
  >
    <path
      d="M768.924 266.66C768.427 291.595 762.886 316.082 754.173 339.373C752.915 342.674 745.954 363.783 742.783 363.783C737.834 363.783 732.883 363.785 727.931 363.785C673.018 363.796 618.104 363.804 563.19 363.811C454.976 363.827 346.763 363.843 238.549 363.853C174.465 363.861 110.379 363.902 46.2949 363.863C42.092 363.861 38.5908 358.431 36.1761 355.564C31.7314 350.282 28.6436 344.281 26.7669 337.915C24.2119 329.242 22.6134 316.417 27.3596 300.102C29.9224 291.293 35.4172 282.411 39.5733 275.739C48.4991 261.417 60.1176 249.115 72.9474 238.238C87.6954 225.733 106.67 214.216 113.932 195.358C124.675 167.471 106.241 140.109 108.757 112.009C112.952 65.1604 159.502 30.1462 200.395 14.3403C232.08 2.09277 267.2 -0.87034 300.91 0.20314C317.405 0.728184 333.876 2.3553 350.144 5.12347C365.004 7.64992 382.507 10.2335 396.135 17.0461C425.366 27.8641 450.669 45.4479 472.113 67.9286C482.531 78.8505 491.771 91.1111 503.14 101.084C516.708 112.984 530.593 112.555 547.485 109.28C579.627 102.974 611.031 96.1016 643.612 104.027C675.164 111.7 702.29 130.869 724.688 153.833C754.298 184.202 769.74 224.293 768.924 266.66C768.893 268.279 769.119 256.612 768.924 266.66Z"
      fill="#EDF4FF"
    />
    <path
      d="M166.077 260.54C166.077 260.54 141.457 254.439 131.786 269.725C122.114 285.009 102.77 285.602 92.6152 297.878C82.4601 310.154 74.7222 332.273 97.9332 343.216C121.144 354.159 178.878 338.101 178.878 338.101L189.912 266.887L166.077 260.54Z"
      fill="#E1ECFE"
    />
    <path
      d="M202.67 191.679C202.67 191.679 199.371 171.361 181.962 163.08C171.51 158.11 140.125 154.409 141.091 176.653C141.861 194.328 124.165 192.163 131.097 212.723C137.065 230.426 157.36 223.562 157.36 223.562L202.67 191.679Z"
      fill="#ACD29A"
    />
    <path
      d="M734.627 310.775C711.488 292.295 677.885 314.643 671.437 289.498C664.988 264.351 634.533 262.453 634.533 262.453L603.431 363.807C644.932 363.802 686.432 363.794 727.934 363.789C732.882 363.789 737.834 363.787 742.786 363.787C745.954 363.787 752.917 342.678 754.175 339.377C754.287 339.081 754.394 338.782 754.505 338.483C751.67 330.571 745.367 319.355 734.627 310.775Z"
      fill="#ACD29A"
    />
    <path
      d="M634.536 111.086H429.143V364.066H634.536V111.086Z"
      fill="#98BAF4"
    />
    <path
      d="M634.534 123.459H449.627V151.016H634.534V123.459Z"
      fill="#EDF4FF"
    />
    <path d="M529.335 168.836H297.37V364.932H529.335V168.836Z" fill="white" />
    <path d="M532.963 161.711H291.633V168.835H532.963V161.711Z" fill="white" />
    <path d="M638.346 107.523H424.999V114.648H638.346V107.523Z" fill="white" />
    <path d="M447.454 186.562H297.37V223.011H447.454V186.562Z" fill="#FF9F55" />
    <path d="M297.368 192.068H157.36V269.723H297.368V192.068Z" fill="#AFCDFB" />
    <path d="M297.368 285.879H157.36V363.533H297.368V285.879Z" fill="#AFCDFB" />
    <path
      d="M297.369 269.721H176.765V285.877H297.369V269.721Z"
      fill="#7FA8E5"
    />
    <path
      d="M317.784 217.878C314.275 217.878 310.977 216.511 308.495 214.032C306.013 211.552 304.648 208.251 304.648 204.742C304.648 201.233 306.015 197.935 308.495 195.452C310.977 192.973 314.275 191.605 317.784 191.605C321.293 191.605 324.592 192.973 327.074 195.452C329.556 197.935 330.921 201.233 330.921 204.742C330.921 208.251 329.554 211.549 327.074 214.032C324.594 216.511 321.293 217.878 317.784 217.878ZM317.784 192.814C314.598 192.814 311.603 194.054 309.35 196.307C307.096 198.561 305.857 201.555 305.857 204.742C305.857 207.929 307.096 210.923 309.35 213.176C311.603 215.43 314.598 216.67 317.784 216.67C320.971 216.67 323.965 215.43 326.219 213.176C328.472 210.923 329.712 207.929 329.712 204.742C329.712 201.555 328.472 198.561 326.219 196.307C323.968 194.057 320.971 192.814 317.784 192.814Z"
      fill="white"
    />
    <path
      d="M326.27 202.598H319.928V196.256H315.642V202.598H309.3V206.881H315.642V213.224H319.928V206.881H326.27V202.598Z"
      fill="white"
    />
    <path
      d="M341.341 198.449H344.13V203.466H349.274V198.449H352.063V211.126H349.274V206.036H344.13V211.126H341.341V198.449Z"
      fill="white"
    />
    <path
      d="M354.543 204.786C354.543 201.181 357.387 198.23 361.298 198.23C365.21 198.23 368.017 201.147 368.017 204.786C368.017 208.391 365.174 211.341 361.262 211.341C357.353 211.344 354.543 208.427 354.543 204.786ZM365.104 204.786C365.104 202.613 363.51 200.801 361.265 200.801C359.019 200.801 357.462 202.576 357.462 204.786C357.462 206.959 359.055 208.77 361.301 208.77C363.547 208.77 365.104 206.995 365.104 204.786Z"
      fill="white"
    />
    <path
      d="M369.541 209.278L371.188 207.305C372.33 208.246 373.525 208.844 374.973 208.844C376.114 208.844 376.803 208.392 376.803 207.612C376.803 206.905 376.369 206.544 374.25 206C371.695 205.348 370.047 204.641 370.047 202.088C370.047 199.788 371.895 198.268 374.484 198.268C376.332 198.268 377.907 198.847 379.194 199.879L377.746 201.979C376.623 201.2 375.519 200.729 374.45 200.729C373.382 200.729 372.821 201.218 372.821 201.87C372.821 202.704 373.364 202.975 375.555 203.536C378.126 204.207 379.576 205.13 379.576 207.375C379.576 209.891 377.655 211.305 374.921 211.305C373 211.308 371.061 210.637 369.541 209.278Z"
      fill="white"
    />
    <path
      d="M382.001 198.449H387.181C390.207 198.449 392.034 200.243 392.034 202.868C392.034 205.802 389.752 207.323 386.908 207.323H384.79V211.126H382.001V198.449ZM386.999 204.841C388.392 204.841 389.209 204.006 389.209 202.884C389.209 201.633 388.34 200.963 386.945 200.963H384.79V204.838H386.999V204.841Z"
      fill="white"
    />
    <path d="M394.228 198.449H397.017V211.126H394.228V198.449Z" fill="white" />
    <path
      d="M403.138 201.02H399.28V198.449H409.784V201.02H405.926V211.126H403.138V201.02Z"
      fill="white"
    />
    <path
      d="M414.639 198.357H417.21L422.642 211.125H419.726L418.567 208.281H413.207L412.048 211.125H409.204L414.639 198.357ZM417.571 205.82L415.887 201.708L414.202 205.82H417.571Z"
      fill="white"
    />
    <path
      d="M424.6 198.449H427.389V208.591H433.71V211.126H424.6V198.449Z"
      fill="white"
    />
    <path
      d="M634.534 271.156H540.993V332.888H634.534V271.156Z"
      fill="#AFCDFB"
    />
    <path d="M634.534 180.26H540.993V241.991H634.534V180.26Z" fill="#AFCDFB" />
    <path
      d="M634.533 124.926H451.574V149.247H634.533V124.926Z"
      fill="#AFCDFB"
    />
    <path
      d="M297.37 171.284L529.335 178.279V168.836H297.37V171.284Z"
      fill="#DFEBFD"
    />
    <path d="M297.367 191.605H148.6V199.294H297.367V191.605Z" fill="white" />
    <path
      d="M148.384 331.797C130.223 335.925 125.381 350.668 113.882 341.82C102.383 332.972 87.856 338.872 79.9881 339.257C72.1177 339.642 73.3263 321.18 56.3794 315.285C48.223 312.446 34.5797 311.945 24.8248 312.368C23.5381 322.947 24.8949 331.553 26.7716 337.919C28.6482 344.284 31.7361 350.288 36.1808 355.567C38.2394 358.013 41.0881 362.32 44.4905 363.537H177.441C184.095 347.507 166.542 327.667 148.384 331.797Z"
      fill="#ACD29A"
    />
    <path
      d="M99.9868 81.3827C99.9868 81.3827 112.286 66.944 141.699 68.5477C141.699 68.5477 184.402 44.4815 229.938 65.3403C229.938 65.3403 278.343 60.173 314.189 69.8162C338.089 76.2441 345.983 82.989 345.983 82.989L99.9868 81.3827Z"
      fill="#F8FCFF"
    />
    <path
      d="M365.079 63.674C365.079 58.4392 369.321 54.1973 374.556 54.1973C379.791 54.1973 384.033 58.4392 384.033 63.674C384.033 68.9089 379.791 73.1508 374.556 73.1508C369.324 73.1508 365.079 68.9063 365.079 63.674Z"
      fill="#F8FCFF"
    />
    <path
      d="M241.931 123.712C241.931 123.712 248.668 115.803 264.778 116.681C264.778 116.681 288.166 103.501 313.108 114.924C313.108 114.924 339.62 112.094 359.255 117.375C372.344 120.897 376.669 124.591 376.669 124.591L241.931 123.712Z"
      fill="#F8FCFF"
    />
    <path
      d="M496.465 277.766H333.468V362.916H496.465V277.766Z"
      fill="#98BAF4"
    />
    <path d="M491.745 283.721H338.947V363.202H491.745V283.721Z" fill="white" />
    <path d="M486.269 288.748H344.733V307.01H486.269V288.748Z" fill="#AFCDFB" />
    <path d="M386.12 311.969H344.733V362.856H386.12V311.969Z" fill="#AFCDFB" />
    <path
      d="M440.943 311.969H390.058V362.856H440.943V311.969Z"
      fill="#AFCDFB"
    />
    <path
      d="M486.269 311.969H444.442V362.856H486.269V311.969Z"
      fill="#AFCDFB"
    />
    <path
      d="M406.732 311.969H440.943V330.803L434.846 362.859H397.05L406.732 311.969Z"
      fill="#CCE1FF"
    />
    <path d="M434.799 321.656H396.73V362.919H434.799V321.656Z" fill="white" />
    <path d="M432.982 323.52H398.544V362.916H432.982V323.52Z" fill="#98BAF4" />
    <path d="M416.406 323.52H415.122V362.916H416.406V323.52Z" fill="white" />
    <path
      d="M412.052 343.218C412.052 342.768 412.416 342.404 412.866 342.404C413.315 342.404 413.679 342.768 413.679 343.218C413.679 343.668 413.315 344.031 412.866 344.031C412.416 344.031 412.052 343.668 412.052 343.218Z"
      fill="white"
    />
    <path
      d="M417.847 343.218C417.847 342.768 418.211 342.404 418.66 342.404C419.11 342.404 419.474 342.768 419.474 343.218C419.474 343.668 419.11 344.031 418.66 344.031C418.211 344.031 417.847 343.668 417.847 343.218Z"
      fill="white"
    />
    <path
      d="M357.898 311.969H378.853L372.165 362.859H351.21L357.898 311.969Z"
      fill="#CCE1FF"
    />
    <path
      d="M381.904 288.748L379.505 307.01H358.55L360.949 288.748H381.904Z"
      fill="#CCE1FF"
    />
    <path
      d="M454.342 311.969H475.297L468.609 362.859H447.654L454.342 311.969Z"
      fill="#CCE1FF"
    />
    <path
      d="M478.349 288.748L475.947 307.01H454.992L457.394 288.748H478.349Z"
      fill="#CCE1FF"
    />
    <path
      d="M448.944 288.748L445.469 307.01H407.673L411.149 288.748H448.944Z"
      fill="#CCE1FF"
    />
    <path
      d="M444.476 315.719H444.554L444.476 316.124V315.719Z"
      fill="#CCE1FF"
    />
    <path
      d="M321.992 362.881C321.992 362.881 320.986 353.978 330.013 351.72C339.04 349.464 342.583 341.403 353.063 348.174C363.543 354.945 360.227 362.881 360.227 362.881H321.992Z"
      fill="#ACD29A"
    />
    <path
      d="M584.711 241.991H553.762L567.302 180.26H598.253L584.711 241.991Z"
      fill="#BDD7FD"
    />
    <path
      d="M612.136 332.888H581.184L594.726 271.156H625.675L612.136 332.888Z"
      fill="#CCE1FF"
    />
    <path
      d="M568.118 271.156L554.579 332.888H540.993V271.156H568.118Z"
      fill="#CCE1FF"
    />
    <path
      d="M634.533 180.26V241.991H600.509L615.621 180.26H634.533Z"
      fill="#CCE1FF"
    />
    <path
      d="M498.952 124.926H496.374V149.247H498.952V124.926Z"
      fill="#EDF4FF"
    />
    <path d="M551.02 124.926H548.442V149.247H551.02V124.926Z" fill="#EDF4FF" />
    <path d="M601.799 124.926H599.22V149.247H601.799V124.926Z" fill="#EDF4FF" />
    <g opacity="0.5">
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M189.273 219.938H173.763V244.42H189.273V219.938Z"
          fill="#98BAF4"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M189.915 245.21H172.828V219.15H189.915V245.21ZM174.405 243.633H188.337V220.728H174.405V243.633Z"
              fill="white"
            />
          </g>
          <path
            opacity="0.5"
            d="M188.534 228.125H174.207V229.308H188.534V228.125Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M188.534 236.273H174.207V237.456H188.534V236.273Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M181.962 244.419V219.822H180.78V244.419H181.962Z"
            fill="white"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M234.058 219.938H218.548V244.42H234.058V219.938Z"
          fill="#98BAF4"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M234.699 245.21H217.611V219.15H234.699V245.21ZM219.189 243.633H233.121V220.728H219.189V243.633Z"
              fill="white"
            />
          </g>
          <path
            opacity="0.5"
            d="M233.319 228.125H218.992V229.308H233.319V228.125Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M233.319 236.273H218.992V237.456H233.319V236.273Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M226.747 244.419V219.822H225.564V244.419H226.747Z"
            fill="white"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M278.841 219.938H263.332V244.42H278.841V219.938Z"
          fill="#98BAF4"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M279.484 245.21H262.397V219.15H279.484V245.21ZM263.972 243.633H277.904V220.728H263.972V243.633Z"
              fill="white"
            />
          </g>
          <path
            opacity="0.5"
            d="M278.103 228.125H263.776V229.308H278.103V228.125Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M278.103 236.273H263.776V237.456H278.103V236.273Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M271.53 244.419V219.822H270.348V244.419H271.53Z"
            fill="white"
          />
        </g>
      </g>
    </g>
    <g opacity="0.5">
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M189.273 303.885H173.764V328.367H189.273V303.885Z"
          fill="#98BAF4"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M189.915 329.154H172.828V303.094H189.915V329.154ZM174.406 327.576H188.338V304.671H174.406V327.576Z"
              fill="white"
            />
          </g>
          <path
            opacity="0.5"
            d="M188.534 312.07H174.208V313.253H188.534V312.07Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M188.534 320.217H174.208V321.399H188.534V320.217Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M181.964 328.364V303.768H180.781V328.364H181.964Z"
            fill="white"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M234.058 303.885H218.549V328.367H234.058V303.885Z"
          fill="#98BAF4"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M234.699 329.154H217.612V303.094H234.699V329.154ZM219.19 327.576H233.121V304.671H219.19V327.576Z"
              fill="white"
            />
          </g>
          <path
            opacity="0.5"
            d="M233.32 312.07H218.993V313.253H233.32V312.07Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M233.32 320.217H218.993V321.399H233.32V320.217Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M226.748 328.364V303.768H225.565V328.364H226.748Z"
            fill="white"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M278.842 303.885H263.332V328.367H278.842V303.885Z"
          fill="#98BAF4"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M279.485 329.154H262.397V303.094H279.485V329.154ZM263.973 327.576H277.904V304.671H263.973V327.576Z"
              fill="white"
            />
          </g>
          <path
            opacity="0.5"
            d="M278.104 312.07H263.777V313.253H278.104V312.07Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M278.104 320.217H263.777V321.399H278.104V320.217Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M271.532 328.366V303.77H270.35V328.366H271.532Z"
            fill="white"
          />
        </g>
      </g>
    </g>
    <path
      d="M536.171 252.008H293.765V260.538H536.171V252.008Z"
      fill="#DFEBFD"
    />
    <path
      d="M214.015 191.605C214.015 191.605 218.205 177.967 228.524 178.279C238.84 178.591 242.066 188.086 245.611 186.562C249.157 185.039 259.476 185.918 260.12 191.605H214.015Z"
      fill="#ACD29A"
    />
    <path
      d="M705.361 315.911V321.689C705.361 322.203 705.238 322.697 705.02 323.134C704.49 324.197 703.385 324.927 702.122 324.927H701.085C699.31 324.927 697.846 323.474 697.846 321.689V315.911C697.846 315.302 698.02 314.736 698.317 314.247C698.317 314.239 698.317 314.239 698.317 314.239C698.891 313.298 699.918 312.672 701.085 312.672H702.122C703.905 312.672 705.361 314.133 705.361 315.911Z"
      fill="#233862"
    />
    <path
      opacity="0.3"
      d="M705.02 323.133C704.489 324.196 703.385 324.926 702.121 324.926H701.084C699.309 324.926 697.846 323.473 697.846 321.688V315.91C697.846 315.301 698.02 314.735 698.316 314.246C698.379 314.602 699.753 322.532 705.02 323.133Z"
      fill="#233862"
    />
    <path
      d="M654.03 358.206H670.034V342.055H653.336L654.03 358.206Z"
      fill="#172944"
    />
    <path
      d="M704.495 324.065L625.866 326.004V284.541H677.499C682.422 284.541 686.929 287.296 689.172 291.676L692.551 298.27L704.495 324.065Z"
      fill="white"
    />
    <path
      d="M659.827 355.579C659.827 359.964 662.088 364.802 662.088 364.802C662.088 364.802 665.449 364.802 665.917 364.802C669.28 364.802 672.007 360.674 672.007 355.579C672.007 350.485 665.452 346.357 662.088 346.357C658.725 346.357 659.827 350.488 659.827 355.579Z"
      fill="#233862"
    />
    <path
      d="M655.998 355.579C655.998 360.671 658.725 364.802 662.088 364.802C665.451 364.802 668.178 360.674 668.178 355.579C668.178 350.485 665.451 346.357 662.088 346.357C658.725 346.357 655.998 350.488 655.998 355.579Z"
      fill="#2B478B"
    />
    <path
      d="M657.579 355.578C657.579 359.414 659.632 362.523 662.166 362.523C664.701 362.523 666.754 359.414 666.754 355.578C666.754 351.741 664.701 348.633 662.166 348.633C659.632 348.633 657.579 351.741 657.579 355.578Z"
      fill="#AFCDFB"
    />
    <path
      d="M703.852 355.579C703.852 359.964 706.114 364.802 706.114 364.802C706.114 364.802 709.475 364.802 709.942 364.802C713.306 364.802 716.032 360.674 716.032 355.579C716.032 350.485 709.477 346.357 706.114 346.357C702.75 346.357 703.852 350.488 703.852 355.579Z"
      fill="#233862"
    />
    <path
      d="M700.024 355.579C700.024 360.671 702.751 364.802 706.114 364.802C709.477 364.802 712.204 360.674 712.204 355.579C712.204 350.485 709.477 346.357 706.114 346.357C702.751 346.357 700.024 350.488 700.024 355.579Z"
      fill="#2B478B"
    />
    <path
      d="M602.753 355.579C602.753 359.964 605.015 364.802 605.015 364.802C605.015 364.802 608.375 364.802 608.843 364.802C612.207 364.802 614.933 360.674 614.933 355.579C614.933 350.485 608.378 346.357 605.015 346.357C601.651 346.357 602.753 350.488 602.753 355.579Z"
      fill="#233862"
    />
    <path
      d="M598.924 355.579C598.924 360.671 601.651 364.802 605.014 364.802C608.378 364.802 611.104 360.674 611.104 355.579C611.104 350.485 608.378 346.357 605.014 346.357C601.651 346.357 598.924 350.488 598.924 355.579Z"
      fill="#2B478B"
    />
    <path
      d="M722.099 351.228C722.107 355.083 718.983 358.207 715.136 358.207H670.034C670.164 357.451 670.234 356.666 670.234 355.858C670.234 349.895 666.658 345.058 662.252 345.058C657.839 345.058 654.262 349.898 654.262 355.858C654.262 356.666 654.322 357.451 654.454 358.207H628.101C628.101 358.207 627.038 346.441 627.17 340.54C627.274 335.404 627.274 325.345 626.97 322.889C626.97 322.523 652.385 322.478 658.8 322.455C659.671 322.447 660.194 322.447 660.246 322.447L660.331 322.507L660.428 322.577C661.61 323.404 668.243 328.051 672.361 330.775C675.989 333.169 702.779 334.144 713.285 334.44C715.895 334.51 717.506 334.536 717.506 334.536C717.506 334.536 718.944 336.007 720.212 338.331C721.187 340.132 722.058 342.448 722.076 344.98C722.084 346.555 722.094 348.453 722.094 350.316C722.099 350.618 722.099 350.922 722.099 351.228Z"
      fill="white"
    />
    <path
      d="M698.155 322.444H662.819C662 322.444 661.262 321.966 660.921 321.225L652.175 301.765C651.886 301.131 652.357 300.408 653.053 300.408H690.331C691.15 300.408 691.899 300.897 692.229 301.653L698.496 315.97L700.063 319.546C700.653 320.913 699.652 322.444 698.155 322.444Z"
      fill="#98BAF4"
    />
    <path
      d="M681.694 350.314C681.687 350.925 681.661 351.499 681.627 352.011C681.372 355.398 677.97 358.208 677.97 358.208H670.032C670.162 357.451 670.235 356.666 670.235 355.858C670.235 353.833 669.816 351.933 669.104 350.314C667.7 347.166 665.161 345.058 662.255 345.058C659.339 345.058 656.797 347.166 655.393 350.314C654.681 351.933 654.262 353.833 654.262 355.858C654.262 356.669 654.325 357.451 654.457 358.208H625.866V284.543H648.817L648.926 298.269C648.926 298.269 649.313 300.328 650.096 302.166C650.798 303.808 651.731 305.994 652.744 308.373C654.834 313.251 657.275 318.946 658.801 322.453C659.565 324.228 660.105 325.437 660.243 325.699C660.672 326.515 667.04 329.967 672.423 333.032C673.767 333.796 675.051 334.534 676.161 335.202C677.746 336.156 678.986 336.962 679.571 337.492C681.042 338.817 681.775 345.695 681.694 350.314Z"
      fill="#DFEBFD"
    />
    <path
      d="M717.504 334.534H675.623L660.332 322.508L660.243 322.445H702.491L714.518 332.125L717.504 334.534Z"
      fill="white"
    />
    <path
      d="M681.044 341.601L677.257 341.227C675.934 341.097 674.863 340.104 674.629 338.799L673.758 334.011C673.74 333.933 673.733 333.863 673.74 333.785C676.438 335.334 678.71 336.717 679.57 337.492C680.199 338.058 680.696 339.626 681.044 341.601Z"
      fill="#A8302D"
    />
    <path
      d="M692.556 298.27H648.925C648.925 298.27 648.756 295.479 647.662 292.289C646.688 289.474 645.003 286.332 642.056 284.541H677.709C683.521 284.541 688.67 287.645 690.716 292.289C690.812 292.482 690.895 292.674 690.968 292.866C691.581 294.48 692.135 296.284 692.556 298.27Z"
      fill="white"
    />
    <path
      d="M681.052 341.601L677.257 341.227C675.934 341.097 674.863 340.104 674.629 338.799L673.758 334.011C673.74 333.933 673.733 333.855 673.74 333.777C676.438 335.334 678.71 336.72 679.57 337.494C680.199 338.058 680.696 339.626 681.052 341.601Z"
      fill="#A2C2DD"
    />
    <path
      d="M722.099 351.227C722.107 355.082 718.834 358.206 714.798 358.206H670.037C670.166 357.45 670.237 356.665 670.237 355.857C670.237 353.829 669.818 351.932 669.106 350.312H722.094C722.099 350.617 722.099 350.921 722.099 351.227Z"
      fill="#233862"
    />
    <path
      d="M654.265 355.857C654.265 356.665 654.325 357.45 654.457 358.206H625.866V350.312H655.398C654.681 351.932 654.265 353.829 654.265 355.857Z"
      fill="#233862"
    />
    <path
      d="M692.611 334.873H709.737C710.283 334.873 710.701 335.611 710.618 336.427L710.01 342.38C709.922 343.256 709.423 343.9 708.838 343.9H694.729C693.825 343.9 693.027 343.006 692.764 341.696L691.748 336.635C691.571 335.759 692.005 334.873 692.611 334.873Z"
      fill="#233862"
    />
    <path
      d="M698.495 315.97C678.294 314.935 664.63 304.125 660.505 300.408H690.331C691.15 300.408 691.898 300.897 692.228 301.653L698.495 315.97Z"
      fill="#AFCDFB"
    />
    <path d="M633.947 284.537H557.535V358.212H633.947V284.537Z" fill="white" />
    <path
      d="M692.556 298.268H648.926C648.926 298.268 648.757 295.476 647.663 292.287H690.716C690.812 292.479 690.896 292.672 690.968 292.864C691.582 294.478 692.135 296.282 692.556 298.268Z"
      fill="#DFEBFD"
    />
    <path
      d="M720.209 338.329C719.965 338.729 719.653 338.992 719.313 339.051L713.438 340.14C712.942 340.237 712.56 339.374 712.69 338.487L713.264 334.536L713.282 334.44L713.394 333.632C713.498 332.935 713.802 332.405 714.176 332.257L714.517 332.127L717.501 334.539C717.503 334.534 718.938 336.005 720.209 338.329Z"
      fill="#233862"
    />
    <path d="M679.57 333.029H557.534V339.239H679.57V333.029Z" fill="#EA6A60" />
    <path
      d="M633.946 297.305H557.534V303.512H633.946V297.305Z"
      fill="#EA6A60"
    />
    <path
      d="M584.862 318.131C584.862 312.124 589.731 307.256 595.737 307.256C601.744 307.256 606.615 312.124 606.615 318.131C606.615 324.138 601.744 329.009 595.737 329.009C589.731 329.009 584.862 324.138 584.862 318.131Z"
      fill="#EA6A60"
    />
    <path
      d="M595.74 327.762C593.166 327.762 590.749 326.762 588.93 324.942C587.11 323.123 586.109 320.705 586.109 318.132C586.109 315.559 587.11 313.142 588.93 311.322C590.749 309.503 593.166 308.502 595.74 308.502C598.313 308.502 600.73 309.503 602.549 311.322C604.369 313.142 605.37 315.559 605.37 318.132C605.37 320.705 604.369 323.123 602.549 324.942C600.73 326.762 598.31 327.762 595.74 327.762ZM595.74 309.388C593.403 309.388 591.209 310.298 589.556 311.949C587.905 313.599 586.996 315.795 586.996 318.132C586.996 320.469 587.905 322.663 589.556 324.316C591.206 325.966 593.403 326.876 595.74 326.876C598.074 326.876 600.27 325.966 601.923 324.316C603.576 322.665 604.483 320.469 604.483 318.132C604.483 315.795 603.574 313.602 601.923 311.949C600.273 310.295 598.074 309.388 595.74 309.388Z"
      fill="white"
    />
    <path
      d="M601.958 316.56H597.31V311.91H594.168V316.56H589.521V319.7H594.168V324.35H597.31V319.7H601.958V316.56Z"
      fill="white"
    />
    <path
      d="M688.233 342.315L681.054 341.6H681.047L677.26 341.226C675.937 341.096 674.866 340.103 674.632 338.799L673.761 334.011C673.743 333.933 673.735 333.863 673.743 333.785V333.777C673.699 332.768 674.621 331.95 675.658 332.158L686.843 334.429C687.558 334.577 688.122 335.108 688.314 335.804L689.629 340.662C689.868 341.549 689.146 342.411 688.233 342.315Z"
      fill="#233862"
    />
    <path
      d="M649.071 303.909L656.598 322.449H643.682C643.682 322.449 641.034 322.005 640.693 316.59C640.329 310.81 640.332 300.837 640.332 300.837L646.001 300.811C648.161 301.133 648.499 302.321 649.071 303.909Z"
      fill="#98BAF4"
    />
    <path
      d="M652.767 312.928C652.767 312.928 653.419 320.421 649.721 322.472H656.632L652.767 312.928Z"
      fill="#233862"
    />
    <path
      d="M653.784 317.942C653.784 317.942 652.225 316.832 652.089 315.912V321.152H654.132L653.784 317.942Z"
      fill="#233862"
    />
    <path
      d="M652.089 315.911V321.689C652.089 322.203 651.966 322.697 651.748 323.134C651.218 324.197 650.113 324.927 648.85 324.927H647.813C646.038 324.927 644.574 323.474 644.574 321.689V315.911C644.574 315.302 644.748 314.736 645.045 314.247C645.045 314.239 645.045 314.239 645.045 314.239C645.619 313.298 646.646 312.672 647.813 312.672H648.85C650.633 312.672 652.089 314.133 652.089 315.911Z"
      fill="#233862"
    />
    <path
      d="M634.019 355.857C634.019 356.665 634.13 357.45 634.372 358.206H581.761V350.312H636.101C634.788 351.932 634.019 353.829 634.019 355.857Z"
      fill="#233862"
    />
    <path
      d="M609.792 355.857C609.792 356.665 609.903 357.45 610.145 358.206H557.534V350.312H611.874C610.561 351.932 609.792 353.829 609.792 355.857Z"
      fill="#233862"
    />
    <path
      d="M582.492 358.212H563.697C563.299 356.515 562.104 344.436 572.638 344.434C582.209 344.434 582.913 355.457 582.492 358.212Z"
      fill="#172944"
    />
    <path
      d="M569.579 355.579C569.579 359.964 571.84 364.802 571.84 364.802C571.84 364.802 575.201 364.802 575.669 364.802C579.032 364.802 581.759 360.674 581.759 355.579C581.759 350.485 575.204 346.357 571.84 346.357C568.477 346.357 569.579 350.488 569.579 355.579Z"
      fill="#233862"
    />
    <path
      d="M565.75 355.579C565.75 360.671 568.477 364.802 571.84 364.802C575.204 364.802 577.93 360.674 577.93 355.579C577.93 350.485 575.204 346.357 571.84 346.357C568.477 346.357 565.75 350.488 565.75 355.579Z"
      fill="#2B478B"
    />
    <path
      d="M567.331 355.58C567.331 359.416 569.384 362.525 571.919 362.525C574.453 362.525 576.506 359.416 576.506 355.58C576.506 351.743 574.453 348.635 571.919 348.635C569.384 348.635 567.331 351.743 567.331 355.58Z"
      fill="#AFCDFB"
    />
    <path
      d="M446.282 279.859C445.892 279.108 454.777 276.405 454.917 283.815C454.917 283.815 471.56 283.407 463.614 274.13C460.864 270.92 461.145 268.264 461.228 265.792C461.311 263.32 459.515 256.136 453.355 256.858C453.355 256.858 451.741 256.201 449.729 257.246C445.152 259.622 434.895 282.593 446.282 279.859Z"
      fill="#163560"
    />
    <path
      d="M473.493 288.145C473.493 288.145 474.577 286.494 475.45 286.463C476.324 286.432 477.94 285.977 477.81 286.302C477.681 286.624 476.68 286.658 476.581 286.853C476.485 287.048 478.068 287.339 478.52 287.37C478.972 287.401 480.041 287.37 480.007 287.596C479.976 287.823 478.083 288.761 476.75 288.631C475.417 288.501 473.766 289.018 473.766 289.018L473.493 288.145Z"
      fill="#FFB27D"
    />
    <path
      d="M457.825 288.677C457.825 288.677 458.415 289.845 459.484 291.183C460.77 292.795 462.756 294.658 465.249 295.025C466.439 295.202 467.603 294.944 468.674 294.469C468.677 294.469 468.677 294.469 468.677 294.469C471.705 293.125 473.979 290.034 473.979 290.034C474.512 288.579 473.639 287.594 473.639 287.594C473.639 287.594 467.528 288.969 466.946 288.677C466.364 288.386 462.969 278.605 462.161 277.376C461.352 276.147 458.597 275.51 458.597 275.51L457.825 288.677Z"
      fill="#163560"
    />
    <path
      d="M435.022 354.651C435.022 354.651 433.158 356.354 433.52 357.563C433.884 358.771 435.568 359.577 435.794 361.056C436.02 362.535 436.932 363.341 437.751 363.341C438.57 363.341 441.655 363.341 441.655 363.341C441.655 363.341 442.258 362.301 440.803 361.986C439.347 361.672 438.799 359.936 438.89 358.324C438.981 356.713 438.752 354.563 438.752 354.563L435.659 353.846L435.022 354.651Z"
      fill="#233862"
    />
    <path
      d="M450.807 355.375C450.807 355.375 450.136 362.585 450.955 362.988L461.433 362.967C461.433 362.967 461.433 361.759 459.748 361.488C458.067 361.221 455.109 360.233 454.61 358.039C454.111 355.845 450.807 355.375 450.807 355.375Z"
      fill="#233862"
    />
    <path
      d="M443.339 303.646C445.497 298.811 446.814 297.423 446.562 295.921C446.375 294.803 447.134 275.093 447.134 275.093C447.389 275.015 447.904 274.943 448.184 274.88C449.068 274.683 449.801 274.062 450.105 273.209C450.472 272.182 450.763 270.664 450.757 268.762L451.033 268.816L455.678 269.736C455.678 269.736 455.571 271.197 455.569 272.575C455.569 272.585 455.569 272.598 455.569 272.609C455.563 273.804 456.348 274.859 457.497 275.192C458.085 275.361 458.594 275.507 458.594 275.507C458.594 275.507 460.299 280.375 460.624 285.376C460.949 290.377 457.523 292.55 460.65 302.546C463.842 312.756 442.149 306.318 443.339 303.646Z"
      fill="#FFB27D"
    />
    <path
      d="M455.678 269.738C455.678 269.738 455.571 271.199 455.569 272.577C452.97 272.504 451.597 270.134 451.033 268.818L455.678 269.738Z"
      fill="#E8945B"
    />
    <path
      d="M456.547 271.044C456.547 271.044 450.397 271.145 450.394 267.314C450.389 263.483 449.594 260.917 453.518 260.691C457.443 260.465 458.207 261.926 458.522 263.202C458.831 264.484 458.145 270.919 456.547 271.044Z"
      fill="#FFB27D"
    />
    <path
      d="M456.318 260.599C456.318 260.599 454.633 265.093 452.05 265.756C449.466 266.422 448.346 265.728 448.346 265.728C448.346 265.728 450.326 264.152 450.74 261.405C450.74 261.405 455.049 258.46 456.318 260.599Z"
      fill="#163560"
    />
    <path
      d="M455.901 260.961C455.901 260.961 457.149 261.429 457.664 262.339C458.165 263.23 458.594 264.408 458.495 265.856C458.495 265.856 459.764 262.801 458.339 261.073C456.824 259.241 455.901 260.961 455.901 260.961Z"
      fill="#163560"
    />
    <path
      d="M455.208 268.668C455.208 268.668 455.631 269.227 456.481 269.185C457.165 269.154 457.43 268.559 457.43 268.559C457.43 268.559 456.172 268.26 455.208 268.668Z"
      fill="#EBF3FA"
    />
    <path
      d="M444.177 283.814C444.177 283.814 444.2 286.73 444.356 289.371C444.46 291.123 444.621 292.753 444.873 293.333C445.505 294.788 446.215 295.025 446.215 295.025L451.717 296.374L453.651 296.847L459.751 295.025C459.751 295.025 462.53 287.269 461.415 282.904C460.3 278.538 458.595 275.507 458.595 275.507C458.595 275.507 453.617 280.381 447.135 275.094L444.177 283.814Z"
      fill="#163560"
    />
    <path
      d="M459.364 296.203C459.364 296.203 462.384 305.607 462.579 308.807C462.774 312.009 456.803 354.285 456.564 355.762C456.338 357.152 454.612 358.039 454.612 358.039C454.612 358.039 453.817 358.507 452.684 358.187C451.111 357.742 450.615 357.212 450.615 357.212C450.615 357.212 448.262 327.061 449.718 316.29C451.174 305.519 451.189 296.206 451.189 296.206H459.364V296.203Z"
      fill="#ED7D2B"
    />
    <path
      d="M446.563 295.926C446.563 295.926 441.955 302.109 441.526 306.289C441.097 310.468 440.852 325.149 439.938 329.224C439.023 333.3 435.659 341.139 435.659 353.85C435.659 353.85 436.007 354.577 437.089 354.967C437.434 355.092 437.897 355.212 438.344 355.315C439.233 355.521 440.148 355.084 440.554 354.265C442.828 349.688 450.069 334.925 450.441 331.86C450.703 329.692 452.991 315.337 457.979 306.915C459.177 304.893 457.243 296.854 457.243 296.854L446.563 295.926Z"
      fill="#F99746"
    />
    <path
      d="M455.342 268.772C455.342 268.772 455.449 269.225 456.572 269.154C456.572 269.154 457.515 269.097 457.487 268.601C457.487 268.598 456.572 268.245 455.342 268.772Z"
      fill="white"
    />
    <path
      d="M459.845 294.752C459.845 294.752 458.631 295.711 456.224 295.76C453.346 295.818 446.214 295.027 446.214 295.027C446.214 295.027 445.897 295.914 445.91 296.847C445.91 296.847 454.62 299.043 459.665 297.167C459.665 297.167 460.318 295.942 459.845 294.752Z"
      fill="#233862"
    />
    <path
      d="M455.6 296.754C455.6 295.936 456.19 295.268 456.912 295.268C457.635 295.268 458.225 295.936 458.225 296.754C458.225 297.573 457.635 298.241 456.912 298.241C456.19 298.241 455.6 297.573 455.6 296.754ZM456.036 296.754C456.036 297.334 456.429 297.804 456.915 297.804C457.401 297.804 457.793 297.334 457.793 296.754C457.793 296.175 457.398 295.704 456.915 295.704C456.431 295.704 456.036 296.175 456.036 296.754Z"
      fill="white"
    />
    <path
      d="M435.109 310.787L448.814 313.742L450.47 306.061L436.766 303.106L435.109 310.787Z"
      fill="#163560"
    />
    <path
      d="M450.443 306.057C450.443 306.057 446.612 309.267 442.354 308.149C438.097 307.032 436.74 303.102 436.74 303.102L450.443 306.057Z"
      fill="#17467F"
    />
    <path
      d="M447.766 305.553C447.766 305.134 448.107 304.791 448.528 304.791C448.949 304.791 449.289 305.132 449.289 305.553C449.289 305.974 448.949 306.314 448.528 306.314C448.107 306.314 447.766 305.971 447.766 305.553ZM448.018 305.553C448.018 305.833 448.247 306.062 448.528 306.062C448.808 306.062 449.037 305.833 449.037 305.553C449.037 305.272 448.808 305.043 448.528 305.043C448.247 305.043 448.018 305.272 448.018 305.553Z"
      fill="#17467F"
    />
    <path
      d="M437.372 306.52C437.372 306.52 437.367 307.632 437.156 308.17C436.946 308.708 436.312 309.423 436.101 309.423C435.891 309.423 436.293 308.305 436.189 308.326C436.085 308.347 435.573 310.187 435.204 310.231C434.835 310.275 434.5 308.167 434.692 307.341C434.885 306.514 435.722 305.521 435.722 305.521L437.372 306.52Z"
      fill="#FFB27D"
    />
    <path
      d="M447.134 275.098C447.134 275.098 443.732 275.56 441.902 279.165C440.488 281.952 439.516 290.722 438.518 293.482C437.561 296.123 436.243 301.23 435.508 305.324C435.451 305.641 435.625 305.953 435.926 306.073L437.177 306.569C437.447 306.676 437.754 306.572 437.902 306.322C439.014 304.456 443.397 296.983 444.917 292.658C446.695 287.603 450.025 284.463 450.025 284.463L447.134 275.098Z"
      fill="#163560"
    />
    <path
      d="M448.777 304.966C448.785 304.841 449.578 292.339 449.424 285.308C449.344 281.617 448.775 279.015 448.309 277.484C447.803 275.813 447.046 275.129 447.025 275.096L446.768 275.171C446.786 275.202 448.886 278.36 449.035 285.313C449.185 292.326 448.395 304.813 448.387 304.937L448.777 304.966Z"
      fill="#0C2442"
    />
    <path
      d="M236.015 356.712L234.073 356.083C234.073 356.083 233.671 350.812 234.533 344.493C235.079 340.49 236.085 336.155 237.902 332.672C238.726 331.097 239.714 329.696 240.904 328.578C240.904 328.578 242.394 325.118 244.369 320.492C246.563 315.348 260.3 313.84 252.46 324.614C250.851 326.824 249.126 330.717 247.057 333.08C243.522 337.119 241.024 341.837 239.311 345.988C236.862 351.927 236.015 356.712 236.015 356.712Z"
      fill="#FFB27D"
    />
    <path
      d="M231.593 357.007C231.003 357.961 232.277 358.897 233.849 359.778C235.422 360.659 234.772 360.748 236.781 362.245C238.793 363.742 239.978 363.578 240.288 363.357C240.561 363.162 239.739 362.24 238.876 361.226C238.767 361.099 238.661 360.971 238.551 360.841C237.587 359.687 237.444 358.24 236.995 356.737C236.566 355.315 237.244 351.778 237.244 351.778L234.559 349.646C234.559 349.646 234.013 352.862 233.714 354.2C233.538 354.988 232.893 355.648 232.37 356.132C232.087 356.399 231.801 356.67 231.593 357.007Z"
      fill="#FFB27D"
    />
    <path
      d="M233.85 359.775C235.422 360.656 234.772 360.744 236.781 362.242C238.793 363.739 239.978 363.575 240.288 363.354C240.561 363.159 239.739 362.236 238.876 361.223L238.85 361.233C238.85 361.233 237.034 360.989 235.482 359.611C234.216 358.488 233.236 356.913 232.371 356.131C232.087 356.396 231.799 356.669 231.593 357.004C231.006 357.961 232.277 358.896 233.85 359.775Z"
      fill="#1C468A"
    />
    <path
      d="M224.536 360.134L222.298 361.293C222.298 361.293 220.96 359.549 219.764 357.441C218.927 355.965 218.158 354.312 217.97 352.955C217.513 349.664 214.027 344.253 214.391 343.332C214.753 342.412 221.664 340.816 221.664 340.816C221.664 340.816 223.016 350.064 223.912 356.043C224.172 357.792 224.396 359.258 224.536 360.134Z"
      fill="#FFB27D"
    />
    <path
      d="M222.832 347.221C221.553 347.842 220.365 348.198 219.344 348.398C217.823 348.697 216.682 348.645 216.175 348.596C215.988 348.578 215.889 348.56 215.889 348.56C215.889 348.56 214.647 347.663 213.204 345.576C211.884 343.663 210.901 340.926 210.901 340.892L221.454 339.865L222.832 347.221Z"
      fill="#2B478B"
    />
    <path
      d="M219.343 348.4C217.823 348.699 216.682 348.647 216.175 348.598L217.36 343.992C217.363 343.99 218.899 346.389 219.343 348.4Z"
      fill="#233862"
    />
    <path
      d="M224.536 360.133L222.298 361.293C222.298 361.293 220.96 359.549 219.764 357.441C220.695 357.371 222.314 357.09 223.913 356.045C224.172 357.792 224.396 359.258 224.536 360.133Z"
      fill="white"
    />
    <path
      d="M219.45 342.932C219.45 342.932 216.622 346.924 214.907 352.533C214.504 353.856 213.129 355.501 211.741 356.962C210.394 358.381 209.032 359.626 208.528 360.24L206.249 359.143C206.249 359.143 207.049 356.845 208.237 354.758C208.676 353.984 209.17 353.238 209.695 352.648C210.42 351.834 210.181 341.57 210.906 340.891C211.626 340.215 219.45 342.932 219.45 342.932Z"
      fill="#FFB27D"
    />
    <path
      d="M220.797 340.557L216.62 349.046C216.596 349.048 213.506 349.142 211.315 347.915C210.057 347.211 209.493 346.379 209.493 346.379L210.795 340.559H220.797V340.557Z"
      fill="#2B478B"
    />
    <path
      d="M213.315 312.213C213.315 312.213 211.893 309.227 208.462 311.004C205.031 312.782 208.371 319.561 206.57 321.238C204.769 322.911 207.223 324.008 206.856 325.209C206.49 326.41 207.535 327.775 209.936 327.613C212.338 327.452 214.867 327.582 215.951 325.482C217.035 323.382 216.79 318.758 216.369 318.108C215.948 317.458 213.315 312.213 213.315 312.213Z"
      fill="#B64C41"
    />
    <path
      d="M213.033 324.623L213.045 324.629C213.033 324.638 213.033 324.623 213.033 324.623Z"
      fill="#FFB27D"
    />
    <path
      d="M218.878 328.15L213.043 324.628C213.074 324.615 213.194 324.438 213.63 323.177C213.815 322.639 213.914 322.171 213.963 321.802C213.968 321.766 213.973 321.729 213.976 321.693C214.036 321.155 213.992 320.846 213.992 320.846L215.271 321.308L217.636 322.164C217.607 322.764 217.693 323.185 217.82 323.474C218.13 324.175 218.704 324.18 218.72 324.18L218.878 328.15Z"
      fill="#FFB27D"
    />
    <path
      d="M217.818 323.478C215.533 323.673 214.247 322.181 213.963 321.807C213.922 321.752 213.901 321.721 213.901 321.721C213.901 321.721 213.929 321.713 213.976 321.698C214.161 321.643 214.665 321.49 215.271 321.311L217.636 322.166C217.607 322.766 217.691 323.19 217.818 323.478Z"
      fill="#ED985F"
    />
    <path
      d="M217.932 322.657C217.932 322.657 212.856 323.216 212.752 319.783C212.648 316.349 211.896 314.779 215.411 314.472C218.922 314.166 219.754 315.431 219.959 316.593C220.606 320.222 219.361 322.504 217.932 322.657Z"
      fill="#FFB27D"
    />
    <path
      d="M208.466 311.003C208.395 310.844 208.343 310.74 208.343 310.74C208.395 310.813 208.434 310.904 208.466 311.003Z"
      fill="#D35C2B"
    />
    <path
      d="M219.564 324.527C219.564 324.527 224.433 320.786 224.953 319.549C225.473 318.312 227.708 313.779 228.285 313.316C228.285 313.316 229.496 313.16 229.668 313.997C229.842 314.834 228.516 319.806 227.591 321.306C226.668 322.806 222.242 327.895 220.789 328.633L219.564 324.527Z"
      fill="#FFB27D"
    />
    <path
      d="M231.394 311.027C231.313 311.461 231.136 311.926 230.929 312.165C230.656 312.488 230.222 313.13 229.949 313.546C229.772 313.803 229.66 313.995 229.66 313.995L228.28 313.322C228.28 313.322 228.249 310.978 229.164 310.481C229.195 310.465 229.21 310.45 229.244 310.45C229.629 310.242 229.998 310.096 230.32 310C230.754 309.888 231.09 309.873 231.285 309.985C231.459 310.081 231.49 310.546 231.394 311.027Z"
      fill="#FFB27D"
    />
    <path
      d="M215.684 316.15C213.888 315.695 214.574 318.879 214.122 318.661C213.615 318.417 212.742 318.422 212.88 319.035C213.017 319.649 213.215 319.867 213.319 320.252C213.423 320.634 213.319 321.393 212.9 321.239C212.482 321.086 208.599 316.652 211.341 313.195C214.083 309.74 222.263 311.869 219.687 315.482C219.232 316.114 216.963 316.472 215.684 316.15Z"
      fill="#B64C41"
    />
    <path
      d="M222.203 341.806C222.203 341.806 220.677 342.614 218.275 342.578C216.744 342.554 213.495 341.889 211.408 341.135C210.218 340.706 209.404 340.249 209.503 339.864C209.643 339.311 210.662 335.056 211.606 331.118C212.466 327.518 213.264 324.183 213.264 324.183C213.264 324.183 215.642 325.873 217.027 325.277C218.413 324.68 218.236 324.006 218.236 324.006C218.236 324.006 219.143 323.64 219.567 323.775C219.991 323.91 221.199 323.227 221.199 323.227C221.199 323.227 222.533 324.256 222.582 326.468C222.59 326.795 222.572 327.149 222.512 327.528L221.55 328.636C221.548 328.638 223.588 335.069 222.203 341.806Z"
      fill="#F99746"
    />
    <path
      d="M228.246 362.884H221.56C221.56 362.884 221.433 361.797 221.586 360.867C221.605 360.752 221.664 360.651 221.753 360.562C222.145 360.695 222.72 360.789 223.359 360.56C223.884 360.373 224.271 360.084 224.542 359.824C224.612 359.892 225.992 361.207 226.631 361.207C227.281 361.204 228.282 361.519 228.246 362.884Z"
      fill="#AA443E"
    />
    <path
      d="M211.741 356.964C210.394 358.383 209.032 359.628 208.528 360.242L206.249 359.145C206.249 359.145 207.049 356.847 208.237 354.76C208.642 355.394 209.713 356.699 211.741 356.964Z"
      fill="white"
    />
    <path
      d="M209.024 359.649C209.024 359.649 208.941 361.1 209.425 361.292C209.908 361.484 210.81 361.96 210.81 362.88H207.462C207.462 362.88 205.154 361.744 204.845 361.261C204.538 360.777 206.384 358.719 206.384 358.719C206.384 358.719 207.517 359.977 209.024 359.649Z"
      fill="#AA443E"
    />
    <path
      d="M214.564 336.065C214.564 336.065 216.03 335.792 216.604 336.166C217.179 336.54 217.347 337.128 216.934 337.37C215.64 338.121 214.327 337.289 214.327 337.289L214.564 336.065Z"
      fill="#FFB27D"
    />
    <path
      d="M213.321 324.645C213.321 324.645 208.806 325.601 207.023 332.622C206.849 333.308 207.028 334.041 207.504 334.568C208.322 335.476 210.129 336.804 214.327 337.292C214.784 337.347 214.846 336.182 214.846 336.182C214.8 336.276 211.995 334.368 210.799 333.344C210.584 333.16 210.591 332.77 210.747 332.533C211.15 331.917 211.829 330.851 212.055 330.306C212.39 329.508 214.584 325.006 213.321 324.645Z"
      fill="#FFB27D"
    />
    <path
      d="M213.265 324.184C213.265 324.184 210.611 324.373 208.446 327.258C208.446 327.258 209.179 329.915 212.618 330.622C212.615 330.624 214.736 325.842 213.265 324.184Z"
      fill="#F99746"
    />
    <path
      d="M249.463 255.599C249.463 255.599 244.725 255.022 242.344 259.43C239.963 263.839 240.156 270.225 237.845 273.237C235.537 276.25 238.23 279.106 238.23 279.106L256.221 279.715C256.221 279.715 257.472 276.346 256.221 272.307C254.974 268.268 261.082 256.212 249.463 255.599Z"
      fill="#B64C41"
    />
    <path
      d="M264.697 309.105C264.513 309.809 263.91 311.397 263.442 311.397C263.06 311.397 262.925 309.453 262.433 309.04C261.721 308.442 261.142 307.184 261.048 306.88C260.858 306.254 260.679 306.121 260.679 306.121H260.676C260.676 306.121 254.987 295.719 254.542 293.057C254.397 292.192 254.025 290.45 253.586 288.503C253.11 286.385 252.559 284.02 252.143 282.25C253.96 281.896 255.969 281.771 257.607 281.74C257.939 283.895 258.238 286.097 258.496 288.012C258.878 290.858 259.169 293.068 259.348 293.541C259.842 294.84 261.207 301.84 262.491 305.474C262.639 305.832 263.055 306.763 263.512 307.08C264.084 307.47 264.879 308.4 264.697 309.105Z"
      fill="#E8945B"
    />
    <path
      d="M254.386 298.841C251.244 288.795 257.57 288.927 257.243 283.901C256.915 278.874 253.024 273.47 253.024 273.47C253.024 273.47 252.884 273.457 252.642 273.433C252.51 273.42 252.393 273.335 252.349 273.207C252.203 272.81 252.161 271.942 252.164 271.034C252.167 269.652 252.273 268.183 252.273 268.183L247.605 267.258L247.327 267.203C247.335 269.55 246.89 271.315 246.409 272.303C246.181 272.771 245.734 273.093 245.216 273.166C244.213 273.306 242.451 273.605 241.26 274.104C240.683 274.346 240.241 274.632 240.088 274.97C238.807 277.787 237.528 284.901 236.628 289.975C236.127 292.803 235.745 294.997 235.545 295.462C234.988 296.759 233.288 303.899 231.835 307.424L233.691 307.98C233.691 307.98 239.81 297.825 240.366 295.184C240.548 294.323 240.993 292.603 241.512 290.674C241.59 290.383 241.671 290.087 241.751 289.785C242.669 292.273 243.137 293.424 243.111 294.498C243.075 296.026 242.037 297.401 239.872 302.261C238.674 304.944 257.594 309.1 254.386 298.841Z"
      fill="#FFB27D"
    />
    <path
      d="M252.27 268.181C252.27 268.181 252.163 269.65 252.161 271.033C249.548 270.96 248.168 268.576 247.602 267.256L252.27 268.181Z"
      fill="#E8945B"
    />
    <path
      d="M253.141 269.491C253.141 269.491 246.963 269.592 246.958 265.743C246.953 261.893 246.152 259.315 250.098 259.086C254.043 258.857 254.81 260.326 255.125 261.61C255.439 262.897 254.75 269.364 253.141 269.491Z"
      fill="#FFB27D"
    />
    <path
      d="M261.765 359.2L259.782 359.689C259.782 359.689 256.663 355.418 254.072 349.593C252.429 345.903 250.999 341.687 250.711 337.77C250.581 335.997 250.685 334.284 251.106 332.709C251.106 332.709 250.552 328.982 249.793 324.009C248.951 318.481 247.86 311.413 247.004 306.218L247.522 306.064L255.959 301.131C255.959 301.131 259.837 309.968 258.844 323.253C258.641 325.979 259.223 330.195 258.706 333.296C257.828 338.593 258.189 343.917 258.919 348.348C259.964 354.688 261.765 359.2 261.765 359.2Z"
      fill="#FFB27D"
    />
    <path
      d="M241.385 288.784C242.544 291.984 243.139 293.288 243.111 294.497C246.802 288.781 253.471 287.918 253.471 287.918L240.811 286.471C240.925 287.253 241.112 288.03 241.385 288.784Z"
      fill="#CB7D45"
    />
    <path
      d="M254.942 259.472C253.988 258.315 253.268 258.567 252.863 258.916C249.549 257.078 247.306 259.805 247.306 259.805C246.892 262.568 244.901 264.148 244.901 264.148C244.901 264.148 246.001 264.733 248.623 264.177C251.737 263.516 252.592 260.007 252.717 259.553C253.094 259.89 253.838 260.626 254.064 261.349C254.373 262.328 254.511 264.345 255.098 264.278C255.101 264.278 256.374 261.208 254.942 259.472Z"
      fill="#B64C41"
    />
    <path
      d="M231.965 307.111C231.965 307.111 231.401 308.44 230.814 308.811C230.226 309.183 229.392 310.08 229.545 310.789C229.699 311.499 229.855 312.614 230.595 312.614C231.336 312.614 233.067 309.339 233.286 308.72C233.501 308.102 233.689 307.979 233.689 307.979L231.965 307.111Z"
      fill="#FFB27D"
    />
    <path
      d="M261.082 336.323C253.62 340.586 233.977 333.796 233.998 333.404C234.102 331.317 235.989 318.755 238.206 306.767C239.568 299.396 243.197 296.64 242.617 296.175C242.612 296.17 242.609 296.167 242.609 296.167C240.371 294.579 241.338 291.855 241.486 290.881C240.8 291.065 238.583 291.484 236.046 290.083C237.091 284.972 238.58 277.809 240.072 274.971C240.145 274.833 240.27 274.706 240.439 274.583C240.429 274.573 240.423 274.565 240.423 274.565C241.822 273.546 244.676 273.234 244.676 273.234C244.676 273.234 246.214 277.001 246.685 277.591C249.731 281.396 255.2 279.678 254.457 277.307C254.189 276.444 252.806 273.728 252.637 273.424C252.629 273.409 253.024 273.461 253.024 273.461C253.942 273.583 254.592 273.674 255.06 273.754C255.977 273.91 256.206 274.019 256.435 274.196C257.24 275.002 258.288 277.001 258.371 280.572C258.402 281.9 259.785 296.56 255.312 296.308C255.21 296.303 255.296 296.542 255.491 296.994C256.123 298.447 257.916 302.088 258.436 306.762C260.123 321.972 261.082 336.323 261.082 336.323Z"
      fill="white"
    />
    <path
      d="M251.365 267.218C251.365 267.218 251.482 267.691 252.665 267.606C252.665 267.606 253.658 267.533 253.621 267.01C253.619 267.01 252.652 266.652 251.365 267.218Z"
      fill="white"
    />
    <path
      d="M233.285 308.721C233.285 308.721 233.082 310.215 232.687 310.561C232.292 310.907 232.235 311.528 231.832 311.193C231.429 310.86 233.285 308.721 233.285 308.721Z"
      fill="#FFB27D"
    />
    <path
      d="M258.16 361.778C258.163 362.898 259.735 363.023 261.537 362.945C263.338 362.867 262.831 363.283 265.329 363.499C267.827 363.712 268.75 362.948 268.895 362.599C269.025 362.29 267.84 361.937 266.574 361.531C266.415 361.482 266.254 361.427 266.096 361.375C264.669 360.902 263.785 359.746 262.61 358.706C261.498 357.721 260.211 354.357 260.211 354.357L256.806 353.957C256.806 353.957 258.036 356.977 258.485 358.274C258.75 359.036 258.55 359.935 258.36 360.624C258.262 361.001 258.158 361.383 258.16 361.778Z"
      fill="#FFB27D"
    />
    <path
      d="M261.536 362.945C263.337 362.867 262.831 363.283 265.329 363.498C267.826 363.712 268.749 362.947 268.895 362.599C269.025 362.29 267.839 361.936 266.574 361.531L266.555 361.554C266.555 361.554 264.884 362.303 262.838 361.949C261.172 361.661 259.509 360.837 258.36 360.629C258.259 361.003 258.157 361.385 258.157 361.78C258.162 362.901 259.735 363.025 261.536 362.945Z"
      fill="#1C468A"
    />
    <path
      d="M261.082 336.323C253.62 340.586 233.977 333.797 233.998 333.405C234.102 331.317 235.989 318.755 238.206 306.768C239.568 299.396 243.197 296.641 242.617 296.176C242.755 296.288 244.86 297.985 248.72 297.754C252.174 297.546 254.836 297.114 255.488 296.997C256.12 298.45 257.914 302.092 258.433 306.765C260.123 321.973 261.082 336.323 261.082 336.323Z"
      fill="#F99746"
    />
    <path
      d="M242.609 296.172C242.609 296.172 248.72 298.722 255.309 296.317L255.644 297.349C255.644 297.349 248.863 299.704 242.344 297.024L242.609 296.172Z"
      fill="#ED7D2B"
    />
    <path
      d="M773.124 364.934H0.87594C0.392484 364.934 0 364.539 0 364.058V363.759C0 363.275 0.395083 362.883 0.87594 362.883H773.124C773.607 362.883 774 363.278 774 363.759V364.058C774 364.539 773.607 364.934 773.124 364.934Z"
      fill="#BBBEDB"
    />
    <path
      d="M511.925 363.61H505.307C505.357 363.264 505.525 362.206 505.835 361.234C505.941 360.896 506.066 360.566 506.209 360.28C506.854 358.996 510.94 359.812 510.94 359.812C510.94 359.812 511.319 360.137 511.615 361.38C511.748 361.928 511.865 362.656 511.925 363.61Z"
      fill="#233862"
    />
    <path
      d="M499.776 262.527C499.776 262.527 494.503 263.453 493.309 266.567C492.116 269.68 485.382 319.957 485.382 319.957C485.382 319.957 488.717 322.601 495.878 321.652C503.038 320.703 499.776 262.527 499.776 262.527Z"
      fill="#9DB0F4"
    />
    <path
      d="M491.927 361.523C491.908 362.423 491.797 363.275 491.469 363.613H478.379C478.546 363.14 479.016 362.428 480.36 362.21C481.888 361.963 484.267 361.227 485.657 359.759C486.154 359.236 486.52 358.62 486.684 357.903C487.3 355.161 491.776 358.742 491.776 358.742C491.776 358.742 491.958 360.182 491.927 361.523Z"
      fill="#233862"
    />
    <path
      d="M486.291 360.19C487.755 360.655 490.549 362.082 492.327 361.412C493.476 360.98 499.891 342.422 500.306 334.302C500.338 333.712 500.452 332.888 500.634 331.884C501.013 329.789 501.679 326.91 502.443 323.733C504.496 315.221 507.272 304.574 507.213 301.169C507.119 295.888 491.469 300.062 491.469 300.062C491.469 300.062 490.393 304.395 490.177 310.67C489.754 323.122 488.584 344.158 485.405 358.766C485.275 359.384 485.683 359.998 486.291 360.19Z"
      fill="#233862"
    />
    <path
      d="M497.151 301.188L505.424 361.147C505.424 361.147 509.058 362.023 511.883 361.321C511.883 361.321 514.306 329.431 514.103 321.722C513.9 314.012 515.842 301.092 513.661 299.667C511.48 298.246 497.151 301.188 497.151 301.188Z"
      fill="#2B478B"
    />
    <path
      d="M514.706 265.273C514.706 265.273 506.011 259.651 499.776 262.525C498.783 262.983 497.65 264.262 496.836 265.156C494.582 267.641 490.177 307.094 490.177 307.094C490.177 307.094 502.679 311.328 514.703 305.657V265.273H514.706Z"
      fill="white"
    />
    <path
      d="M499.282 262.383C499.282 262.383 490.481 265.991 489.288 268.761C488.095 271.532 484.222 288.552 484.222 290.548C484.222 292.544 490.967 290.995 492.054 287.775C492.956 285.103 495.997 276.889 497.6 271.696L499.282 262.383Z"
      fill="#9DB0F4"
    />
    <path
      d="M501.11 265.134C503.03 265.511 505.853 265.111 507.92 263.749C506.987 261.521 506.604 259.762 506.469 258.623C506.461 258.563 506.456 258.504 506.448 258.446C506.386 257.856 506.396 257.456 506.425 257.271H501.458C501.458 257.271 500.101 257.893 500.101 258.322C500.101 258.55 500.099 258.771 500.096 258.979C500.086 259.551 500.062 260.05 500.034 260.476C499.94 261.87 499.779 262.527 499.779 262.527C499.779 262.527 499.171 264.752 501.11 265.134Z"
      fill="#FFB27D"
    />
    <path
      d="M500.031 260.476C503.794 261.204 505.978 259.148 506.469 258.62C506.539 258.545 506.576 258.5 506.576 258.5C506.537 258.48 506.495 258.462 506.451 258.446C505.05 257.879 501.871 258.329 500.096 258.981C500.083 259.548 500.062 260.047 500.031 260.476Z"
      fill="#E2915A"
    />
    <path
      d="M507.699 256.451C507.025 260.069 502.357 259.953 500.338 259.724C499.768 259.661 499.412 259.589 499.412 259.589C498.773 259.417 498.414 258.409 498.248 257.088C497.918 254.473 498.334 250.634 498.776 249.652C499.441 248.175 500.694 246.577 505.396 247.731C510.1 248.885 508.562 251.814 507.699 256.451Z"
      fill="#FFB27D"
    />
    <path
      d="M498.601 247.636C499.243 246.747 500.608 245.658 501.845 244.99C504.504 243.55 507.909 245.034 507.922 245.042C511.763 246.731 512.767 249.671 510.729 254.248C508.574 259.091 506.526 258.545 506.526 258.545C506.526 258.545 506.96 258.274 506.921 256.764C506.919 256.678 506.916 256.606 506.911 256.546C506.89 256.237 506.999 255.927 507.23 255.719C507.709 255.293 507.94 254.006 507.54 253.648C507.059 253.216 506.38 253.741 506.165 254.144C505.975 254.43 505.637 254.529 505.323 254.329C504.127 253.572 504.904 252.062 503.76 251.137C502.617 250.212 500.301 250.989 498.585 250.251C496.345 249.414 498.118 248.306 498.601 247.636Z"
      fill="#233862"
    />
    <path
      d="M498.066 323.651C498.822 323.846 499.571 324.005 500.317 324.129C500.974 324.241 501.632 324.324 502.287 324.382C506.007 324.706 509.744 324.181 514.114 322.929C515.096 322.648 516.112 322.326 517.168 321.972C517.279 321.936 516.882 310.382 516.419 298.467C516.372 297.238 516.323 296.003 516.276 294.776C516.255 294.256 516.234 293.739 516.214 293.225C515.795 282.687 515.382 273.169 515.294 272.657C515.093 271.498 514.906 270.51 514.704 269.652C514.108 267.154 513.37 265.787 511.759 264.61C511.603 264.49 510.313 263.84 509.178 263.276C508.237 262.808 507.4 262.4 507.4 262.4C507.025 262.585 506.576 263.443 506.105 264.649C505.24 266.871 504.307 270.271 503.657 272.813C503.654 272.821 503.654 272.823 503.654 272.826C503.236 274.466 502.937 275.745 502.851 276.111C502.706 276.73 502.513 277.928 502.29 279.553C501.983 281.762 501.619 284.764 501.232 288.169C501.193 288.52 501.151 288.873 501.112 289.232C501.065 289.64 501.019 290.054 500.974 290.472C500.959 290.607 500.946 290.74 500.93 290.875C500.782 292.224 500.631 293.614 500.483 295.028C500.358 296.198 498.586 297.043 498.464 298.233C498.393 298.933 498.245 305.376 498.056 307.432C497.915 308.893 498.014 322.96 498.066 323.651Z"
      fill="#AEC3FF"
    />
    <path
      d="M510.898 276.906C510.904 276.93 511.2 287.563 503.202 288.852C495.204 290.144 500.517 290.485 500.517 290.485C500.517 290.485 515.564 289.845 515.814 289.448C516.063 289.05 510.898 276.906 510.898 276.906Z"
      fill="#9DB0F4"
    />
    <path
      d="M504.14 293.416C504.14 293.416 504.291 293.523 504.579 293.708C504.585 293.71 504.59 293.713 504.595 293.715C505.814 294.495 509.435 296.616 514.709 298.04C515.262 298.191 515.834 298.332 516.424 298.464C516.377 297.235 516.328 296 516.281 294.773C516.978 294.719 517.643 294.63 518.262 294.5C518.241 294.339 518.22 294.175 518.199 294.014C517.529 293.739 516.863 293.474 516.219 293.222C515.696 293.016 515.189 292.821 514.709 292.642C512.543 291.818 510.937 291.262 510.937 291.262L504.525 293.292L504.14 293.416Z"
      fill="#9DB0F4"
    />
    <path
      d="M502.443 293.24C502.443 293.24 503.267 293.446 504.577 293.711C504.583 293.711 504.585 293.711 504.59 293.713C507.008 294.199 511.073 294.88 514.704 294.844C515.239 294.839 515.767 294.818 516.276 294.776C516.973 294.722 517.638 294.633 518.257 294.503C519.585 294.223 520.695 293.75 521.423 293.009C526.824 287.506 518.475 267.76 516.721 265.839C514.673 263.596 510.93 262.808 508.866 262.538C508.78 262.528 508.697 262.517 508.617 262.507C507.891 262.421 507.423 262.403 507.397 262.4C507.397 262.4 507.559 262.92 507.832 263.804C507.938 264.144 508.06 264.542 508.193 264.984C509.461 269.138 511.91 277.304 512.988 281.908C513.384 283.592 513.594 284.801 513.498 285.162C513.077 286.734 508.713 287.543 505.947 287.901C504.793 288.05 503.92 288.12 503.722 288.135C503.709 288.135 503.696 288.135 503.681 288.138L503.519 288.793L503.137 290.365L502.76 291.909L502.443 293.24Z"
      fill="#AEC3FF"
    />
    <path
      d="M487.378 302.705L505.929 301.922L502.165 278.805L483.614 279.587L487.378 302.705Z"
      fill="#FF9F55"
    />
    <path
      d="M503.686 288.137C503.686 288.137 500.076 287.885 499.894 288.727C499.473 290.689 502.997 292.301 504.141 292.189C505.284 292.08 504.518 288.007 503.686 288.137Z"
      fill="#FFB27D"
    />
    <path
      d="M484.628 284.278C484.628 284.278 488.082 283.197 488.454 283.974C489.32 285.786 486.26 288.17 485.124 288.325C483.986 288.484 483.791 284.343 484.628 284.278Z"
      fill="#FFB27D"
    />
    <path
      d="M507.025 261.236C507.025 261.236 508.738 261.533 509.5 262.627C509.5 262.627 503.625 272.915 503.651 272.821C503.677 272.727 505.572 262.5 507.025 261.236Z"
      fill="#9DB0F4"
    />
    <path
      d="M499.953 261.379C499.454 261.569 498.493 262.551 498.277 263.094L497.601 271.703C497.601 271.703 499.044 265.387 499.777 262.53C499.964 261.803 499.93 261.582 499.953 261.379Z"
      fill="#9DB0F4"
    />
    <path
      d="M496.015 279.063C496.015 279.063 495.869 278.421 495.025 278.496C494.18 278.572 489.436 278.803 489.436 278.803C489.436 278.803 488.521 278.788 488.607 279.375L496.015 279.063Z"
      fill="#C65447"
    />
  </svg>
);

export default SbLogo;
