import { number, string } from "prop-types";

const propTypes = {
  currentPage: number,
  itemsPerPage: number,
  totalItems: number,
  title: string
};

const defaultProps = {
  itemsPerPage: 20,
  title: "Invoices"
};

/*generates message for range and total no. of itme being displayed  */
const DisplayAllItems = ({ currentPage, itemsPerPage, totalItems, title }) =>
  `Displaying ${title} ${(currentPage - 1) * itemsPerPage + 1}-
${
  totalItems > itemsPerPage
    ? currentPage * itemsPerPage > totalItems
      ? totalItems
      : currentPage * itemsPerPage
    : totalItems
}${" "}
of ${totalItems}
${" in total"}`;

DisplayAllItems.propTypes = propTypes;
DisplayAllItems.defaultProps = defaultProps;
export default DisplayAllItems;
