import React from "react";
import Primitives from "../../../components/primitives";

const FreeQuantity = item => (
  <Primitives.Flex>
    {Math.floor(
      item["scheme_purchase_qty"] && item["qty"]
        ? (item["qty"] / item["scheme_purchase_qty"]) * item["scheme_free_qty"]
        : 0
    )}
  </Primitives.Flex>
);
export default FreeQuantity;
