export default {
  retrieveAndDecodeFile: key => {
    const file = localStorage.getItem(localStorage.getItem(key));
    if (file) {
      var arr = file.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], localStorage.getItem(key), { type: mime });
    }
  },
  encodeAndStoreFile: (file, key) => {
    const { name } = file;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.addEventListener("load", () => {
      localStorage.setItem(key, name);
      localStorage.setItem(name, reader.result);
    });
  }
};
