import React from "react";
import { array, func, number, object, oneOfType } from "prop-types";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import NavItem from "../../../components/nav-item";

const propTypes = {
  selected: number,
  tabsData: oneOfType([array, object]),
  tabsHandler: func
};

const Tabs = ({ selected, tabsData, tabsHandler }) => (
  <>
    {tabsData.map((item, index) => (
      <Primitives.Button
        key={index}
        onClick={() => {
          tabsHandler(index);
        }}
      >
        <NavItem
          backgroundColor={index === selected ? "light-blue" : "white"}
          borderColor="highlight-blue"
          color={index === selected ? "highlight-blue" : colors.text[0]}
          fontSize={1}
          fontWeight={index === selected ? 2 : 1}
          hoverColor={index === selected ? "highlight-blue" : colors.text[0]}
          label={item.label}
          px={3}
          pt={2}
          pb={2}
          selected={index === selected}
        />
      </Primitives.Button>
    ))}
  </>
);

Tabs.propTypes = propTypes;
export default Tabs;
