import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/context";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import { convertDate } from "../../components/utils";
import Tags from "../../components/tags";
import { array, bool, func, object, oneOfType, string } from "prop-types";
import Months from "../month-list";
import { useParams } from "react-router";
import useApiRequest from "../../hooks/useApiRequest";
import GenericDropdown from "./generic-dropdown";
import MoleculesService from "../../services/reports/top-molecules";
import PurchaseMarginService from "../../services/reports/purchase-margin";
import GenericSearch from "./generic-search";
import FilterDateRange from "./date-range";
import ApplyFilter from "./apply-filter";
import { FilterContext } from "../../utils/filter-context";
import Checkbox from "../../components/checkbox";

const propTypes = {
  button: bool,
  config: object,
  endDate: string,
  category: string,
  headers: oneOfType([array, object]),
  hospital: string,
  flexWrap: string,
  filterUpdate: func,
  tagSearchHeading: string,
  startDate: string,
  tags: bool,
  tagProps: object,
  clearAllFilters: bool,
  setClearAllFilters: func
};

const strToDate = str => {
  var dateParts = str.split("/");
  // month is 0-based, that's why we need dataParts[1] - 1
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};

const invoiceStatusArr = [
  { display_name: "Confirmed", id: "confirmed" },
  { display_name: "Dispatched", id: "dispatched" },
  { display_name: "Delivered", id: "delivered" },
  { display_name: "Cancelled", id: "cancelled" }
];

const rcAvailabilityArr = [
  { name: "Product With RC", id: "product_with_rc=true" },
  { name: "Product Without RC", id: "product_without_rc=true" }
];

const rcFollowedArr = [
  { name: "Show only RC not followed", id: "rc_not_followed=true" },
  { name: "Show only incorrect PO rate", id: "po_rc_rate_not_match=true" }
];
const reverseDate = date =>
  date
    ?.split("-")
    .reverse()
    .join("-");

const Filters = ({
  filterUpdate,
  flexWrap,
  config,
  endDate,
  tagProps,
  startDate,
  tagSearchHeading,
  headers,
  clearAllFilters,
  setClearAllFilters
}) => {
  const defaultDate = `start_date=${
    startDate
      ? strToDate(startDate)
      : convertDate(new Date().setDate(new Date().getDate() - 30))
  }&end_date=${endDate ? strToDate(endDate) : convertDate(new Date())}`;

  const defaultSelectedTag = () =>
    tagProps && tagProps.items.findIndex(item => item.selected === true);

  const {
    categories,
    cities,
    fulfilmentStatus,
    getHospitalEntities,
    hospitals,
    hospitalsEntities,
    invoiceNumbers,
    BASE_URL,
    molecules,
    moleculeGroups,
    poNumbers,
    tenants,
    universalSubCategories
  } = useContext(AppContext);
  const ids = useParams().ids;
  const fulfilmentStartDate = reverseDate(useParams().startDate);
  const fulfilmentEndDate = reverseDate(useParams().endDate);
  const categoryId = useParams().orderedSkusId;
  const request = categoryId ? `${BASE_URL}/skus?id=${categoryId}` : "";
  const { data: skuData } = useApiRequest(request);
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: colors.primary[0],
      new: true
    }
  ]);
  const [filterActive, setFilterActive] = useState(
    startDate || endDate ? true : false
  );
  const [collapse, setCollpase] = useState(false);
  const [filterState, setFilterState] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  const [subCategory, setSubCategory] = useState("");
  const [fulfilmentManufacturer, setFulfilmentManufacturer] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [getConfig, setGetConfig] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedTag, setSelectedTag] = useState(defaultSelectedTag());
  const marginRight = Object.keys(config).length === 1 ? 0 : 20;
  const marginBottom = flexWrap ? 4 : 0;
  const filterContext = useContext(FilterContext);
  const filterStateObj = filterContext && filterContext.filterState;
  const [hospitalCenters, setHospitalCenters] = useState([]);

  useEffect(() => {
    if (getHospitalEntities && Array.isArray(getHospitalEntities)) {
      setHospitalCenters(getHospitalEntities);
    }
  }, [getHospitalEntities]);
  const filterObj = {
    hospitals: {
      data: hospitals["response"],
      queryString: "&hospital_ids="
    },
    poNumbers: {
      data: poNumbers["po_numbers"],
      queryString: "&q[sales_invoice_sales_order_id_in][]="
    },
    invoices: {
      data: invoiceNumbers["invoices_numbers"],
      queryString: "&q[sales_invoice_id_in][]="
    },
    skus: {
      data: skuData["response"],
      queryString: "&q[master_sku_universal_sku_id_in][]="
    },
    months: {
      data: Months,
      singleSelect: true,
      queryString: "months="
    },
    procurementsMonths: {
      data: Months,
      singleSelect: true,
      queryString: "selected_month="
    },
    hospitalEntitySingleSelect: {
      data: hospitalsEntities["response"],
      singleSelect: true
    },
    hospitalEntity: {
      data: hospitalsEntities["response"],
      queryString: "&hospital_entity_id="
    },
    invoiceStatus: {
      data: invoiceStatusArr,
      queryString: "&q[invoice_status][]="
    },
    crossProcurementHospital: {
      data: hospitalsEntities["response"],
      queryString: "&q[id_in][]="
    },
    entity: {
      data: getHospitalEntities,
      queryString: "&hospital_entity_id="
    },
    hospitalCenter: {
      data: hospitalCenters,
      queryString: "&hospital_entity_ids="
    },
    city: {
      data: cities["cities"],
      queryString: "&q[universal_city_id_in][]="
    },
    category: {
      data: categories,
      queryString: "&category_id="
    },
    crossProcurementCategory: {
      data: categories,
      queryString: "&q[master_sku_universal_sku_universal_category_id_in][]="
    },
    fulfilmentStatus: {
      data: fulfilmentStatus,
      singleSelect: true
    },
    hospitalSingleSelect: {
      data: hospitals && hospitals["response"],
      singleSelect: true
    },
    moleculeGroup: {
      data: moleculeGroups && moleculeGroups,
      queryString: "&q[universal_molecule_group_id_in][]="
    },
    molecules: {
      data: molecules && molecules
    },
    universalMolecules: {
      data: config?.universalMolecules?.productMasterData?.molecules
    },

    subcategory: {
      data: ids
        ? subCategory["sub_categories"]
        : universalSubCategories["sub_categories"],
      queryString: "&sub_category_ids="
    },
    marginCategoryManufacturer: {
      data: fulfilmentManufacturer,
      singleSelect: true
    },
    fulfilmentManufacturer: {
      data: config?.manufacturerData?.manufacturers,
      singleSelect: true
    },
    distributor: {
      data: tenants,
      queryString: "&tenant_ids="
    },
    distributorSingleSelect: {
      data: tenants,
      singleSelect: true
    },
    rcAvailability: {
      data: rcAvailabilityArr,
      singleSelect: true
    },
    rcFollowed: {
      data: rcFollowedArr,
      queryString: "&"
    },
    moleculeStrength: {
      data: subCategory["molecule_strengths"],
      singleSelect: true
    },
    status: {
      data: config.tabData,
      queryString: "&status="
    }
  };

  const searchObj = {
    skuNameSearch: { margin: config.otifCategoryMargin },
    productSearch: {},
    manufacturerSearch: {},
    invoiceSearch: {},
    orderSearch: {},
    hospitalSearch: { margin: true },
    poNumberSearch: {},
    moleculeSearch: {},
    orderListingSearch: {},
    orderListingProductSearch: { margin: false, throttle: true },
    manufacturerThrottleSearch: { margin: true, throttle: true }
  };
  const checkboxObj = {
    formularyCheckbox: {}
  };

  useEffect(() => {
    Object.keys(filterObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = [];
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
  }, []);

  useEffect(() => {
    Object.keys(checkboxObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = false;
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
  }, []);

  useEffect(() => {
    Object.keys(searchObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = "";
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
  }, []);

  useEffect(() => {
    if (filterStateObj && Object.keys(filterStateObj).length > 0) {
      setFilterActive(true);
    }
  }, [filterStateObj]);

  const generateStrFn = (selectedData, queryString) =>
    selectedData.length > 0
      ? `${queryString}${selectedData.join()}`
      : queryString;

  const multiSelectKeys = [
    "hospitals",
    "category",
    "crossProcurementCategory",
    "entity",
    "hospitalEntity",
    "hospitalCenter",
    "invoiceStatus",
    "subcategory",
    "distributor",
    "status"
  ];
  const mapMultiSingleSelect = key => {
    const mapperFn = (data, filter) => (filter || "") + data.join(filter);
    if (filterObj[key].singleSelect || multiSelectKeys.includes(key)) {
      if (filterObj[key].queryString) {
        return generateStrFn(filterState[key], filterObj[key].queryString);
      } else {
        return filterState[key][1];
      }
    } else {
      return filterState[key].length > 0
        ? mapperFn(filterState[key], filterObj[key].queryString)
        : filterObj[key].queryString;
    }
  };

  const generateStr = () => {
    const filterRes = {};
    const searchRes = {};
    const checkboxRes = {};
    const dateStr = !dateRanges[0].new
      ? `start_date=${convertDate(
          dateRanges[0].startDate
        )}&end_date=${convertDate(dateRanges[0].endDate)}`
      : fulfilmentStartDate
      ? `start_date=${fulfilmentStartDate}&end_date=${fulfilmentEndDate}`
      : config.defaultDateRange || !config.defaultDateRange
      ? ""
      : defaultDate;
    Object.keys(filterObj).forEach(item => {
      filterRes[item] = mapMultiSingleSelect(item);
    });
    Object.keys(searchObj).map(item => {
      searchRes[item] = filterState[item];
    });
    Object.keys(checkboxObj).map(item => {
      checkboxRes[item] = filterState[item];
    });

    const queryObj = {
      ...filterRes,
      ...searchRes,
      ...checkboxRes,
      date: dateStr
    };

    return queryObj;
  };

  useEffect(() => {
    if (getConfig) {
      let updatedValue = {};
      updatedValue[getConfig] = selectedItems;
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    }
  }, [selectedItems]);

  useEffect(() => {
    if (getConfig) {
      let updatedValue = {};
      updatedValue[getConfig] = searchValue;
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    }
  }, [searchValue]);

  const quickSearchObject = {
    1: "quick_filter=weekly",
    2: "quick_filter=monthly",
    3: "quick_filter=quaterly",
    7: "quick_filter=today",
    31: "quick=30_days",
    61: "quick=90_days",
    8: "date_filter=till_date",
    30: "quick_filter=yesterday",
    40: "tab=all",
    41: "tab=pending",
    42: "tab=invoiced",
    43: "tab=partially_invoiced",
    44: "tab=delivered"
  };

  const tagMapper = key => quickSearchObject[key];

  const commonFilterUpdate = (timeStr, quickSearchStr) => {
    const queryObj = generateStr();
    filterUpdate({ ...queryObj, time: timeStr, quickSearch: quickSearchStr });
  };

  const tagHandler = (index, key) => {
    setSelectedTag(index);
    const quickSearchStr = tagMapper(key);
    const timeStr = `time_period=${key}`;
    commonFilterUpdate(timeStr, quickSearchStr);
    setFilterActive(true);
  };

  const dateChangeHandler = (item, collapseTheDateOptions = false) => {
    item.selection.new = false;
    setDateRanges([item.selection]);
    collapseTheDateOptions &&
      item.selection.startDate.getTime() !== item.selection.endDate.getTime() &&
      setCollpase(!collapse);
  };
  const checkboxClickHandler = checkbox => {
    let updatedValue = {};
    updatedValue[checkbox] = !filterState[checkbox];
    setFilterState(prevState => ({
      ...prevState,
      ...updatedValue
    }));
    setCheckboxState(prevState => ({
      ...prevState,
      ...updatedValue
    }));
  };
  const fetchMoleculeDetails = async ids => {
    try {
      const response = await MoleculesService.moleculeDeatils(ids);
      if (response && response.total_skus_details) {
        setSubCategory(response.total_skus_details);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMarginCategoryDetails = async query => {
    try {
      const response = await PurchaseMarginService.marginCategoryDetails(query);
      if (response) {
        setFulfilmentManufacturer(response.comapny_list);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchMoleculeDetails(ids);
    categoryId && fetchMarginCategoryDetails(categoryId);
  }, []);

  const applyFilter = () => {
    commonFilterUpdate(null, "");
    setFilterActive(true);
  };

  const clearFilter = () => {
    setDateRanges([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: colors.primary[0],
        new: true
      }
    ]);
    setFilterActive(false);
    setSelectedTag(defaultSelectedTag());
    const clearObj = { ...searchObj, ...filterObj };
    Object.keys(clearObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = [];
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
    Object.keys(checkboxObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = false;
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
      setCheckboxState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
    filterUpdate({
      quickSearch: "quick_filter=monthly",
      time: "time_period=30",
      filterCleared: true
    });
    if (filterContext && filterContext.filterState) {
      filterContext.updateFilterState("");
    }
  };
  useEffect(() => {
    if (clearAllFilters) {
      clearFilter();
      setClearAllFilters(false);
    }
  }, [clearAllFilters]);
  const filterSize = Object.keys(config)?.length ?? 0;

  return (
    <Primitives.Flex flexDirection="column">
      <Primitives.Flex flex={1} flexWrap={flexWrap}>
        {Object.keys(config).map((item, index) => (
          <Primitives.Flex
            key={index}
            zIndex={filterSize - index}
            justifyContent="space-between"
          >
            {searchObj[item] && (
              <GenericSearch
                clearFilter={filterActive}
                config={item}
                getClickEvent={() => applyFilter()}
                getConfig={item => setGetConfig(item)}
                getSearchValue={item => setSearchValue(item)}
                margin={searchObj[item].margin}
                throttle={searchObj[item].throttle}
                heading={headers && headers[index]}
              />
            )}
            {filterObj[item] && (
              <Primitives.Box width={200}>
                <Primitives.Flex
                  flex={1}
                  mb={marginBottom}
                  mr={filterObj[item].ml === 0 ? 0 : 20}
                  flexDirection="column"
                >
                  <GenericDropdown
                    clearFilter={filterActive}
                    config={item}
                    dropdownData={filterObj[item].data}
                    filterContextState={
                      filterContext &&
                      filterContext.filterState &&
                      filterContext.filterState[item]
                    }
                    getConfig={item => setGetConfig(item)}
                    getSelectedItems={items => setSelectedItems(items)}
                    selectType={
                      filterObj[item].singleSelect
                        ? "singleSelect"
                        : "multiSelect"
                    }
                    heading={headers && headers[index]}
                  />
                </Primitives.Flex>
              </Primitives.Box>
            )}
            {item === "date" && dateRanges && (
              <Primitives.Box width={200}>
                <Primitives.Flex flexDirection="column">
                  <FilterDateRange
                    dateRangeCollapse={collapse}
                    dateChangeHandler={dateChangeHandler}
                    dateRanges={dateRanges}
                    heading={headers && headers[index]}
                    marginRight={marginRight}
                    {...(config.date.maxSpan && {
                      maxSpan: config.date.maxSpan
                    })}
                    {...(config.date.buffer && {
                      buffer: config.date.buffer
                    })}
                    defaultValue={
                      filterContext &&
                      filterContext.filterState &&
                      filterContext.filterState.dateRange
                        ? {
                            startDate: filterContext.filterState.dateRange
                              .split("&")[0]
                              .split("=")[1],
                            endDate: filterContext.filterState.dateRange
                              .split("&")[1]
                              .split("=")[1]
                          }
                        : config.defaultDateRange
                        ? {
                            startDate:
                              config.defaultDateRange.startDate ||
                              convertDate(
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  1
                                )
                              ),
                            endDate:
                              config.defaultDateRange.endDate ||
                              new Date().toLocaleDateString()
                          }
                        : "Date Range"
                    }
                  />
                </Primitives.Flex>
              </Primitives.Box>
            )}
            {checkboxObj[item] && (
              <Primitives.Flex flex={1} pl={1} alignSelf="center">
                <Checkbox
                  flex={1}
                  checked={checkboxState[item]}
                  clickHandler={() => {
                    checkboxClickHandler(item);
                  }}
                />
                <Primitives.Text pl={1} fontSize="12px" flex={1}>
                  {config[item].heading}
                </Primitives.Text>
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        ))}

        {!config.noFilter && (
          <Primitives.Flex
            flex={0.5}
            mr={20}
            alignItems={flexWrap ? "start" : "end"}
          >
            <ApplyFilter
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              filterActive={filterActive}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
      {tagProps && (
        <Primitives.Box mt={2}>
          <Primitives.Text color={colors.text[1]} fontSize={1} mr={20}>
            {tagSearchHeading}
          </Primitives.Text>
          <>
            <Tags
              clickHandler={tagHandler}
              selected={selectedTag}
              {...tagProps}
            />
          </>
        </Primitives.Box>
      )}
    </Primitives.Flex>
  );
};
Filters.propTypes = propTypes;
export default Filters;
