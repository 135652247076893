import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "../../../../components/button";
import Dropdown from "../../../../components/dropdown";
import Loading from "../../../../components/loading";
import NoResults from "../../../../components/no-results";
import Primitives from "../../../../components/primitives";
import Table from "../../../../components/table-two/TableComponent";
import { colors } from "../../../../components/utils/theme";
import { useParams } from "react-router";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import Pagination from "../../../../components/pagination";
import Checkbox from "../../../../components/checkbox";
import DropdownClick from "../../../../lib/dropdown-click";
import styled from "styled-components";
import Filter from "../../../../components/glyphs/elements/filter";
import Navigation from "../../../../lib/navigation";
import DownloadBtn from "../../../../lib/download";
import downloadHandlerFn from "../../../../components/utils/download-handler";

const StyledText = styled(Primitives.Text)`
  text-decoration: underline;
`;

const modifyData = data =>
  data.map(item => ({
    "SKU Name": item["name"],
    Manufacturer: item["manufacturer"],
    MRP: `₹ ${item["mrp"]}`,
    Rate: `₹ ${item["rate"]}`,
    Scheme: item["schemes"],
    Tax: `₹ ${item["tax"]}`
  }));

const NewProducts = () => {
  const header = [
    { heading: "SKU Name", type: "string" },
    { heading: "Manufacturer", type: "string" },
    { heading: "MRP", type: "string" },
    { heading: "Rate", type: "string" },
    { heading: "Scheme", type: "string" },
    { heading: "Tax", type: "string" }
  ];
  const category = useParams().category;
  const categoryID = useParams().categoryId;
  const { BASE_URL } = useContext(AppContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [manufacturerCollapse, setManufacturerCollapse] = useState(false);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [request, updateRequest] = useState(
    `${BASE_URL}/product_masters_category_available_products?category_id=${categoryID}`
  );
  const { data, isLoaded } = useApiRequest(request);
  const req = `${BASE_URL}/manufacturers?category_id=${categoryID}`;
  const manufacturer = useApiRequest(req);
  const isClearFilter = selectedManufacturers.length;
  const [isDownload, setIsDownload] = useState(false);

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setPageNumber(1);
    }
  };

  const moleculeSelectHandler = option => {
    const id =
      manufacturer &&
      manufacturer.data &&
      manufacturer.data["manufacturers"].filter(
        item => item.name === option.text
      )[0].name;
    !selectedManufacturers.includes(id)
      ? setSelectedManufacturers([...selectedManufacturers, id])
      : setSelectedManufacturers(
          selectedManufacturers.filter(data => data !== id)
        );
  };

  const node = useRef();
  const handleClick = e => {
    if (!DropdownClick(node, e)) {
      setManufacturerCollapse(false);
    }
  };

  const manufacturerMapper = manufacturer => {
    let mapString = [];
    manufacturer.forEach(item => mapString.push(`"${item}"`));
    return mapString;
  };

  const applyFilter = () => {
    setPageNumber(1);
    updateRequest(
      `${BASE_URL}/product_masters_category_available_products?category_id=${categoryID}${
        selectedManufacturers.length > 0
          ? `&manufacturer=[${manufacturerMapper(selectedManufacturers)}]`
          : ""
      }`
    );
    selectedManufacturers.length && setFilterActive(true);
  };

  const clearFilter = () => {
    setFilterActive(false);
    setSelectedManufacturers("");
    setPageNumber(0);
  };

  const downloadHandler = async query =>
    downloadHandlerFn(
      `${BASE_URL}/product_masters_category_available_products.xlsx?is_download=true&${query}`,
      setIsDownload
    );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/product_masters_category_available_products?category_id=${categoryID}&page=${pageNumber}${
        selectedManufacturers.length > 0
          ? `&manufacturer=[${manufacturerMapper(selectedManufacturers)}]`
          : ""
      }`
    );
  }, [pageNumber]);

  return (
    <>
      <Navigation selected={2} />
      <Primitives.Flex flexDirection="column" mx={3} my={2}>
        <Primitives.Flex flexDirection="column" px={1}>
          <Primitives.Text
            color={colors.text[0]}
            fontSize={5}
            fontWeight={2}
            lineHeight={3}
          >
            New {category} Products Available
          </Primitives.Text>
          <Primitives.Text color={colors.text[0]} fontSize={1}>
            <Primitives.Text display="flex" height={20} pt={1}>
              {data && data["total_items"] >= 0 ? (
                data["total_items"]
              ) : (
                <Loading onlySpinner />
              )}
              {" products available"}
            </Primitives.Text>
          </Primitives.Text>
          <Primitives.Flex>
            <Primitives.Flex flex={0.2} my={2} mr={1} ref={node}>
              <Dropdown
                className="dropdown-filter"
                collapsed={manufacturerCollapse}
                defaultValue={
                  selectedManufacturers.length
                    ? `${selectedManufacturers.length} selected`
                    : "Select Molecules"
                }
                focusHandler={() => {
                  setManufacturerCollapse(true);
                }}
                id="cities"
                listMaxHeight={195}
                listWidth={320}
                name="cities"
                options={
                  manufacturer &&
                  manufacturer.data["manufacturers"] &&
                  manufacturer.data["manufacturers"].map(molecule => {
                    return {
                      glyph: (
                        <Checkbox
                          checked={
                            selectedManufacturers.includes(molecule.name)
                              ? true
                              : false
                          }
                        />
                      ),
                      text: molecule.name
                    };
                  })
                }
                searchable={true}
                selectHandler={moleculeSelectHandler}
                selectType="multiSelect"
              />
            </Primitives.Flex>
            <Primitives.Flex flex={0.25} ml={1}>
              <Primitives.Flex
                alignItems="center"
                backgroundColor={filterActive ? colors.primary[3] : "white"}
                border={`1px solid ${colors.text[2]}`}
                borderRadius={4}
                cursor="pointer"
                height={38}
                justifyContent="center"
                mt={2}
                mb={3}
                onClick={() => {
                  applyFilter();
                }}
                px={3}
              >
                <Button
                  backgroundColor={filterActive ? colors.primary[3] : "white"}
                  border={0}
                  borderColor={colors.text[3]}
                  glyph={
                    <Filter
                      fill={filterActive ? "white" : colors.text[1]}
                      height="17px"
                      width="20px"
                    />
                  }
                  opacity="0.69"
                  py={1}
                />
                <Primitives.Text
                  color={filterActive ? "white" : colors.primary[3]}
                  fontSize={1}
                  fontWeight={1}
                  ml="8px"
                >
                  Apply Filter
                </Primitives.Text>
              </Primitives.Flex>
              <StyledText
                color={colors.text[1]}
                cursor={isClearFilter ? "pointer" : "default"}
                fontSize={1}
                lineHeight={4}
                mb="auto"
                ml={3}
                mt="auto"
                onClick={() => {
                  if (isClearFilter) {
                    clearFilter();
                  }
                }}
              >
                {isClearFilter ? "Clear Filter" : ""}
              </StyledText>
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
        {isLoaded ? (
          <Primitives.Flex flexDirection="column" ml={2}>
            {data && data["total_items"] > 0 && (
              <Primitives.Flex
                alignItems="center"
                flex={1}
                justifyContent="space-between"
              >
                <Primitives.Text color={colors.text[1]} fontSize={1}>
                  Showing {(pageNumber - 1) * 20 + 1}-
                  {data["total_items"] > 20
                    ? pageNumber * 20 > data["total_items"]
                      ? data["total_items"]
                      : pageNumber * 20
                    : data["total_items"]}{" "}
                  of {data["total_items"]}
                  {" SKUs"}
                </Primitives.Text>
                {isDownload ? (
                  <Loading onlySpinner />
                ) : (
                  <DownloadBtn
                    clickHandler={() => {
                      downloadHandler(`category_id=${categoryID}`);
                    }}
                  />
                )}
              </Primitives.Flex>
            )}
            {data && data["total_items"] ? (
              <Table
                borderBottom={{
                  head: `1px solid ${colors.text[3]}`,
                  row: `1px solid ${colors.text[3]}`
                }}
                clickHandler={() => null}
                color={{ head: colors.text[1], row: colors.text[0] }}
                columnWidth={[4, 1, 1, 1, 1, 1]}
                data={modifyData(data["product_masters_category"])}
                header={header}
                pb={{ headContainer: 2, row: 2 }}
                pt={{ headContainer: 2, row: 2 }}
              />
            ) : (
              <NoResults />
            )}
          </Primitives.Flex>
        ) : (
          <Loading />
        )}
        {data && data["total_items"] > 20 && (
          <Primitives.Flex bottom={10} ml="auto" right={40}>
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              marginPagesDisplayed={1}
              pageCount={parseInt(data["total_items"]) / 20}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

export default NewProducts;
