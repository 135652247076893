import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Back from "../../../../../components/glyphs/elements/back";
import Loading from "../../../../../components/loading";
import NoResults from "../../../../../components/no-results";
import Pagination from "../../../../../components/pagination";
import Primitives from "../../../../../components/primitives";
import Table from "../../../../../components/table-two/TableComponent";
import { colors } from "../../../../../components/utils/theme";
import DownloadBtn from "../../../../../lib/download";
import Filters from "../../../../../lib/filters";
import Navigation from "../../../../../lib/navigation";
import OtifService from "../../../../../services/reports/order-fulfilment/index";
import DisplayAllItems from "../../../lib/display-all-items";
import downloadHandlerFn from "../../../../../components/utils/download-handler";
import { BASE_URL } from "../../../../../services/config";
import splitDateRange from "../../../../../utils/split-date-range";
import { FulfillmentContext } from "../../../../../utils/fulfillment-report-context";
import { subDays } from "date-fns";
import formatDate from "../../../../../utils/format-date";

const downloadHandler = async (setIsDownload, query) =>
  downloadHandlerFn(
    `${BASE_URL}/reports/purchase_order_otif_report.xlsx?${query}&is_download=true`,
    setIsDownload
  );

const cellFn = data => (
  <Primitives.Flex color={colors.text[0]} pl={2} py={1}>
    {data}
  </Primitives.Flex>
);

const column = [
  { heading: cellFn("PO Number"), type: "string", width: 0.7 },
  { heading: cellFn("PO Date"), type: "string", width: 0.5 },
  { heading: cellFn("Hospital Name"), type: "string", width: 1.5 },
  {
    heading: cellFn("Uniq Products"),
    type: "number",
    width: 0.5,
    align: "flex-end"
  },
  {
    heading: cellFn("Total Qty."),
    type: "number",
    width: 0.5,
    align: "flex-end"
  },
  {
    heading: cellFn("Total Amt"),
    type: "number",
    width: 0.5,
    align: "flex-end"
  },
  { heading: " ", type: "string", width: 0.5, align: "flex-end" }
];

const modifyData = (data, history) =>
  data &&
  data.map((item, index) => ({
    category: cellFn(item.po_number),
    manufacturer: cellFn(item.po_date),
    batchNumber: cellFn(item.buyer_party),
    skuName: cellFn(item.uniq_products_count),
    batchExpiry: cellFn(item.total_quantity),
    quantity: cellFn("₹" + item.total_amount.toFixed(2)),
    "": (
      <Primitives.Flex
        color={colors.primary[3]}
        cursor="pointer"
        flex={1}
        fontSize="13px"
        key={index}
        my={1}
        onClick={() =>
          history.push({
            pathname: `/hospital/orders/${item.sales_order_id}`
          })
        }
      >
        View PO Details
      </Primitives.Flex>
    )
  }));

const PurchaseOrderDetails = () => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const status = useParams().otifStatus;
  const fulfillmentContext = useContext(FulfillmentContext);
  const startDate = fulfillmentContext.otifStartDate,
    endDate = fulfillmentContext.otifEndDate;
  const [pageNumber, setPageNumber] = useState(1);
  const [isDownload, setIsDownload] = useState(false);
  const [query, updateQuery] = useState(
    `&otif_status=${status}&order_date_gteq=${startDate}&order_date_lteq=${endDate}`
  );

  const fetchPurchaseOrderOtif = async query => {
    try {
      setIsLoaded(false);
      const response = await OtifService.purchaseOrderOtif(query);
      if (response) {
        setIsLoaded(true);
        setData(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchPurchaseOrderOtif(query);
  }, []);

  const filterUpdate = queryObj => {
    const dateRange = splitDateRange(queryObj.date);
    const start_date =
      (dateRange && dateRange.startDate) ||
      formatDate(subDays(subDays(new Date(), 3), 29));
    const end_date =
      (dateRange && dateRange.endDate) || formatDate(subDays(new Date(), 3));
    fulfillmentContext.updateOtifStartDate(start_date);
    fulfillmentContext.updateOtifEndDate(end_date);
    updateQuery(
      `&otif_status=${status}&q[po_number_cont]=${queryObj.poNumberSearch ||
        ""}&q[buyer_party_of_Hospital_type_display_name_cont]=${queryObj.hospitalSearch ||
        ""}&order_date_lteq=${end_date}&order_date_gteq=${start_date}`
    );
  };

  useEffect(() => {
    query && query.length > 0 && fetchPurchaseOrderOtif(query);
  }, [query]);

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setPageNumber(1);
    }
  };

  useEffect(() => {
    setData([]);
    fetchPurchaseOrderOtif(
      `&otif_status=${status}&page=${pageNumber}${query || ""}`
    );
  }, [pageNumber]);

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 3]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          py={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex mr={2}>
              <Link exact="true" to="/hospital/fulfillment_reports">
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Text color={colors.text[0]} fontSize={5} fontWeight={2}>
              Purchase Order OTIF Report -{" "}
              {status === "on_time"
                ? "On Time"
                : status.charAt(0).toUpperCase() + status.slice(1)}{" "}
              PO&#39;s
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex flexDirection="column" pt={3} pl={4}>
            <Primitives.Text color={colors.text[1]}>
              Purchase Orders
            </Primitives.Text>
            <Primitives.Text height="19px" pt={1}>
              {data.total_po_count}
            </Primitives.Text>
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Box mt={2} pl={5}>
          <Filters
            config={{
              poNumberSearch: true,
              hospitalSearch: true,
              date: { buffer: 3 },
              defaultDateRange: { startDate: startDate, endDate: endDate }
            }}
            filterUpdate={filterUpdate}
          />
        </Primitives.Box>
        {data &&
          data.purchase_order_otif &&
          data.purchase_order_otif.length > 0 && (
            <Primitives.Flex alignItems="center" my={1} px={5}>
              <Primitives.Flex fontSize={1} color={colors.text[1]}>
                <DisplayAllItems
                  currentPage={pageNumber}
                  itemsPerPage={20}
                  totalItems={data.total_po_count}
                  title="Orders"
                />
              </Primitives.Flex>
              <Primitives.Flex flex={1} justifyContent="flex-end">
                <Primitives.Flex flex={1} justifyContent="flex-end">
                  {isDownload ? (
                    <Loading onlySpinner />
                  ) : (
                    <DownloadBtn
                      clickHandler={() => {
                        downloadHandler(setIsDownload, query);
                      }}
                    />
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          )}
        {isLoaded && data && data.purchase_order_otif ? (
          <Primitives.Flex flexDirection="column" overflow="scroll" px={5}>
            <Primitives.Flex
              backgroundColor="white"
              borderRadius={10}
              border="1px solid"
              borderColor={colors["athens-gray"]}
              boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.1)"
              flexDirection="column"
              my={2}
            >
              {data &&
              data.purchase_order_otif &&
              data.purchase_order_otif.length > 0 ? (
                <Table
                  borderBottom={{
                    head: `1px solid ${colors["pattens-blue"]}`,
                    row: "0px"
                  }}
                  color={{ head: colors.text[1], row: colors.text[0] }}
                  data={modifyData(data.purchase_order_otif, history)}
                  column={column}
                  coloredRow={true}
                  pb={{ headContainer: 2, row: 2 }}
                  pt={{ headContainer: 2, row: 2 }}
                />
              ) : (
                <Primitives.Flex m="auto" my={7}>
                  <NoResults />
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
        {data && data.total_po_count > 20 && (
          <Primitives.Flex
            backgroundColor={colors.accent[0]}
            bottom={0}
            right={0}
            width="100vw"
            justifyContent="flex-end"
            zIndex={2}
          >
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              marginPagesDisplayed={1}
              pageCount={data.total_po_count / 20}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

export default PurchaseOrderDetails;
