import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../../components/utils/theme";
import Button from "../../../components/button";
import Primitives from "../../../components/primitives";
import Error from "../../../components/glyphs/elements/error";
import Alert from "../../../components/alert";
import Modal from "../../../components/modal";
import { AppContext } from "../../../utils/context";

const inputProps = {
  mt: 3,
  height: 33,
  border: 1,
  borderStyle: "solid",
  borderColor: colors.text[2],
  borderRadius: 4,
  pl: 10,
  type: "password"
};

const ChangePassword = () => {
  const [modal, toggleModal] = useState(false);
  const [alert, toggleAlert] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { BASE_URL } = useContext(AppContext);

  const updatePassword = async () => {
    if (
      currentPassword.length === 0 ||
      confirmPassword.length === 0 ||
      newPassword.length === 0 ||
      currentPassword.indexOf(" ") >= 0 ||
      confirmPassword.indexOf(" ") >= 0 ||
      newPassword.indexOf(" ") >= 0
    ) {
      setErrorMessage("Password cannot be empty");
      toggleAlert(true);
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user: {
          current_password: currentPassword,
          password: newPassword,
          password_confirmation: confirmPassword
        }
      });
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
        const response = await fetch(
          `${BASE_URL}/users/update_password`,
          requestOptions
        );
        const json = await response.json();
        if (json["status"] === "success") {
          window.location.reload();
        } else {
          setErrorMessage(json["message"]);
          toggleAlert(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        toggleAlert(!alert);
      }, 2000);
    }
  }, [alert]);

  return (
    <>
      <Primitives.Flex
        borderTop="1px solid"
        borderColor="background-light-blue"
        flexDirection="column"
        mx={5}
      >
        {modal && (
          <Modal
            border={`1px solid ${colors.text[3]}`}
            borderRadius={8}
            closeHandler={() => {
              toggleModal(false);
            }}
            content={
              <Primitives.Flex flex={1} flexDirection="column">
                <Primitives.Flex flexDirection="column">
                  <Primitives.Flex
                    justifyContent="center"
                    left={0}
                    position="absolute"
                    top={14}
                    width={1}
                  >
                    {alert && (
                      <Alert
                        backgroundColor="white"
                        borderColor={colors.error[1]}
                        color={colors.error[1]}
                        fontSize={1}
                        glyph={<Error fill={colors.error[1]} />}
                        label={
                          <Primitives.Text
                            alignItems="center"
                            display="inline-flex"
                          >
                            {errorMessage}
                          </Primitives.Text>
                        }
                        lineHeight={3}
                        mx={15}
                        py={1}
                      />
                    )}
                  </Primitives.Flex>
                  <Primitives.Text fontSize={4} fontWeight={2}>
                    Change Password
                  </Primitives.Text>
                  <Primitives.Text fontSize={2} mt={4}>
                    Enter Current Password
                  </Primitives.Text>

                  <Primitives.Input
                    onChange={e => {
                      setCurrentPassword(e.target.value);
                    }}
                    placeholder="Current Password"
                    {...inputProps}
                  />

                  <Primitives.Text fontSize={2} mt={4}>
                    Enter New Password
                  </Primitives.Text>

                  <Primitives.Input
                    onChange={e => {
                      setNewPassword(e.target.value);
                    }}
                    placeholder="Enter New Password"
                    {...inputProps}
                  />

                  <Primitives.Text fontSize={2} mt={4}>
                    Confirm New Password
                  </Primitives.Text>

                  <Primitives.Input
                    onChange={e => {
                      setConfirmPassword(e.target.value);
                    }}
                    placeholder="Confirm Password"
                    {...inputProps}
                  />

                  <Primitives.Flex justifyContent="center" mt={3} mb={2}>
                    <Primitives.Flex ml={2}>
                      <Button
                        active={{
                          backgroundColor: colors.primary[6],
                          color: "white"
                        }}
                        backgroundColor={colors.primary[3]}
                        clickHandler={updatePassword}
                        color="white"
                        fontWeight={2}
                        hover={{
                          backgroundColor: colors.primary[6],
                          color: "white"
                        }}
                        label="Update"
                        lineHeight={4}
                        px={7}
                      />
                    </Primitives.Flex>
                  </Primitives.Flex>
                </Primitives.Flex>
              </Primitives.Flex>
            }
            open={true}
            pb={2}
            py={3}
            px={4}
            width="30vw"
          />
        )}
        <Primitives.Text
          color={colors.text[0]}
          fontWeight={3}
          fontSize={5}
          mt={4}
        >
          Password
        </Primitives.Text>

        <Primitives.Flex my={3}>
          <Button
            active={{ backgroundColor: colors.primary[6], color: "white" }}
            border="1px solid"
            clickHandler={() => {
              toggleModal(true);
            }}
            color={colors.primary[3]}
            fontWeight={2}
            hover={{ backgroundColor: colors.primary[6], color: "white" }}
            label="Change Password"
            lineHeight={4}
            px={5}
          />
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};
export default ChangePassword;
