/**
  last modified: 23 jan
  modified by: shubham
 */
import React from "react";
import Primitives from "../../../../components/primitives";
import { array, func, number, object, oneOfType, string } from "prop-types";
import NavItem from "../../../../components/nav-item";
import { colors } from "../../../../components/utils/theme";

const propTypes = {
  selectedTab: oneOfType([number, string]),
  tabsHeading: oneOfType([array, object]),
  tabSelectHandler: func
};

const TabsSection = ({ tabsHeading, selectedTab, tabSelectHandler }) => (
  <Primitives.Flex flexDirection="column">
    <Primitives.Flex
      backgroundColor="white"
      borderBottom="1px solid"
      borderTop="1px solid"
      borderColor="athens-gray"
      px={5}
    >
      {Object.keys(tabsHeading).map((item, index) => {
        return (
          <Primitives.Button
            key={index}
            onClick={() => {
              tabSelectHandler(item);
            }}
          >
            <NavItem
              backgroundColor={item === selectedTab ? "light-blue" : "white"}
              borderColor="highlight-blue"
              color={item === selectedTab ? "highlight-blue" : colors.text[0]}
              fontSize={1}
              fontWeight={item === selectedTab ? 2 : 1}
              hoverColor={
                item === selectedTab ? "highlight-blue" : colors.text[0]
              }
              label={tabsHeading[item].toUpperCase()}
              px={3}
              pt={2}
              pb={2}
              selected={item === selectedTab}
            />
          </Primitives.Button>
        );
      })}
    </Primitives.Flex>
  </Primitives.Flex>
);

TabsSection.propTypes = propTypes;
export default TabsSection;
