import React, { useContext, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import Alert from "../../../../components/alert";
import Error from "../../../../components/glyphs/elements/error";
import SbLogo from "../../../../components/glyphs/elements/sb-logo";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import { AppContext } from "../../../../utils/context";
import Button from "../../button";
import Footer from "../../footer";
import SmartbuyTrademark from "../../sb-trademark";
const alertData = [
  {
    text: "Link not valid or expired. Try generating a new link.",
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  },
  {
    text: "Password too weak. Try a different password.",
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  }
];

const ResetPassword = () => {
  let history = useHistory();
  const { BASE_URL } = useContext(AppContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidation, togglePasswordValidation] = useState([false, ""]);
  const [isLoaded, toggleLoading] = useState(false);
  const [alert, toggleAlert] = useState([false, 0]);
  const token = useParams().token;
  const email = useParams().email;

  const handleSubmit = event => {
    event.preventDefault();
    if (password !== confirmPassword) {
      togglePasswordValidation([true, "Passwords do not match"]);
    } else if (password === "" || confirmPassword === "") {
      togglePasswordValidation([true, "Password cannot be empty"]);
    } else {
      toggleLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "_aknamed_admin_session=KNT91jPIl2iY0q52%2BbgDAdmTo9SEP3CP92q3Sh3iM1r7A%2F6VcESDgdk5IFBfSbjuzg%3D%3D--kK9wtn8Cf%2FFV0BVy--RLvNl2gaR7OL9A6dvntJ9g%3D%3D"
      );

      var raw = JSON.stringify({
        password: password,
        token: token,
        email: email
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${BASE_URL}/reset_password`, requestOptions)
        .then(response => response.json())
        .then(result => {
          toggleLoading(false);
          if (result.alert) {
            history.push("/");
          } else if (result.status === 409) {
            toggleAlert([true, 1]);
          } else if (result.status === 404) {
            toggleAlert([true, 0]);
          }
        })
        .catch(error => console.log("error", error));
    }
  };

  useEffect(() => {
    if (passwordValidation[0]) {
      if (password === confirmPassword) {
        togglePasswordValidation([false, ""]);
      }
    }
  }, [password, confirmPassword]);

  return (
    <Primitives.Flex flexDirection="row" alignItems="center" height="100vh">
      <Primitives.Flex marginRight={5} width={1}>
        <Primitives.Flex flex={1} flexDirection="column">
          <Primitives.Flex flexDirection="column" m="auto">
            <SmartbuyTrademark />
            <Primitives.Flex flexDirection="column" pt={2}>
              <Primitives.Text
                color={colors.text[0]}
                fontSize={6}
                fontWeight={0}
              >
                Enter New Password
              </Primitives.Text>
            </Primitives.Flex>
            {alert[0] && (
              <Primitives.Flex mt={2}>
                <Alert
                  backgroundColor={alertData[alert[1]].backgroundColor}
                  borderColor={alertData[alert[1]].borderColor}
                  color={alertData[alert[1]].color}
                  fontSize={1}
                  glyph={alertData[alert[1]].glyph}
                  label={
                    <Primitives.Text alignItems="center" display="inline-flex">
                      {alertData[alert[1]].text}
                    </Primitives.Text>
                  }
                  lineHeight={3}
                  mx={2}
                  pl={0}
                  pr={[0, 1]}
                  py="5px"
                />
              </Primitives.Flex>
            )}
            <Primitives.Input
              border={1}
              borderColor={colors.text[2]}
              borderRadius={4}
              borderStyle="solid"
              height={33}
              mt={3}
              onChange={e => {
                setPassword(e.target.value);
              }}
              pl={10}
              placeholder="Enter New Password"
              type="password"
            ></Primitives.Input>

            {passwordValidation[0] && (
              <Primitives.Flex mb={-21} px="3px" py="3px">
                <Primitives.Text color={colors.error[0]} fontSize={1}>
                  {passwordValidation[1]}
                </Primitives.Text>
              </Primitives.Flex>
            )}
            <Primitives.Input
              border={1}
              borderColor={colors.text[2]}
              borderRadius={4}
              borderStyle="solid"
              height={33}
              mt={3}
              onChange={e => {
                setConfirmPassword(e.target.value);
              }}
              pl={10}
              placeholder="Retype New Password"
              type="password"
              width="29vw"
            ></Primitives.Input>
            <Button isLoaded={isLoaded} handleSubmit={handleSubmit}>
              Change Password
            </Button>
            <Footer />
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex flex={1}>
          <SbLogo />
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
export default ResetPassword;
