import React from "react";
import { Link, useHistory } from "react-router-dom";
import { colors } from "../../../components/utils/theme";
import { object } from "prop-types";
import Back from "../../../components/glyphs/elements/back";
import Primitives from "../../../components/primitives";
import Button from "../../../components/button";
import UploadedFile from "../../../components/glyphs/elements/uploadedFile";
import { indianNumberFormat } from "../../../components/utils";

const propTypes = {
  data: object,
  fileDetails: object
};

const OrderBand = ({ data, fileDetails }) => {
  let history = useHistory();
  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  return (
    <Primitives.Box
      backgroundColor="background-blue"
      flex={1}
      flexDirection="row"
    >
      <Primitives.Flex flexDirection="column" px={4} pt={2} pb={1}>
        <Primitives.Flex alignItems="flex-end" flexDirection="row">
          <Primitives.Flex mr={10}>
            <Link
              exact="true"
              to=""
              onClick={() => {
                history.goBack();
              }}
            >
              <Back fill="black" />
            </Link>
          </Primitives.Flex>
          <Primitives.Text fontSize={5}>
            Order No:{data.po_number}
          </Primitives.Text>
        </Primitives.Flex>
        <Primitives.Flex flexDirection="row" fontSize={2} ml={4} py={2}>
          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>Amount</Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              ₹{indianNumberFormat(data.total_amount.toFixed(2))}
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>
              Total Products
            </Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              {indianNumberFormat(data.items_count)}
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>Total Qty.</Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              {indianNumberFormat(data.total_quantity)}
            </Primitives.Text>
          </Primitives.Flex>

          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>
              Pending Qty.
            </Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              {indianNumberFormat(data.total_pending_quantity)}
            </Primitives.Text>
          </Primitives.Flex>

          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>
              Pending Amt.
            </Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              ₹{indianNumberFormat(data.total_pending_amount.toFixed(2))}
            </Primitives.Text>
          </Primitives.Flex>

          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>
              Date Issued
            </Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              {days[new Date(data.order_date).getDay()].concat(", ") +
                new Date(data.order_date).getDate() +
                " " +
                months[new Date(data.order_date).getMonth()].concat(" ") +
                new Date(data.order_date).getFullYear()}
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex flexDirection="column" flex="auto">
            <Primitives.Text color={colors.text[1]}>Hospital</Primitives.Text>
            <Primitives.Text color={colors.text[0]} pt={1}>
              {data.buyer_name}
            </Primitives.Text>
          </Primitives.Flex>
          {fileDetails && fileDetails.name && (
            <Primitives.Flex flexDirection="column" flex="auto">
              <Primitives.Text color={colors.text[1]}>
                Attachments
              </Primitives.Text>
              <Primitives.Flex mt={1}>
                <Button
                  color={colors.text[0]}
                  fontWeight={1}
                  glyph={<UploadedFile height={20} width={20} />}
                  hover={{
                    backgroundColor: colors.text[2],
                    color: colors.text[0]
                  }}
                  lineHeight={3}
                />
                <Primitives.Flex color={colors["highlight-blue"]} ml={1}>
                  <Link to={{ pathname: fileDetails.file }} target="_blank">
                    Original PO
                  </Link>
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Box>
  );
};

OrderBand.propTypes = propTypes;
export default OrderBand;
