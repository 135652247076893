import React, { useEffect, useState } from "react";
import { subDays } from "date-fns";
import { StyledSelect } from "../dropdown";
import {
  DefinedRange,
  DateRange as Calendar,
  defaultStaticRanges
} from "react-date-range";
import { colors } from "../utils/theme";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import Primitives from "../primitives";
import CalendarIcon from "../glyphs/elements/calendar";
import DateRangeSeparator from "../glyphs/elements/date-range-separator";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
  instanceOf
} from "prop-types";
import "./index.css";

const propTypes = {
  /** date buffer */
  buffer: number,
  /** Border around the button */
  border: string,
  /** Border radius for select */
  borderRadius: oneOfType([number, string]),
  /** Handler function for date change */
  changeHandler: func,
  /** Handler function for collapsing date-range options */
  collapseHandler: func,
  /** State of date-range options if it is collapsed or not */
  collapsed: bool,
  /** Text color of select */
  color: string,
  /** Calendar is disabled or not */
  disabled: bool,
  /** disabled dates */
  disabledDates: oneOfType([string, instanceOf(Date)]),
  /** Header for select to be displayed before collapsing */
  defaultValue: oneOfType([object, string]),
  /** What Font family should button text use*/
  fontFamily: string,
  /** What font size should be used on Select */
  fontSize: oneOfType([number, string]),
  /** Font styling for select text */
  fontStyle: string,
  /** What fontweight should be applied to select text */
  fontWeight: string,
  /** line height for texts in select */
  lineHeight: number,
  /** Starting and opening date range */
  ranges: array,
  showInitialDate: bool,
  /** max no of days selected from custom date selector */
  maxSpan: number
};

const defaultProps = {
  disabled: false,
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: 1.2,
  showInitialDate: false,
  buffer: 0,
  maxSpan: 0
};

const DateRangeSelector = ({
  buffer,
  border,
  borderRadius,
  changeHandler,
  collapseHandler,
  collapsed,
  color,
  disabled,
  disabledDates,
  defaultValue,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  ranges,
  showInitialDate,
  maxSpan
}) => {
  const isSelectedFunc = defaultStaticRanges[0].isSelected;
  const [calendarPosition, setcalendarPosition] = useState("right");
  const customStaticRanges = [
    {
      range: () => {
        return {
          startDate: subDays(subDays(new Date(), buffer), 6),
          endDate: subDays(new Date(), buffer),
          isCustomDateSelected: false
        };
      },
      isSelected: isSelectedFunc,
      label: "Last 7 days"
    },
    {
      range: () => {
        return {
          startDate: subDays(subDays(new Date(), buffer), 29),
          endDate: subDays(new Date(), buffer),
          isCustomDateSelected: false
        };
      },
      isSelected: isSelectedFunc,
      label: "Last 30 days"
    },
    {
      range: () => {
        return {
          startDate: subDays(subDays(new Date(), buffer), 89),
          endDate: subDays(new Date(), buffer),
          isCustomDateSelected: false
        };
      },
      isSelected: isSelectedFunc,
      label: "Last 90 days"
    },
    {
      range: () => {
        return {
          startDate: subDays(new Date(), buffer),
          endDate: subDays(new Date(), buffer),
          isCustomDateSelected: true
        };
      },
      isSelected: () => isCustomDateSelected,
      label: "Custom Range"
    }
  ];
  const [isCustomDateSelected, selectCustomDate] = useState(false);

  const dateDisplay = date =>
    typeof date !== "string"
      ? `${String(date.getDate()).padStart(2, "0")}/${String(
          date.getMonth() + 1
        ).padStart(2, "0")}/${date.getFullYear()}`
      : date;

  const dateSelectorValue = (startDate, endDate, showPlaceholder = false) => (
    <Primitives.Flex
      color={colors.text[0]}
      fontSize="0.85em"
      alignItems="center"
    >
      <Primitives.Text mr="2px">
        {showPlaceholder ? "Start date" : dateDisplay(startDate)}
      </Primitives.Text>
      <DateRangeSeparator />
      <Primitives.Text ml="2px">
        {showPlaceholder ? "End date" : dateDisplay(endDate)}
      </Primitives.Text>
    </Primitives.Flex>
  );

  const showDate = (ranges, defaultValue, showInitialDate) => {
    if (ranges[0] && ranges[0].startDate && ranges[0].endDate) {
      if (!ranges[0].new) {
        return dateSelectorValue(ranges[0].startDate, ranges[0].endDate);
      }
      if (ranges[0].new) {
        if (showInitialDate) {
          return dateSelectorValue(ranges[0].startDate, ranges[0].endDate);
        } else if (defaultValue) {
          if (defaultValue.startDate) {
            return dateSelectorValue(
              defaultValue.startDate,
              defaultValue.endDate
            );
          } else {
            return (
              <Primitives.Text fontSize="0.85em">
                {defaultValue}
              </Primitives.Text>
            );
          }
        } else {
          return (
            <Primitives.Text fontSize="0.85em">
              {dateSelectorValue(ranges[0].startDate, ranges[0].endDate, true)}
            </Primitives.Text>
          );
        }
      }
    }
  };
  useEffect(() => {
    const customCalendar = document.getElementById("parentWrapper");
    // 412 is width of Calendar
    if (
      customCalendar &&
      customCalendar.getBoundingClientRect().right + 412 > window.innerWidth
    ) {
      setcalendarPosition("left");
    }
  }, []);
  return (
    <Primitives.Flex flexDirection="column" position="relative" width={1}>
      <StyledSelect
        border={border}
        borderColor={"#D9D9D9"}
        borderRadius={borderRadius}
        color={color}
        disabled={disabled}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        onClick={collapseHandler}
        px="4px"
        py="12px"
        width={1}
      >
        {showDate(ranges, defaultValue, showInitialDate)}
        <Primitives.Flex ml="auto">
          <CalendarIcon fill={colors.text[1]} />
        </Primitives.Flex>
      </StyledSelect>
      <Primitives.Flex
        display={collapsed && "none"}
        position="absolute"
        top={50}
        zIndex={1}
        width={1}
        style={{ direction: calendarPosition === "left" ? "rtl" : "ltr" }}
      >
        <Primitives.Flex
          id="parentWrapper"
          position={["fixed", "unset"]}
          width={1}
          height={["100%", "unset"]}
          left={[0, "unset"]}
          top={[0, "unset"]}
          justifyContent={["center", "unset"]}
          zIndex={2}
          flexDirection={calendarPosition === "left" && "row-reverse"}
          style={{ direction: "ltr" }}
        >
          <DefinedRange
            onChange={item => {
              changeHandler(item);
              if (item.selection.isCustomDateSelected) {
                selectCustomDate(true);
              } else {
                selectCustomDate(false);
              }
            }}
            ranges={ranges}
            inputRanges={[]}
            staticRanges={customStaticRanges}
          />
          {isCustomDateSelected && (
            <Calendar
              editableDateInputs={false}
              moveRangeOnFirstSelection={false}
              onChange={item => {
                const days =
                  (item.selection.endDate.getTime() -
                    item.selection.startDate.getTime()) /
                  (1000 * 60 * 60 * 24);
                if (maxSpan && days >= maxSpan) {
                  alert(
                    `total days selected must be less or equal to ${maxSpan} days, currently ${days +
                      1} selected`
                  );
                } else {
                  changeHandler(item, true);
                  selectCustomDate(true);
                }
              }}
              ranges={ranges}
              showMonthAndYearPickers={false}
              weekdayDisplayFormat="EEEEE"
              months={1}
              direction="horizontal"
              minDate={disabledDates}
              maxDate={subDays(new Date(), buffer)}
            />
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

DateRangeSelector.propTypes = propTypes;
DateRangeSelector.defaultProps = defaultProps;
export default DateRangeSelector;
