import React, { useContext, useState } from "react";
import Primitives from "../../components/primitives";
import Filters from "../../lib/filters";
import Navigation from "../../lib/navigation";
import OperationalSummary from "./operational-summary";
import ExpiryReport from "./expiry-report";
import Savings from "./savings";
import SkuDistribution from "./sku-distribution";
import { AppContext } from "../../utils/context";
import { convertDate } from "../../components/utils";
import ProcurementSummary from "./procurement-summary";
import MarginTrend from "./margin-trend";

export const objectToArr = obj =>
  obj ? Object.values(obj).map(d => (d === null ? 0 : d)) : [];

export const objectToKey = obj =>
  obj ? Object.keys(obj).map(d => (d === null ? 0 : d)) : [];

const Dashboard = () => {
  const { BASE_URL } = useContext(AppContext);
  const defaultDate = `start_date=${convertDate(
    new Date().setDate(new Date().getDate() - 7)
  )}&end_date=${convertDate(new Date())}`;
  const [query, updateQuery] = useState(`hospital_entity_id=&${defaultDate}`);
  const path = `${BASE_URL}/dashboards`;
  const props = { path: path, query: query };

  const filterUpdate = queryObj => {
    updateQuery(`&${defaultDate}${queryObj.hospitalEntity || ""}`);
  };

  return (
    <>
      <Navigation selected={0} />
      <Primitives.Flex background="#f4f6f6" flexDirection="column" p="30px">
        <Primitives.Flex fontSize="20px" fontWeight={3} mb={2}>
          <Primitives.Text mr={3} my="auto">
            Dashboard
          </Primitives.Text>
          <Filters
            config={{ hospitalEntity: true }}
            filterUpdate={filterUpdate}
          />
        </Primitives.Flex>
        <OperationalSummary query={query} />
        <ProcurementSummary />
        <Primitives.Box my={60}>
          <MarginTrend query={query} />
        </Primitives.Box>
        <Primitives.Box mb={30}>
          <Savings {...props} />
        </Primitives.Box>
        <Primitives.Flex mb={30}>
          <ExpiryReport {...props} />
          <Primitives.Flex flexDirection="column" flex={1}>
            <Primitives.Box>
              <SkuDistribution {...props} />
            </Primitives.Box>
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default Dashboard;
