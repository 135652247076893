/**
  It renders procurement summary report. Last modified on 30/01/23 for testing 
*/
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/context";
import { convertDate } from "../../../components/utils";
import Header from "../lib/header";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";
import Pagination from "../../../components/pagination";
import Primitives from "../../../components/primitives";
import SkuDetail from "./sku-detail";
import Table from "../../../components/table-two/TableComponent";
import { colors } from "../../../components/utils/theme";
import useApiRequest from "../../../hooks/useApiRequest";
import DownloadBtn from "../../../lib/download";
import DisplayALlItems from "../lib/display-all-items";
import Navigation from "../../../lib/navigation";
import downloadHandlerFn from "../../../components/utils/download-handler";
import formatDate from "../../../utils/format-date";
import { subDays } from "date-fns";

/** Table column detail */
const column = [
  { heading: "SNO", type: "number", width: 0.3, sort: false },
  {
    heading: "MANUFACTURER NAME",
    type: "string",
    width: 1.5
  },
  {
    heading: "TOTAL PURCHASE VALUE",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "TOTAL PURCHASE QTY",
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "UNIQUE SKUS PURCHASED",
    type: "number",
    width: 1,
    align: "flex-end"
  }
];

/** Transform the input data into a new format  */
const modifyData = (data, currentPage) =>
  data?.response?.report?.map((item, index) => ({
    SNO: currentPage === 1 ? index + 1 : currentPage * 20 + index + 1,
    "MANUFACTURER NAME": item.hospital_name,
    "TOTAL PURCHASE VALUE": `₹ ${item.total_amount}`,
    "TOTAL PURCHASE QTY": item.total_quantity,
    "UNIQUE SKUS PURCHASED": item.uniq_skus
  }));
const indexes = {
  1: "hospital_name",
  2: "total_amount",
  3: "total_quantity",
  4: "uniq_skus"
};

/** Handles download */
const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);

/** Component function */
const ProcurementSummary = () => {
  const { BASE_URL } = useContext(AppContext);
  const defaultDate = `start_date=${convertDate(
    new Date().setDate(new Date().getDate() - 6)
  )}&end_date=${convertDate(new Date())}`;

  const defaultDateRange = {
    startDate: formatDate(subDays(new Date(), 6)),
    endDate: formatDate(new Date())
  };
  const [filteredDate, setFilteredDate] = useState(defaultDate);
  const [selectedRow, setSelectedRow] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortState, setSortState] = useState("asc");
  const [isDownload, setIsDownload] = useState(false);
  const [query, setQuery] = useState(`&${defaultDate}`);
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/procurement_report`
  );
  const [sortingIndex, setSortingIndex] = useState();
  const [hospitalEntity, setHospitalEntity] = useState();
  const [category, setCategory] = useState();

  const { data, isLoaded } = useApiRequest(request);

  const tableClickHandler = (row, index) => {
    setSelectedRow(index);
  };

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setCurrentPage(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setCurrentPage(1);
    }
  };

  /** handling pagination and query updates */
  useEffect(() => {
    updateRequest(
      `${BASE_URL}/reports/procurement_report?page=${currentPage || 1}${query}`
    );
  }, [currentPage, query, BASE_URL]);

  const headClickHandler = id => {
    const order = sortState === "asc" ? "desc" : "asc";
    setSortState(order);
    setSortingIndex(id);
  };

  useEffect(() => {
    setQuery(
      `&${filteredDate && filteredDate}${category || ""}${hospitalEntity ||
        ""}${
        sortingIndex in indexes
          ? `&q[s]=${indexes[sortingIndex]} ${sortState}`
          : ""
      }`
    );
  }, [filteredDate, hospitalEntity, category, sortingIndex, sortState]);
  const filterUpdate = ({ date, hospitalEntity, category }) => {
    setFilteredDate(date || defaultDate);
    setHospitalEntity(
      hospitalEntity?.replace(
        "&hospital_entity_id=",
        "&universal_hospital_ids="
      )
    );
    setCategory(category);
  };

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 2]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Header
          config={{
            date: true,
            defaultDateRange: defaultDateRange,
            category: true,
            hospitalEntity: true
          }}
          label="Procurement Summary"
          filterUpdate={filterUpdate}
        />
        {isLoaded ? (
          data?.response?.report?.length > 0 ? (
            <Primitives.Flex px={4} flex={1} flexDirection="column">
              <Primitives.Flex mr={5} py={2}>
                <Primitives.Flex
                  flex={1}
                  fontSize="12px"
                  color={colors.text[1]}
                >
                  <DisplayALlItems
                    currentPage={currentPage}
                    totalItems={data?.response?.total_items}
                  />
                </Primitives.Flex>
                <Primitives.Flex flex={1} justifyContent="flex-end">
                  {!isDownload ? (
                    <DownloadBtn
                      clickHandler={() => {
                        downloadHandler(
                          setIsDownload,
                          `${data.download_link}&${query}`
                        );
                      }}
                    />
                  ) : (
                    <Loading onlySpinner />
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
              <Primitives.Flex height="66vh">
                <Primitives.Flex
                  flex={1.5}
                  flexDirection="column"
                  overflow="scroll"
                >
                  <Primitives.Flex>
                    <Primitives.Flex flex={1}></Primitives.Flex>
                  </Primitives.Flex>
                  <Primitives.Flex flex={1} pr={1}>
                    <Table
                      clickHandler={tableClickHandler}
                      color={{ head: colors.text[1], row: colors.text[0] }}
                      cursor="pointer"
                      data={modifyData(data, currentPage)}
                      column={column}
                      headClickHandler={headClickHandler}
                      selected={selectedRow}
                      page="procurement-summary-listing"
                      sortingType={sortState}
                      sortingIndex={sortingIndex}
                      sorting={true}
                    />
                  </Primitives.Flex>

                  {data?.response?.total_items > 20 && (
                    <Primitives.Flex justifyContent="flex-end">
                      <Pagination
                        changeHandler={paginationChangeHandler}
                        forcePage={currentPage - 1}
                        marginPagesDisplayed={1}
                        pageCount={data.response.total_items / 20}
                        pageRangeDisplayed={5}
                      />
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
                <Primitives.Flex flex={1}>
                  <SkuDetail
                    hospitalEntity={hospitalEntity}
                    category={category}
                    filteredDate={filteredDate}
                    hospitalDetails={data?.response?.report?.[selectedRow]}
                  />
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          ) : (
            <Primitives.Flex mt={7} ml="auto" mr="auto">
              <NoResults />
            </Primitives.Flex>
          )
        ) : (
          <Loading />
        )}
      </Primitives.Flex>
    </>
  );
};

export default ProcurementSummary;
