import React, { useContext, useState } from "react";

import { useHistory } from "react-router-dom";
import Primitives from "../../components/primitives";
import { AppContext } from "../../utils/context";
import { colors } from "../../components/utils/theme";
import SbLogo from "../../components/glyphs/elements/sb-logo";
import Loading from "../../components/loading";
import ForgotPassword from "./forgot-password";
import Alert from "../../components/alert";
import SmartbuyTrademark from "./sb-trademark";
import Footer from "./footer";
import UserServices from "../../services/user";
import Error from "../../components/glyphs/elements/error";
import OtpLoginPopups from "./otp-login";

const Login = () => {
  let history = useHistory();
  const { BASE_URL, loadUser } = useContext(AppContext);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [alertData, setAlertData] = useState("");

  const forgotPasswordHandler = data => {
    setForgotPassword(data[0]);
    setAlertData(data[1]);
  };

  const loadUserData = async () => {
    const response = await fetch(`${BASE_URL}/is_logged_in`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    const json = await response.json();
    if (json) {
      loadUser(json);
      setLoader(false);

      history.push(
        localStorage.getItem("newUser") === "true"
          ? "/hospital/purchase_orders"
          : "/hospital/orders"
      );
    }
  };

  const alertUser = error => {
    setLoader(false);
    setAlertData(error[0].detail);
  };

  const handleSubmit = async () => {
    setLoader(true);
    var raw = JSON.stringify({
      user: {
        email: email,
        password: password,
        account: "smartbuy"
      }
    });
    const res = await UserServices.userLogin(raw);
    res.success ? loadUserData() : alertUser(res.errors);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Primitives.Flex flexDirection="row" alignItems="center" height="100vh">
      {window.location.pathname.includes("otp_login") && (
        <OtpLoginPopups loadUserData={loadUserData} />
      )}
      <Primitives.Flex flexDirection="column" mr="auto" ml="auto">
        {alertData && typeof alertData === "string" && (
          <Primitives.Flex position="absolute" mt={-16}>
            <Alert
              backgroundColor={colors["background-dull-red"]}
              borderColor={colors.error[1]}
              color={colors.error[1]}
              fontSize={1}
              glyph={<Error fill={colors.error[1]} />}
              label={
                <Primitives.Text alignItems="center" display="inline-flex">
                  {alertData}
                </Primitives.Text>
              }
              lineHeight={3}
              mx={1}
              pl={1}
              pr={2}
              py="7px"
            />
          </Primitives.Flex>
        )}
        <SmartbuyTrademark />
        {!forgotPassword ? (
          <Primitives.Flex flexDirection="column" onKeyPress={handleKeyDown}>
            <Primitives.Flex>
              <Primitives.Text
                fontSize={10}
                fontFamily="lato"
                mt={11}
                color={colors.text[0]}
              >
                Improve Healthcare <br></br>Outcomes
              </Primitives.Text>
            </Primitives.Flex>

            <Primitives.Flex>
              <Primitives.Text
                fontSize={15}
                fontFamily="lato"
                mt={11}
                color={colors.text[2]}
              >
                Managing Orders, Tracking Delivery, <br></br> Tracking Profit
                Margins became easier
              </Primitives.Text>
            </Primitives.Flex>

            {alertData &&
              typeof alertData !== "string" &&
              Object.keys(alertData).length !== 0 && (
                <Primitives.Flex mt={2}>
                  <Alert
                    backgroundColor={alertData.backgroundColor}
                    borderColor={alertData.borderColor}
                    color={alertData.color}
                    fontSize={1}
                    glyph={alertData.glyph}
                    label={
                      <Primitives.Text
                        alignItems="center"
                        display="inline-flex"
                      >
                        {alertData.text}
                      </Primitives.Text>
                    }
                    lineHeight={3}
                    mx={1}
                    pl={0}
                    pr={1}
                    py="13px"
                  />
                </Primitives.Flex>
              )}

            <Primitives.Input
              onChange={e => {
                setemail(e.target.value);
              }}
              mt={3}
              height={33}
              border={1}
              borderStyle="solid"
              borderColor={colors.text[2]}
              borderRadius={4}
              placeholder="Email"
              pl={10}
            ></Primitives.Input>

            <Primitives.Input
              onChange={e => {
                setpassword(e.target.value);
              }}
              type="password"
              mt={18}
              height={33}
              border={1}
              borderStyle="solid"
              borderColor={colors.text[2]}
              borderRadius={4}
              placeholder="Password"
              pl={10}
            ></Primitives.Input>

            <Primitives.Button
              active={{ backgroundColor: colors.primary[6], color: "white" }}
              background={colors.primary[3]}
              borderRadius={4}
              color={colors.white}
              disabled={loader}
              fontFamily="lato"
              fontSize="13px"
              fontWeight={3}
              hover={{ backgroundColor: colors.primary[6], color: "white" }}
              mt={20}
              onClick={handleSubmit}
              paddingTop={10}
              paddingBottom={10}
              width="30vw"
            >
              {loader ? (
                <Primitives.Flex justifyContent="center" height={16}>
                  <Loading onlySpinner />
                </Primitives.Flex>
              ) : (
                "Login"
              )}
            </Primitives.Button>

            <Primitives.Flex mr="auto" ml="auto" mt={3}>
              <Primitives.Button
                color={colors.primary[3]}
                fontSize={3}
                fontFamily="lato"
                onClick={() => {
                  setForgotPassword(true);
                }}
              >
                Forgot Password?
              </Primitives.Button>
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <ForgotPassword forgotPasswordHandler={forgotPasswordHandler} />
        )}
        <Footer />
      </Primitives.Flex>
      <Primitives.Flex marginRight={5}>
        <Primitives.Flex>
          <SbLogo />
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
export default Login;
