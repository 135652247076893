import React from "react";

const ViewDetails = ({ ...props }) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="viewDetails-icon"
    {...props}
  >
    <path
      d="M0.99707 1L5.99707 6L0.99707 11"
      stroke="#F58158"
      strokeWidth="1.5"
      strokeLinecap="round"
      {...props}
    />
  </svg>
);

export default ViewDetails;
