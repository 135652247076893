const DropdownClick = (node, e) => {
  if (node.current.contains(e.target)) {
    // inside click
    return true;
  }
  // outside click
  if (!e.target.closest(".dropdown-filter")) {
    return false;
  }
};

export default DropdownClick;
