import React from "react";
import Primitives from "../../../../components/primitives";
import { array, object, oneOfType } from "prop-types";

const propTypes = {
  data: oneOfType([array, object])
};

const SideTag = ({ data }) => (
  <>
    <Primitives.Flex
      background="white"
      borderRight="3px solid #E78661"
      flex={1.8}
      flexDirection="column"
      justifyContent="space-around"
    >
      <Primitives.Flex fontSize="14px" py={2} px={2}>
        <Primitives.Text>Total Products with RC</Primitives.Text>
        <Primitives.Text fontWeight={3} ml="auto">
          {data[0]}
        </Primitives.Text>
      </Primitives.Flex>
    </Primitives.Flex>
  </>
);

export default SideTag;
SideTag.propTypes = propTypes;
