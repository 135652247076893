import React, { useState, useRef, useEffect } from "react";
import { object, func, bool, string } from "prop-types";
import styled from "styled-components";
import Primitives from "../../../../components/primitives";
import Button from "../../button";
import Back from "../../../../components/glyphs/elements/back";
import Dropdown from "../../../../components/dropdown";
import useApiRequest from "../../../../hooks/useApiRequest";
import UserServices from "../../../../services/user/index";

const proptypes = {
  BASE_URL: string,
  errors: object,
  setErrors: func,
  isLoading: bool,
  setIsLoading: func,
  setIndex: func,
  loadUserData: func
};
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 2px;
`;
const OnboardingComponent = ({
  BASE_URL,
  errors,
  setErrors,
  isLoading,
  setIsLoading,
  setIndex,
  loadUserData
}) => {
  const { data: cityOption, isLoaded } = useApiRequest(
    `${BASE_URL}/universal_cities_states`
  );
  const [citySearch, setCitySearch] = useState("");
  const [cityCollapse, setCityCollapse] = useState(false);
  const [cityId, setCityId] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [dlNo, setDlNo] = useState("");
  const dropdownRef = useRef(null);
  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCityCollapse(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const citySelectHandler = option => {
    setCitySearch(option.text);
    setCityId(option.id);
    setCityCollapse(false);
  };
  const detailsClickHandler = async () => {
    setIsLoading(true);
    const flag = cityId === null || orgName.trim() === "" || dlNo.trim() === "";
    if (flag) {
      if (!cityId) errors.city = "select city from dropdown options";
      if (!orgName.trim()) errors.orgName = "Please Enter Org Name";
      if (!dlNo.trim()) errors.dlNo = "please Enter DL Number";
      setErrors({ ...errors });
      setIsLoading(false);
      return;
    }
    setErrors({});
    try {
      const response = await UserServices.sentApiRequestWithBody(
        `${BASE_URL}/sb_sales_orders#create`,
        {
          organisation_name: orgName,
          universal_city_id: parseInt(cityId),
          delivery_dl_number: dlNo
        }
      );
      const sbCreateData = await response.json();
      if (response.status === 200) {
        localStorage.setItem("id", sbCreateData.draft_so.id);
        localStorage.setItem(
          "po_number",
          JSON.stringify(sbCreateData.draft_so.sb_so_number)
        );
        localStorage.setItem("hospital", JSON.stringify(["New Hospital"]));
        localStorage.setItem(
          "distributor",
          JSON.stringify(["New Distributor"])
        );
        loadUserData();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Primitives.Flex px={5} flexDirection="column" py={5}>
      <Primitives.Button textAlign="start" pb={5} onClick={() => setIndex(1)}>
        <Back fill="black" />
      </Primitives.Button>
      <Primitives.Text fontSize={7} pt={1}>
        Let us serve you better
      </Primitives.Text>
      <Primitives.Text fontWeight={400} color="#979797" pt={1} pb={5}>
        Share your information for Order Delivery and verification purposes.
      </Primitives.Text>

      <Primitives.Text pb={1} fontSize={2}>
        Organization Legal Name*
      </Primitives.Text>
      <Primitives.Flex mb={3} flexDirection="column">
        <Primitives.Input
          height={33}
          border={1}
          borderStyle="solid"
          borderColor="#BEC8D2"
          borderRadius={4}
          placeholder=""
          onChange={e => setOrgName(e.target.value)}
          error={errors.orgName}
          pl={10}
        ></Primitives.Input>
        {errors.orgName && <ErrorMessage> {errors.orgName} </ErrorMessage>}
      </Primitives.Flex>
      <Primitives.Flex mb={3} flexDirection="column">
        <Primitives.Text pb={1} fontSize={2}>
          City*
        </Primitives.Text>
        <Primitives.Flex ref={dropdownRef}>
          <Dropdown
            mb={1}
            id="city"
            name="city"
            listWidth="100%"
            search={citySearch}
            placeholder={"select city"}
            changeHandler={e => {
              setCitySearch(e.target.value);
            }}
            collapsed={cityCollapse}
            focusHandler={() => {
              setCityCollapse(true);
            }}
            options={
              (isLoaded &&
                cityOption &&
                cityOption
                  .map(({ city, id, state, state_id }) => {
                    return {
                      text: city,
                      id: id,
                      state: state,
                      stateId: state_id
                    };
                  })
                  .filter(item => item.text !== null)) || [
                {
                  text: "",
                  id: "",
                  state: "",
                  stateId: ""
                }
              ]
            }
            searchable={true}
            selectHandler={citySelectHandler}
            selectType="singleSelect"
          />
        </Primitives.Flex>
      </Primitives.Flex>
      {errors.city && <ErrorMessage> {errors.city} </ErrorMessage>}

      <Primitives.Flex flexDirection="column">
        <Primitives.Text pb={1} pt={4} fontSize={2}>
          Drug License Number*
        </Primitives.Text>
        <Primitives.Input
          height={33}
          border={1}
          borderStyle="solid"
          borderColor="#BEC8D2"
          borderRadius={4}
          placeholder="Drug Licence no"
          onChange={e => setDlNo(e.target.value)}
          error={errors.dlNo}
          pl={10}
        ></Primitives.Input>
        {errors.dlNo && <ErrorMessage mb={3}> {errors.dlNo} </ErrorMessage>}
      </Primitives.Flex>

      <Button mt={4} handleSubmit={detailsClickHandler} isLoaded={isLoading}>
        Get Started
      </Button>
    </Primitives.Flex>
  );
};

OnboardingComponent.propTypes = proptypes;
export default OnboardingComponent;
