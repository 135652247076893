const isURLInList = url => {
  const list = [
    "/hospital/orders",
    "/hospital/users",
    "/hospital/product_wise_invoices",
    "/hospital/order_fulfilment_details"
  ];
  return list.some(item => url.startsWith(item));
};
const DisabledRoute = user => {
  let pathName = window.location.pathname;

  if (!user["inventory_access"]) {
    pathName = pathName === "/hospital/product_inventory" ? "/" : pathName;
  }
  if (user.role === "hospital_warehouse") {
    pathName = isURLInList(pathName) ? pathName : "/";
  }
  return pathName;
};
export default DisabledRoute;
