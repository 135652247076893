import React, { useState } from "react";
import Primitives from "../../../../components/primitives";
import Download from "../../../../components/glyphs/elements/download";
import ListDropdown from "../../../../components/list-dropdown";
import Button from "../../../../components/button";
import { colors } from "../../../../components/utils/theme";
import { func } from "prop-types";

const propTypes = {
  selectHandler: func
};

const InvoiceDownload = ({ selectHandler }) => {
  const [downloadCollapsed, setDownloadCollapsed] = useState(false);
  return (
    <>
      <Primitives.Flex position="relative">
        <Button
          active={{
            backgroundColor: colors.text[2],
            color: colors.text[0],
            borderColor: "inherit"
          }}
          alignItems="center"
          backgroundColor="background-light"
          color={colors.text[1]}
          clickHandler={() => setDownloadCollapsed(!downloadCollapsed)}
          glyph={<Download height="18px" width="18px" />}
          hover={{
            backgroundColor: colors.text[2],
            color: colors.text[0]
          }}
          isDropdown={true}
          label="Download"
          lineHeight={3}
          px={12}
          py="11px"
        />
        {downloadCollapsed && (
          <ListDropdown
            options={[
              {
                text: "Download Invoice (PDF)"
              },
              {
                text: "Download Invoice (XLS)"
              }
            ]}
            right={0}
            selectHandler={option => selectHandler(option)}
            width="auto"
          />
        )}
      </Primitives.Flex>
    </>
  );
};
InvoiceDownload.propTypes = propTypes;
export default InvoiceDownload;
