import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { colors } from "../../components/utils/theme";
import Grn from "./grn/";
import Invoice from "./invoice/";
import OrderBand from "./cover-band/";
import Primitives from "../../components/primitives";
import PO from "./po/";
import Tabs from "./tabs/";
import useApiRequest from "../../hooks/useApiRequest";
import Loading from "../../components/loading";
import { AppContext } from "../../utils/context";
import NoResults from "../../components/no-results";
import Navigation from "../../lib/navigation";
import Filters from "../../lib/filters";

const statusObject = {
  Delivered: colors["background-dark-green"],
  Invoiced: colors["background-dark-green"],
  "Partially Invoiced": colors["background-yellow"],
  "In Progress": colors["background-error"]
};

const filteredList = (o, filters) =>
  filters.reduce((acc, curr) => {
    switch (curr) {
      case "product_name":
        acc["PRODUCT"] = o[curr];
        break;
      case "batch_number":
        acc["BATCH"] = o[curr];
        break;
      case "batch_expiry":
        acc["EXPIRY"] = o[curr]
          .replace(/-/g, "/")
          .split("/")
          .reverse()
          .join("/");
        break;
      case "rate":
        acc["RATE"] = `₹ ${o[curr]}`;
        break;
      case "mrp":
        acc["MRP"] = "₹".concat(o[curr] || "0.00");
        break;
      case "discount_rate":
        acc["DISC RATE"] = o[curr] ? `${o[curr]} %` : "0.00";
        break;
      case "discount_amount":
        acc["DISC AMT"] = "₹".concat(o[curr] || "0.00");
        break;
      case "delivered_quantity":
        acc["QTY"] = o[curr] || 0;
        break;
      case "net_amount":
        acc["NET AMT"] = "₹".concat(o[curr].toFixed(2));
        break;
      case "gst_rate":
        acc["SGST"] = o[curr] ? `${o[curr]} %` : "0.00";
        break;
      case "cgst_rate":
        acc["CGST"] = o[curr] ? `${o[curr]} %` : "0.00";
        break;
      case "total_tax_amount":
        acc["TOTAL TAX"] = "₹".concat(o[curr].toFixed(2));
        // acc["SGST"] = "5%";
        // acc["CGST"] = "1.5%";
        break;
      case "total_amount":
        acc["TOTAL AMT"] = "₹".concat(o[curr].toFixed(2));
        break;
      case "unit_of_measurement":
        acc["UOM"] = o[curr];
        break;
      case "free_quantity":
        acc["FREE QTY"] = o[curr] || 0;
        break;
      case "status":
        acc["STATUS"] = (
          <Primitives.Flex color={statusObject[o[curr]]}>
            {o[curr]}
          </Primitives.Flex>
        );
        break;
      default:
        acc[curr.toUpperCase()] = o[curr];
    }
    return acc;
  }, {});

const OrderDetail = () => {
  const [selected, updateSelected] = useState(
    parseInt(new URL(window.location.href).searchParams.get("invoice")) || 0
  );
  const orderId = useParams().orderId;
  const { BASE_URL } = useContext(AppContext);
  const [query, updateQuery] = useState("tab=all");
  const [request, updateRequest] = useState(
    `${BASE_URL}/orders/${orderId}?${query}`
  );
  const { data, isLoaded } = useApiRequest(request);
  const newRequest = () => {
    updateRequest(
      `${BASE_URL}/orders/${orderId}/?grn=${new Date().getTime()}&${query}`
    );
  };
  const invoiceTabs =
    data.response &&
    data.response.invoices &&
    data.response.invoices
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .map(invoice => ({
        grn: invoice.hospital_grn ? true : false,
        label: invoice.invoice_number,
        status: invoice.status
      }));
  const statusTabs =
    data.response &&
    data.response.po_number &&
    [{ label: data.response.po_number }].concat(invoiceTabs);
  const filterUpdate = queryObj => {
    updateQuery(queryObj.quickSearch);
    updateRequest(`${BASE_URL}/orders/${orderId}?${queryObj.quickSearch}`);
  };
  return (
    <>
      <Navigation selected={1} />
      {data && data.response && (
        <Primitives.Flex flexDirection="column">
          <OrderBand
            data={data.response}
            fileDetails={{
              file: data.download_link,
              name:
                data.download_file_name &&
                data.download_file_name.substring(0, 30)
            }}
          />
          <Primitives.Flex
            flex={1}
            borderBottom="1px solid"
            borderTop="1px solid"
            borderColor={colors["pattens-blue"]}
          >
            <Tabs
              currentTab={selected}
              invoices={data.response.invoices.length}
              statusTabs={statusTabs.length > 0 && statusTabs}
              updateSelected={updateSelected}
            />
          </Primitives.Flex>
          {selected === 0 && (
            <Primitives.Flex pl={54} py={1}>
              <Filters
                filterUpdate={filterUpdate}
                config={{ noFilter: true }}
                tagProps={{
                  backgroundColor: "background-light-blue",
                  color: colors.text[1],
                  items: [
                    {
                      label: `ALL(${data.response.items_count})`,
                      key: 40,
                      selected: true
                    },
                    {
                      label: `In Progress(${data.pending_item_count})`,
                      key: 41
                    },
                    {
                      label: `Invoiced(${data.invoiced_item_count})`,
                      key: 42
                    },
                    {
                      label: `Partially Invoiced(${data.partially_invoiced_item_count})`,
                      key: 43
                    },
                    {
                      label: `Delivered(${data.delivered_item_count})`,
                      key: 44
                    }
                  ],
                  mr: 1
                }}
                tagSearchHeading="Filter by Status"
              />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      )}
      {isLoaded ? (
        data.response ? (
          <Primitives.Flex backgroundColor="white" flexDirection="column">
            <Primitives.Flex px={5} py={3} flexDirection="column">
              {selected > 0 ? (
                data.response.invoices[selected - 1].hospital_grn ? (
                  <Grn
                    data={data.response.invoices[selected - 1]}
                    newRequest={newRequest}
                    response={data}
                    tableData={
                      data.response.invoices[selected - 1].hospital_grn
                        .hospital_grn_items
                    }
                    poData={data.response.sales_order_items}
                  />
                ) : (
                  <Invoice
                    data={data.response.invoices[selected - 1]}
                    filteredList={filteredList}
                    tableData={
                      data.response.invoices[selected - 1].sales_invoice_items
                    }
                    newRequest={newRequest}
                  />
                )
              ) : (
                <PO tableData={data.response.sales_order_items} />
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <NoResults />
        )
      ) : (
        <Loading />
      )}
    </>
  );
};
export default OrderDetail;
