import React from "react";
import Panel from "../../../components/panel";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import LineChart from "../../../components/charts/line";
import useApiRequest from "../../../hooks/useApiRequest";
import { objectToArr, objectToKey } from "../index";
import { string } from "prop-types";
import Loading from "../../../components/loading";
import ConvertMinutes from "../../../components/utils/convert-minutes-to-days-hours";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import Header from "../header";
import { indianNumberFormat } from "../../../components/utils";

const propTypes = {
  path: string,
  query: string
};

const Savings = ({ path, query }) => {
  const { data, isLoaded } = useApiRequest(`${path}/savings?${query}`);
  const {
    avg_discount_amount,
    avg_discount_amount_graph,
    avg_discount_percentage,
    avg_disccount_percent_graph,
    time_saved_by_grn,
    time_saved_graph
  } = data;
  const savingsArray = [
    {
      key: "AVERAGE DISCOUNT AMOUNT",
      value: `₹ ${avg_discount_amount &&
        indianNumberFormat(roundOffTwoDecimal(avg_discount_amount))}`,
      backgroundColor: "rgba(40, 81, 200, 0.15)",
      borderColor: colors.accent[2],
      chartData: avg_discount_amount_graph
    },
    {
      key: "AVERAGE DISCOUNT %",
      value: avg_discount_percentage && avg_discount_percentage,
      backgroundColor: "rgba(202, 209, 239, 0.165)",
      borderColor: colors.accent[5],
      chartData: avg_disccount_percent_graph
    },
    {
      key: "POTENTIAL TIME SAVED BY FAST GRN",
      value: time_saved_by_grn && ConvertMinutes(time_saved_by_grn),
      backgroundColor: "rgba(40, 81, 200, 0.15)",
      borderColor: colors.accent[2],
      chartData: time_saved_graph
    }
  ];

  return (
    <Primitives.Box>
      <Panel
        heading={
          <Header
            header="Savings"
            dateRangeStart={query.split("&")[1].split("=")[1]}
            dateRangeEnd={query.split("&")[2].split("=")[1]}
          />
        }
        ctaDetails={{
          label: "Show more details",
          link: "/hospital/analytics/savings_report"
        }}
        data={
          <Primitives.Flex px={5} py={3} width={1}>
            {isLoaded ? (
              <>
                {savingsArray.map((item, index) => (
                  <Primitives.Flex flex={1} justifyContent="center" key={index}>
                    <Primitives.Flex width={229} flexDirection="column">
                      <Primitives.Flex flexDirection="column" mb={3}>
                        <Primitives.Flex
                          key={index}
                          color={colors.text[1]}
                          fontSize={1}
                          fontWeight={1}
                          mb="4px"
                        >
                          <Primitives.Text>{item.key}</Primitives.Text>
                        </Primitives.Flex>

                        <Primitives.Flex
                          color={colors.text[0]}
                          fontSize={5}
                          fontWeight={3}
                        >
                          <Primitives.Text>
                            {item.key === "AVERAGE DISCOUNT %"
                              ? item.value + "%"
                              : item.value}
                          </Primitives.Text>
                        </Primitives.Flex>
                      </Primitives.Flex>

                      <LineChart
                        backgroundColor={item.backgroundColor}
                        borderColor={item.borderColor}
                        chartData={objectToArr(item.chartData)}
                        height={320}
                        labels={objectToKey(item.chartData)}
                        legend={false}
                        pointRadius={4}
                        width={660}
                        xAxes={{ labels: false }}
                      />
                    </Primitives.Flex>
                  </Primitives.Flex>
                ))}
              </>
            ) : (
              <Primitives.Flex m="auto" alignItems="center" minHeight={182}>
                <Loading onlySpinner={true} />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        }
      />
    </Primitives.Box>
  );
};
Savings.propTypes = propTypes;
export default Savings;
