import React from "react";
import Primitives from "../../../components/primitives";
import { string } from "prop-types";

const propTypes = {
  text: string
};

const SectionHeader = ({ text }) => (
  <Primitives.Flex flex="1" my={2}>
    <Primitives.Text color="#768698" fontSize={2} fontWeight={2} mr={2}>
      {text}
    </Primitives.Text>
    <Primitives.Flex
      borderTop="1px solid #ccc"
      flex="1"
      height="1px"
      m="auto"
    ></Primitives.Flex>
  </Primitives.Flex>
);
SectionHeader.propTypes = propTypes;
export default SectionHeader;
