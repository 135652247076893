import React from "react";
import { bool, func, number } from "prop-types";

import { colors } from "../../components/utils/theme";
import Download from "../../components/glyphs/elements/download";
import Button from "../../components/button";

const propTypes = {
  clickHandler: func,
  isGlyph: bool,
  py: number
};

const defaultProps = {
  isGlyph: true,
  py: 10
};
const DownloadBtn = ({ clickHandler, isGlyph, py }) => (
  <Button
    active={{
      backgroundColor: isGlyph ? colors.text[2] : "inherit",
      color: isGlyph ? colors.text[0] : "inherit",
      borderColor: "inherit"
    }}
    border={isGlyph ? "1px solid #C2C4D2" : "inherit"}
    backgroundColor="background-light"
    border-radius="4px"
    clickHandler={clickHandler}
    color={isGlyph ? colors.text[1] : "inherit"}
    glyph={isGlyph && <Download height={20} width={20} />}
    hover={{
      backgroundColor: colors.text[2],
      color: colors.text[0]
    }}
    label="Download"
    lineHeight={3}
    px={2}
    py={py}
  />
);
DownloadBtn.propTypes = propTypes;
DownloadBtn.defaultProps = defaultProps;
export default DownloadBtn;
