import React, { useContext, useEffect, useState } from "react";
import { string } from "prop-types";
import Primitives from "../../../components/primitives";
import SectionHeader from "../section-header";
import { AppContext } from "../../../utils/context";
import BarChart from "../../../components/charts/bar";
import { colors } from "../../../components/utils/theme";
import useApiRequest from "../../../hooks/useApiRequest";
import Loading from "../../../components/loading";
import Cta from "../../../components/cta";
import ForwardAngular from "../../../components/glyphs/elements/forwardAngular";

const propTypes = {
  header: string,
  dateRangeStart: string,
  dateRangeEnd: string,
  query: string
};

const statusStyle = {
  background: "white",
  borderRadius: "10px",
  boxShadow: "rgb(0 0 0 / 10%) 0px 0px 4px",
  flex: 1
};

const dataArr = (data, category, labels) => {
  let arr = [];
  labels.map((_, index) => arr.push(data[index][category]));
  return arr;
};

const barChartCallback = data => ({
  callback: {
    title: tooltipItem => {
      return (
        "    Total Margin   " +
        data[tooltipItem[0]["index"]]["average_margin"].toFixed(2) +
        "%"
      );
    },
    label: () => {},
    afterLabel: tooltipItem => {
      const labels = [
        "Pharma - ",
        "Surgicals - ",
        "Consumables - ",
        "Implants - "
      ];

      const poStatus = labels
        .map(
          item =>
            item +
            data[tooltipItem["index"]][item.split(" ")[0].toLowerCase()] +
            "%"
        )
        .filter(Boolean);

      return poStatus[tooltipItem.datasetIndex];
    }
  },
  yAxes: {
    borderDash: [4, 6],
    gridLines: true,
    fontColor: "#525983",
    labels: true,
    padding: 20
  },
  xAxes: {
    barPercentage: 0.5,
    fontColor: "#525983",
    gridLines: false,
    labels: true
  },
  tooltip: {
    enabled: true,
    backgroundColor: "white",
    borderWidth: 1,
    bodyFontColor: colors.text[1],
    titleFontColor: colors.text[0],
    titleMarginBottom: 10,
    mode: "nearest",
    position: "average"
  },
  legend: {
    display: true,
    padding: 0,
    position: "bottom"
  },
  layout: {
    padding: {
      bottom: 10,
      left: 30,
      right: 30,
      top: 40
    }
  }
});

const datasets = (data, labels) => [
  {
    label: "Pharma",
    backgroundColor: "#47A1B1",
    borderColor: "#47A1B1",
    borderWidth: 1,
    hoverBackgroundColor: "#47A1B1",
    hoverBorderColor: "#47A1B1",
    data: dataArr(data, "pharma", labels)
  },
  {
    label: "Surgicals",
    backgroundColor: "#A8E4EF",
    borderColor: "#A8E4EF",
    borderWidth: 1,
    hoverBackgroundColor: "#A8E4EF",
    hoverBorderColor: "#A8E4EF",
    data: dataArr(data, "surgicals", labels)
  },
  {
    label: "Consumables",
    backgroundColor: "#FFD670",
    borderColor: "#FFD670",
    borderWidth: 1,
    hoverBackgroundColor: "#FFD670",
    hoverBorderColor: "#FFD670",
    data: dataArr(data, "consumables", labels)
  },
  {
    label: "Implants",
    backgroundColor: "#AAC2FF",
    borderColor: "#AAC2FF",
    borderWidth: 1,
    hoverBackgroundColor: "#AAC2FF",
    hoverBorderColor: "#AAC2FF",
    data: dataArr(data, "implants", labels)
  }
];

const MarginTrend = ({ query }) => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/dashboards/top_category_margin_trend?`
  );
  const { data, isLoaded } = useApiRequest(request);

  const labels = data && data.map(item => item["month_name"].split("-")[0]);

  useEffect(() => {
    let mappedQuery =
      query.split("&hospital_entity_id=")[1] &&
      query
        .split("&hospital_entity_id=")[1]
        .split(",")
        .map(item => "&hospital_ids[]=" + item);
    updateRequest(
      `${BASE_URL}/dashboards/top_category_margin_trend?${mappedQuery}`
    );
  }, [query]);

  return (
    <Primitives.Flex flex={1} flexDirection="column">
      <SectionHeader text="Analytics" />
      <Primitives.Flex flex={1} flexDirection="column" mr={1}>
        <Primitives.Flex
          flexDirection="column"
          mb={1}
          {...statusStyle}
          maxHeight="65vh"
        >
          <Primitives.Text
            borderBottom="1px solid #F0F0F0"
            color={colors.text[0]}
            fontWeight={3}
            pl={4}
            py={3}
          >
            Margin Trend across top categories
          </Primitives.Text>
          {isLoaded ? (
            <>
              <BarChart
                height={200}
                labels={labels}
                onClick={(e, items) => {
                  let chart = items[0]?._chart;
                  let element = chart?.getElementAtEvent(e)[0];
                  let dataset = chart?.data?.datasets[element?._datasetIndex];
                  let month = chart?.data?.labels[element?._index];
                  let label = dataset?.label;
                  if (month && label) {
                    window.location.href = `/hospital/purchase_margin_report/${month}/${label}`;
                  }
                }}
                tickColor="rgba(0,0,0,0)"
                values={data && data.length > 0 && datasets(data, labels)}
                {...barChartCallback(data)}
              />
              <Primitives.Flex flex={1} m="auto" mb={3}>
                <Cta
                  color={colors.primary[3]}
                  fontSize={2}
                  fontWeight={2}
                  label={
                    <Primitives.Flex alignItems="center">
                      <Primitives.Text mr={1}>
                        View more details
                      </Primitives.Text>
                      <ForwardAngular fill={colors.primary[3]} />
                    </Primitives.Flex>
                  }
                  type="hyperlink"
                  link="/hospital/purchase_margin_report"
                  hover={{ color: colors.primary[3], textDecorationLine: true }}
                  textdecorationline={false}
                />
              </Primitives.Flex>
            </>
          ) : (
            <Primitives.Flex alignItems="center" height="40vh" margin="auto">
              <Loading onlySpinner={true} />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
MarginTrend.propTypes = propTypes;
export default MarginTrend;
