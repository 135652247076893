import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import BarChart from "../../../../components/charts/bar";
import { colors } from "../../../../components/utils/theme";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import Loading from "../../../../components/loading";
import DownloadButton from "../download-button";
import { string } from "prop-types";

const propTypes = {
  quickSearch: string,
  hospital: string
};

const AverageMargin = ({ quickSearch, hospital }) => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/overview/average_margin?`
  );
  const { isLoaded, data } = useApiRequest(request);

  useEffect(() => {
    quickSearch || hospital
      ? updateRequest(
          `${BASE_URL}/overview/average_margin?${quickSearch}&hospital_entity_id=${hospital}`
        )
      : updateRequest(`${BASE_URL}/overview/average_margin?`);
  }, [quickSearch, hospital]);

  return (
    <Primitives.Flex flexDirection="column" pl={4}>
      {isLoaded ? (
        <Primitives.Flex>
          {data && data["values"] && data["values"].length > 0 && (
            <Primitives.Flex
              flex={1}
              flexDirection="column"
              height="50vh"
              py={3}
              width="45vw"
            >
              <Primitives.Flex justifyContent="space-between" width="100%">
                <Primitives.Text fontSize={4} fontWeight={2}>
                  Average Margin
                </Primitives.Text>
                <DownloadButton
                  downloadCtaProps={request
                    .split("?")
                    .join(".xlsx?is_download=true&")}
                />
              </Primitives.Flex>

              <BarChart
                labels={data && data["dates"]}
                legend={{
                  display: true
                }}
                max={100}
                values={[
                  {
                    backgroundColor: colors["highlight-blue"],
                    data: data && data["values"],
                    label: "Margin %"
                  }
                ]}
              />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      ) : (
        <Primitives.Flex
          height="50vh"
          justifyContent="center"
          mt="25%"
          width="47vw"
        >
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

AverageMargin.propTypes = propTypes;

export default AverageMargin;
