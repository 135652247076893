import React from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";

const Footer = () => (
  <>
    <Primitives.Text mt={2}>
      Don&apos;t have a SmartBuy account?
      <Primitives.Text
        color={colors.primary[3]}
        cursor="pointer"
        fontFamily="lato"
        ml={1}
        onClick={() =>
          (window.location.href =
            "https://www.aknamed.com/smartbuy?openModal=true")
        }
      >
        Request access here
      </Primitives.Text>
    </Primitives.Text>
  </>
);

export default Footer;
