import MonthRange from "../date-range";
const date = new Date();

const Months = MonthRange(
  date.getFullYear() - 1 + "-" + date.getMonth(),
  date.getFullYear() + "-" + date.getMonth()
).map((item, index) => ({
  text: item,
  id: index
}));
export default Months;
