import React from "react";
import { Line } from "react-chartjs-2";
import { array, bool, number, object, oneOfType, string } from "prop-types";

const propTypes = {
  bodyFontSize: number,
  borderColor: string,
  borderWidth: number,
  backgroundColor: oneOfType([array, string]),
  callback: object,
  chartData: array.isRequired,
  height: number,
  label: string,
  labels: array,
  legend: bool,
  lineTension: number,
  max: number,
  point: object,
  scaleLabelPadding: number,
  tooltip: object,
  width: number,
  xAxes: object,
  yAxes: object
};

const defaultProps = {
  callback: {
    labelColor: () => ({
      backgroundColor: "inherit"
    }),
    labelTextColor: () => {}
  },
  legend: false,
  lineTension: 0.4,
  point: { radius: 5 },
  tooltip: {
    enabled: true,
    backgroundColor: "#000000bd",
    borderWidth: 0,
    bodyFontColor: "white",
    titleFontColor: "white",
    titleMarginBottom: 10
  },
  xAxes: { labels: true, padding: 0 },
  yAxes: { labels: false, padding: 0, labelString: "", scaleLabelPadding: 0 }
};

const LineChart = ({
  borderColor,
  borderWidth,
  backgroundColor,
  callback,
  chartData,
  height,
  label,
  labels,
  legend,
  lineTension,
  max,
  point,
  tooltip,
  width,
  xAxes,
  yAxes
}) => {
  const canvas = document.getElementById("chart");
  const ctx = canvas && canvas.getContext("2d");
  const gradient = ctx && ctx.createLinearGradient(0, 100, 100, 400);
  typeof backgroundColor === "object" &&
    gradient &&
    gradient.addColorStop(0, backgroundColor[0]);
  typeof backgroundColor === "object" &&
    gradient &&
    gradient.addColorStop(1, backgroundColor[1]);
  typeof backgroundColor === "string" &&
    gradient &&
    gradient.addColorStop(1, backgroundColor);

  const data = {
    labels:
      labels ||
      Array.from({ length: chartData.length }, () => chartData.length),
    datasets: [
      {
        data: chartData,
        fill: true,
        label: label,
        labels: labels,
        backgroundColor: gradient,
        borderColor: borderColor,
        borderWidth: borderWidth,
        pointHoverRadius: point.radius,
        pointRadius: point.radius,
        pointStyle: point.style,
        pointBackgroundColor: point.backgroundColor,
        lineTension: lineTension
      }
    ],
    toolTipdata: tooltip.data
  };

  const options = {
    legend: {
      display: legend,
      onClick: e => e.stopPropagation()
    },
    layout: {
      padding: {
        top: 20
      }
    },
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      backgroundColor: tooltip.backgroundColor,
      bodyFontColor: tooltip.bodyFontColor,
      enabled: tooltip.enabled,
      callbacks: callback,
      bodyFontSize: tooltip.bodyFontSize,
      titleFontColor: tooltip.titleFontColor,
      titleFontSize: tooltip.titleFontSize,
      titleMarginBottom: tooltip.titleMarginBottom,
      borderColor: "#EAEBF0",
      borderWidth: tooltip.borderWidth
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: xAxes.gridLines
          },
          ticks: {
            display: xAxes.labels,
            fontColor: xAxes.fontColor,
            fontSize: xAxes.fontSize,
            padding: xAxes.padding
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: yAxes.labels,
            fontColor: yAxes.fontColor,
            fontSize: yAxes.fontSize,
            padding: yAxes.padding,
            max: max
          },
          gridLines: {
            drawBorder: false,
            borderDash: [4, 6],
            color: "rgba(0,0,0,0.1)"
          },
          scaleLabel: {
            display: yAxes.labels,
            fontSize: yAxes.scaleLabelFontSize,
            padding: yAxes.scaleLabelPadding,
            labelString: yAxes.labelString
          }
        }
      ]
    }
  };
  return (
    <>
      <Line
        id="chart"
        data={data}
        height={height}
        options={options}
        width={width}
      />
    </>
  );
};

LineChart.propTypes = propTypes;
LineChart.defaultProps = defaultProps;
export default LineChart;
