import React, { useState, useEffect, useContext, useRef } from "react";
import Dropdown from "../../components/dropdown";
import Table from "../../components/table-two/TableComponent";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import Pagination from "../../components/pagination";
import AddToOrder from "../../components/add-to-order";
import NoResults from "../../components/no-results";
import Loading from "../../components/loading";
import { AppContext } from "../../utils/context";
import useApiRequest from "../../hooks/useApiRequest";
import Navigation from "../../lib/navigation";
import Checkbox from "../../components/checkbox";
import { useHistory } from "react-router";
import checkEmptyNull from "../../components/utils/check-empty-null";
import paginationHandler from "../../components/utils/pagination-handler";
import { indianNumberFormat } from "../../components/utils";
import styled from "styled-components";
import Button from "../../components/button";
import UploadOrder from "../../components/glyphs/elements/upload-order";
import UploadExcelModal from "./upload-excel-modal";
import DropdownClick from "../../lib/dropdown-click";

const column = newUser => [
  {
    heading: "PRODUCT NAME",
    type: "string",
    width: 3
  },
  {
    heading: "MANUFACTURER NAME",
    type: "string",
    width: 3
  },
  {
    heading: "UOM",
    type: "string",
    width: 0.7
  },
  {
    heading: "PACK SIZE",
    type: "number",
    align: "flex-end",
    width: 0.8
  },
  {
    heading: "MRP",
    type: "string",
    align: "flex-end",
    width: 1
  },
  ...(!newUser
    ? [
        {
          heading: "MARGIN",
          type: "number",
          align: "flex-end",
          width: 0.7
        }
      ]
    : []),
  {
    heading: "RATE",
    type: "string",
    align: "flex-end",
    width: 1
  },
  {
    heading: " ",
    type: "string",
    align: "flex-end",
    width: 0.5
  },
  {
    heading: " ",
    type: "string",
    align: "flex-end",
    width: 1
  }
];

const dataFn = item => <Primitives.Text mt={2}>{item}</Primitives.Text>;

const modifyData = (
  data,
  summary,
  changeHandler,
  clickHandler,
  incrementHandler,
  decrementHandler,
  newUser
) =>
  data &&
  data.map(item => ({
    "PRODUCT NAME": (
      <Primitives.Flex mt={2}>
        {item["scheme_purchase_qty"] > 0 && (
          <Primitives.Flex
            backgroundColor={colors.success[1]}
            color="white"
            position="absolute"
            py="2px"
            px={1}
            top={0}
          >
            <Primitives.Text>
              {item["scheme_purchase_qty"]}+{item["scheme_free_qty"]} Scheme
            </Primitives.Text>
          </Primitives.Flex>
        )}
        {item["product_name"]}
      </Primitives.Flex>
    ),
    "MANUFACTURER NAME": dataFn(item["company_name"]),
    UOM: dataFn(item["packaging"]),
    "PACK SIZE": (
      <Primitives.Text ml={2} mt={2}>
        {item["pack_size"]}
      </Primitives.Text>
    ),
    MRP: (
      <Primitives.Text fontWeight={2} mt={2}>
        {item.mrp ? dataFn(`₹ ${item["mrp"]}`) : "--"}
      </Primitives.Text>
    ),
    ...(!newUser && { MARGIN: dataFn(item["margin_percentage"]) }),

    RATE: (
      <Primitives.Text fontWeight={2} mt={2}>
        {item["discount_percentage"] && Number(item["rate"]) > 0
          ? `₹ ${indianNumberFormat(
              (
                item["rate"] -
                (item["discount_percentage"] / 100) * item["rate"]
              ).toFixed(2)
            )}`
          : item["rate"]
          ? `₹ ${checkEmptyNull(item["rate"], "string")}`
          : "--"}
      </Primitives.Text>
    ),
    " ": (
      <Primitives.Flex>
        {item["discount_percentage"] ? (
          <Primitives.Flex flexDirection="column">
            <Primitives.Text
              color={colors.text[1]}
              fontWeight={2}
              textDecoration="line-through"
            >{`₹${item["rate"]}`}</Primitives.Text>
            <Primitives.Text color={colors.primary[5]}>
              {item["discount_percentage"]}% off
            </Primitives.Text>
          </Primitives.Flex>
        ) : (
          ""
        )}
      </Primitives.Flex>
    ),
    "": (
      <Primitives.Flex flex={1} alignItems="flex-start">
        <AddToOrder
          active={summary.some(object => object.id === item.id) ? true : false}
          disabled={Number(item.rate) === 0}
          changeHandler={e => changeHandler(item, e)}
          clickHandler={() => clickHandler(item)}
          decrementHandler={() => decrementHandler(item)}
          incrementHandler={() => incrementHandler(item)}
          min={0}
          placeholder="Add to Order"
          value={
            summary.find(object => object.id === item.id)
              ? summary.find(object => object.id === item.id)["quantity"]
              : 0
          }
        />
      </Primitives.Flex>
    )
  }));
const StyledButton = styled(Primitives.Button)`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: #e2f2ff;
  ${props => props.active === "true" && "border: 2px solid #0072c6"};

  &:hover,
  &:active,
  &:focus {
    background: #e2f2ff;
    border: ${props => (props.active === "true" ? "2px solid #0072c6" : "")};
    outline: none;
  }

  img {
    width: 80%;
    border-radius: 50%;
  }
`;

const CreateNewPurchaseOrder = () => {
  const { BASE_URL, defaultHospital, hospitals, tenants } = useContext(
    AppContext
  );
  const [summary, setSummary] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [hospitalCollapse, setHospitalCollapse] = useState(false);
  const [distributorCollapse, setDistributorCollapse] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState([]);
  const localData = localStorage.getItem("summary");
  const soi = localStorage.getItem("sales_order_id");
  const localHospital = localStorage.getItem("hospital");
  const localDistributor = localStorage.getItem("distributor");
  const [distributorSearch, setDistributorSearch] = useState("");
  const [hospitalSearch, setHospitalSearch] = useState("");
  const [search, setSearch] = useState("");
  const [newUser] = useState(localStorage.getItem("newUser") === "true");
  const [request, updateRequest] = useState(
    newUser
      ? `${BASE_URL}/sb_sales_orders?q[name_cont]=${search}#index`
      : `${BASE_URL}/purchase_orders?tenant_id=${
          localStorage.getItem("distributor")
            ? JSON.parse(localStorage.getItem("distributor"))[1]
            : 1
        }`
  );
  useEffect(() => {
    setPageNumber(1);
  }, [search]);

  const { data, isLoaded } = useApiRequest(request);
  const {
    data: universalCategories,
    isLoaded: isCategoriesLoaded
  } = useApiRequest(`${BASE_URL}/universal_categories`);
  const [universalCategoryName, setUniversalCategoryName] = useState("");
  const [showUploadModal, toggleUploadModal] = useState(false);

  const [scheme, setScheme] = useState(false);
  const [hospitalId, setHospitalId] = useState(null);
  const [distributorId, setDistributorId] = useState(
    JSON.parse(localStorage.getItem("distributor"))
  );
  const history = useHistory();
  useEffect(() => {
    if (newUser) {
      updateRequest(
        `${BASE_URL}/sb_sales_orders?q[name_cont]=${encodeURIComponent(
          search
        )}&q[universal_category_category_type_cont]=${universalCategoryName}&page=${pageNumber}#index`
      );
    } else {
      const distributorId =
        JSON.parse(localStorage.getItem("distributor"))[1] || 1;
      updateRequest(
        `${BASE_URL}/purchase_orders?q[name_cont]=${encodeURIComponent(
          search
        )}&q[master_category_category_type_cont]=${universalCategoryName}&tenant_id=${distributorId}&product_with_scheme=${scheme}&page=${pageNumber}`
      );
    }
  }, [
    search,
    newUser,
    universalCategoryName,
    pageNumber,
    scheme,
    hospitalId,
    distributorId
  ]);
  useEffect(() => {
    soi && localStorage.removeItem("sales_order_id");
  }, []);

  useEffect(() => {
    if (localHospital === null) {
      defaultHospital &&
        localStorage.setItem("hospital", JSON.stringify(defaultHospital));
    }
    defaultHospital && setHospitalId(defaultHospital[1]);
  }, [defaultHospital]);

  useEffect(() => {
    if (selectedHospital.length !== 0) {
      localStorage.setItem("hospital", JSON.stringify(selectedHospital));
      setHospitalId(selectedHospital[1]);
    }
  }, [selectedHospital]);

  useEffect(() => {
    if (localDistributor === null) {
      tenants &&
        tenants[0] &&
        localStorage.setItem(
          "distributor",
          JSON.stringify([tenants[0].name, tenants[0].id])
        );
    }
  }, [tenants]);

  useEffect(() => {
    if (selectedDistributor.length !== 0) {
      localStorage.setItem("distributor", JSON.stringify(selectedDistributor));
      setDistributorId(selectedDistributor[1]);
      const keysToRemove = ["hospital", "summary", "po_number"];
      keysToRemove.map(item => localStorage.removeItem(item));
      history.go(0);
    }
  }, [selectedDistributor]);

  useEffect(() => {
    localData && setSummary(JSON.parse(localData));
    let total_amount = 0;
    localData &&
      JSON.parse(localData).map(item => {
        total_amount += item["rate"] * item["quantity"];
      });
    setTotalAmount(total_amount);
  }, [localData]);

  useEffect(() => {
    data &&
      data["purchase_order_number"] &&
      localStorage.setItem(
        "po_number",
        JSON.stringify(data["purchase_order_number"])
      );
  }, [data && data["purchase_order_number"]]);

  useEffect(() => {
    if (localHospital === null) {
      defaultHospital &&
        localStorage.setItem("hospital", JSON.stringify(defaultHospital));
    }
    defaultHospital && setHospitalId(defaultHospital[1]);
  }, [defaultHospital]);

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setPageNumber(page);
  };

  const changeHandler = (obj, e) => {
    let updatedValue;
    if (!/^[1-9][0-9]*$/.test(e.target.value) && e.target.value !== "") {
      updatedValue = 1;
      e.preventDefault();
    } else {
      updatedValue = parseInt(e.target.value);
    }
    let updatedTotal =
      totalAmount -
      summary.find(object => object.id === obj.id).rate *
        summary.find(object => object.id === obj.id)["quantity"] +
      summary.find(object => object.id === obj.id).rate * updatedValue;
    const newSummary = summary.map(object =>
      object.id === obj.id ? { ...object, quantity: updatedValue } : object
    );
    setSummary(newSummary.filter(item => item.quantity !== null));
    localStorage.setItem("summary", JSON.stringify(newSummary));
    let newTotalQuantity =
      totalQuantity -
      summary.find(object => object.id === obj.id)["quantity"] +
      updatedValue;
    updatedTotal && setTotalAmount(updatedTotal);
    newTotalQuantity && setTotalQuantity(newTotalQuantity);
  };

  const clickHandler = obj => {
    obj["quantity"] = 1;
    const newSummary = [obj, ...summary];
    let newTotalAmount = totalAmount + obj.quantity * obj.rate;
    setTotalAmount(newTotalAmount);
    let newTotalQuantity = totalQuantity + 1;
    setTotalQuantity(newTotalQuantity);
    localStorage.setItem("summary", JSON.stringify(newSummary));
    setSummary(newSummary);
  };

  const decrementHandler = obj => {
    if (summary.find(object => object.id === obj.id)["quantity"] <= 1) {
      const newSummary = [...summary];
      newSummary.splice(
        summary.findIndex(
          object =>
            object.id === summary.find(object => object.id === obj.id).id
        ),
        1
      );
      let newTotalQuantity = totalQuantity - 1;
      let newTotalAmount =
        totalAmount -
        summary.find(object => object.id === obj.id).quantity *
          summary.find(object => object.id === obj.id).rate;
      setTotalAmount(newTotalAmount);
      setTotalQuantity(newTotalQuantity);
      localStorage.setItem("summary", JSON.stringify(newSummary));
      setSummary(newSummary);
    } else {
      const newSummary = summary.map(object =>
        object.id === obj.id
          ? {
              ...object,
              quantity: !isNaN(
                summary.find(object => object.id === obj.id)["quantity"]
              )
                ? summary.find(object => object.id === obj.id)["quantity"] - 1
                : (summary.find(object => object.id === obj.id)["quantity"] = 1)
            }
          : object
      );
      setSummary(newSummary);
      localStorage.setItem("summary", JSON.stringify(newSummary));
      const newAmount =
        totalAmount - summary.find(object => object.id === obj.id).rate;
      setTotalAmount(newAmount);
      const newQuantity = totalQuantity - 1;
      setTotalQuantity(newQuantity);
    }
  };

  const hospitalSelectHandler = option => {
    if (option.id !== selectedHospital[1]) {
      !selectedHospital.includes(option.text)
        ? setSelectedHospital([option.text, option.id])
        : setSelectedHospital(
            selectedHospital.filter(data => data !== option.text),
            option.id
          );
    }
    setHospitalCollapse(false);
  };

  const distributorSelectHandler = option => {
    if (option.id !== selectedDistributor[1]) {
      !selectedDistributor.includes(option.text)
        ? setSelectedDistributor([option.text, option.id])
        : setSelectedDistributor(
            selectedDistributor.filter(data => data !== option.text),
            option.id
          );
    }
    setPageNumber(1);
    setDistributorCollapse(false);
  };

  const hospitalRef = useRef();
  const distributorRef = useRef();
  const handleClick = e => {
    if (localStorage.getItem("newUser") === "true") return;
    if (!DropdownClick(hospitalRef, e)) {
      setHospitalCollapse(false);
    }
    if (!DropdownClick(distributorRef, e)) {
      setDistributorCollapse(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const incrementHandler = obj => {
    const newSummary = summary.map(object =>
      object.id === obj.id
        ? {
            ...object,
            quantity: !isNaN(
              summary.find(object => object.id === obj.id)["quantity"]
            )
              ? summary.find(object => object.id === obj.id)["quantity"] + 1
              : (summary.find(object => object.id === obj.id)["quantity"] = 1)
          }
        : object
    );
    const newAmount =
      totalAmount + summary.find(object => object.id === obj.id).rate;
    setTotalAmount(newAmount);
    const newQuantity = totalQuantity + 1;
    setTotalQuantity(newQuantity);
    setSummary(newSummary);
    localStorage.setItem("summary", JSON.stringify(newSummary));
  };

  const categories = [
    { path: "pharma.png", text: "Pharma" },
    { path: "Consumables.png", text: "Consumables" },
    { path: "vaccines.png", text: "Vaccines" },
    { path: "Surgicals.png", text: "Surgicals" },
    { path: "devices.png", text: "Devices" },
    { path: "Equipments.png", text: "Equipment" },
    { path: "implants.png", text: "Implants" }
  ];
  const [activeCategories, setActiveCategories] = useState(
    Array(categories.length).fill(false)
  );

  const handleCategories = (categoryName, index) => {
    if (isCategoriesLoaded === false) return;
    const selectedCatName = universalCategories.categories.find(
      c => c.category_name === categoryName.toUpperCase()
    ).category_name;
    setPageNumber(1);
    if (selectedCatName !== universalCategoryName) {
      setUniversalCategoryName(selectedCatName);
      const newActiveCategories = Array(categories.length).fill(false);
      newActiveCategories[index] = !newActiveCategories[index];
      setActiveCategories(newActiveCategories);
    } else {
      setUniversalCategoryName("");
      const newActiveCategories = [...activeCategories];
      newActiveCategories[index] = !newActiveCategories[index];
      setActiveCategories(newActiveCategories);
    }
  };

  return (
    <>
      <Navigation
        selected={1}
        subNavIndex={[1, 1]}
        orderPage={true}
        setOrderSearch={setSearch}
      />
      <Primitives.Flex
        py={2}
        px={5}
        flex={1}
        fontWeight={2}
        fontSize={2}
        flexDirection="row"
        boxShadow="0px 6px 12px rgba(0, 0, 0, 0.08)"
      >
        {categories.map((category, index) => (
          <Primitives.Flex
            key={index}
            flex={1}
            flexDirection="column"
            alignItems="center"
          >
            <StyledButton
              onClick={() => handleCategories(category.text, index)}
              active={activeCategories[index].toString()}
            >
              <img
                src={require(`../../assets/${category.path}`)}
                alt={category.text}
                style={{
                  width: "80%",
                  borderRadius: "50%"
                }}
              ></img>
            </StyledButton>
            <Primitives.Text pl={2} pt={2}>
              {category.text}
            </Primitives.Text>
          </Primitives.Flex>
        ))}
      </Primitives.Flex>
      <Primitives.Flex flexDirection="column" px={4} py={4} overflow="scroll">
        {!newUser && (
          <Primitives.Flex py={2} flex={1}>
            <Primitives.Flex flex={0.5} justifyContent="flex">
              <Primitives.Flex
                pr={16}
                flex={1}
                flexDirection="column"
                ref={distributorRef}
              >
                <Dropdown
                  className="dropdown-filter"
                  search={distributorSearch}
                  defaultValue={
                    JSON.parse(localDistributor) &&
                    JSON.parse(localDistributor).length
                      ? JSON.parse(localDistributor)[0]
                      : "Filter by Distributor"
                  }
                  changeHandler={e => setDistributorSearch(e.target.value)}
                  collapsed={distributorCollapse}
                  focusHandler={() => {
                    setDistributorCollapse(true);
                  }}
                  id="distributor"
                  listMaxHeight={195}
                  listWidth={320}
                  name="distributor"
                  options={
                    tenants &&
                    tenants.map(tenant => {
                      return {
                        text: tenant.name,
                        id: tenant.id
                      };
                    })
                  }
                  searchable={true}
                  selectHandler={distributorSelectHandler}
                  selectType="singleSelect"
                />
              </Primitives.Flex>
              <Primitives.Flex
                pr={16}
                flex={1}
                flexDirection="column"
                ref={hospitalRef}
              >
                <Dropdown
                  className="dropdown-filter"
                  collapsed={hospitalCollapse}
                  search={hospitalSearch}
                  changeHandler={e => setHospitalSearch(e.target.value)}
                  focusHandler={() => {
                    setHospitalCollapse(true);
                  }}
                  defaultValue={
                    JSON.parse(localHospital) &&
                    JSON.parse(localHospital).length
                      ? JSON.parse(localHospital)[0]
                      : "Filter by Branch"
                  }
                  id="hospitals"
                  listMaxHeight={195}
                  listWidth={320}
                  name="hospitals"
                  options={
                    hospitals &&
                    hospitals.response &&
                    hospitals.response.map(hospital => {
                      return {
                        text: hospital.display_name,
                        id: hospital.id
                      };
                    })
                  }
                  searchable={true}
                  selectHandler={hospitalSelectHandler}
                  selectType="singleSelect"
                />
              </Primitives.Flex>
              <Primitives.Flex alignItems="center" mr={16}>
                <Checkbox
                  checked={scheme}
                  clickHandler={() => setScheme(!scheme)}
                />
                <Primitives.Text fontSize={1} ml={1}>
                  Show only products <br></br> with schemes
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex pr={16} flex={0.5} justifyContent="flex-end">
              <Button
                active={{
                  backgroundColor: "white",
                  borderColor: colors.primary[3],
                  color: colors.primary[3]
                }}
                alignItems="center"
                backgroundColor="background-light"
                clickHandler={() => {
                  toggleUploadModal(true);
                }}
                glyph={<UploadOrder height={20} width={20} />}
                hover={{ backgroundColor: colors["athens-gray"] }}
                label={
                  <Primitives.Flex flexDirection="column" ml="5px">
                    <Primitives.Text color={colors.text[1]}>
                      Or Place bulk orders
                    </Primitives.Text>
                    <Primitives.Text color={colors.text[1]}>
                      using excel.
                      <Primitives.Text color="#4BB4B6" fontWeight={2} ml="5px">
                        Upload
                      </Primitives.Text>
                    </Primitives.Text>
                  </Primitives.Flex>
                }
                lineHeight={3}
                px={2}
              />
            </Primitives.Flex>
            {showUploadModal && (
              <UploadExcelModal
                toggleUploadModal={toggleUploadModal}
                hospitalId={hospitalId}
                distributorId={distributorId}
              />
            )}
          </Primitives.Flex>
        )}
        {isLoaded ? (
          <Primitives.Flex flexDirection="column" mr={1}>
            {data && data["total_items"] > 0 ? (
              <Primitives.Flex flexDirection="column">
                <Primitives.Text color={colors.text[1]} fontSize={1} pb={2}>
                  Showing {(pageNumber - 1) * 20 + 1}-
                  {data["total_items"] > 20
                    ? pageNumber * 20 > data["total_items"]
                      ? data["total_items"]
                      : pageNumber * 20
                    : data["total_items"]}{" "}
                  of {data["total_items"]}
                  {" SKUs"}
                </Primitives.Text>

                <Table
                  color={{ head: colors.text[1], row: colors.text[0] }}
                  data={modifyData(
                    data && data["product_list"] && data["product_list"],
                    summary,
                    changeHandler,
                    clickHandler,
                    incrementHandler,
                    decrementHandler,
                    newUser
                  )}
                  column={column(newUser)}
                />
                {data["total_items"] > 20 && (
                  <Primitives.Flex justifyContent="flex-end">
                    <Pagination
                      changeHandler={paginationChangeHandler}
                      forcePage={pageNumber - 1}
                      marginPagesDisplayed={1}
                      pageCount={parseInt(data["total_items"]) / 20}
                    />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            ) : (
              <Primitives.Flex margin="auto" mt="20vh">
                <NoResults />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        ) : (
          <Loading />
        )}
      </Primitives.Flex>
    </>
  );
};

export default CreateNewPurchaseOrder;
