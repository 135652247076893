import React from "react";
import { array, number, object, string } from "prop-types";
import CircleChecked from "../../components/glyphs/elements/circle-checked";
import { colors } from "../../components/utils/theme";
import Primitives from "../../components/primitives";
import Circle from "../../components/glyphs/elements/circle";

const propTypes = {
  /** orders array reference */
  element: array,
  /** Font-family of text */
  fontFamily: string,
  /** Line Height of text of order status and date-time */
  lineHeight: number,
  /** Current Index */
  index: number,
  /** Font style of text */
  fontStyle: string,
  /** Order status */
  order: object
};

const OrderStatus = ({
  fontStyle,
  fontFamily,
  lineHeight,
  order,
  index,
  element
}) => {
  return (
    <React.Fragment>
      {console.log(order)}
      <Primitives.Flex
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        flexDirection="column"
      >
        <Primitives.Flex width={1}>
          <Primitives.Flex alignItems="center" flexDirection="column" mr={1}>
            {order.tracking ? (
              <Primitives.Box my={1}>
                <Circle height="6px" fill={colors["background-dark-green"]} />
              </Primitives.Box>
            ) : (
              <Primitives.Box my="6px">
                <CircleChecked
                  fill={colors["background-dark-green"]}
                  height="17px"
                />
              </Primitives.Box>
            )}
            {element[index + 1] && (
              <Primitives.Flex
                borderRight="2px solid"
                borderColor={colors["background-dark-green"]}
                bottom={4.3}
                height="calc(100% - 23px)"
                minHeight="20px"
              />
            )}
          </Primitives.Flex>
          <Primitives.Flex
            flex={1}
            flexDirection="column"
            fontSize="13px"
            lineHeight={lineHeight}
            mt={order.tracking ? 1 : "6px"}
            mb="6px"
            width={1}
          >
            <Primitives.Flex>
              <Primitives.Text
                fontWeight={3}
                mr="2px"
                color={
                  order.tracking
                    ? colors.text[1]
                    : colors["background-dark-green"]
                }
              >
                {order.title}
              </Primitives.Text>
              {order.dateTime && (
                <Primitives.Text color={colors.text[1]}>
                  on {order.dateTime}
                </Primitives.Text>
              )}
            </Primitives.Flex>
            {order.details && (
              <Primitives.Flex color={colors.text[1]}>
                {order.details}
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </React.Fragment>
  );
};
OrderStatus.propTypes = propTypes;
export default OrderStatus;
