import React from "react";
import Primitives from "../../../components/primitives";
import Toggle from "../../../components/toggle-button";
import { array, func, string } from "prop-types";

const propTypes = {
  data: array,
  fontColor: string,
  toggledData: func
};
const defaultProps = {
  fontColor: "black"
};
const ToggleContent = ({ data, fontColor, toggledData }) => {
  return (
    <Primitives.Flex flexDirection="column" my={2} width={280}>
      <Primitives.Flex justifyContent="space-between">
        <Primitives.Text color={fontColor} fontWeight={1} fontSize={4}>
          {data[0]}
        </Primitives.Text>
        <Primitives.Box height={24} width={55}>
          <Toggle clickHandler={() => toggledData(data)} selected={data[1]} />
        </Primitives.Box>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

ToggleContent.defaultProps = defaultProps;
ToggleContent.propTypes = propTypes;
export default ToggleContent;
