import React, { useEffect, useState } from "react";
import { object, func, bool, string } from "prop-types";
import SmartbuyTrademark from "../../sb-trademark";
import styled from "styled-components";
import Primitives from "../../../../components/primitives";
import Button from "../../button";
import UserServices from "../../../../services/user/index";
const proptypes = {
  BASE_URL: string,
  errors: object,
  setErrors: func,
  email: string,
  setEmail: func,
  isLoading: bool,
  setIsLoading: func,
  setIndex: func
};
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 2px;
`;

const EmailComponent = ({
  BASE_URL,
  errors,
  setErrors,
  email,
  setEmail,
  isLoading,
  setIsLoading,
  setIndex
}) => {
  localStorage.clear();
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    setErrorMsg(errors.email);
  }, [errors.email]);
  const emailClickHandler = () => {
    setIsLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email.trim());
    if (!isEmailValid) {
      setErrors({ email: "please enter valid email" });
      setIsLoading(false);
      return;
    }
    const response = UserServices.sendOtpLoginRequest(
      `${BASE_URL.replace("smartbuy", "api/auth/send_otp_verification_email")}`,
      { email: email }
    );
    if (response.errors) {
      setErrors({ email: response.errors });
      setIsLoading(false);
      return;
    }
    setErrors({});
    setIndex(1);
    setIsLoading(false);
  };
  return (
    <Primitives.Flex px={5} flexDirection="column" justifyContent="center">
      <SmartbuyTrademark />
      <Primitives.Text fontSize={7} pt={2}>
        Better Healthcare. Better Patient Outcomes
      </Primitives.Text>
      <Primitives.Text fontWeight={400} color="#979797" pt={1}>
        Streamline your operations, improve margins, and provide better care.
      </Primitives.Text>
      <Primitives.Input
        height={33}
        border={1}
        mt={3}
        borderStyle="solid"
        borderColor="#BEC8D2"
        borderRadius={4}
        placeholder="Offical Email"
        pl={10}
        onChange={e => setEmail(e.target.value)}
      ></Primitives.Input>
      {errorMsg !== "" && <ErrorMessage> {errorMsg} </ErrorMessage>}
      <Button handleSubmit={emailClickHandler} isLoaded={isLoading}>
        Next
      </Button>
    </Primitives.Flex>
  );
};

EmailComponent.propTypes = proptypes;
export default EmailComponent;
