import React from "react";
import Modal from "../../../../components/modal";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import { func, array } from "prop-types";
import Button from "../../../../components/button";
import ProductCodesService from "../../../../services/product-masters";

const propTypes = {
  approveData: array,
  isConfirmed: func,
  toggleApproveModal: func
};

const ApproveModal = ({ approveData, isConfirmed, toggleApproveModal }) => {
  const confirmHandler = async () => {
    const res = await ProductCodesService.approveCodes(approveData);
    if (res && res.data && res.data.status) {
      isConfirmed(true);
      toggleApproveModal(false);
    }
  };

  return (
    <Modal
      border={`1px solid ${colors.text[3]}`}
      borderRadius={8}
      closeHandler={() => toggleApproveModal(false)}
      content={
        <Primitives.Flex flex={1} flexDirection="column">
          <Primitives.Flex flexDirection="column">
            <Primitives.Text fontSize={5} fontWeight={3} my={2}>
              Are you sure?
            </Primitives.Text>
            <Primitives.Text fontSize={5}>
              Clicking on confirm will save the products codes for the
              respective products names.
            </Primitives.Text>
            <Primitives.Flex ml="auto" mt={4}>
              <Primitives.Flex mr={3}>
                <Button
                  backgroundColor="background-light"
                  clickHandler={() => toggleApproveModal(false)}
                  color={colors.text[1]}
                  hover={{
                    backgroundColor: colors.text[2],
                    color: colors.text[0],
                    stroke: colors.text[0]
                  }}
                  label="Cancel"
                  lineHeight={3}
                  px={5}
                  py={1}
                />
              </Primitives.Flex>
              <Button
                backgroundColor={colors.primary[3]}
                clickHandler={confirmHandler}
                color="white"
                fontWeight={2}
                hover={{ backgroundColor: colors.primary[6], color: "white" }}
                label="Confirm"
                lineHeight={4}
                px={5}
              />
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
      }
      open={true}
      py={4}
      px={4}
      width={580}
    />
  );
};

ApproveModal.propTypes = propTypes;
export default ApproveModal;
