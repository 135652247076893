import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import DoughnutChart from "../../../../components/charts/doughnut";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import Loading from "../../../../components/loading";
import DownloadButton from "../download-button";
import { string } from "prop-types";
import Table from "../../../../components/table-two";
import { colors } from "../../../../components/utils/theme";
import convertToObj from "../../../../components/utils/convert-to-object";
import roundOffTwoDecimal from "../../../../components/utils/round-off-two-decimal";

const propTypes = {
  quickSearch: string,
  hospital: string
};

const itemIndicator = [
  colors.accent[5],
  colors.accent[3],
  colors.accent[1],
  colors["shadow-grey"],
  colors["highlight-blue"]
];

const keyStyles = { width: 12, height: 12, borderRadius: "100%" };

const column = [
  { heading: "Product Category", type: "string" },
  { heading: "percentage", type: "string" }
];

const modifyData = data =>
  data &&
  Object.keys(data).map((item, index) => ({
    purchaseItems: item,
    percentage: (
      <Primitives.Flex justifyContent="space-between" width={1}>
        <Primitives.Flex>{roundOffTwoDecimal(data[item])} %</Primitives.Flex>
        <Primitives.Flex
          ml={1}
          backgroundColor={itemIndicator[index]}
          {...keyStyles}
        />
      </Primitives.Flex>
    )
  }));

const PurchaseCategory = ({ quickSearch, hospital }) => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/overview/purchase_category?`
  );
  const { isLoaded, data } = useApiRequest(request);

  useEffect(() => {
    quickSearch || hospital
      ? updateRequest(
          `${BASE_URL}/overview/purchase_category?${quickSearch}&hospital_entity_id=${hospital}`
        )
      : updateRequest(`${BASE_URL}/overview/purchase_category?`);
  }, [quickSearch, hospital]);

  return (
    <>
      {isLoaded ? (
        <>
          {data && data["labels"] && data["labels"].length > 0 && (
            <Primitives.Flex flexDirection="column" width="46vw" my={5} px={2}>
              <Primitives.Flex justifyContent="space-between">
                <Primitives.Text fontSize={4} fontWeight={2}>
                  Purchase Order Value Category Wise
                </Primitives.Text>
                <DownloadButton
                  downloadCtaProps={request
                    .split("?")
                    .join(".xlsx?is_download=true&")}
                />
              </Primitives.Flex>
              <Primitives.Flex mt={3} alignItems="center">
                <Primitives.Flex flex={1}>
                  <Table
                    data={
                      data &&
                      modifyData(convertToObj(data["labels"], data["values"]))
                    }
                    column={column}
                  />
                </Primitives.Flex>
                <Primitives.Flex height={240}>
                  <DoughnutChart
                    backgroundColor={itemIndicator}
                    chartData={
                      data && data["values"].map(value => value.toFixed(2))
                    }
                    labels={data && data["labels"]}
                    tooltip={true}
                  />
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          )}
        </>
      ) : (
        <Primitives.Flex
          justifyContent="center"
          height="50vh"
          mt="25%"
          width="47vw"
        >
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </>
  );
};

PurchaseCategory.propTypes = propTypes;

export default PurchaseCategory;
