import React from "react";
import NavItem from "../../../components/nav-item";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { array, func, number } from "prop-types";

const propTypes = {
  currentTab: number,
  invoices: number,
  statusTabs: array,
  updateSelected: func
};

const statusColorObj = {
  cancelled: {
    text: colors.text[1],
    background: colors.text[3]
  },
  delivered: {
    text: colors.success[1],
    background: "#57ad681a"
  },
  confirmed: {
    text: colors.warning[1],
    background: "#e898061a"
  },
  dispatched: {
    text: "#3BB6D1",
    background: "#ebf8fa"
  }
};

const Tabs = ({ invoices, currentTab, statusTabs, updateSelected }) =>
  statusTabs.map((statusTab, index) => (
    <Primitives.Flex flexDirection="column" key={index} ml={index === 0 && 28}>
      <Primitives.Flex
        my={index > 1 ? "16.5px" : 1}
        justifyContent="center"
        cursor="default"
      >
        <Primitives.Text fontSize="14px" color={colors.text[1]}>
          {index === 0
            ? " Hospital PO"
            : index === 1
            ? `Invoices (${invoices}):`
            : ""}
        </Primitives.Text>
      </Primitives.Flex>
      <Primitives.Button height={1} onClick={() => updateSelected(index)}>
        <NavItem
          backgroundColor={currentTab === index ? "light-blue" : ""}
          borderColor="highlight-blue"
          borderTopRadius="10px"
          color={currentTab === index ? "highlight-blue" : ""}
          hoverColor={currentTab === index ? "highlight-blue" : ""}
          key="PO"
          label={
            index > 0 && statusTab.grn ? (
              <Primitives.Flex alignItems="center">
                <Primitives.Text>{statusTab.label}</Primitives.Text>
                <Primitives.Text
                  backgroundColor={
                    currentTab === index ? colors.accent[3] : colors.accent[1]
                  }
                  borderRadius={3}
                  color={"white"}
                  ml={1}
                  px={1}
                >
                  GRN
                </Primitives.Text>
              </Primitives.Flex>
            ) : index === 0 ? (
              <Primitives.Flex alignItems="flex-start">
                <Primitives.Flex flexDirection="column">
                  <Primitives.Text
                    color="highlight-blue"
                    fontSize="13px"
                    fontWeight={2}
                    px={1}
                    py={14}
                  >
                    {statusTab.label}
                  </Primitives.Text>
                </Primitives.Flex>
              </Primitives.Flex>
            ) : (
              <Primitives.Flex alignItems="flex-start" flexDirection="column">
                <Primitives.Flex>
                  <Primitives.Text
                    color="highlight-blue"
                    fontSize="13px"
                    fontWeight={2}
                    px={1}
                  >
                    {statusTab.label}
                  </Primitives.Text>
                </Primitives.Flex>
                {index > 0 && (
                  <Primitives.Text
                    borderRadius={8}
                    borderStyle="solid"
                    borderWidth="1px"
                    color={
                      statusColorObj[statusTab.status] &&
                      statusColorObj[statusTab.status].text
                    }
                    background={
                      statusColorObj[statusTab.status] &&
                      statusColorObj[statusTab.status].background
                    }
                    fontSize="11px"
                    fontWeight={1}
                    mt={1}
                    ml={1}
                    px={1}
                    py="2px"
                  >
                    {statusTab.status.toUpperCase()}
                  </Primitives.Text>
                )}
              </Primitives.Flex>
            )
          }
          selected={currentTab === index}
          px={4}
        />
      </Primitives.Button>
    </Primitives.Flex>
  ));
Tabs.propTypes = propTypes;
export default Tabs;
