/** It renders Cross Product Procurement Report. Last Modified on 24/01/23 for testing */
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import GenericReport from "../lib/generic-report";
import { withRouter } from "react-router-dom";
import { object } from "prop-types";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import paginationHandler from "../../../components/utils/pagination-handler";
import { FilterContext } from "../../../utils/filter-context";

const propTypes = {
  history: object
};

const modifyData = data =>
  data &&
  data["orders_report"] &&
  data["orders_report"].map(item => ({
    "Hospital Name": item.name,
    "Products Purchased": item.uniq_skus,
    "Ordered in All Centers": item.all_centers,
    "Ordered in Some Centers": item.ordered_in_some_centers,
    "Ordered Only at this Center": item.only_at_this_center
  }));

const column = [
  {
    heading: "Hospital Name",
    type: "string"
  },
  {
    heading: "Products Purchased",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Ordered in All Centers",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Ordered in Some Centers",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Ordered Only at this Center",
    type: "number",
    align: "flex-end"
  }
];

const CrossProductProcurementReport = ({ history }) => {
  const filterContext = useContext(FilterContext);
  const { BASE_URL, cities } = useContext(AppContext);
  const [request, updateRequest] = useState("");
  const { data, isLoaded } = useApiRequest(request);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const filterUpdate = ({
    crossProcurementHospital,
    crossProcurementCategory,
    city,
    filterCleared
  }) => {
    if (filterCleared) {
      updateRequest(`${BASE_URL}/cross_procurement_reports`);
      setQuery("");
      return;
    }
    setCurrentPage(1);
    setQuery(`${crossProcurementHospital}${crossProcurementCategory}${city}`);
    updateRequest(
      `${BASE_URL}/cross_procurement_reports?${crossProcurementHospital}${crossProcurementCategory}${city ||
        `&q[universal_city_id_in][]=${cities["cities"][0].id}`}`
    );
  };

  useEffect(() => {
    if (cities?.cities) {
      filterUpdate({
        city: `&q[universal_city_id_in][]= ${cities["cities"] &&
          cities["cities"][0].id}`
      });

      filterContext.updateFilterState({
        city: String(cities["cities"][0].id)
      });
    }
  }, [cities]);

  const tableClickHandler = (row, index) => {
    history.push(`/hospital/ordered_skus/${data["orders_report"][index].id}`);
  };
  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
      title="Hospitals"
    />
  );

  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const headerProps = {
    config: {
      crossProcurementHospital: true,
      crossProcurementCategory: true,
      city: true
    },
    filterUpdate: filterUpdate,
    label: "Cross Product Procurement Report"
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
    page &&
      updateRequest(
        `${BASE_URL}/reports/cross_procurement_report?page=${page}&${query}`
      );
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    cursor: "pointer",
    clickHandler: tableClickHandler,
    data: modifyData(data),
    column: column,
    justify: "center",
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      headerProps={headerProps}
      subNavIndex={[3, 11]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
CrossProductProcurementReport.propTypes = propTypes;
export default withRouter(CrossProductProcurementReport);
