import React from "react";

const Wallet = ({ ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4506 0.402678C14.5126 0.099229 15.6196 0.714213 15.923 1.77628C15.9741 1.95497 16 2.13989 16 2.32573V4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H2C0.89543 20 0 19.1046 0 18H0.0267034C0.00895177 17.8925 0 17.7835 0 17.6743V5.7543C0 4.86134 0.591955 4.07656 1.45056 3.83125L13.4506 0.402678ZM8.14005 18H18V10H16V14.2457C16 15.1387 15.408 15.9234 14.5494 16.1687L8.14005 18ZM18 6V8H16V6H18ZM2 5.75427V17.6742L14 14.2457V2.3257L2 5.75427ZM12 8C12 8.55228 11.5523 9 11 9C10.4477 9 10 8.55228 10 8C10 7.44771 10.4477 7 11 7C11.5523 7 12 7.44771 12 8Z"
      fill="inherit"
    />
  </svg>
);
export default Wallet;
