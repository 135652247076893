import React, { useContext, useEffect, useState } from "react";
import { string } from "prop-types";
import Primitives from "../../../../components/primitives";
import Button from "../../../../components/button";
import { colors } from "../../../../components/utils/theme";
import ViewDetails from "../../../../components/glyphs/elements/view-details";
import LineChart from "../../../../components/charts/line";
import { objectToArr } from "../../index";
import Loading from "../../../../components/loading";
import { useHistory } from "react-router";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";

const propTypes = {
  query: string
};

const HistoricalTrend = ({ query }) => {
  const history = useHistory();
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/dashboards/order_fulfillment_graph?`
  );
  const { data, isLoaded } = useApiRequest(request);

  const chartData =
    data &&
    data.month_details &&
    Object.values(data.month_details).map(item => item.fulfillment_percent);

  useEffect(() => {
    if (query.length > 0) {
      let mappedQuery =
        query.split("&hospital_entity_id=")[1] &&
        query
          .split("&hospital_entity_id=")[1]
          .split(",")
          .map(item => "&hospital_ids[]=" + item);
      updateRequest(
        `${BASE_URL}/dashboards/order_fulfillment_graph?${mappedQuery}`
      );
    }
  }, [query]);

  const pointerImage = () =>
    chartData &&
    chartData.map(item => {
      var img = new Image();
      img.src = `https://ui-avatars.com/api/?length=6&font-size=0.23&background=47a1b1&color=fff&name=${item}%&rounded=true&bold=true`;
      img.height = "42";
      img.width = "42";
      return img;
    });

  return (
    <Primitives.Flex flex={1} flexDirection="column" minHeight="60vh">
      {isLoaded && data && data.month_details ? (
        <>
          <Primitives.Flex
            borderBottom="1px solid #F0F0F0"
            color={colors.text[0]}
            fontWeight={2}
            px={3}
            py={4}
          >
            Historical Fulfillment Trend
          </Primitives.Flex>
          <Primitives.Flex px={3} py={3}>
            <Primitives.Text
              color="#666666"
              ml="1%"
              mt="19%"
              position="absolute"
            >
              Period
            </Primitives.Text>
            <LineChart
              backgroundColor={[
                "rgba(71, 161, 177, 0.3)",
                "rgba(255, 255, 255, 0.1)"
              ]}
              callback={{
                title: (tooltipItem, data) => {
                  return (
                    "    Fullfilment Percentage   " +
                    data["datasets"][0]["data"][tooltipItem[0]["index"]] +
                    "%"
                  );
                },
                label: () => {},
                afterLabel: (tooltipItem, data) => {
                  const labels = [
                    "POs Created - ",
                    "POs Completed - ",
                    "POs In Progress - ",
                    "POs In Shortclosed - "
                  ];
                  const poStatus = Object.values(
                    data.toolTipdata[tooltipItem["index"]]
                  )
                    .map((item, index) => labels[index] + item)
                    .filter(Boolean);

                  return poStatus;
                }
              }}
              borderWidth={1}
              borderColor="#A8E4EF"
              chartData={chartData}
              labels={Object.keys(data.month_details).map((item, index) => [
                item,
                `(${chartData[index]}%)`
              ])}
              lineTension={0}
              point={{
                radius: 15,
                style: pointerImage(),
                backgroundColor: "#47a1b1"
              }}
              tooltip={{
                enabled: true,
                backgroundColor: "white",
                borderWidth: 2,
                data: objectToArr(data.month_details),
                bodyFontColor: "#768698",
                bodyFontSize: 15,
                titleFontColor: colors.text[0],
                titleFontSize: 15,
                titleMarginBottom: 10
              }}
              xAxes={{
                labels: true,
                padding: 13,
                fontSize: 15,
                fontColor: "black",
                gridLines: false
              }}
              yAxes={{
                labels: true,
                padding: 50,
                fontSize: 15,
                fontColor: "black",
                labelString: "Fulfillment Percentage",
                scaleLabelFontSize: 15,
                scaleLabelPadding: 8
              }}
            />
          </Primitives.Flex>
          <Primitives.Flex
            borderTop="1px solid #F0F0F0"
            justifyContent="center"
            py="12px"
          >
            <Button
              color={colors.primary[3]}
              clickHandler={() => history.push("/hospital/fulfillment_reports")}
              hover={{
                color: colors.primary[3]
              }}
              label={
                <Primitives.Flex alignItems="center" fontWeight={2}>
                  View Fulfillment by SKU and Order Value &nbsp;{" "}
                  {<ViewDetails />}
                </Primitives.Flex>
              }
              px={1}
              py="2px"
            />
          </Primitives.Flex>
        </>
      ) : (
        <Primitives.Flex m="auto">
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

HistoricalTrend.propTypes = propTypes;
export default HistoricalTrend;
