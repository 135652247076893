import React, { useState } from "react";
import { colors } from "../utils/theme";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from "prop-types";
import Primitives from "../primitives/";
import styled from "styled-components";
import Sorting from "../glyphs/elements/sorting";
import AscendingSort from "../glyphs/elements/ascending-sort";
import DescendingSort from "../glyphs/elements/descending-sort";
import ToolTipComponent from "../tool-tip/ToolTipComponent";
import { indianNumberFormat } from "../utils";

const propTypes = {
  alternateRowColor: string,
  /** Background color of header */
  backgroundColor: object,
  /**Boolean for making the whole row bold */
  boldRow: bool,
  /** Border For rows */
  border: string,
  /** Border Bottom for each row */
  borderBottom: oneOfType([object, string]),
  /** index where border colour to be present */
  borderIndex: number,
  /** Text color of columns and rows text */
  color: object,
  /* custom col width */
  columnWidth: array,
  /** row click handler */
  clickHandler: func,
  /** Cursor type for each row */
  cursor: string,
  /** Table data to be recieved */
  data: array,
  /** Array specifying data type of each columns */
  dataTypes: array,
  /** Font family of the text */
  fontFamily: string,
  /** Font size of the text */
  fontSize: object,
  /** Font style of the text */
  fontStyle: string,
  /** Font weight of the text */
  fontWeight: object,
  /** frozen table column column number */
  freeze: number,
  /** custom header tabs */
  header: array,
  /** if header is clicakble */
  headClick: bool,
  /** Clcking the head of table */
  headClickHandler: func,
  /** In the table, Integer value should be shown with Lkhs, Crores or not */
  isLakhsCroresNeeded: bool,
  /** no sorting columns */
  noSortingColumns: array,
  /** If table is to striped or not */
  striped: bool,
  /** If table is to striped columnwise or not */
  stripedColumns: oneOfType([bool, string]),
  /** text jutsify */
  justify: string,
  /** Line height of the text */
  lineHeight: object,
  /** Padding bottom for column and row */
  pb: object,
  /** padding left */
  pl: number,
  /** Padding top for column and row */
  pt: object,
  /** Selected/clicked row in table */
  selected: number,
  /** if table needs sorting */
  sorting: bool,
  /** sorting type asc or desc */
  sortingType: string,
  /** sorting index where based on sorting type sorting glyphs will be shown */
  sortingIndex: number,
  /** column index in table with tooltip information */
  toolTipText: object,
  /** table width needed only for frozen columns */
  width: number
};

const defaultProps = {
  color: { column: "inherit", row: "inherit" },
  cursor: "default",
  borderBottom: { head: "0px", row: "1px solid" },
  fontFamily: "Lato",
  fontSize: { head: 1, row: 2 },
  fontStyle: "normal",
  fontWeight: { head: 1, row: 1 },
  isLakhsCroresNeeded: false,
  striped: false,
  sortingType: "desc",
  lineHeight: { head: 0.9, row: 0 },
  pb: { headContainer: 15, row: 3 },
  pt: { headContainer: 0, row: 18 }
};
export const RowContainer = styled(Primitives.Flex)`
  &:hover,
  &:active {
    background-color: ${props =>
      props.striped === "true" ? "" : colors["light-blue"]};
    border-color: ${colors["light-blue"]};
  }
  &:hover .displayOnHover {
    display: flex;
  }
  word-break: break-word;
`;

const sortIcon = {
  desc: <DescendingSort fill="black" />,
  asc: <AscendingSort fill="black" />
};

const Table = ({
  alternateRowColor,
  backgroundColor,
  boldRow,
  border,
  borderBottom,
  borderIndex,
  color,
  columnWidth,
  clickHandler,
  cursor,
  data = [],
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  freeze,
  header,
  headClick,
  headClickHandler,
  noSortingColumns,
  striped,
  stripedColumns,
  justify,
  lineHeight,
  pb,
  pl,
  pt,
  selected,
  sorting,
  sortingType,
  sortingIndex,
  toolTipText,
  width
}) => {
  const head =
    header ||
    (data.length > 0 &&
      Object.keys(data[0]).map(title =>
        title
          .split("_")
          .map(item => item)
          .join(" ")
      ));
  const rows = data.length > 0 && data.map(item => Object.values(item));

  const rowColor = (index, isFrozen) => {
    if (isFrozen) {
      return "transparent";
    } else if (stripedColumns && index === 0) {
      return "#fef2ee";
    } else {
      return striped
        ? index % 2 === 0
          ? "white"
          : alternateRowColor
          ? alternateRowColor
          : colors["light-blue"]
        : selected === index && colors["light-blue"];
    }
  };

  const colOpacity = (isFrozen, index) => (isFrozen && index >= freeze ? 0 : 1);

  let timeout;
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState();

  const showTip = index => {
    setActiveIndex(index);
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const createHeader = isFrozen => (
    <Primitives.Flex
      backgroundColor={backgroundColor && !isFrozen && backgroundColor.head}
      color={color.head}
      flexDirection="row"
      fontSize={fontSize.head}
      fontWeight={fontWeight.head}
      lineHeight={lineHeight.head}
      pl={pl}
    >
      {head.length &&
        head.map((item, index) => (
          <Primitives.Flex
            flex={columnWidth ? columnWidth[index] : 1}
            borderBottom={
              stripedColumns
                ? ""
                : borderIndex && index in borderIndex
                ? borderIndex[index]
                : borderBottom.head
            }
            key={index}
          >
            {headClick || sorting ? (
              <Primitives.Button
                backgroundColor={
                  isFrozen
                    ? backgroundColor
                      ? backgroundColor.head
                      : "white"
                    : "transparent"
                }
                display="flex"
                flex={columnWidth ? columnWidth[index] : 1}
                id="tableHeader"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => headClickHandler(index)}
                pb={pb.headContainer}
                pt={stripedColumns ? 0 : pt.headContainer}
                textAlign="left"
                opacity={colOpacity(isFrozen, index)}
                onMouseEnter={() => {
                  toolTipText &&
                    toolTipText[index] !== undefined &&
                    showTip(index);
                }}
                onMouseLeave={hideTip}
                position="relative"
              >
                {item}{" "}
                {sorting &&
                  (noSortingColumns
                    ? !noSortingColumns.includes(index)
                    : 1) && (
                    <Primitives.Box ml={1}>
                      {sortingIndex === index ? (
                        sortIcon[sortingType]
                      ) : (
                        <Sorting
                          height="15px"
                          width="15px"
                          fill={colors.text[0]}
                        />
                      )}
                    </Primitives.Box>
                  )}
                {active &&
                  activeIndex === index &&
                  toolTipText &&
                  toolTipText[index] && (
                    <ToolTipComponent
                      top={document.querySelector("#tableHeader").offsetHeight}
                      {...(activeIndex ===
                        parseInt(
                          Object.keys(toolTipText)[
                            Object.keys(toolTipText).length - 1
                          ]
                        ) && {
                        width: 80
                      })}
                    >
                      {toolTipText[index] && toolTipText[index]}
                    </ToolTipComponent>
                  )}
              </Primitives.Button>
            ) : (
              <Primitives.Flex
                backgroundColor={
                  stripedColumns && index % 2 === 1
                    ? colors["purple"]
                    : isFrozen
                    ? backgroundColor
                      ? backgroundColor.head
                      : "white"
                    : "transparent"
                }
                boxShadow={
                  isFrozen && index === freeze - 1
                    ? `20px 0px 20px -20px rgba(0,0,0,0.8)`
                    : ""
                }
                flex={columnWidth ? columnWidth[index] : 1}
                justifyContent={index === 0 ? "flex-start" : justify}
                lineHeight={1}
                pb={pb.headContainer}
                pt={15}
                opacity={colOpacity(isFrozen, index)}
              >
                {item}
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        ))}
    </Primitives.Flex>
  );
  const createRows = isFrozen => (
    <>
      {rows.length &&
        rows.map((row, index) => (
          <RowContainer
            backgroundColor={rowColor(index, isFrozen)}
            borderBottom={borderBottom.row}
            borderColor={
              selected === index ? colors["light-blue"] : colors["pattens-blue"]
            }
            color={color.row}
            cursor={cursor}
            flexDirection="row"
            fontSize={fontSize.row}
            fontWeight={
              boldRow && index === rows.length - 1 ? 2 : fontWeight.row
            }
            striped={striped || stripedColumns ? `true` : `false`}
            lineHeight={lineHeight.row}
            onClick={() => clickHandler(row, index)}
            pl={pl}
            key={index}
          >
            {row.map((item, colIndex) => (
              <Primitives.Flex
                // alignItems="center"
                backgroundColor={
                  stripedColumns && colIndex % 2 === 1
                    ? index === 0
                      ? "#f7ecec"
                      : colors["purple"]
                    : ""
                }
                borderRight={border && border}
                boxShadow={
                  isFrozen && colIndex === freeze - 1
                    ? ` 20px 0px 20px -20px rgba(0,0,0,0.8)`
                    : ""
                }
                flex={columnWidth ? columnWidth[colIndex] : 1}
                justifyContent={colIndex === 0 ? "flex-start" : justify}
                key={colIndex}
                opacity={colOpacity(isFrozen, colIndex)}
                position="relative"
                pb={pb.row}
                pt={pt.row}
              >
                {typeof item === "number" ? indianNumberFormat(item) : item}
              </Primitives.Flex>
            ))}
          </RowContainer>
        ))}
    </>
  );
  return (
    <Primitives.Flex
      border={border && border}
      flexDirection="column"
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      overflow="hidden"
      position="relative"
      width={freeze > 0 ? width : 1}
    >
      {createHeader()}
      {createRows()}
      {freeze > 0 && (
        <Primitives.Box position="fixed" width={width}>
          {createHeader(true)}
          {createRows(true)}
        </Primitives.Box>
      )}
    </Primitives.Flex>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
export default Table;
