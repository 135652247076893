import moment from "moment";

const today = moment();
const oneWeekAgo = moment(today)
  .subtract(1, "weeks")
  .add(1, "days");

const startDate = oneWeekAgo.format("YYYY-MM-DD");
const endDate = today.format("YYYY-MM-DD");

export { startDate, endDate };
