/**
  It renders grn report.
  Last modified on 27/01/23 for testing 
*/

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import paginationHandler from "../../../components/utils/pagination-handler";

const modifyData = data =>
  data &&
  data["sales_invoices"] &&
  data["sales_invoices"].map(item => ({
    invoiceNumber: item.invoice_number,
    refPoNumber: item.ref_po_number,
    poDate: item.po_date,
    invoiceDate: item.invoice_date,
    centerName: item.center_name,
    noOfProducts: item.no_of_products,
    totalAmount: `₹ ${item.total_amount.toFixed(2)}`
  }));

const column = [
  {
    heading: "Invoice Number",
    type: "string"
  },
  {
    heading: "Ref. PO Number",
    type: "string"
  },
  {
    heading: "PO Date",
    type: "string"
  },
  {
    heading: "Invoice Date",
    type: "string"
  },
  {
    heading: "Center Name",
    type: "string"
  },
  {
    heading: "No.of Products",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Total Amount",
    type: "string",
    align: "flex-end"
  }
];
const AllGrn = () => {
  const { BASE_URL } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(1);
  const [request, updateRequest] = useState(`${BASE_URL}/reports/grn_reports?`);
  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    setQuery(`${queryObj.hospitals}&${queryObj.date}`);
    updateRequest(
      `${BASE_URL}/reports/grn_reports?page=${currentPage}&${queryObj.hospitals}&${queryObj.date}`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/reports/grn_reports?page=${currentPage}&${currentPage}&${query}`
    );
  }, [currentPage]);
  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );

  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const headerProps = {
    filterUpdate: filterUpdate,
    config: { hospitals: true, date: true },
    label: "All GRN Reports"
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    data: modifyData(data),
    column: column,
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      headerProps={headerProps}
      subNavIndex={[3, 17]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
export default AllGrn;
