import React, { useContext, useEffect, useState } from "react";
import Button from "../../../components/button";
import CheckedCircleWithBackground from "../../../components/glyphs/elements/checked-circle-with-background";
import Download from "../../../components/glyphs/elements/download";
import ErrorWithBackground from "../../../components/glyphs/elements/error-with-background";
import Input from "../../../components/input";
import ListDropdown from "../../../components/list-dropdown";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import GrnModal from "./modal";
import Table from "../../../components/table-two/TableComponent";
import { colors } from "../../../components/utils/theme";
import { array, func, object, oneOfType } from "prop-types";
import AlertNotification from "../../../components/alert-notification";
import { AppContext } from "../../../utils/context";
import Loading from "../../../components/loading";
import downloadHandlerFn from "../../../components/utils/download-handler";

const propTypes = {
  data: object,
  poData: oneOfType([array, object]),
  newRequest: func,
  response: object,
  tableData: array
};

const GrnLayout = ({ data, newRequest, tableData, poData }) => {
  const [compare, toggleCompare] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [confirmModal, toggleConfirmModal] = useState(false);
  const [downloadCollapsed, setDownloadCollapsed] = useState(false);
  const [compareArr, updateCompareArr] = useState([]);
  const [inputs, setInputs] = useState([...tableData]);
  const [grnTable, updateGrnTable] = useState([]);
  const [status, toggleStatus] = useState("");
  const [isDownload, setIsDownload] = useState(false);
  const { BASE_URL, user } = useContext(AppContext);
  const isProcurementRole = user?.user?.role === "hospital_warehouse";
  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const changeHandler = ({ target }, index) => {
    const newArr = [...inputs];
    newArr[index] = {
      ...newArr[index],
      [target.name]: target.value
    };
    setInputs(newArr);
  };

  const inputStyles = (index, key, value) => {
    return {
      borderColor:
        (compareArr &&
          compareArr[index] &&
          compareArr[index].indexOf(key) > -1) ||
        (key === "rate" && poData[index] && value !== poData[index].rate)
          ? colors.warning[0]
          : colors.text[2],
      color:
        (compareArr &&
          compareArr[index] &&
          compareArr[index].indexOf(key) > -1) ||
        (key === "rate" &&
          poData[index] &&
          value !== poData[index].rate &&
          colors.warning[0]),
      px: 0,
      py: 0,
      textAlign: "center"
    };
  };
  const inputField = (value, key, index, type) => {
    return (
      <Primitives.Flex mr={1}>
        {data.hospital_grn.status === "confirmed" ? (
          <Primitives.Text lineHeight={4}>
            {!isNaN(value) && value % 1 !== 0 ? value.toFixed(2) : value}
          </Primitives.Text>
        ) : (
          <Primitives.Flex flexDirection="column">
            <Input
              changeHandler={e => changeHandler(e, index)}
              value={
                !isNaN(inputs[index][key]) && inputs[index][key] % 1 !== 0
                  ? parseFloat(inputs[index][key]).toFixed(2)
                  : inputs[index][key]
              }
              fieldName={key}
              type={type ? type : "number"}
              {...inputStyles(index, key, value)}
              isDisabled={
                key === "product_code" && tableData[index].product_code_approved
              }
            />
            {key === "rate" && poData[index] && value !== poData[index].rate && (
              <>
                {value > poData[index].rate ? (
                  <Primitives.Text
                    textAlign="center"
                    mt={1}
                    fontSize={1}
                    color="red"
                  >
                    {`+${parseFloat(value - poData[index].rate).toFixed(2)}`}
                  </Primitives.Text>
                ) : (
                  <Primitives.Text
                    textAlign="center"
                    mt={1}
                    fontSize={1}
                    color="green"
                  >
                    {`-${parseFloat(poData[index].rate - value).toFixed(2)}`}
                  </Primitives.Text>
                )}
              </>
            )}
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    );
  };

  const downloadHandler = async (path, format) =>
    downloadHandlerFn(path, setIsDownload, format);

  const selectHandler = option => {
    switch (option.text) {
      case "Download Invoice (PDF)":
        downloadHandler(
          `${BASE_URL}/invoices/${data.id}.pdf?is_download=true`,
          "pdf"
        );
        break;
      case "Download as PDF":
        downloadHandler(
          `${BASE_URL}/invoices/${data.id}.pdf?is_download=true`,
          "pdf"
        );
        break;
      case "Download GRN (XLS)":
        downloadHandler(
          `${BASE_URL}/invoices/download_grn.xlsx?hospital_grn_id=${data.hospital_grn.id}`,
          "xlsx"
        );
        break;
    }
  };

  useEffect(() => {
    let arr = [];
    inputs.map((item, index) => {
      arr.push([]);
      for (var k in item) {
        if (item[k] !== tableData[index][k]) {
          arr[index].push(k);
        } else if (
          item[k] === tableData[index][k] &&
          arr[index].indexOf(k) > -1
        ) {
          array.splice(arr[index].indexOf(k), 1);
        }
      }
    });
    updateCompareArr(arr);
  }, [inputs]);

  useEffect(() => {
    setInputs([...tableData]);
  }, [tableData]);

  useEffect(() => {
    updateGrnTable(
      inputs.map((item, index) => {
        if (data.hospital_grn.status === "confirmed") {
          return {
            Product: (
              <>
                {compareArr && compareArr[index] && compareArr[index].length ? (
                  <Primitives.Box position="absolute" left="-40px">
                    <ErrorWithBackground />
                  </Primitives.Box>
                ) : (
                  compare && <CheckedCircleWithBackground />
                )}{" "}
                <Primitives.Text pt="12px">{item.product_name}</Primitives.Text>
              </>
            ),
            "Product Code": inputField(
              item.product_code,
              "product_code",
              index
            ),
            HSN: inputField(item.hsn_code, "hsn_code", index),
            Batch: inputField(item.batch_number, "batch_number", index, "text"),
            Expiry: inputField(
              item.batch_expiry,
              "batch_expiry",
              index,
              "text"
            ),
            Rate: inputField(`₹ ${item.rate}`, "rate", index),
            MRP: inputField(`₹ ${item.mrp}`, "mrp", index),
            "Disc %": inputField(
              `${item.discount_rate}%`,
              "discount_rate",
              index
            ),
            "Disc Amt": inputField(
              `₹ ${item.discount_amount}`,
              "discount_amount",
              index
            ),
            QTY: inputField(item.quantity, "quantity", index),
            "Delivered Qty": inputField(
              item.delivered_quantity,
              "delivered_quantity",
              index
            ),
            "Free Qty": inputField(item.free_quantity, "free_quantity", index),
            UOM: inputField(
              item.unit_of_measurement,
              "unit_of_measurement",
              index,
              "text"
            ),
            SGST: inputField(`${item.gst_rate}%`, "gst_rate", index),
            CGST: inputField(`${item.cgst_rate}%`, "cgst_rate", index),
            "Total Tax": (
              <Primitives.Text pt="12px" pl={2}>
                {!isNaN(item.total_tax_amount) &&
                item.total_tax_amount % 1 !== 0
                  ? `₹ ${item.total_tax_amount.toFixed(2)}`
                  : `₹ ${item.total_tax_amount}`}
              </Primitives.Text>
            ),
            "Total Amt": (
              <Primitives.Text pt="12px">
                {!isNaN(item.total_amount) && item.total_amount % 1 !== 0
                  ? `₹ ${item.total_amount.toFixed(2)}`
                  : `₹  ${item.total_amount}`}
              </Primitives.Text>
            )
          };
        } else {
          return {
            Product: (
              <>
                {(compareArr &&
                  compareArr[index] &&
                  compareArr[index].length) ||
                item.rate !== data.sales_invoice_items[index].rate ? (
                  <Primitives.Box position="absolute" left="-40px">
                    <ErrorWithBackground />
                  </Primitives.Box>
                ) : (
                  compare && <CheckedCircleWithBackground />
                )}{" "}
                <Primitives.Text pt="12px">{item.product_name}</Primitives.Text>
              </>
            ),
            "Product Code": inputField(
              item.product_code,
              "product_code",
              index,
              "text"
            ),
            HSN: inputField(item.hsn_code, "hsn_code", index),
            Batch: inputField(item.batch_number, "batch_number", index, "text"),
            Expiry: inputField(
              item.batch_expiry,
              "batch_expiry",
              index,
              "text"
            ),
            Rate: inputField(`₹ ${item.rate}`, "rate", index),
            MRP: inputField(`₹ ${item.mrp}`, "mrp", index),
            "Disc %": inputField(
              `${item.discount_rate}%`,
              "discount_rate",
              index
            ),
            "Disc Amt": inputField(
              `₹ ${item.discount_amount}`,
              "discount_amount",
              index
            ),
            "Delivered Qty": inputField(
              item.delivered_quantity,
              "delivered_quantity",
              index
            ),
            "Free Qty": inputField(item.free_quantity, "free_quantity", index),
            UOM: inputField(
              item.unit_of_measurement,
              "unit_of_measurement",
              index,
              "text"
            ),
            SGST: inputField(`${item.gst_rate}%`, "gst_rate", index),
            CGST: inputField(`${item.cgst_rate}%`, "cgst_rate", index),
            "Total Tax": (
              <Primitives.Text pt="12px" pl={2}>
                {!isNaN(item.total_tax_amount) &&
                item.total_tax_amount % 1 !== 0
                  ? `₹ ${item.total_tax_amount.toFixed(2)}`
                  : `₹ ${item.total_tax_amount}`}
              </Primitives.Text>
            ),
            "Total Amt": (
              <Primitives.Text pt="12px">
                {!isNaN(item.total_amount) && item.total_amount % 1 !== 0
                  ? `₹ ${item.total_amount.toFixed(2)}`
                  : `₹ ${item.total_amount}`}
              </Primitives.Text>
            )
          };
        }
      })
    );
  }, [inputs, compareArr]);

  const compareItems = scannerTable => {
    let arr = [...Array(tableData.length)].map(() => []);
    tableData.map((item, index) => {
      let scanIndex = scannerTable.findIndex(x => x.id === item.id);
      if (scanIndex > -1) {
        for (var k in item) {
          if (item[k] !== scannerTable[scanIndex][k]) {
            arr[index].push(k);
          }
        }
      }
    });
    updateCompareArr(arr);
  };
  const saveGrn = async id => {
    const arr = [{}].concat(
      grnTable.map((item, index) => {
        const obj = {};
        obj.id = tableData[index].id;
        obj.master_sku_id = tableData[index].master_sku_id;
        obj._destroy = false;
        Object.keys(item).map(function(key) {
          if (
            item[key].props.children.props &&
            item[key].props.children.props.children[0]
          ) {
            const value =
              item[key].props.children.props.children[0].props.value;
            switch (key) {
              case "Product Code":
                obj["product_code"] = value;
                break;
              case "HSN":
                obj["hsn_code"] = value;
                break;
              case "UOM":
                obj["unit_of_measurement"] = value;
                break;
              case "Batch":
                obj["batch_number"] = value;
                break;
              case "Expiry":
                obj["batch_expiry"] = value;
                break;
              case "Free Qty":
                obj["free_quantity"] = value;
                break;
              case "Delivered Qty":
                obj["delivered_quantity"] = value;
                break;
              case "CGST":
                obj["cgst_rate"] = value;
                break;
              case "SGST":
                obj["gst_rate"] = value;
                break;
              case "Total Tax":
                obj["total_tax_amount"] = value;
                break;
              case "Disc %":
                obj["discount_rate"] = value;
                break;
              case "Disc Amt":
                obj["discount_amount"] = value;
                break;
              default:
                obj[key.toLowerCase().replace(" ", "_")] = value;
                break;
            }
          } /*else {
            obj[key.toLowerCase()] = item[key].props.children[2];
          }*/
        });
        return obj;
      })
    );
    var grnArr = { ...arr };
    delete grnArr["0"];
    const requestOptions = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({
        hospital_grn_id: id,
        hospital_grn: {
          status: "verified",
          hospital_grn_items_attributes: grnArr
        }
      })
    };
    try {
      const response = await fetch(
        `${BASE_URL}/invoices/update_grn`,
        requestOptions
      );
      const json = await response.json();
      if (json) {
        toggleStatus("GRN saved successfully");
        setTimeout(function() {
          toggleStatus("");
          newRequest();
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const confirmGrn = id => {
    toggleConfirmModal(false);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch(
      `${BASE_URL}/invoices/confirm_grn?hospital_grn_id=${id}`,
      requestOptions
    )
      .then(() => {
        //console.log(response);
      })
      .then(() => {
        toggleStatus("GRN is confirmed");
        setTimeout(function() {
          toggleStatus("");
          newRequest();
        }, 3000);
      });
  };
  const grnColumn =
    grnTable.length &&
    Object.keys(grnTable[0]).map(title => ({
      heading: title,
      width: [
        "Product",
        "Product Code",
        "HSN",
        "Batch",
        "Expiry",
        "Total Tax",
        "Total Amt"
      ].includes(title)
        ? 1.5
        : 1,
      align: (title === "Total Tax" || title === "Total Amt") && "flex-end"
    }));
  return (
    <>
      <Primitives.Flex>
        <Primitives.Flex flex={1} flexDirection="column" mb={3}>
          <Primitives.Flex fontSize={2} mt={1}>
            <Primitives.Flex flexDirection="column" mr={5}>
              <Primitives.Text color={colors.text[1]} mb={1}>
                Date Issued
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]}>
                {days[new Date(data.hospital_grn.invoice_date).getDay()].concat(
                  ", "
                ) +
                  new Date(data.hospital_grn.invoice_date).getDate() +
                  " " +
                  months[
                    new Date(data.hospital_grn.invoice_date).getMonth()
                  ].concat(" ") +
                  new Date(data.hospital_grn.invoice_date).getFullYear()}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mr={5}>
              <Primitives.Text color={colors.text[1]} mb={1}>
                Total Invoice Value
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]}>
                {`₹ ${data.hospital_grn.total_amount}`}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mr={5}>
              <Primitives.Text color={colors.text[1]} mb={1}>
                Total Invoice quantity
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]}>
                {data.hospital_grn.total_quantity}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mr={5}>
              <Primitives.Text color={colors.text[1]} mb={1}>
                Total Products
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]}>
                {data.hospital_grn.hospital_grn_items.length}
              </Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
        {!isProcurementRole && (
          <Primitives.Flex
            alignItems="center"
            flex={1}
            justifyContent="flex-end"
          >
            {data.hospital_grn.status !== "confirmed" && (
              <>
                <Primitives.Flex>
                  <Button
                    active={{
                      backgroundColor: colors.primary[6],
                      color: "white"
                    }}
                    backgroundColor={colors.primary[3]}
                    border="1px solid"
                    borderColor={colors.primary[3]}
                    color="white"
                    clickHandler={() => {
                      toggleModal(!showModal);
                    }}
                    fontWeight={1}
                    hover={{
                      backgroundColor: colors.primary[6],
                      color: "white"
                    }}
                    label={compare ? "Resume Scan" : "Start Scan"}
                    lineHeight={4}
                    px={1}
                    py="1px"
                  />
                </Primitives.Flex>
                <Primitives.Flex ml={2} mr={2}>
                  <Button
                    active={{
                      backgroundColor: colors.primary[6],
                      color: "white",
                      borderColor: "inherit"
                    }}
                    backgroundColor="white"
                    border="1px solid"
                    borderColor={colors.primary[3]}
                    clickHandler={() =>
                      data.hospital_grn.status === "verified" &&
                      compareArr.filter(item => item.length !== 0).length === 0
                        ? toggleConfirmModal(true)
                        : saveGrn(data.hospital_grn.id)
                    }
                    color={colors.primary[3]}
                    fontWeight={1}
                    hover={{
                      backgroundColor: colors.primary[3],
                      color: "white"
                    }}
                    label={
                      data.hospital_grn.status === "verified" &&
                      compareArr.filter(item => item.length !== 0).length === 0
                        ? "Confirm GRN"
                        : "Save GRN"
                    }
                    lineHeight={4}
                    px={1}
                    py="1px"
                  />
                </Primitives.Flex>
              </>
            )}

            {isDownload ? (
              <Loading onlySpinner />
            ) : (
              <Primitives.Flex position="relative">
                <Button
                  active={{
                    backgroundColor: colors.text[2],
                    color: colors.text[0],
                    borderColor: "inherit"
                  }}
                  alignItems="center"
                  backgroundColor="background-light"
                  color={colors.text[1]}
                  clickHandler={() => setDownloadCollapsed(!downloadCollapsed)}
                  glyph={<Download height="18px" width="18px" />}
                  hover={{
                    backgroundColor: colors.text[2],
                    color: colors.text[0]
                  }}
                  isDropdown={true}
                  label="Download"
                  lineHeight={3}
                  px={12}
                  py="11px"
                />
                {downloadCollapsed && (
                  <ListDropdown
                    options={
                      data.hospital_grn.status === "confirmed"
                        ? [
                            {
                              text: "Download Invoice (PDF)"
                            },
                            {
                              text: "Download GRN (XLS)"
                            }
                            // {
                            //   text: "Download GRN Report (PDF)"
                            // }
                          ]
                        : [
                            {
                              text: "Download as PDF"
                            }
                          ]
                    }
                    right={0}
                    selectHandler={option => selectHandler(option)}
                    width={
                      data.hospital_grn.status === "confirmed" ? "auto" : 1
                    }
                  />
                )}
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        )}
      </Primitives.Flex>
      <Primitives.Flex
        flexDirection="column"
        width="100%"
        overflowX="auto"
        pb={3}
      >
        <Primitives.Flex width="120%" pr={2}>
          <Table
            color={{ head: colors.text[1], row: colors.text[0] }}
            data={grnTable}
            column={grnColumn || undefined}
            page="detail"
          />
        </Primitives.Flex>
      </Primitives.Flex>

      {showModal && (
        <GrnModal
          id={data.sales_order_id}
          invoiceId={data.hospital_grn}
          compareItems={compareItems}
          length={data.hospital_grn.hospital_grn_items.length}
          showModal={showModal}
          toggleCompare={toggleCompare}
          toggleModal={toggleModal}
        />
      )}
      <Modal
        buttons={[
          {
            active: { backgroundColor: colors.primary[6], color: "white" },
            backgroundColor: colors.primary[3],
            clickHandler: () => confirmGrn(data.hospital_grn.id),
            color: "white",
            hover: { backgroundColor: colors.primary[6], color: "white" },
            label: "Confirm"
          },
          {
            active: { backgroundColor: colors.text[2], color: colors.text[0] },
            backgroundColor: colors.text[3],
            clickHandler: () => toggleConfirmModal(false),
            color: colors.text[1],
            hover: { backgroundColor: colors.text[2], color: colors.text[0] },
            label: "Don't confirm"
          }
        ]}
        border={`1px solid ${colors.text[3]}`}
        borderRadius={8}
        closeHandler={() => toggleConfirmModal(false)}
        content={
          <Primitives.Flex
            flexDirection="column"
            fontFamily="Lato"
            fontStyle="normal"
            fontWeight={1}
          >
            <Primitives.Flex
              fontSize={6}
              lineHeight={2}
              letterSpacing={-0.02}
              mb={3}
            >
              Do You want to confirm this GRN?
            </Primitives.Flex>
            <Primitives.Flex fontSize={2} height={46} lineHeight={2}>
              GRN once confirmed cannot be edited. Please check the GRN again
              before confirming.
            </Primitives.Flex>
          </Primitives.Flex>
        }
        open={confirmModal}
        width={601}
      />
      {status && (
        <Primitives.Box position="fixed" width={1} left={0} top={0} zIndex={1}>
          <AlertNotification
            backgroundColor={colors.success[1]}
            label={status}
          />
        </Primitives.Box>
      )}
    </>
  );
};
GrnLayout.propTypes = propTypes;
export default GrnLayout;
