import React from "react";

const Stack = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="icon"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1566 2.28024L21.2077 6.7531C22.2641 7.33997 22.2641 8.66003 21.2077 9.2469L19.8521 10L21.2077 10.7531C22.2641 11.34 22.2641 12.66 21.2077 13.2469L19.8521 14L21.2077 14.7531C22.2641 15.34 22.2641 16.66 21.2077 17.2469L13.1566 21.7198C12.484 22.0934 11.516 22.0934 10.8434 21.7198L2.79227 17.2469C1.73591 16.66 1.73591 15.34 2.79227 14.7531L4.14785 14L2.79227 13.2469C1.73591 12.66 1.73591 11.34 2.79227 10.7531L4.14785 10L2.79227 9.2469C1.73591 8.66003 1.73591 7.33997 2.79227 6.7531L10.8434 2.28024C11.516 1.90659 12.484 1.90659 13.1566 2.28024ZM13.1566 13.7198L17.793 11.144L19.3339 12L17.793 12.856L15.7339 14L12.1853 15.9714C12.1168 16.0095 11.8832 16.0095 11.8147 15.9714L8.2661 14L6.20698 12.856L4.6661 12L6.20698 11.144L10.8434 13.7198C11.516 14.0934 12.484 14.0934 13.1566 13.7198ZM6.20698 15.144L4.6661 16L11.8147 19.9714C11.8832 20.0095 12.1168 20.0095 12.1853 19.9714L19.3339 16L17.793 15.144L13.1566 17.7198C12.484 18.0934 11.516 18.0934 10.8434 17.7198L6.20698 15.144ZM11.8147 4.02855C11.8832 3.99048 12.1168 3.99048 12.1853 4.02855L19.3339 8L12.1853 11.9714C12.1168 12.0095 11.8832 12.0095 11.8147 11.9714L4.6661 8L11.8147 4.02855Z"
      fill="inherit"
    />
  </svg>
);

export default Stack;
