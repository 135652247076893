import React from "react";
import Panel from "../../../components/panel";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Stack from "../../../components/charts/stack";
import useApiRequest from "../../../hooks/useApiRequest";
import { string } from "prop-types";
import Loading from "../../../components/loading";
import Header from "../header";
import { indianNumberFormat } from "../../../components/utils";

const propTypes = {
  path: string,
  query: string
};

const ExpiryReport = ({ path, query }) => {
  const { data, isLoaded } = useApiRequest(`${path}/expiry_report?${query}`);
  const totalExpiryPercentage =
    data["365_days"] != null &&
    data["365_days"] +
      data["180_to_365_days"] +
      data["90_to_180_days"] +
      data["30_to_90_days"] +
      data["0_to_30_days"];

  return (
    <Primitives.Flex flex={1} mr={20} height="max-content">
      <Panel
        heading={
          <Header
            header="Expiry Report"
            dateRangeStart={null}
            dateRangeEnd={null}
          />
        }
        ctaDetails={{
          label: "Show more details",
          link: "/hospital/analytics/expiry_check"
        }}
        data={
          <Primitives.Flex flexDirection="column">
            {isLoaded ? (
              <>
                <Primitives.Flex justifyContent="center" pb={5} pt={5}>
                  {data["365_days"] != null && (
                    <Stack
                      chartData={[
                        {
                          color: colors.accent[1],
                          label: "365+ DAYS",
                          value: `${data["365_days"].toFixed(2)}%`,
                          percentage: data["365_days"] / totalExpiryPercentage
                        },
                        {
                          color: colors.accent[2],
                          label: "180 - 365 DAYS",
                          value: `${data["180_to_365_days"].toFixed(2)}%`,
                          percentage:
                            data["180_to_365_days"] / totalExpiryPercentage
                        },
                        {
                          color: colors.accent[3],
                          label: "90 - 180 DAYS",
                          value: `${data["90_to_180_days"].toFixed(2)}%`,
                          percentage:
                            data["90_to_180_days"] / totalExpiryPercentage
                        },
                        {
                          color: colors.accent[4],
                          label: "30 - 90 DAYS",
                          value: `${data["30_to_90_days"].toFixed(2)}%`,
                          percentage:
                            data["30_to_90_days"] / totalExpiryPercentage
                        },
                        {
                          color: colors.accent[5],
                          label: "1 - 30 DAYS",
                          value: `${data["0_to_30_days"].toFixed(2)}%`,
                          percentage:
                            data["0_to_30_days"] / totalExpiryPercentage
                        }
                      ]}
                    />
                  )}
                </Primitives.Flex>
              </>
            ) : (
              <Primitives.Flex m="auto" alignItems="center" minHeight={310}>
                <Loading onlySpinner={true} />
              </Primitives.Flex>
            )}

            <Primitives.Flex background={colors.accent[0]} py={3} px={5}>
              <Primitives.Flex flexDirection="column">
                <Primitives.Flex
                  color={colors.text[1]}
                  fontSize={1}
                  fontWeight={1}
                  mb="4px"
                  mr={5}
                >
                  <Primitives.Text>
                    UNIQUE PRODUCTS EXPIRING IN 6 MONTHS
                  </Primitives.Text>
                </Primitives.Flex>
                <Primitives.Flex
                  color={colors.text[0]}
                  fontSize={5}
                  fontWeight={3}
                >
                  {isLoaded ? (
                    <Primitives.Text>
                      {data.uniq_skus_expiring_in_six_month &&
                        indianNumberFormat(
                          data.uniq_skus_expiring_in_six_month
                        )}
                    </Primitives.Text>
                  ) : (
                    <Primitives.Flex height={22}>
                      <Loading onlySpinner={true} />
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
              <Primitives.Flex flexDirection="column">
                <Primitives.Flex
                  color={colors.text[1]}
                  fontSize={1}
                  fontWeight={1}
                  mb="4px"
                >
                  <Primitives.Text>
                    TOTAL QUANTITY EXPIRING IN 6 MONTHS
                  </Primitives.Text>
                </Primitives.Flex>
                <Primitives.Flex
                  color={colors.text[0]}
                  fontSize={5}
                  fontWeight={3}
                >
                  {isLoaded ? (
                    <Primitives.Text>
                      {data.total_qty_expiring_in_six_month &&
                        indianNumberFormat(
                          data.total_qty_expiring_in_six_month
                        )}
                    </Primitives.Text>
                  ) : (
                    <Primitives.Flex height={22}>
                      <Loading onlySpinner={true} />
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
        }
      />
    </Primitives.Flex>
  );
};
ExpiryReport.propTypes = propTypes;
export default ExpiryReport;
