import React, { useContext, useState, useEffect } from "react";
import { colors } from "../../../components/utils/theme";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table/TableComponent";
import Scanner from "../../../components/glyphs/elements/scanner";
import Input from "../../../components/input";
import { bool, func, number, object, oneOfType, string } from "prop-types";
import { AppContext } from "../../../utils/context";

const propTypes = {
  compareItems: func,
  id: oneOfType([number, string]),
  invoiceId: oneOfType([object, string]),
  length: number,
  response: object,
  showModal: bool,
  toggleModal: func
};
const invoiceFilters = [
  "master_sku_name",
  "hsn_code",
  "batch_number",
  "batch_expiry",
  "rate",
  "mrp",
  "discount_rate",
  "discount_amount",
  "quantity",
  "free_quantity",
  "net_amount",
  "gst_rate",
  "cgst_rate",
  "total_tax_amount",
  "total_amount"
];

const filteredList = (o, filters) =>
  filters.reduce((acc, curr) => {
    switch (curr) {
      case "master_sku_name":
        acc["product"] = o[curr];
        break;
      case "hsn_code":
        acc["HSN"] = o[curr];
        break;
      case "batch_number":
        acc["batch"] = o[curr];
        break;
      case "batch_expiry":
        acc["expiry"] = o[curr];
        break;
      case "discount_rate":
        acc["Disc %"] = `${o[curr]} %`;
        break;
      case "discount_amount":
        acc["Disc Amt"] = `₹ ${o[curr]}`;
        break;
      case "quantity":
        acc["QTY"] = o[curr];
        break;
      case "net_amount":
        acc["net amt"] = o[curr] && `₹ ${o[curr].toFixed(2)}`;
        break;
      case "gst_rate":
        acc["SGST"] = `${o[curr]} %`;
        break;
      case "cgst_rate":
        acc["CGST"] = `${o[curr]} %`;
        break;
      case "total_tax_amount":
        acc["Total Tax"] = o[curr] && `₹ ${o[curr].toFixed(2)}`;
        break;
      case "total_amount":
        acc["Total Amt"] = o[curr] && `₹  ${o[curr].toFixed(2)}`;
        break;
      case "unit_of_measurement":
        acc["UOM"] = o[curr];
        break;
      case "free_quantity":
        acc["Free Qty "] = o[curr];
        break;
      case "status":
        acc[""] = (
          <Primitives.Flex
            color={
              o[curr] === "Delivered"
                ? colors["background-dark-green"]
                : o[curr] === "Partially Delivered"
                ? colors["background-yellow"]
                : colors["background-error"]
            }
          >
            {o[curr]}
          </Primitives.Flex>
        );
        break;
      default:
        acc[curr] = o[curr];
    }
    return acc;
  }, {});

const GrnModal = ({
  id,
  compareItems,
  invoiceId,
  length,
  showModal,
  toggleModal
}) => {
  const [scannerTable, update_scanned_items] = useState([]);
  const { BASE_URL } = useContext(AppContext);
  function idExists(arr, id) {
    return arr.some(function(el) {
      return el.id === id;
    });
  }
  useEffect(() => {
    document.querySelectorAll("#grnInput input")[0].focus();
  }, []);
  const focusInput = () => {
    document.querySelectorAll("#grnInput input")[0].focus();
  };
  let str = "";
  const changeHandler = e => {
    str += e.target.value;
    if (e.target.value === "}") {
      const sku_obj = JSON.parse(str);
      const item_id = sku_obj["item_id"];
      const sku_id = sku_obj["sku_id"];
      const invoice_id = sku_obj["invoice_id"];
      if (!idExists(scannerTable, item_id)) {
        //scannerTable.push(item_id);
        fetch(
          `${BASE_URL}/orders/${id}/get_master_sku_details?master_sku_id=${sku_id}&invoice_id=${invoice_id}&item_id=${item_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
          .then(res => res.json())
          .then(
            data => {
              if (data.status === "404") {
                alert("Product not found in invoice");
              } else {
                update_scanned_items([...scannerTable].concat(data));
              }
              str = "";
            },
            error => {
              console.log(error);
              alert("Product not found in invoice");
              str = "";
            }
          );
      } else {
        console.log("duplicate record");
        // blink(item_id);
      }
    }
  };
  return (
    <Modal
      buttons={[
        {
          active: { backgroundColor: colors.primary[6], color: "white" },
          backgroundColor: colors.primary[3],
          clickHandler: () => {
            toggleModal(!showModal);
            compareItems(scannerTable);
          },
          color: "white",
          hover: { backgroundColor: colors.primary[6], color: "white" },
          label: "STOP SCAN"
        }
      ]}
      border={`1px solid ${colors.text[3]}`}
      borderRadius={8}
      content={
        <Primitives.Flex
          flexDirection="column"
          fontFamily="Lato"
          fontStyle=" normal"
          fontWeight={1}
          width={1}
          onClick={() => focusInput()}
        >
          <Primitives.Flex
            backgroundColor={colors["background-blue"]}
            fontSize={6}
            flexDirection="column"
            lineHeight={2}
            letterSpacing={-0.02}
            pb={4}
            pt={4}
            pl={5}
            pr={2}
          >
            <Primitives.Text
              color={colors.text[0]}
              fontWeight={2}
              fontSize={4}
              lineHeight={3}
              mb={1}
            >
              GRN for Invoice {invoiceId.invoice_number} against PO #
              {invoiceId.sales_invoice_id}
            </Primitives.Text>
            <Primitives.Flex
              alignItems="center"
              fontWeight={1}
              fontSize={2}
              lineHeight={1}
            >
              <Primitives.Text color={colors.text[0]} mr={1}>
                Scan Items
              </Primitives.Text>
              <Primitives.Flex
                backgroundColor={colors.accent[3]}
                borderRadius={16}
                color="white"
                px={1}
                py={1}
              >
                {scannerTable.length} of {length} items
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Box id="grnInput">
            <Input
              autoFocus={true}
              changeHandler={e => changeHandler(e)}
              value=""
              color="white"
              height={1}
              border={0}
              borderColor="transparent"
            />
          </Primitives.Box>
          {scannerTable.length > 0 ? (
            <Primitives.Flex pt={3} px={1}>
              <Table
                color={{ head: colors.text[1], row: colors.text[0] }}
                data={scannerTable.map(o => filteredList(o, invoiceFilters))}
                page="detail"
              />
            </Primitives.Flex>
          ) : (
            <Primitives.Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Primitives.Flex pt={6}>
                <Scanner />
              </Primitives.Flex>
              <Primitives.Flex
                alignItems="center"
                flexDirection="column"
                width={1 / 4}
              >
                <Primitives.Text>It&apos;s time to scan</Primitives.Text>
                <Primitives.Text textAlign="center">
                  Point the QR Gun to the QR code on the items and start
                  scanning away.
                </Primitives.Text>
              </Primitives.Flex>
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
      haveCloseButton={false}
      open={showModal}
      pb={3}
      pt={0}
      px={0}
      width={1300}
    />
  );
};
GrnModal.propTypes = propTypes;
export default GrnModal;
