import React, { useState, useEffect, useContext } from "react";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two/TableComponent";
import { colors } from "../../../components/utils/theme";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";
import Pagination from "../../../components/pagination";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import InputButton from "./input-button";
import ApproveModal from "./approve-modal";
import Button from "../../../components/button";
import Alert from "../../../components/alert";
import Error from "../../../components/glyphs/elements/error";
import Navigation from "../../../lib/navigation";
import paginationHandler from "../../../components/utils/pagination-handler";

/** Columns details of product-codes table */
const column = [
  {
    heading: "PRODUCT CODE",
    type: "object",
    width: 1
  },
  {
    heading: "PRODUCT NAME",
    type: "string",
    width: 1.5
  },
  {
    heading: "MANUFACTURER NAME",
    type: "string",
    width: 1
  },
  {
    heading: "GRN DATE",
    type: "string",
    width: 1
  },
  {
    heading: "GRN CONFIRMED BY",
    type: "string",
    width: 1
  }
];

/** Renders a table column component with the provided data containing a Text component to display the data */
const tableColumn = data => (
  <Primitives.Flex alignItems="center">
    <Primitives.Text>{data}</Primitives.Text>
  </Primitives.Flex>
);

/** Transforms original data array with modified properties */
const modifyData = (data, approveAllData) =>
  data.map(item => ({
    "PRODUCT CODE": (
      <InputButton
        getApproveAllData={item => (approveAllData[item[1]] = item[0])}
        inputData={[item["product_code"], item["product_key_id"]]}
      />
    ),
    "PRODUCT NAME": tableColumn(item["product_name"].toUpperCase()),
    "MANUFACTURER NAME": tableColumn(item["manufacturer"].toUpperCase()),
    "GRN DATE": tableColumn(item["grn_date"].toUpperCase()),
    "GRN CONFIRMED BY": (
      <Primitives.Flex alignItems="center" flex={1}>
        {item["grn_confirmed_by"].toUpperCase()}
      </Primitives.Flex>
    )
  }));

/** This is the component function */
const ProductCodes = () => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/product_masters/product_codes`
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [approveAllData] = useState({});
  const [showAlert, toggleAlert] = useState(false);
  const [showApproveModal, toggleApproveModal] = useState(false);
  const { data, isLoaded } = useApiRequest(request);
  const startIndex = (pageNumber - 1) * 20 + 1;
  const { total_items } = data;
  const endIndex = Math.min(total_items, pageNumber * 20 || total_items);
  /** Handles page number  changes */
  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setPageNumber(page);
  };

  /** Function for handling the approval of all data and showing alert */
  const approveAllHandler = () =>
    Object.keys(approveAllData).length < 20
      ? toggleAlert(true)
      : toggleApproveModal(true);

  /** Updates the approveAllData object based on the data["hospital_codes"] */
  useEffect(() => {
    const hasValidHopitalCodes = data?.hospital_codes?.length > 0;
    hasValidHopitalCodes &&
      data["hospital_codes"].forEach(
        item =>
          item["product_code"] &&
          (approveAllData[item["product_key_id"]] = item["product_code"])
      );
  }, [data]);

  /** Update URL request whenever their is change in pageNumber or data */
  useEffect(
    () =>
      updateRequest(
        `${BASE_URL}/product_masters/product_codes?page=${pageNumber}`
      ),
    [pageNumber]
  );

  /** Sets up a timeout using setTimeout to toggle off an alert after a delay of 1 second */
  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        toggleAlert(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  return (
    <>
      <Navigation selected={2} subNavIndex={[2, 2]} />
      <Primitives.Flex flexDirection="column" px={5} py={4}>
        {/** Renders a text component with a "product code confirmation" message */}
        <Primitives.Text color={colors.text[0]} fontSize={5} fontWeight={2}>
          Product Code Confirmation
        </Primitives.Text>
        {/** shows visible rows count out of the total no of rows in SKU table */}
        {isLoaded && data?.total_items > 0 && (
          <Primitives.Text color={colors.text[1]} fontSize={1} py={1}>
            Showing {startIndex}-{endIndex} of {total_items} SKUs
          </Primitives.Text>
        )}
        {/** Approve All button */}
        {isLoaded && (
          <Primitives.Flex flex={1} mt={-45} pb={3}>
            <Primitives.Flex ml="auto">
              <Primitives.Flex ml={4}>
                <Button
                  active={{
                    backgroundColor: colors.primary[6],
                    color: "white"
                  }}
                  backgroundColor={colors.primary[3]}
                  clickHandler={approveAllHandler}
                  color="white"
                  fontWeight={2}
                  hover={{
                    backgroundColor: colors.primary[6],
                    color: "white"
                  }}
                  label="Approve All"
                  lineHeight={4}
                  px={5}
                />
              </Primitives.Flex>
              {/** Renders an ApproveModal component with the necessary props for data handling, confirmation, and toggling the modal */}
              {showApproveModal && (
                <ApproveModal
                  approveData={approveAllData}
                  isConfirmed={data => {
                    if (data) {
                      window.location.reload();
                    }
                  }}
                  toggleApproveModal={data => toggleApproveModal(data)}
                />
              )}
              {/** Displays alert for missing product code */}
              {showAlert && (
                <Primitives.Flex
                  height={21}
                  left="40vw"
                  position="fixed"
                  top={126}
                  zIndex={1}
                >
                  <Alert
                    backgroundColor="white"
                    borderColor={colors.error[1]}
                    color={colors.error[1]}
                    fontSize={1}
                    glyph={<Error fill={colors.error[1]} />}
                    label={
                      <Primitives.Text
                        alignItems="center"
                        display="inline-flex"
                      >
                        Product code missing for{" "}
                        {20 - Object.keys(approveAllData).length} products
                      </Primitives.Text>
                    }
                    lineHeight={3}
                    mx={10}
                    pl={2}
                    pr={2}
                    py={20}
                  />
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        )}
        {/** Update the product code confirmation table */}
        {isLoaded && data?.hospital_codes ? (
          <Primitives.Flex flexDirection="column" py={3}>
            {data?.hospital_codes?.length > 0 ? (
              <Table
                color={{ head: colors.text[1], row: colors.text[0] }}
                data={modifyData(data["hospital_codes"], approveAllData)}
                column={column}
              />
            ) : (
              <NoResults />
            )}
          </Primitives.Flex>
        ) : (
          <Loading />
        )}
      </Primitives.Flex>

      {/** Renders a pagination component if data is available and the total number of items is greater than 20 */}
      {data?.total_items > 20 && (
        <Primitives.Flex
          backgroundColor={colors.accent[0]}
          bottom={0}
          position="fixed"
          pr={3}
          right={0}
          width="100vw"
          justifyContent="flex-end"
          zIndex={2}
        >
          <Pagination
            changeHandler={paginationChangeHandler}
            forcePage={pageNumber - 1}
            marginPagesDisplayed={1}
            pageCount={parseInt(data["total_items"]) / 20}
          />
        </Primitives.Flex>
      )}
    </>
  );
};
export default ProductCodes;
