import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two/TableComponent";
import checkEmptyNull from "../../../components/utils/check-empty-null";
import { colors } from "../../../components/utils/theme";
import useApiRequest from "../../../hooks/useApiRequest";
import Filters from "../../../lib/filters";
import Navigation from "../../../lib/navigation";
import { AppContext } from "../../../utils/context";
import { FilterContext } from "../../../utils/filter-context";
import ProductsOrdered from "./products-ordered";
import SideTag from "./side-tag";
import splitDateRange from "../../../utils/split-date-range.js";

/** Returns table data  */
const modifyData = (data, history, filterContext) =>
  data.map(item => ({
    "Product Category": (
      <Primitives.Text
        color="#2D43CB"
        cursor="pointer"
        onClick={() => {
          filterContext.updateFilterState({
            ...filterContext.filterState,
            crossProcurementCategory: String(item["category_id"])
          });
          history.push("/hospital/product_specific_compliance");
        }}
      >
        {item["category_name"]}
      </Primitives.Text>
    ),
    "Uniq Products Ordered": item["total_uniq_products_count"],
    "Products with RC": item["total_uniq_products_with_rc_count"],
    "RC Compliance": item["compliance_percentage"] + "%"
  }));

/** Column details of Compliance across categories table */
const column = [
  { heading: "Product Category" },
  { heading: "Unique Products Ordered", align: "flex-end" },
  { heading: "Products with RC", align: "flex-end" },
  { heading: "RC Compliance" }
];

/** Column detils of RC products ordered table */
const rcValueFn = ({ products_with_rc }) => [
  {
    heading: "PRODUCTS",
    value: products_with_rc?.uniq_products_count
  },
  {
    heading: "QUANTITY",
    value: products_with_rc && checkEmptyNull(products_with_rc.total_quantity)
  },
  {
    heading: "VALUE",
    value:
      products_with_rc && "₹" + checkEmptyNull(products_with_rc.total_amount)
  },
  {
    heading: "RC COMPLIANCE",
    value: products_with_rc && products_with_rc.compliance_percentage + "%"
  }
];

/** Column detils of Non RC products ordered table */
const nonRcValueFn = ({ products_without_rc }) => [
  {
    heading: "PRODUCTS",
    value:
      products_without_rc &&
      checkEmptyNull(products_without_rc.uniq_products_count)
  },
  {
    heading: "QUANTITY",
    value:
      products_without_rc && checkEmptyNull(products_without_rc.total_quantity)
  },
  {
    heading: "VALUE",
    value:
      products_without_rc &&
      "₹" + checkEmptyNull(products_without_rc.total_amount)
  }
];

/** This is component function */
const RcComplianceReport = () => {
  const { BASE_URL } = useContext(AppContext);
  const history = useHistory();
  const [request, updateRequest] = useState(`${BASE_URL}/rc_adherence_reports`);
  const { data, isLoaded } = useApiRequest(request);
  const [query, setQuery] = useState("");
  const filterContext = useContext(FilterContext);

  /**  Update the filter state in the filterContext */
  useEffect(() => {
    filterContext.updateFilterState("");
  }, []);
  const filterUpdate = queryObj => {
    const { startDate, endDate } = splitDateRange(queryObj.date);
    /** Build the date range string */
    const date = queryObj.date
      ? `&start_date=${startDate}&end_date=${endDate}`
      : "";
    /** Extract the value of hospitalEntity */
    const hospitals = queryObj?.hospitalEntity?.split("=")?.[1] ?? "";

    /** Extract the value of distributor */
    const distributor = queryObj?.distributor?.split("tenant_ids=")?.[1] ?? "";

    /** Set the dateRange variable as queryObj.date */
    const dateRange = queryObj.date || "";
    /** Update the filter state in the filterContext with the extracted values */
    filterContext.updateFilterState({
      hospitalEntity: hospitals,
      distributor: distributor,
      dateRange: dateRange
    });
    /** Set the query string by combining the different values */
    setQuery(
      `${date}&${queryObj.distributor}&universal_hospital_ids=${hospitals}`
    );
  };

  useEffect(() => {
    updateRequest(`${BASE_URL}/rc_adherence_reports?${query}`);
  }, [query]);

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 14]} />
      <Primitives.Flex
        background="#f9faff"
        flexDirection="column"
        height="93vh"
        px={4}
      >
        <Primitives.Flex pb={4} pt={2}>
          <Primitives.Flex flex={8} flexDirection="column">
            <Primitives.Text fontSize="20px" fontWeight={3} pb="10px">
              Rate Contract Compliance
            </Primitives.Text>
            <Primitives.Text>
              Report shows how much of your orders followed rate contracts
            </Primitives.Text>
          </Primitives.Flex>
          <SideTag
            data={[
              data["total_uniq_rc_products"],
              data["total_uniq_products_without_rc"]
            ]}
          />
        </Primitives.Flex>
        <Primitives.Flex flexDirection="column" pb={4}>
          <Filters
            config={{
              hospitalEntity: true,
              distributor: true,
              date: true
            }}
            headers={["Select Hospital", "Select Distributor", "Invoice Date"]}
            filterUpdate={filterUpdate}
          />
        </Primitives.Flex>
        <Primitives.Flex>
          <Primitives.Flex flex={1.2} flexDirection="column">
            <ProductsOrdered
              header="RC products ordered"
              values={rcValueFn(data)}
              loader={isLoaded}
            />
            <ProductsOrdered
              header="Non RC products ordered"
              values={nonRcValueFn(data)}
              loader={isLoaded}
            />
          </Primitives.Flex>

          <Primitives.Flex
            background="white"
            borderRadius="4px"
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
            flex={1.6}
            flexDirection="column"
            height="fit-content"
            ml={2}
          >
            <Primitives.Text
              borderBottom="1px solid #E5E5E5"
              borderTopRightRadius="10px"
              borderTopLeftRadius="10px"
              color={colors.text[0]}
              fontSize="14px"
              fontWeight={2}
              px="17px"
              py="12px"
            >
              Compliance across categories
            </Primitives.Text>
            {isLoaded && data["category_compliance"] ? (
              <Primitives.Flex p={3}>
                {data["category_compliance"].length > 0 ? (
                  <Table
                    alternateRowColor="#F8F8F8"
                    border={`1px solid #00000008`}
                    borderBottom="0px"
                    backgroundColor={{
                      head: colors["background-light-blue"]
                    }}
                    color={{ head: colors.text[0], row: colors.text[0] }}
                    data={modifyData(
                      data["category_compliance"],
                      history,
                      filterContext
                    )}
                    column={column}
                    striped={true}
                  />
                ) : (
                  <Primitives.Flex m="auto">
                    <NoResults />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            ) : (
              <Primitives.Flex alignItems="center" height="38vh" margin="auto">
                <Loading onlySpinner />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default RcComplianceReport;
