import React, { useState } from "react";
import { array, string, number } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import TooltipOnClick from "../../../../lib/tooltip-onclick";
import Table from "../../../../components/table-two";
import Close from "../../../../components/glyphs/elements/close";
import ConvertMoneyToLakhsCrores from "../../../../components/utils/convert-money-to-lakh-crores";

const propTypes = {
  column: array,
  data: array,
  header: string,
  index: number
};
const formatData = (data, column) => {
  if (!data) return;
  return data.map(obj => {
    Object.keys(obj).forEach((key, index) => {
      if (column[index]["isCurrency"]) {
        if (obj[key][0] !== "₹")
          obj[key] = "₹" + ConvertMoneyToLakhsCrores(obj[key]);
      } else if (column[index]["isPercentage"]) {
        obj[key] = obj[key] + "%";
      } else if (column[index]["isMonth"]) {
        obj[key] = (
          <Primitives.Text fontWeight={700}>{obj[key]}</Primitives.Text>
        );
      }
    });
    return obj;
  });
};
const ViewTrend = ({ header, column, data, index }) => {
  const [active, setActive] = useState(false);
  return (
    <TooltipOnClick
      backgroundColor="white"
      boxShadow={9}
      close={active}
      padding="16px"
      content={
        <Primitives.Flex
          flexDirection="column"
          maxHeight="35vh"
          overflow="scroll"
          width={1}
        >
          <Primitives.Flex pb="16px" justifyContent="space-between">
            <Primitives.Text color="#1E2B51" fontSize={2} fontWeight={2}>
              {header} Trend
            </Primitives.Text>
            <Close
              fill={colors.text[0]}
              onClick={() => setActive(!active)}
              cursor="pointer"
              height="10px"
              width="10px"
            />
          </Primitives.Flex>
          <Primitives.Flex width={1}>
            <Table
              alternateRowColor="whitesmoke"
              backgroundColor={{ head: colors["background-light-blue"] }}
              border={`0.5px solid ${colors.text[3]}`}
              column={column}
              color={{
                head: colors.text[0],
                row: "#525983",
                footer: colors.text[0],
                selected: "#EDF4FF"
              }}
              data={formatData(data, column)}
              striped={true}
              borderBottom={`0.5px solid ${colors.text[3]}`}
            />
          </Primitives.Flex>
        </Primitives.Flex>
      }
      contentWidth="350px"
      containerWidth="calc(100% - 32px)"
      containerTextAlign="center"
      left={index === 0 ? "125%" : "30%"}
      toolTipTriangle={{
        left: index === 0 ? "15%" : "60%",
        transform: "translateX(-50%)"
      }}
      borderRadius="9px"
      value={<Primitives.Text cursor="pointer">View Trend</Primitives.Text>}
    />
  );
};

ViewTrend.propTypes = propTypes;
export default ViewTrend;
