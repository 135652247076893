import React from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { string } from "prop-types";

const propTypes = {
  header: string,
  dateRangeStart: string,
  dateRangeEnd: string
};

const Header = ({ header, dateRangeStart, dateRangeEnd }) => (
  <Primitives.Text color={colors.text[0]} fontSize={3} fontWeight={3}>
    {header}{" "}
    {dateRangeStart && (
      <Primitives.Text
        fontSize="12px"
        color={colors.text[1]}
        fontWeight={1}
        ml="5px"
      >
        ({dateRangeStart} - {dateRangeEnd})
      </Primitives.Text>
    )}
  </Primitives.Text>
);
Header.propTypes = propTypes;
export default Header;
