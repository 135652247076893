import React from "react";
import { array, func, string } from "prop-types";
import GenericSavingsMarginsReport from "../../../../../lib/generic-savings-margins-report";

const propTypes = {
  data: array,
  date: string,
  download: string,
  modifyData: func
};

const title = "Hospital Wise Margins";

const column = [
  { heading: "Hospital Name", type: "string" },
  { heading: "Total Unique SKUs Purchased", type: "number", align: "flex-end" },
  { heading: "MRP Value", type: "number", align: "flex-end" },
  { heading: "Purchase Value", type: "number", align: "flex-end" },
  { heading: "Margin %", type: "string", align: "flex-end" },
  { heading: "Last Month Margin %", type: "number", align: "flex-end" }
];

const HospitalWiseMargin = ({ data, download, modifyData }) => (
  <GenericSavingsMarginsReport
    data={data && modifyData(data, true)}
    downloadLink={download}
    column={column}
    report="margin"
    title={title}
    width="96.5%"
  />
);

HospitalWiseMargin.propTypes = propTypes;
export default HospitalWiseMargin;
