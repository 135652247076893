import React, { useEffect, useState, useRef } from "react";
import { string, oneOfType, array } from "prop-types";
import Primitives from "../../../../components/primitives";
import Button from "../../../../components/button";
import { colors } from "../../../../components/utils/theme";
import ViewDetails from "../../../../components/glyphs/elements/view-details";
import StatusBox from "./status-box";
import Dropdown from "../../../../components/dropdown";
import { useHistory } from "react-router";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";
import { indianNumberFormat } from "../../../../components/utils";

const propTypes = {
  buttonText: string,
  url: string,
  header: string,
  hospitals: oneOfType([string, array])
};

const timeFilterData = [
  { text: "last 6 hours", id: 1 },
  { text: "last 12 hours", id: 2 }
];

const orderStatus = data => ({
  "New PO Placed": data["placed"] && indianNumberFormat(data["placed"]),
  "PO Completed": data["completed"] && indianNumberFormat(data["completed"]),
  "PO In Progress":
    data["in_progress"] && indianNumberFormat(data["in_progress"]),
  "PO Shortclosed":
    data["short_closed"] && indianNumberFormat(data["short_closed"])
});

const invoiceStatus = data => ({
  "Invoices Created": data["created"] && indianNumberFormat(data["created"]),
  "Ready for dispatch":
    data["read_for_dispatch"] && indianNumberFormat(data["read_for_dispatch"]),
  "Out for delivery":
    data["out_for_delivery"] && indianNumberFormat(data["out_for_delivery"]),
  Delivered: data["delivered"] && indianNumberFormat(data["delivered"])
});

const hourObj = {
  1: "time_in_hour=6",
  2: "time_in_hour=12"
};

const OperationalStatus = ({ buttonText, header, hospitals, url }) => {
  const [request, updateRequest] = useState(url);
  const { data, isLoaded } = useApiRequest(request);
  const [collapse, setCollapse] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState([]);
  const node = useRef();
  const history = useHistory();

  const handleClick = e => {
    if (node.current && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    if (!e.target.closest(".dropdown-filter")) {
      setCollapse(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const hospitalSelectHandler = option => {
    if (!selectedEntity.includes(option.id)) {
      setSelectedEntity([option.text, option.id]);
      updateRequest(`${url}${hospitals}&${hourObj[option.id]}`);
    }
    setCollapse(false);
  };

  useEffect(() => {
    updateRequest(`${url}${hospitals}`);
  }, [hospitals]);

  return (
    <>
      {isLoaded ? (
        <Primitives.Flex flex={1} flexDirection="column">
          <Primitives.Flex
            borderBottom="1px solid #F0F0F0"
            color={colors.text[0]}
            fontWeight={2}
            px={3}
            py="22px"
          >
            <Primitives.Text flex={2.5} my="auto">
              {header} Status of Today
            </Primitives.Text>
            <Primitives.Flex flex={1} ref={node}>
              <Dropdown
                className="dropdown-filter"
                collapsed={collapse}
                defaultValue={
                  selectedEntity.length > 0 ? selectedEntity[0] : "Last 6 Hours"
                }
                focusHandler={() => {
                  setCollapse(true);
                }}
                id="hospitals"
                listWidth={205}
                name="hospitals"
                options={timeFilterData}
                searchable={true}
                selectHandler={hospitalSelectHandler}
                selectType="singleSelect"
              />
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex justifyContent="space-between" px={3} py={3}>
            {Object.keys(
              header === "Invoices" ? invoiceStatus(data) : orderStatus(data)
            ).map((item, index) => (
              <StatusBox
                header={item}
                key={index}
                objectKey={header}
                objectKeyIndex={index}
                timeQuery={hourObj[selectedEntity[1]] || hourObj[1]}
                value={
                  header === "Invoices"
                    ? invoiceStatus(data)[item]
                    : orderStatus(data)[item]
                }
              />
            ))}
          </Primitives.Flex>
          <Primitives.Flex
            borderTop="1px solid #F0F0F0"
            justifyContent="center"
            py="12px"
          >
            <Button
              color={colors.primary[3]}
              hover={{
                color: colors.primary[3]
              }}
              label={
                <Primitives.Flex
                  alignItems="center"
                  fontWeight={2}
                  onClick={() =>
                    history.push(
                      `${
                        header === "Invoices"
                          ? "/hospital/product_wise_invoices/"
                          : "/hospital/orders"
                      }`
                    )
                  }
                >
                  {buttonText} &nbsp; {<ViewDetails />}
                </Primitives.Flex>
              }
              px={1}
              py="2px"
            />
          </Primitives.Flex>
        </Primitives.Flex>
      ) : (
        <Primitives.Flex m="auto">
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </>
  );
};

OperationalStatus.propTypes = propTypes;
export default OperationalStatus;
