/** It renders Header Component. Last modified on 27/01/23 for testing */
import React from "react";
import { object } from "prop-types";
import Loading from "../../../../../../../components/loading";
import Primitives from "../../../../../../../components/primitives";
import { colors } from "../../../../../../../components/utils/theme";

const propTypes = {
  item: object
};

const CenteredText = children => (
  <Primitives.Text flex={1} textAlign="center">
    {children}
  </Primitives.Text>
);

const HeaderComponent = ({ item }) => (
  <Primitives.Flex
    flex={1}
    flexDirection="column"
    justifyContent="space-between"
  >
    <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
      {CenteredText(Object.keys(item))}
    </Primitives.Flex>
    <Primitives.Flex color={colors.text[0]} fontSize={2} height={18}>
      {Object.values(item)[0] || Object.values(item)[0] === 0 ? (
        CenteredText(Object.values(item))
      ) : (
        <Loading onlySpinner />
      )}
    </Primitives.Flex>
  </Primitives.Flex>
);

HeaderComponent.propTypes = propTypes;
export default HeaderComponent;
