import React, { useState, useRef } from "react";
import { colors } from "../../../../../components/utils/theme";
import Alert from "../../../../../components/alert";
import Primitives from "../../../../../components/primitives";
import ErrorIcon from "../../../../../components/glyphs/elements/error";
import UploadedFileButton from "../../uploaded-file-tag";
import FunctionButton from "./function-button";
import { array, func, string } from "prop-types";

const propTypes = {
  listOfDocuments: array,
  setListOfDocuments: func,
  setDocTypeId: func,
  text: string
};

const UploadFileContent = ({
  listOfDocuments,
  setDocTypeId,
  setListOfDocuments
}) => {
  const defaultBtn = useRef();
  const fileContainer = useRef();
  const customBtn = useRef();
  const wrongFormat = useRef();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [buttonName, setButtonName] = useState("Upload XLSX File");
  const handleChange = () => {
    const defaultBtnFunc = () => {
      if (defaultBtn.current.value) {
        fileContainer.current.style.display = "flex";
        setButtonName("Next");
      }
    };
    if (buttonName === "Upload XLSX File") {
      defaultBtn.current.click();
      defaultBtn.current.addEventListener("change", () => {
        defaultBtnFunc();
        setUploadedFileName(defaultBtn.current.files[0].name);
      });
    } else if (buttonName === "Next") {
      var filePath = defaultBtn.current.files[0].name;
      var allowedExtensions = /(\.xlsx|\.dmg)$/i;
      if (!allowedExtensions.exec(filePath)) {
        fileContainer.current.style.display = "none";
        wrongFormat.current.style.display = "flex";
        setButtonName("Upload Again");
        defaultBtn.current.value = null;
      } else {
        fileContainer.current.style.display = "none";
        setButtonName("Upload XLSX File");
        const newListOfDocuments = [
          ...listOfDocuments,
          {
            id: Math.floor(Math.random() * 10000),
            value: defaultBtn.current.files[0].name,
            file: defaultBtn.current.files[0]
          }
        ];
        setListOfDocuments(newListOfDocuments);
        defaultBtn.current.value = null;
        setDocTypeId(2);
      }
    } else if (buttonName === "Upload Again") {
      defaultBtn.current.click();
      defaultBtn.current.addEventListener("change", () => {
        defaultBtnFunc();
        wrongFormat.current.style.display = "none";
      });
    }
  };

  const handleRemove = () => {
    defaultBtn.current.value = null;
    fileContainer.current.style.display = "none";
    setButtonName("Upload XLSX File");
  };

  return (
    <Primitives.Flex
      backgroundColor="background-blue"
      flexDirection="column"
      padding={3}
      width={317}
    >
      <Primitives.Text fontWeight={2}>
        Upload Rate Contract XLSX File{" "}
        <Primitives.Text color={colors.error[1]} ml={1}>
          *
        </Primitives.Text>
      </Primitives.Text>
      <Primitives.Flex flexDirection="column" mt={1}>
        <Primitives.Text color={colors.text[1]} fontSize={2} fontWeight={1}>
          The Excel file should have Product Code, Product Name, MRP, Rate, GST,
          Scheme.
        </Primitives.Text>
        <Primitives.Flex flexDirection="column" mt={3}>
          <Primitives.Flex display="none" ref={fileContainer}>
            <UploadedFileButton value={uploadedFileName} />
            <Primitives.Text
              color={colors.primary[3]}
              cursor="pointer"
              fontSize={1}
              ml={2}
              mt={1}
              onClick={handleRemove}
              textDecoration="underline"
            >
              Remove
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex
            display="none"
            height={48}
            ref={wrongFormat}
            width={235}
            mb={2}
          >
            <Alert
              backgroundColor="background-transparent-red"
              borderColor={colors.error[1]}
              color={colors.error[1]}
              glyph={<ErrorIcon fill={colors.error[1]} />}
              label={
                <Primitives.Text
                  display="inline-flex"
                  fontSize={2}
                  fontWeight={2}
                  alignItems="center"
                  ml={3}
                >
                  Wrong format uploaded.
                </Primitives.Text>
              }
            />
          </Primitives.Flex>
          <Primitives.Input display="none" ref={defaultBtn} type="file" />
          <Primitives.Flex onClick={handleChange} ref={customBtn}>
            <FunctionButton>
              <Primitives.Text>{buttonName}</Primitives.Text>
            </FunctionButton>
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

UploadFileContent.propTypes = propTypes;
export default UploadFileContent;
