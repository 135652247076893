import React from "react";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import { array, func, number, oneOfType } from "prop-types";
import { colors } from "../../../components/utils/theme";
import UploadButton from "./upload-button";

const propTypes = {
  distributorId: oneOfType([array, number]),
  toggleUploadModal: func,
  hospitalId: number
};

const UploadExcelModal = ({ distributorId, toggleUploadModal, hospitalId }) => (
  <>
    <Modal
      borderRadius={8}
      closeHandler={() => toggleUploadModal(false)}
      content={
        <Primitives.Flex flexDirection="column">
          <Primitives.Text fontWeight={3}>Upload Excel File</Primitives.Text>
          <Primitives.Text fontSize="14px" color={colors.text[1]} mt={1}>
            The file should have product name and quantity
          </Primitives.Text>
          <UploadButton
            distributorId={distributorId}
            hospitalId={hospitalId}
          ></UploadButton>
        </Primitives.Flex>
      }
      open={true}
      pb={1}
      pt={4}
      px={4}
      width={400}
    />
  </>
);

UploadExcelModal.propTypes = propTypes;
export default UploadExcelModal;
