import React, { useEffect, useRef, useContext, useState } from "react";
import Primitives from "../../../components/primitives";
import CartCount from "../cart-count";
import { AppContext } from "../../../utils/context";

const NavRightComponent = (
  orderPage,
  searchInput,
  setSearchInput,
  setOrderSearch,
  hideCart,
  cartFn,
  cartCount
) => {
  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setOrderSearch(searchInput);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [searchInput, setOrderSearch]);

  const { user } = useContext(AppContext);
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    const isProcurementRole = user?.user?.role === "hospital_warehouse";
    setShowCart(!Array.isArray(user) && !isProcurementRole && !hideCart);
  }, [user]);

  return (
    <Primitives.Flex flex={1} p={1} justifyContent="flex-end">
      {orderPage && (
        <Primitives.Flex pr={2} flex={1.2}>
          <Primitives.Input
            ref={inputRef}
            px={2}
            py={1}
            width={300}
            fontSize={1}
            border="1.5px solid #BEC8D2"
            borderRadius={5}
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            placeholder="Search products, manufactures or molecules"
            onKeyPress={event => {
              if (event.key === "Enter") {
                setOrderSearch(searchInput);
                event.target.blur();
              }
            }}
          ></Primitives.Input>
        </Primitives.Flex>
      )}
      {showCart && (
        <Primitives.Flex
          cursor="pointer"
          onClick={cartFn}
          flex={0.12}
          alignItems="center"
          justifyContent="flex-end"
        >
          <CartCount count={cartCount} />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

export default NavRightComponent;
