import React from "react";

const Edit = ({ ...props }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.76555 0.0405949C7.64106 0.0906115 7.53718 0.181298 7.47123 0.297956L0.770571 11.8947V11.8949C0.704204 12.0125 0.680158 12.1494 0.702417 12.2826L1.0833 14.7743C1.11037 14.9519 1.21659 15.1074 1.37199 15.1972C1.52753 15.2869 1.71523 15.301 1.88246 15.2358L4.23074 14.3197L4.23087 14.3198C4.35523 14.2699 4.45911 14.1791 4.5252 14.0625L11.2255 2.46567C11.3042 2.32978 11.3257 2.16805 11.285 2.01637C11.2443 1.86469 11.145 1.73538 11.0088 1.65705L8.27965 0.0812761C8.12342 -0.0103747 7.93394 -0.0253489 7.76535 0.0406059L7.76555 0.0405949ZM8.19892 1.39666L9.90439 2.3864L3.59981 13.2973L2.1341 13.8685L1.90024 12.306L8.19892 1.39666Z"
      fill="#7D8A9A"
    />
  </svg>
);

export default Edit;
