import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import Modal from "../../../../components/modal";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import imageStorage from "../../../../utils/image-storage";

const propTypes = {
  toggleUploadModal: func,
  getDeliveryData: func,
  getTotalFiles: func,
  setErrors: func,
  setIsValid: func
};

const inputFields = [
  {
    flex: 1,
    fileName: "dl_file",
    header: (
      <Primitives.Text>
        DL Number/MCI<Primitives.Text color="red">*</Primitives.Text>
      </Primitives.Text>
    ),
    id: 0,
    multiple: false,
    placeholder: "DL Number/MCI",
    name: "dlNumber"
  },
  {
    flex: 1,
    fileName: "gst_file",
    header: "GST",
    id: 1,
    multiple: false,
    placeholder: "GST",
    name: "gstNumber"
  },
  {
    flex: 2,
    fileName: "supporting_files[]",
    id: 2,
    multiple: true,
    header:
      "FSSAI license, MSME Licence, NDPS license & Schedule X Drug License Upload Documents",
    name: "docs"
  }
];

const UploadDocsModal = ({
  toggleUploadModal,
  getDeliveryData,
  getTotalFiles,
  setErrors,
  setIsValid
}) => {
  const [validation, setValidation] = useState("");
  const [fileName, setFileName] = useState([]);
  const [deliveryData, setDeliveryData] = useState({});
  const dlFileName = localStorage.getItem("dl_file");
  const localDeliveryData = JSON.parse(localStorage.getItem("delivery_data"));
  const gstFileName = localStorage.getItem("gst_file");
  const supportingFileNames = JSON.parse(
    localStorage.getItem("supporting_files[]")
  );

  const handleSubmit = () => {
    let validationMessage = "";
    if (!deliveryData["dlNumber"]) {
      validationMessage = "Please Enter DL Number";
    } else if (!deliveryData["dl_file"]) {
      validationMessage = "Please Upload DL File";
    } else if (
      deliveryData["gstNumber"] &&
      deliveryData["gstNumber"].length < 15
    ) {
      validationMessage =
        "Delivery GST number is too short (minimum is 15 characters)";
    }

    if (validationMessage) {
      setValidation(validationMessage);
      setErrors(prev => ({ ...prev, dl_file: validationMessage }));
      setIsValid(false);
    } else {
      setIsValid(true);
      setValidation("");
      setErrors(prev => ({ ...prev, file_upload: "" }));
      getDeliveryData(deliveryData);
      getTotalFiles(fileName ? fileName.flat() : []);
      toggleUploadModal("none");
    }
  };

  useEffect(() => {
    setFileName([dlFileName, gstFileName, supportingFileNames]);
    const dlNumber = localDeliveryData && localDeliveryData["dlNumber"];
    const gstNumber = localDeliveryData && localDeliveryData["gstNumber"];
    const dlFile = imageStorage.retrieveAndDecodeFile("dl_file");
    const gstFile = imageStorage.retrieveAndDecodeFile("gst_file");
    const supportingFiles =
      supportingFileNames &&
      supportingFileNames.map((_, index) =>
        imageStorage.retrieveAndDecodeFile("supporting_files[]" + index)
      );
    setDeliveryData({
      dlNumber: dlNumber,
      gstNumber: gstNumber,
      ...(dlFile && { dl_file: dlFile }),
      ...(gstFile && { gst_file: gstFile }),
      ...(supportingFiles && { "supporting_files[]": supportingFiles })
    });
  }, []);

  const handleChange = e => {
    let updatedArray = [...fileName];
    let file;
    if (inputFields[e.target.id].fileName === "supporting_files[]") {
      file = e.target.files;
      let fileNames = [];
      Array.from(file).forEach((file, index) => {
        fileNames.push(file.name);
        imageStorage.encodeAndStoreFile(file, `supporting_files[]${index}`);
      });
      updatedArray[e.target.id] = fileNames;
      localStorage.setItem("supporting_files[]", JSON.stringify(fileNames));
    } else {
      file = e.target.files[0];
      updatedArray[e.target.id] = file && file.name;
      imageStorage.encodeAndStoreFile(file, inputFields[e.target.id].fileName);
    }
    setFileName(updatedArray);
    setDeliveryData({
      ...deliveryData,
      [inputFields[e.target.id].fileName]: file
    });
    e.target.type = "";
    e.target.type = "file";
  };

  const clickHandler = index => {
    let newArray = [...fileName];
    newArray[index] = "";
    let data = { ...deliveryData };
    data[inputFields[index]["fileName"]] = null;
    localStorage.removeItem(inputFields[index]["fileName"]);
    setDeliveryData(data);
    setFileName(newArray);
  };

  useEffect(() => {
    getTotalFiles(fileName ? fileName.flat() : []);
  }, [fileName]);

  return (
    <>
      <Modal
        borderRadius={8}
        closeHandler={() => handleSubmit()}
        content={
          <Primitives.Flex flex={1} flexDirection="column">
            <Primitives.Text fontSize={5} fontWeight={2} pb={2}>
              Please Upload Supporting Documents
            </Primitives.Text>
            {inputFields.map((item, index) => (
              <Primitives.Flex alignItems="center" key={index} mb={4}>
                <Primitives.Flex pr={3} flex={item.flex}>
                  <Primitives.Text>{item.header}</Primitives.Text>
                </Primitives.Flex>
                {item.placeholder && (
                  <Primitives.Flex flex={1}>
                    <Input
                      border="1px solid #E8ECEF"
                      borderRadius={4}
                      p={1}
                      lineHeight={1.9}
                      fontSize={1}
                      placeholder={item.placeholder}
                      value={deliveryData[item.name]}
                      changeHandler={e => {
                        setDeliveryData({
                          ...deliveryData,
                          [item.name]: e.target.value.trim()
                        });
                      }}
                    />
                  </Primitives.Flex>
                )}
                <Primitives.Flex flex={1}>
                  <Button
                    active={{
                      backgroundColor: "white",
                      borderColor: colors.primary[3],
                      color: colors.primary[3]
                    }}
                    backgroundColor="white"
                    border="1px solid"
                    color={colors.primary[3]}
                    fontWeight={2}
                    hover={{
                      backgroundColor: colors.primary[3],
                      color: "white"
                    }}
                    label={
                      <>
                        <label htmlFor={item.id}>
                          {fileName[index]
                            ? "Re-Upload Document"
                            : "Upload Document"}
                        </label>
                        <input
                          id={item.id}
                          multiple={item.multiple}
                          type="file"
                          style={{ display: "none" }}
                          accept="image/jpeg, image/png, application/pdf"
                          onChange={handleChange}
                        />
                      </>
                    }
                    lineHeight={3}
                    px={2}
                    py="5px"
                  />
                  {fileName[index] && (
                    <Primitives.Flex position="absolute" mt={4}>
                      <Primitives.Text color="#5B5A5A" fontSize="12px">
                        {Array.isArray(fileName[index])
                          ? fileName[index].map((item, index) => (
                              <Primitives.Text pr={1} key={index}>
                                {item}
                              </Primitives.Text>
                            ))
                          : fileName[index]}
                      </Primitives.Text>
                      {fileName[index].length > 0 && (
                        <Button
                          px={2}
                          clickHandler={() => clickHandler(index)}
                          color={colors.primary[3]}
                          alignItems="center"
                          label={<Primitives.Text>Clear</Primitives.Text>}
                        ></Button>
                      )}
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
            ))}
            <Primitives.Flex
              flexDirection="column"
              alignItems="center"
              m="auto"
              mb={3}
            >
              <Button
                backgroundColor={colors.primary[3]}
                clickHandler={handleSubmit}
                color="white"
                hover={{ backgroundColor: colors.primary[6], color: "white" }}
                label="Submit Documents for Verification"
                active={{
                  backgroundColor: colors.primary[6]
                }}
                height="34"
                justifyContent="center"
                lineHeight="4"
                fontWeight="2"
                px={2}
                width="20vw"
              />
              {validation && (
                <Primitives.Text color="red" fontSize="smaller" pt={1}>
                  {validation}
                </Primitives.Text>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        }
        open={true}
        pb={1}
        pt={4}
        px={4}
      />
    </>
  );
};
UploadDocsModal.propTypes = propTypes;
export default UploadDocsModal;
