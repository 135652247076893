import React, { useState } from "react";
import { colors } from "../../../../components/utils/theme";
import Header from "../header";
import Modal from "../../../../components/modal";
import DownloadBtn from "../../.././../lib/download";
import Pagination from "../../../../components/pagination";
import Primitives from "../../../../components/primitives";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from "prop-types";
import Table from "../../../../components/table-two/TableComponent";
import NoResults from "../../../../components/no-results";
import Loading from "../../../../components/loading";
import TabsSection from "../../expiry-check-report/tabs-section";
import Navigation from "../../../../lib/navigation";
import downloadHandlerFn from "../../../../components/utils/download-handler";

const propTypes = {
  cursor: string,
  displayItems: oneOfType([object, string]),
  downloadCtaProps: string,
  getHeaderId: func,
  headerProps: object,
  selected: number,
  subNavIndex: oneOfType([array, string]),
  isLoaded: bool,
  modalProps: object,
  paginationProps: object,
  tabProps: object,
  tableProps: object
};

const defaultProps = {
  selected: 3
};

const GenericReport = ({
  displayItems,
  downloadCtaProps,
  getHeaderId,
  headerProps,
  selected,
  subNavIndex,
  isLoaded,
  modalProps,
  paginationProps,
  tableProps,
  tabProps
}) => {
  const [order, toggleOrder] = useState("asc");
  const [sortingIndex, updateSortingIndex] = useState(null);
  const [isDownload, setIsDownload] = useState(false);

  /* sorting table data */
  const headClickHandler = id => {
    updateSortingIndex(id);
    toggleOrder(order === "asc" ? "desc" : "asc");
    getHeaderId && getHeaderId(id, order);
  };

  const downloadHandler = async () =>
    downloadHandlerFn(downloadCtaProps, setIsDownload);

  return (
    <>
      {headerProps && headerProps.label && (
        <Navigation selected={selected} subNavIndex={subNavIndex} />
      )}
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Header {...headerProps} />
      </Primitives.Flex>
      {tabProps && <TabsSection {...tabProps} />}
      {isLoaded && tableProps.data && tableProps.data.length > 0 ? (
        <Primitives.Flex px={4} py={2} flexDirection="column">
          <Primitives.Flex alignItems="center" mb={2}>
            <Primitives.Text color={colors.text[1]} fontSize={1} pb={3}>
              {displayItems}
            </Primitives.Text>
            {downloadCtaProps.length > 0 && (
              <Primitives.Flex flex={1} justifyContent="flex-end">
                {isDownload ? (
                  <Loading onlySpinner />
                ) : (
                  <DownloadBtn clickHandler={downloadHandler} />
                )}
              </Primitives.Flex>
            )}
          </Primitives.Flex>
          <Primitives.Flex minHeight={360}>
            <Table
              headClickHandler={headClickHandler}
              sortingIndex={sortingIndex}
              sortingType={order === "asc" ? "desc" : "asc"}
              {...tableProps}
            />
          </Primitives.Flex>
          {paginationProps && paginationProps["pageCount"] > 1 && (
            <Primitives.Flex flex={1} justifyContent="flex-end" mt={4}>
              <Pagination {...paginationProps} />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      ) : (tableProps && tableProps.data === undefined) || !isLoaded ? (
        <Primitives.Flex px={5} py={4} flexDirection="column">
          <Loading />
        </Primitives.Flex>
      ) : (
        <Primitives.Flex flexDirection="column" mt="14vh" px={5} py={4}>
          <NoResults />
        </Primitives.Flex>
      )}
      {modalProps && <Modal {...modalProps} />}
    </>
  );
};
GenericReport.defaultProps = defaultProps;
GenericReport.propTypes = propTypes;

export default GenericReport;
