import React from "react";
import { bool, func, string } from "prop-types";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Loading from "../../../components/loading";
const propTypes = {
  children: string,
  isLoaded: bool,
  handleSubmit: func
};
const Button = ({ children, isLoaded, handleSubmit }) => (
  <Primitives.Button
    active={{ backgroundColor: colors.primary[6], color: "white" }}
    background={colors.primary[3]}
    borderRadius={4}
    color={colors.white}
    disabled={isLoaded}
    fontFamily="lato"
    fontSize={2}
    fontWeight={3}
    hover={{ backgroundColor: colors.primary[6], color: "white" }}
    mt={20}
    onClick={handleSubmit}
    py={10}
  >
    {isLoaded ? (
      <Primitives.Flex justifyContent="center" height={18}>
        <Loading onlySpinner={true} />
      </Primitives.Flex>
    ) : (
      children
    )}
  </Primitives.Button>
);
Button.propTypes = propTypes;
export default Button;
