import React from "react";
import Primitives from "../../../../components/primitives";
import { object, func, bool, string } from "prop-types";
import Button from "../../button";
import styled from "styled-components";
import Back from "../../../../components/glyphs/elements/back";
import UserServices from "../../../../services/user/index";

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 2px;
`;
const proptypes = {
  BASE_URL: string,
  errors: object,
  otp: string,
  setOtp: func,
  email: string,
  setErrors: func,
  isLoading: bool,
  setIsLoading: func,
  setIndex: func,
  loadUserData: func
};

const OtpComponent = ({
  BASE_URL,
  errors,
  setErrors,
  email,
  otp,
  setOtp,
  isLoading,
  setIsLoading,
  setIndex,
  loadUserData
}) => {
  const otpClickHandler = async () => {
    setIsLoading(true);
    const optRegex = /^\d{6}$/;
    const isOtpvalid = optRegex.test(otp.trim());
    if (!isOtpvalid) {
      setErrors({ otp: "please 6 digit opt sent to your mail" });
      setIsLoading(false);
      return;
    }
    const response = await UserServices.sendOtpLoginRequest(
      `${BASE_URL.replace("smartbuy", "api/auth/verify_otp_at_onboarding")}`,
      { email: email, otp: otp }
    );
    if (response.error) {
      errors.otp = response.error;
      setErrors({ otp: response.error });
      setIsLoading(false);
      return;
    }
    setErrors({});
    localStorage.setItem("newUser", response.non_existing_sb_user);
    const otpAuthToken = response.otp_authentication_token;
    const login = await UserServices.sendOtpLoginRequest(
      `${BASE_URL.replace("smartbuy", "api/login")}`,
      {
        user: {
          email: email,
          otp_verification_token: otpAuthToken,
          account: "smartbuy"
        }
      }
    );
    localStorage.setItem("token", login.token);
    if (!response.non_existing_sb_user) {
      setIsLoading(false);
      loadUserData();
      return;
    }
    const postResponse = await UserServices.sentApiRequestWithBody(
      `${BASE_URL}/sb_sales_orders#create`,
      {}
    );
    const sbCreateData = await postResponse.json();
    if (postResponse.status === 200) {
      localStorage.setItem("id", sbCreateData.draft_so.id);
      localStorage.setItem(
        "po_number",
        JSON.stringify(sbCreateData.draft_so.sb_so_number)
      );
      localStorage.setItem("hospital", JSON.stringify(["New Hospital"]));
      localStorage.setItem("distributor", JSON.stringify(["New Distributor"]));
      loadUserData();
    }

    setIsLoading(false);
  };
  return (
    <Primitives.Flex px={5} flexDirection="column" py={5}>
      <Primitives.Button textAlign="start" pb={5} onClick={() => setIndex(0)}>
        <Back fill="black" />
      </Primitives.Button>
      <Primitives.Text fontSize={7} pt={1} fontWeight={500}>
        We’ve sent you the 6 digit OTP to {email}
      </Primitives.Text>
      <Primitives.Input
        error={errors.otp}
        height={33}
        border={1}
        mt={5}
        mb={2}
        borderStyle="solid"
        borderColor="#BEC8D2"
        borderRadius={4}
        placeholder="OTP"
        pl={10}
        onChange={e => setOtp(e.target.value)}
      ></Primitives.Input>
      {errors.otp && <ErrorMessage>{errors.otp}</ErrorMessage>}
      <Button handleSubmit={otpClickHandler} isLoaded={isLoading}>
        Verify OTP
      </Button>
    </Primitives.Flex>
  );
};
OtpComponent.propTypes = proptypes;
export default OtpComponent;
