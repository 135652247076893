import React from "react";
import { array } from "prop-types";
import Primitives from "../../primitives";
import { colors } from "../../utils/theme";

const propTypes = {
  chartData: array.isRequired
};

const Stack = ({ chartData }) => (
  <Primitives.Flex>
    <Primitives.Flex flexDirection="column" justifyContent="flex-end">
      {chartData
        .filter(data => data.value !== "0.00%")
        .map((data, index) => (
          <Primitives.Flex
            background={index > 0 && chartData[index - 1].color}
            key={index}
            height={data.percentage}
          >
            <Primitives.Box
              borderTopLeftRadius={8}
              borderTopRightRadius={8}
              backgroundColor={data.color}
              width={110}
            ></Primitives.Box>
          </Primitives.Flex>
        ))}
    </Primitives.Flex>
    <Primitives.Flex flexDirection="column" justifyContent="flex-end" ml={108}>
      {chartData
        .filter(data => data.value !== "0.00%")
        .map((data, index) => (
          <Primitives.Flex key={index} mt="12px">
            <Primitives.Flex
              alignItems="center"
              borderBottom={`1px solid ${colors.text[3]}`}
              color={colors.text[0]}
              lineHeight={1}
              justifyContent="flex-start"
              pb="4px"
              width={155}
            >
              <Primitives.Flex
                alignSelf="center"
                borderRadius="4px"
                backgroundColor={data.color}
                height={19}
                mr={2}
                width={19}
              />
              <Primitives.Text fontSize={1} fontWeight={2} mr={1}>
                {data.value}
              </Primitives.Text>
              <Primitives.Text fontSize={0}>{data.label} </Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
        ))}
    </Primitives.Flex>
  </Primitives.Flex>
);

Stack.propTypes = propTypes;
export default Stack;
