/**
  It renders product return report.
  Last modified on 27/01/23 for testing 
*/
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import { FilterContext } from "../../../utils/filter-context";
import paginationHandler from "../../../components/utils/pagination-handler";
import ViewInvoice from "./view-invoice";
import DownloadCreditNote from "./download-creditnote";

const viewInvoiceColumn = [
  {
    heading: "Return Date",
    type: "string"
  },
  {
    heading: "PO No.",
    type: "string"
  },
  {
    heading: "Invoice No.",
    type: "string"
  },
  {
    heading: "Credit Note",
    type: "string"
  }
];

const viewInvoiceData = data =>
  data &&
  data.map(item => ({
    return_date: item["return_date"],
    po_number: item["po_number"],
    invoice_number: item["invoice_number"],
    credit_note: <DownloadCreditNote item={item} />
  }));

const modifyData = data =>
  data &&
  data["grouped_sales_return_items"] &&
  data["grouped_sales_return_items"].map(item => ({
    sku_name: item.sku_name,
    manufacturer: item.manufacturer_name,
    quantity_returned: (
      <ViewInvoice
        value={item.quantity_returned}
        column={viewInvoiceColumn}
        data={viewInvoiceData(item["sales_return_items"])}
      />
    ),
    no_of_returns: item.no_of_returns,
    sb_hospital_name: item.sb_hospital_name,
    total_amount: `₹ ${item.total_amount}`
  }));

const column = [
  {
    heading: "Product Name",
    type: "string"
  },
  {
    heading: "Manufacturer",
    type: "string"
  },
  {
    heading: "Total Return Qty",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Credit Notes Issued",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "Hospital Center",
    type: "string"
  },
  {
    heading: "Total Return Value",
    type: "number",
    align: "flex-end"
  }
];

const ProductReturns = () => {
  const { BASE_URL } = useContext(AppContext);
  const filterContext = useContext(FilterContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [request, updateRequest] = useState(`${BASE_URL}/product_returns?`);
  const { isLoaded, data } = useApiRequest(request);

  const filterUpdate = ({
    hospitalEntity,
    filterCleared,
    invoices,
    date,
    poNumbers,
    orderListingProductSearch
  }) => {
    if (filterCleared) {
      updateRequest(`${BASE_URL}/product_returns?`);
    }
    setCurrentPage(1);
    let universalHospitals = hospitalEntity
      ? hospitalEntity
          .split("=")[1]
          .split(",")
          .join("&q[universal_hospital_ids][]=")
      : "";
    setQuery(
      `${invoices}&${date}&${poNumbers}&${universalHospitals &&
        `&q[universal_hospital_ids][]=${universalHospitals}`}&${typeof orderListingProductSearch ===
        "number" && `&q[universal_sku_ids][]=${orderListingProductSearch}`}`
    );
    updateRequest(
      `${BASE_URL}/product_returns?${invoices}&${poNumbers}&${date}&${universalHospitals &&
        `&q[universal_hospital_ids][]=${universalHospitals}`}&${typeof orderListingProductSearch ===
        "number" && `&q[universal_sku_ids][]=${orderListingProductSearch}`}`
    );
  };

  useEffect(() => {
    const { filterState } = filterContext;
    if (filterState && filterState.dateRange) {
      updateRequest(`${BASE_URL}/product_returns?${filterState.dateRange}`);
    }
  }, []);

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );
  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const startDate = new Date();
  const endDate = new Date(new Date().setDate(startDate.getDate() - 30));
  const headerProps = {
    filterUpdate: filterUpdate,
    config: {
      orderListingProductSearch: true,
      hospitalEntity: true,
      invoices: true,
      poNumbers: true,
      date: true,
      defaultDateRange: {
        startDate: startDate,
        endDate: endDate
      }
    },
    label: "Product Returns Report"
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
    page && updateRequest(`${BASE_URL}/product_returns?page=${page}&${query}`);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    data: modifyData(data),
    column: column,
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      headerProps={headerProps}
      subNavIndex={[3, 18]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
export default ProductReturns;
