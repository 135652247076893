import { BASE_URL, GET_REQ_OPT, REQ_OPT } from "../config";
import "regenerator-runtime/runtime";

export default {
  uploadOrderFile: async (distributorId, body, hospitalId) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      body: body,
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/purchase_orders/upload_order_file?hospital_id=${hospitalId}&tenant_id=${distributorId[1]}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  postDataWithFile: async body => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      body: body,
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/purchase_orders/create_draft`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  downloadSampleFile: async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/purchase_orders/download_sample_upload_order_file`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  saveAsDraft: async (url, data) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(data);
    var requestOptions = {
      headers: myHeaders,
      body: raw,
      ...REQ_OPT
    };

    try {
      const response = await fetch(url, requestOptions);
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  pincodeSearch: async query => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      headers: myHeaders,
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/fetch_universal_city_state?universal_pincode=${query}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  deleteDraft: async id => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(
        `${BASE_URL}/purchase_orders/delete_sales_order?id=${id}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};
