import React, { useContext, useState, useRef, useEffect } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { func, object } from "prop-types";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import AlertNotification from "../../../components/alert-notification";
import { AppContext } from "../../../utils/context";
import { useHistory } from "react-router";
import { indianNumberFormat } from "../../../components/utils";
import StatusCard from "../status-card";
import Input from "../../../components/input";
import Dropdown from "../../../components/dropdown";
import DropdownClick from "../../../lib/dropdown-click";
import Frozen from "../../../components/glyphs/elements/frozen";
const capitalize = sentence =>
  sentence &&
  (sentence.charAt(0).toUpperCase() + sentence.slice(1)).split("_").join(" ");

const propTypes = {
  newRequest: func,
  orderDetail: object,
  toggleZindex: func
};

const Detail = ({ newRequest, orderDetail, toggleZindex }) => {
  const history = useHistory();
  const [confirmModal, toggleConfirmModal] = useState(false);
  const [status, toggleStatus] = useState("");
  const { BASE_URL } = useContext(AppContext);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState(false);
  const [dropdownData, setDropdownData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const dropdownRef = useRef(null);
  const handleClick = e => {
    if (!DropdownClick(dropdownRef, e)) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    toggleZindex(confirmModal);
  }, [confirmModal]);

  const cancelOrder = order => {
    const requestOptions = {
      method: "PATCH",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    fetch(`${BASE_URL}/orders/${order.id}/cancel`, requestOptions)
      .then(() => {
        //console.log(response);
        //response.json();
      })
      .then(() => {
        toggleStatus("Order cancelled successfully");
        toggleConfirmModal(false);
        setTimeout(function() {
          toggleStatus("");
          newRequest();
        }, 3000);
      });
  };

  return (
    <Primitives.Flex
      p={2}
      flex={1}
      backgroundColor="background-blue"
      position="fixed"
      justifyContent="center"
      width="30%"
      height="100%"
    >
      {orderDetail && (
        <Primitives.Flex
          bottom={50}
          flex={1}
          py={3}
          flexDirection="column"
          overflow="scroll"
          top="70px"
          mb={6}
          mr={4}
        >
          <Primitives.Flex>
            <Primitives.Text color={colors.text[0]} fontSize={4} fontWeight={3}>
              PO #{orderDetail.po_number}
            </Primitives.Text>
            {orderDetail.is_cold_storage && (
              <Primitives.Flex pl={1}>
                <Frozen height="24px" />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
          {orderDetail.branch_name && (
            <Primitives.Flex color="#1E2B51" fontSize={1} pt={1}>
              {orderDetail.status === "delivered" ? "Arrived" : "Arriving"} from{" "}
              {orderDetail.branch_name} branch
            </Primitives.Flex>
          )}
          <Primitives.Flex flexDirection="row" py={2}>
            <Primitives.Flex flexDirection="column" fontSize={2} flex={0.25}>
              <Primitives.Text color={colors.text[1]}>
                Unique SKU
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} pt={1}>
                {orderDetail.items_count &&
                  indianNumberFormat(orderDetail.items_count)}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" fontSize={2} flex={0.25}>
              <Primitives.Text color={colors.text[1]}>
                Pending SKU
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} pt={1}>
                {orderDetail.total_pending_sku_count &&
                  indianNumberFormat(orderDetail.total_pending_sku_count)}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" fontSize={2} flex={0.25}>
              <Primitives.Text color={colors.text[1]}>
                Total Qty
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} pt={1}>
                {orderDetail.total_quantity &&
                  indianNumberFormat(orderDetail.total_quantity)}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" fontSize={2} flex={0.25}>
              <Primitives.Text color={colors.text[1]}>
                Pending Qty
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} pt={1}>
                {orderDetail.total_pending_quantity &&
                  indianNumberFormat(orderDetail.total_pending_quantity)}
              </Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex flexDirection="row" py={1} alignItems="start">
            {orderDetail.can_cancel && (
              <Primitives.Flex height="40px" pr={2} justifyContent="center">
                <Button
                  active={{
                    backgroundColor: colors.primary[6],
                    color: "white",
                    borderColor: "inherit"
                  }}
                  border="1px solid"
                  borderColor={colors.primary[3]}
                  clickHandler={() => toggleConfirmModal(true)}
                  color={colors.primary[3]}
                  fontWeight={1}
                  hover={{
                    backgroundColor: colors.primary[3],
                    color: "white"
                  }}
                  label="Cancel Order"
                  lineHeight={4}
                  px={1}
                  py="1px"
                />
              </Primitives.Flex>
            )}
            <Primitives.Flex
              backgroundColor={colors.primary[3]}
              borderRadius={4}
              cursor="pointer"
              flexDirection="row"
              justifyContent="center"
              mr={2}
              onClick={() => history.push(`/hospital/orders/${orderDetail.id}`)}
              px={2}
              py={10}
            >
              <Primitives.Flex color={colors.white} fontWeight={1}>
                View Details
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex flexDirection="row" py={2} alignItems="start">
            <Input
              flex={2}
              active={active}
              changeHandler={e => setSearchInput(e.target.value)}
              clickHandler={() => {
                setSearch(searchInput.trim());
                setActive(searchInput.trim().length !== 0);
              }}
              blurHandler={() => {
                if (!searchInput.trim().length) {
                  setSearch(searchInput);
                  setActive(false);
                }
              }}
              glyph="search"
              placeholder="search"
              type="text"
              value={searchInput}
            />
            <Primitives.Flex
              flex={1}
              px={1}
              ref={dropdownRef}
              flexDirection="column"
            >
              <Dropdown
                mb={1}
                listWidth="100%"
                selectedOptions={
                  selectedStatus
                    ? [selectedStatus]
                    : [`ALL(${totalCount || 0})`]
                }
                collapsed={collapsed}
                focusHandler={() => {
                  setCollapsed(true);
                }}
                clickHandler={() => setCollapsed(!collapsed)}
                options={dropdownData?.map(item => ({
                  text: capitalize(item),
                  value: item
                }))}
                selectHandler={e => {
                  setSelectedStatus(e.value);
                  setCollapsed(false);
                }}
                selectType="singleSelect"
              />

              {(selectedStatus || search) && (
                <Primitives.Text
                  color={colors["dusty-grey"]}
                  textAlign="end"
                  pt={3}
                  fontSize={2}
                  cursor="pointer"
                  onClick={() => {
                    setSelectedStatus("");
                    setSearch("");
                    setSearchInput("");
                    setActive(false);
                  }}
                >
                  clear
                </Primitives.Text>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex>
            <StatusCard
              search={search}
              status={selectedStatus}
              id={orderDetail.id}
              setDropdownData={setDropdownData}
              setTotalCount={setTotalCount}
            />
          </Primitives.Flex>
        </Primitives.Flex>
      )}
      <Modal
        buttons={[
          {
            active: { backgroundColor: colors.primary[6], color: "white" },
            backgroundColor: colors.primary[3],
            clickHandler: () => cancelOrder(orderDetail),
            color: "white",
            hover: { backgroundColor: colors.primary[6], color: "white" },
            label: "Cancel the Order"
          },
          {
            active: {
              backgroundColor: colors.text[2],
              color: colors.text[0]
            },
            backgroundColor: colors.text[3],
            clickHandler: () => toggleConfirmModal(false),
            color: colors.text[1],
            hover: { backgroundColor: colors.text[2], color: colors.text[0] },
            label: "Don't cancel"
          }
        ]}
        border={`1px solid ${colors.text[3]}`}
        borderRadius={8}
        closeHandler={() => toggleConfirmModal(false)}
        content={
          orderDetail && (
            <Primitives.Flex
              flexDirection="column"
              fontFamily="Lato"
              fontStyle="normal"
              fontWeight={1}
              width={1}
            >
              <Primitives.Flex
                fontSize={6}
                lineHeight={2}
                letterSpacing={-0.02}
                mb={3}
              >
                Do You want to cancel
                {" " + orderDetail.po_number}
              </Primitives.Flex>
              <Primitives.Flex fontSize={3} height={46} lineHeight={2}>
                Details of the order
              </Primitives.Flex>
              <Primitives.Flex
                width={1}
                borderTop="1px solid lightgrey"
                pt={2}
                flexDirection="column"
              >
                <Primitives.Flex width={1}>
                  <Primitives.Flex flex={2}>PO Number</Primitives.Flex>
                  <Primitives.Flex flex={1}>Date</Primitives.Flex>
                  <Primitives.Flex flex={2}>For Center</Primitives.Flex>
                  <Primitives.Flex flex={1}>Total Amount</Primitives.Flex>
                </Primitives.Flex>
                <Primitives.Flex width={1} mt={2}>
                  <Primitives.Flex flex={2}>
                    {orderDetail.po_number}
                  </Primitives.Flex>
                  <Primitives.Flex flex={1}>
                    {orderDetail.order_date}
                  </Primitives.Flex>
                  <Primitives.Flex flex={2}>
                    {orderDetail.buyer_name}
                  </Primitives.Flex>
                  <Primitives.Flex flex={1}>
                    ₹
                    {orderDetail.total_amount &&
                      orderDetail.total_amount.toFixed(2)}
                  </Primitives.Flex>
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          )
        }
        open={confirmModal}
        width={601}
      />
      {status && (
        <Primitives.Box position="fixed" width={1} left={0} top={5} zIndex={1}>
          <AlertNotification
            backgroundColor={colors.success[1]}
            label={status}
          />
        </Primitives.Box>
      )}
    </Primitives.Flex>
  );
};
Detail.propTypes = propTypes;
export default Detail;
