import React, { useState, useEffect, useContext } from "react";
import Cta from "../../../../components/cta";
import Circle from "../../../../components/glyphs/elements/circle";
import SurgicalProducts from "../../../../components/glyphs/elements/surgical-products";
import NewItems from "../../../../components/new-items";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import Table from "../../../../components/table-two/TableComponent";
import Loading from "../../../../components/loading";
import NoResults from "../../../../components/no-results";
import { useParams } from "react-router";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import { useHistory } from "react-router-dom";
import Pagination from "../../../../components/pagination";
import ViewDetailsBtn from "../../../reports/lib/view-details-btn";
import DownloadBtn from "../../../../lib/download";
import DownwardArrow from "../../../../components/glyphs/elements/downwardArrow";
import UpwardArrow from "../../../../components/glyphs/elements/upwardArrow";
import Navigation from "../../../../lib/navigation";
import downloadHandlerFn from "../../../../components/utils/download-handler";
import Filters from "../../../../lib/filters";
import { convertDate } from "../../../../components/utils";
import FormularyTag from "../../../../components/glyphs/elements/formulary";
import { HorizontalScroll } from "../../../../lib/horizontal-scroll";

const arrowIndicators = {
  "downtrend-green": {
    arrow: <DownwardArrow fill="green" />,
    color: "green"
  },
  "uptrend-red": {
    arrow: <UpwardArrow fill="red" />,
    color: "red"
  }
};

const modifyData = data =>
  data.map(item => ({
    "Product Name": (
      <Primitives.Text>
        {item["sku_name"]}
        {item["new_sku"] && (
          <Primitives.Text ml={1}>
            <NewItems />
          </Primitives.Text>
        )}
        {item.formulory_uploaded === "Yes" && (
          <Primitives.Text m={2}>
            <FormularyTag />
          </Primitives.Text>
        )}
      </Primitives.Text>
    ),
    "Manufacturer Name": item["manufacturer"],
    "Product Code":
      item["product_code"] === "NULL" ? "--" : item["product_code"],
    "No.of Centres Purchasing": item["no_of_centres"],
    MRP: (
      <Primitives.Text>
        {`₹ ${item["mrp"]}`}
        {Math.round(item["mrp_diff"]) !== 0 &&
          item["mrp_arrow"] &&
          item["mrp_arrow"] in arrowIndicators && (
            <Primitives.Text ml={1}>
              ({arrowIndicators[item["mrp_arrow"]]["arrow"]}
              <Primitives.Text
                color={arrowIndicators[item["mrp_arrow"]]["color"]}
              >
                {Math.round(item["mrp_diff"])}
              </Primitives.Text>
              )
            </Primitives.Text>
          )}
      </Primitives.Text>
    ),
    Rate: `₹ ${Math.round(item["rate"])}`,
    Margin: `${
      item["margin_percent"] ? `${Math.round(item["margin_percent"])}` : 0
    }%`,
    "Average Order Quantity": item["avg_qty"],
    "Average Order value": `₹ ${item["avg_val"]}`,
    "": (
      <Primitives.Flex ml={4}>
        <ViewDetailsBtn
          link={`/hospital/product_masters/products/${item["id"]}`}
        />
      </Primitives.Flex>
    )
  }));

const column = data =>
  data &&
  data["column_sum"] && [
    {
      heading: "Product Name",
      type: "string",
      width: 1.5
    },
    {
      heading: "Manufacturer Name",
      type: "string",
      width: 1.5
    },
    { heading: "Product Code", type: "string", width: 1 },
    {
      heading: "No.of Centres Purchasing",
      type: "string",
      width: 1,
      align: "flex-end"
    },
    { heading: "MRP", type: "string", width: 1, align: "flex-end" },
    { heading: "Rate", type: "string", width: 1, align: "flex-end" },
    { heading: "Margin", type: "string", width: 1 },
    {
      heading: "Average Order Quantity",
      type: "number",
      width: 1,
      align: "flex-end"
    },
    {
      heading: "Average Order Value",
      type: "string",
      width: 1,
      align: "flex-end"
    },
    { heading: " ", type: "object", width: 0.5, sort: false, align: "flex-end" }
  ];

const CategoryProductTable = () => {
  const history = useHistory();
  const category = useParams().category;
  const categoryID = useParams().categoryId;
  const { BASE_URL } = useContext(AppContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [sort, updateSort] = useState("");
  const [order, toggleOrder] = useState("asc");
  const [sortingIndex, updateSortingIndex] = useState();
  const [isDownload, setIsDownload] = useState(false);
  const [date, setDate] = useState("");
  const currentDate = new Date();
  const [formularyCheck, setFormularyCheck] = useState(false);
  const defaultDate = `&start_date=${convertDate(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    "-"
  )}&end_date=${convertDate(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ),
    "-"
  )}`;
  const [request, updateRequest] = useState(
    `${BASE_URL}/product_masters_category?category_id=${categoryID}${defaultDate}`
  );
  const { data, isLoaded } = useApiRequest(request);
  const [productSearch, setProductSearch] = useState("");
  const [manufacturerSearch, setManufacturerSearch] = useState("");
  const [universalMolecules, setUniversalMolecules] = useState("");
  const [moleculeGroup, setMoleculeGroup] = useState("");
  const [productMasterRequest] = useState(
    `${BASE_URL}/product_masters_category?category_id=${categoryID}`
  );
  const { data: productMasterData } = useApiRequest(productMasterRequest);

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setPageNumber(1);
    }
  };

  const filterObject = {
    0: "product_name+",
    1: "manufacturer+",
    2: "product_code+",
    3: "centres+",
    4: "mrp+",
    5: "src_static_rate+",
    6: "margin+",
    7: "purchase_qty+",
    8: "purchase_value+"
  };

  /*sorting */
  const headClickHandler = id => {
    updateSortingIndex(id);
    toggleOrder(order === "asc" ? "desc" : "asc");
    updateSort(filterObject[id] + order);
  };

  const filterUpdate = ({
    productSearch,
    manufacturerSearch,
    universalMolecules,
    moleculeGroup,
    date,
    formularyCheckbox
  }) => {
    setFormularyCheck(formularyCheckbox || false);
    productSearch ? setProductSearch(productSearch) : setProductSearch("");
    manufacturerSearch
      ? setManufacturerSearch(manufacturerSearch)
      : setManufacturerSearch("");
    universalMolecules
      ? setUniversalMolecules(universalMolecules)
      : setUniversalMolecules("");
    moleculeGroup
      ? setMoleculeGroup(moleculeGroup.split("=")[1])
      : setMoleculeGroup("");

    const parsedDate = date
      ? `&end_date=${date
          .split("&")[1]
          .split("=")[1]
          .split("/")
          .join("-")}&start_date=${date
          .split("&")[0]
          .split("=")[1]
          .split("/")
          .join("-")}`
      : defaultDate;
    setDate(parsedDate);
  };

  const getCategoryId = index =>
    data && data["product_masters_category"][index]["id"];

  const clickHandler = (row, index) =>
    history.push(`/hospital/product_masters/products/${getCategoryId(index)}`);

  const downloadHandler = async () =>
    downloadHandlerFn(
      `${BASE_URL}/product_masters_category.xlsx?&is_download=true&category_id=${categoryID}&q[product_name_cont]=${productSearch}&q[master_sku_universal_sku_universal_company_name_cont]=${manufacturerSearch}&molecule_id=${universalMolecules}&molecule_group_id=${moleculeGroup}&${date}${
        formularyCheck ? `&only_formulary_products=${formularyCheck}` : ""
      }`,
      setIsDownload
    );

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/product_masters_category?category_id=${categoryID}&page=${pageNumber}${productSearch &&
        `&q[product_name_cont]=${productSearch}`}&q[s]=${sort}&q[master_sku_universal_sku_universal_company_name_cont]=${manufacturerSearch}&molecule_id=${universalMolecules}&molecule_group_id=${moleculeGroup}&${date ||
        defaultDate}${
        formularyCheck ? `&only_formulary_products=${formularyCheck}` : ""
      }`
    );
  }, [
    pageNumber,
    sort,
    productSearch,
    manufacturerSearch,
    universalMolecules,
    moleculeGroup,
    date,
    formularyCheck
  ]);

  return (
    <>
      <Navigation selected={2} subNavIndex={[2, 0]} />
      <Primitives.Flex flexDirection="column">
        <Primitives.Flex
          backgroundColor={colors.text[3]}
          flex={1}
          px={5}
          py={2}
        >
          <SurgicalProducts fill={colors.text[0]} />
          <Primitives.Text color="oxford-blue" fontWeight={2} ml={1}>
            More {category[0] + category.slice(1).toLowerCase()} Products in
            market outside the master
          </Primitives.Text>
          <Primitives.Text
            color="background-green"
            fontWeight={2}
            ml={2}
            mr={1}
          >
            <Primitives.Text display="flex" height="20px">
              <Primitives.Flex minWidth="20px" mr="3px">
                {data && data["new_products"] && data["new_products"]}
              </Primitives.Flex>
              {" available".toUpperCase()}
              <Circle fill={colors.text[3]} height={4} width={4} />
            </Primitives.Text>
          </Primitives.Text>
          <Primitives.Flex
            border="1px solid #27ae60"
            px={2}
            borderRadius="20px"
            pt="4px"
            mt="-7px"
          >
            <Cta
              color="background-green"
              fontSize={3}
              hover={{ color: "background-green", textDecorationLine: false }}
              label="VIEW NOW"
              link={`/hospital/new_product_masters_category/${categoryID}/${category}`}
              textdecorationline={false}
            />
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex flexDirection="column" px={4} py={3}>
          <Primitives.Flex flexDirection="column" px={1}>
            <Primitives.Flex>
              <Primitives.Flex flexDirection="column" flex={9}>
                <Primitives.Text fontSize={5} fontWeight={2}>
                  {category} Product Master
                </Primitives.Text>
                <Primitives.Text display="flex" height="20px" lineHeight={3}>
                  <Primitives.Flex minWidth="10px" mr="3px">
                    {data && data["total_items"] && data["total_items"]}{" "}
                  </Primitives.Flex>
                  {"products".toUpperCase()}{" "}
                  <Circle fill={colors.text[3]} height={4} width={4} />
                </Primitives.Text>
              </Primitives.Flex>

              <Primitives.Flex flex={1}>
                <Primitives.Flex flexDirection="row">
                  <Primitives.Flex flex={1} justifyContent="flex-end">
                    {isDownload ? (
                      <Loading onlySpinner />
                    ) : (
                      <DownloadBtn clickHandler={downloadHandler} />
                    )}
                  </Primitives.Flex>
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex my={3}>
              <Filters
                config={{
                  productSearch: true,
                  manufacturerSearch: true,
                  universalMolecules: { productMasterData },
                  moleculeGroup: true,
                  date: true,
                  defaultDateRange: true,
                  formularyCheckbox: {
                    heading: "Show Formulary Products"
                  }
                }}
                filterUpdate={filterUpdate}
              />
            </Primitives.Flex>
          </Primitives.Flex>
          {isLoaded ? (
            <Primitives.Flex flexDirection="column" pl={1}>
              {data && data["total_items"] > 20 && (
                <Primitives.Text color={colors.text[1]} fontSize={1}>
                  Showing {(pageNumber - 1) * 20 + 1}-
                  {data["total_items"] > 20
                    ? pageNumber * 20 > data["total_items"]
                      ? data["total_items"]
                      : pageNumber * 20
                    : data["total_items"]}{" "}
                  of {data["total_items"]}
                  {" SKUs"}
                </Primitives.Text>
              )}
              {data && data["total_items"] ? (
                <Primitives.Flex
                  flexDirection="column"
                  width="100%"
                  overflowX="auto"
                  pb={5}
                >
                  <HorizontalScroll>
                    <Primitives.Flex width="150%">
                      <Table
                        borderBottom={{
                          head: `1px solid ${colors.text[3]}`,
                          row: `1px solid ${colors.text[3]}`
                        }}
                        border={`1px solid ${colors.text[3]}`}
                        clickHandler={clickHandler}
                        color={{ head: colors.text[1], row: colors.text[0] }}
                        cursor="pointer"
                        data={modifyData(data["product_masters_category"])}
                        column={column(data)}
                        headClickHandler={headClickHandler}
                        isLakhsCroresNeeded={true}
                        pb={{ headContainer: 2, row: 2 }}
                        pt={{ headContainer: 2, row: 2 }}
                        sorting
                        sortingIndex={sortingIndex}
                        sortingType={order === "asc" ? "desc" : "asc"}
                      />
                    </Primitives.Flex>
                  </HorizontalScroll>
                </Primitives.Flex>
              ) : (
                <NoResults />
              )}
            </Primitives.Flex>
          ) : (
            <Loading />
          )}
          {isLoaded && data && data["total_items"] > 20 && (
            <Primitives.Flex
              backgroundColor={colors.accent[0]}
              bottom={0}
              position="fixed"
              pr={3}
              right={0}
              width="100vw"
              justifyContent="flex-end"
              zIndex={2}
            >
              <Pagination
                changeHandler={paginationChangeHandler}
                forcePage={pageNumber - 1}
                marginPagesDisplayed={1}
                pageCount={parseInt(data["total_items"]) / 20}
              />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default CategoryProductTable;
