import React from "react";
import { node, number, string } from "prop-types";
import Button from "../button";
import Primitives from "../primitives";

const propTypes = {
  /** Background color of Nav Item */
  backgroundColor: string,
  /** Border color of Nav Item */
  borderColor: string,
  /** Cta button label */
  cta: string,
  /** Text color of the Nav Item  */
  color: string,
  /** What Font family should Nav Item text use*/
  fontFamily: string,
  /** Nav Item text that should be visible */
  label: node,
  /** line height for texts in  Nav Item  */
  lineHeight: number
};

const defaultProps = {
  color: "white",
  fontFamily: "Lato",
  lineHeight: 4.3
};

const AlertNotification = ({
  backgroundColor,
  cta,
  color,
  fontFamily,
  label,
  lineHeight
}) => (
  <Primitives.Flex
    backgroundColor={backgroundColor}
    color={color}
    fontFamily={fontFamily}
    fontSize={2}
    fontWeight={1}
    justifyContent="center"
    lineHeight={lineHeight}
  >
    <Primitives.Flex>{label}</Primitives.Flex>
    {cta && (
      <Primitives.Flex ml={40} pt={8} pb={12}>
        <Button
          backgroundColor="transparent"
          border="1px solid"
          borderColor="white"
          color="white"
          fontWeight={2}
          label="Try Again"
          lineHeight={4}
          px={2}
        />
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);

AlertNotification.propTypes = propTypes;
AlertNotification.defaultProps = defaultProps;
export default AlertNotification;
