import React from "react";

const joystick = ({ ...props }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 12.9V20H11V12.9C8.71776 12.4367 7 10.419 7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.419 15.2822 12.4367 13 12.9ZM9 15.1573V17.1844C6.06718 17.5505 4 18.3867 4 19C4 19.807 7.57914 21 12 21C16.4209 21 20 19.807 20 19C20 18.3867 17.9328 17.5505 15 17.1844V15.1573C19.0559 15.6017 22 16.9678 22 19C22 21.5068 17.5203 23 12 23C6.47973 23 2 21.5068 2 19C2 16.9678 4.94412 15.6017 9 15.1573ZM15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z"
      fill="inherit"
    />
  </svg>
);

export default joystick;
