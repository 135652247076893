import React, { useState } from "react";
import Button from "../../../../components/button";
import Download from "../../../../components/glyphs/elements/download";
import { colors } from "../../../../components/utils/theme";
import DownloadModal from "../download-modal";
import Primitives from "../../../../components/primitives";
import { array, string } from "prop-types";

const propTypes = {
  rateContract: string,
  supportingDocs: array,
  url: string
};

const DownloadBtn = ({ rateContract, supportingDocs, url }) => {
  const [showDownloadModal, toggleDownloadModal] = useState(false);
  return (
    <Primitives.Flex>
      <Button
        active={{
          color: colors.primary[3],
          borderColor: "inherit"
        }}
        backgroundColor="none!important"
        clickHandler={() => {
          toggleDownloadModal(true);
        }}
        color={colors.primary[3]}
        fontWeight={2}
        glyph={<Download height={20} width={20} />}
        hover={{
          color: colors.primary[3]
        }}
        label="Download"
        lineHeight={3}
      />
      {showDownloadModal && (
        <DownloadModal
          downloadUrl={url}
          rateContract={rateContract}
          supportingDocs={supportingDocs}
          toggleDownloadModal={toggleDownloadModal}
        />
      )}
    </Primitives.Flex>
  );
};

DownloadBtn.propTypes = propTypes;
export default DownloadBtn;
