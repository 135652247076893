import React, { useState } from "react";
import { array, string } from "prop-types";
import Primitives from "../../components/primitives";
import Table from "../../components/table-two";
import NoResults from "../../components/no-results";
import { colors } from "../../components/utils/theme";
import DownloadBtn from "../download";
import Loading from "../../components/loading";
import { reportStyles } from "../report-styles";
import downloadHandlerFn from "../../components/utils/download-handler";

const propTypes = {
  cursor: string,
  data: array,
  date: string,
  downloadLink: string,
  column: array,
  report: string,
  title: string,
  width: string
};

const defaultProps = {
  width: "100%"
};

const downloadHandler = async (setIsDownload, downloadLink) =>
  downloadHandlerFn(downloadLink, setIsDownload);

const reportStyle = {
  margin: { ...reportStyles["marginReports"] },
  savings: { ...reportStyles["savingsReports"] }
};

const GenericSavingsMarginsReport = ({
  cursor,
  data,
  date,
  downloadLink,
  column,
  report,
  title,
  width
}) => {
  const [isDownload, setIsDownload] = useState(false);
  return (
    <Primitives.Flex flexDirection="column" width={width}>
      <Primitives.Flex mb={20}>
        <Primitives.Flex color={colors.text[0]} fontSize={5} fontWeight={2}>
          <Primitives.Text>
            {title}
            {date && (
              <Primitives.Text
                color={colors.text[1]}
                fontSize="12px"
                fontWeight={1}
                ml={1}
              >
                ({date.split("&")[0].split("=")[1]} -{" "}
                {date.split("&")[1].split("=")[1]})
              </Primitives.Text>
            )}
          </Primitives.Text>
        </Primitives.Flex>
        <Primitives.Flex flex={1} justifyContent="flex-end">
          {data && data.length > 0 && (
            <Primitives.Flex flex={1} justifyContent="flex-end">
              {isDownload ? (
                <Loading onlySpinner />
              ) : (
                <DownloadBtn
                  clickHandler={() => {
                    downloadHandler(setIsDownload, downloadLink);
                  }}
                />
              )}
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
      {data && data.length > 0 ? (
        <Table
          cursor={cursor}
          column={column}
          data={data}
          {...reportStyle[report]}
        />
      ) : (
        <Primitives.Flex m="auto">
          <NoResults small />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

GenericSavingsMarginsReport.propTypes = propTypes;
GenericSavingsMarginsReport.defaultProps = defaultProps;
export default GenericSavingsMarginsReport;
