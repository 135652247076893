import React from "react";
import TrackOrder from "../../../../lib/track-order";
import { colors } from "../../../../components/utils/theme";
import useApiRequest from "../../../../hooks/useApiRequest";
import { BASE_URL } from "../../../../services/config";
import { string, oneOfType, number } from "prop-types";

const propTypes = {
  soItemid: oneOfType([string, number]),
  salesInvoiceId: oneOfType([string, number])
};

const modifyData = data =>
  data.map(item => ({ title: item.status, dateTime: item.time }));

const TrackItem = ({ soItemid, salesInvoiceId }) => {
  const { data } = useApiRequest(
    `${BASE_URL}/orders/track_items?so_item_id=${soItemid}&sales_invoice_id=${salesInvoiceId}`
  );
  return (
    data?.tracking_details?.length > 0 && (
      <TrackOrder
        color={[colors.text[1], colors["oxford-blue"]]}
        orders={modifyData(data.tracking_details)}
      />
    )
  );
};
TrackItem.propTypes = propTypes;
export default TrackItem;
