import React from "react";
import { oneOf } from "prop-types";
const propTypes = {
  arrowType: oneOf(["downward", "upward"])
};
const defaultProps = {
  arrowType: "upward"
};
const CircleWithUpwardOrDownwardArrow = ({ arrowType, ...props }) => (
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {arrowType === "upward" ? (
      <path
        d="M2.71443 0.107514L0.143101 2.39299C-0.032374 2.54016 -0.0480189 2.83103 0.104738 3.00161C0.257494 3.17219 0.548853 3.18944 0.714459 3.0313L2.57155 1.37969V6.99962C2.57155 7.23627 2.76347 7.4282 3.00013 7.4282C3.2368 7.4282 3.42871 7.23638 3.42871 6.99962V1.37969L5.28581 3.0313C5.45142 3.18944 5.74229 3.16812 5.89504 2.99743C6.0478 2.82683 6.03265 2.54015 5.85718 2.39299L3.28585 0.107514C3.07799 -0.0417552 2.89135 -0.0297965 2.7145 0.107514H2.71443Z"
        fill="white"
      />
    ) : (
      <path
        d="M3.28557 7.89249L5.8569 5.60701C6.03237 5.45984 6.04802 5.16897 5.89526 4.99839C5.74251 4.82781 5.45115 4.81056 5.28554 4.9687L3.42845 6.62031L3.42845 1.00038C3.42845 0.763727 3.23653 0.571803 2.99987 0.571803C2.7632 0.571803 2.57129 0.76362 2.57129 1.00038L2.57129 6.62031L0.714192 4.9687C0.548581 4.81056 0.257705 4.83188 0.104956 5.00257C-0.0478003 5.17317 -0.0326531 5.45985 0.142822 5.60701L2.71415 7.89249C2.92201 8.04175 3.10865 8.0298 3.2855 7.89249L3.28557 7.89249Z"
        fill="white"
      />
    )}
  </svg>
);

CircleWithUpwardOrDownwardArrow.propTypes = propTypes;
CircleWithUpwardOrDownwardArrow.defaultProps = defaultProps;
export default CircleWithUpwardOrDownwardArrow;
