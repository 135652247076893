import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import BarChart from "../../../../components/charts/bar";
import { colors } from "../../../../components/utils/theme";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import Loading from "../../../../components/loading";
import DownloadButton from "../download-button";
import { string } from "prop-types";

const propTypes = {
  quickSearch: string,
  hospital: string
};

const yLabel = value => {
  return `₹${value}`;
};

const PurchaseTrends = ({ quickSearch, hospital }) => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/overview/purchase_trends?`
  );
  const { isLoaded, data } = useApiRequest(request);

  const toolTipTitle = tooltipItem => {
    return `Purchase Amount : ₹ ${tooltipItem.yLabel}`;
  };

  useEffect(() => {
    quickSearch || hospital
      ? updateRequest(
          `${BASE_URL}/overview/purchase_trends?${quickSearch}&hospital_entity_id=${hospital}`
        )
      : updateRequest(`${BASE_URL}/overview/purchase_trends?${quickSearch}`);
  }, [quickSearch, hospital]);

  return (
    <Primitives.Flex flexDirection="column">
      {isLoaded ? (
        <Primitives.Flex>
          {data && data["values"] && data["values"].length > 0 && (
            <Primitives.Flex
              flex={1}
              flexDirection="column"
              height="50vh"
              px={2}
              py={3}
              width="45vw"
            >
              <Primitives.Flex justifyContent="space-between" width="100%">
                <Primitives.Text fontSize={4} fontWeight={2}>
                  Purchase Trends
                </Primitives.Text>
                <DownloadButton
                  downloadCtaProps={request
                    .split("?")
                    .join(".xlsx?is_download=true&")}
                />
              </Primitives.Flex>
              <BarChart
                labels={data && data["dates"]}
                legend={{
                  display: true
                }}
                toolTipTitle={toolTipTitle}
                values={[
                  {
                    backgroundColor: colors.primary[4],
                    data: data && data["values"],
                    label: "Purchase Amount"
                  }
                ]}
                yLabel={yLabel}
              />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      ) : (
        <Primitives.Flex
          justifyContent="center"
          height="50vh"
          mt="25%"
          width="47vw"
        >
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

PurchaseTrends.propTypes = propTypes;

export default PurchaseTrends;
