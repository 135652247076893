import React, { useState, useEffect, useContext } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Back from "../../../components/glyphs/elements/back";
import Table from "../../../components/table-two/TableComponent";
import Button from "../../../components/button";
import Plus from "../../../components/glyphs/elements/plus";
import Loading from "../../../components/loading";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Input from "../../../components/primitives/input";
import NoResults from "../../../components/no-results";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import InputButton from "../input-button";
import PurchaseOrderService from "../../../services/create-new-purchase-order";
import Navigation from "../../../lib/navigation";
import FreeQuantity from "../free-quantity";
import SectionHeader from "./sub-header";
import { indianNumberFormat } from "../../../components/utils";
import AddDeliveryCenter from "./add-delivery-center";
import imageStorage from "../../../utils/image-storage";
const buttonProps = {
  active: {
    backgroundColor: colors.primary[6]
  },
  height: "34",
  justifyContent: "center",
  lineHeight: "4",
  width: "12vw",
  fontWeight: "2"
};

const loaderFlex = () => (
  <Primitives.Flex height={34} justifyContent="center" width="12vw">
    <Loading onlySpinner />
  </Primitives.Flex>
);

const totalItem = (header, data) => (
  <Primitives.Text px={3}>
    {header}
    <Primitives.Text fontWeight={2}> {data}</Primitives.Text>
  </Primitives.Text>
);
const column = isNewUser => [
  {
    heading: "PRODUCT NAME",
    type: "string",
    width: 2
  },
  {
    heading: "MANUFACTURER",
    type: "string",
    width: 2
  },
  {
    heading: "CATEGORY",
    type: "string",
    width: 1
  },
  {
    heading: "PACKAGING",
    type: "string",
    width: 0.6
  },
  {
    heading: "MRP",
    type: "number",
    align: "flex-end",
    width: 0.8
  },
  {
    heading: "DISCOUNT",
    type: "string",
    align: "flex-end",
    width: 1
  },
  {
    heading: "RATE",
    type: "number",
    align: "flex-end",
    width: 1
  },
  {
    heading: " ",
    type: "string",
    align: "flex-end",
    width: 0.7
  },
  {
    heading: "QTY",
    type: "number",
    align: "flex-end",
    width: 1
  },
  {
    ...(!isNewUser && {
      heading: "FREE QTY",
      type: "number",
      align: "flex-end",
      width: 0.7
    })
  },
  {
    heading: "TOTAL AMT",
    type: "string",
    align: "flex-end",
    width: 1
  },
  {
    heading: " ",
    type: "object",
    align: "flex-end",
    width: 1
  }
];

const alignment = data => {
  return <Primitives.Flex alignItems="center">{data}</Primitives.Flex>;
};

const modifyData = (data, changeHandler, removeLineItem, isNewUser) =>
  data
    .filter(item => item.quantity !== null)
    .map((item, index) => ({
      "PRODUCT NAME": alignment(item["product_name"]),
      MANUFACTURER: alignment(item["company_name"]),
      CATEGORY: alignment(item["category"]),
      PACKAGING: alignment(item["packaging"]),
      MRP: alignment(`₹ ${item["mrp"]}`),
      DISCOUNT: alignment(
        item["discount_percentage"] ? `${item["discount_percentage"]} %` : `0 %`
      ),
      RATE: alignment(
        item["discount_percentage"] && item["rate"] > 0
          ? `₹ ${(
              item["rate"] -
              (item["discount_percentage"] / 100) * item["rate"]
            ).toFixed(2)}`
          : item["rate"]
          ? `₹ ${item["rate"]}`
          : `₹ 0`
      ),
      " ": (
        <Primitives.Flex>
          {item["discount_percentage"] ? (
            <Primitives.Flex flexDirection="column" justifyContent="center">
              <Primitives.Text
                color={colors.text[1]}
                fontWeight={2}
                textDecoration="line-through"
              >{`₹${item["rate"]}`}</Primitives.Text>
              <Primitives.Text color={colors.primary[5]}>
                {item["discount_percentage"]}% off
              </Primitives.Text>
            </Primitives.Flex>
          ) : (
            ""
          )}
        </Primitives.Flex>
      ),
      QTY: <InputButton inputData={item} changeHandler={changeHandler} />,
      ...(!isNewUser && { "FREE QTY": alignment(FreeQuantity(item)) }),
      "TOTAL AMT": (
        <Primitives.Flex
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          {item["quantity"]
            ? `₹${indianNumberFormat(
                item["quantity"] *
                  (
                    item["rate"] -
                    (item["discount_percentage"]
                      ? (item["discount_percentage"] / 100) * item["rate"]
                      : 0)
                  ).toFixed(2)
              )}`
            : 0}
        </Primitives.Flex>
      ),
      "": (
        <Button
          active={{
            color: colors.primary[3]
          }}
          backgroundColor="none!important"
          clickHandler={() => removeLineItem(item, index)}
          color={colors.primary[3]}
          fontWeight={2}
          hover={{
            color: colors.primary[3]
          }}
          label="Remove"
          lineHeight={4}
          px={2}
        />
      )
    }));

const PurchaseOrderCheckout = () => {
  const history = useHistory();
  let location = useLocation();
  const hospitalId = useParams().hospitalId;
  const poNumber = useParams().poNumber;
  const { BASE_URL } = useContext(AppContext);
  const request = `${BASE_URL}/hospital_address?id=${hospitalId}`;
  const isNewUser = localStorage.getItem("newUser") === "true";
  const { data } = useApiRequest(request);
  const [summaryData, setSummaryData] = useState();
  const [totalValue, setTotalValue] = useState({
    totalAmount: 0,
    totalQuantity: 0,
    totalSavings: 0
  });
  const [loadedFile, setLoadedFile] = useState({
    loaded: false,
    draftLoaded: false,
    deleteLoaded: false
  });

  const [uploadedFile, setUploadedFile] = useState(null);
  const po = poNumber;
  const [showModal, setShowModal] = useState(false);
  const [deliveryData, setDeliveryData] = useState({});
  const localData = localStorage.getItem("summary");
  const localDeliveryData = localStorage.getItem("delivery_data");
  const hospital = JSON.parse(localStorage.getItem("hospital"));
  const [isDeliveryDataValid, setIsDeliveryDataValid] = useState(false);
  const [dlFile, setDlFile] = useState(false);

  useEffect(() => {
    setSummaryData(JSON.parse(localData));
    localDeliveryData && setDeliveryData(JSON.parse(localDeliveryData));
    const dlFile = imageStorage.retrieveAndDecodeFile("dl_file");
    const gstFile = imageStorage.retrieveAndDecodeFile("gst_file");
    const sFNumbers =
      JSON.parse(localDeliveryData) &&
      JSON.parse(localDeliveryData)["supporting_files[]"] &&
      Object.keys(JSON.parse(localDeliveryData)["supporting_files[]"]);
    const supportingFiles =
      sFNumbers &&
      sFNumbers.map((_, index) =>
        imageStorage.retrieveAndDecodeFile("supporting_files[]" + index)
      );
    setDeliveryData({
      ...JSON.parse(localDeliveryData),
      dl_file: dlFile,
      gst_file: gstFile,
      "supporting_files[]": supportingFiles
    });
  }, []);

  useEffect(() => {
    location.state &&
      location.state.file &&
      setUploadedFile(location.state.file);
  }, [location]);

  useEffect(() => {
    let totalQty = 0;
    let totalAmt = 0;
    let totalSavings = 0;
    summaryData &&
      summaryData.map((item, index) => {
        item["id"] = item["id"] === null ? index : item["id"];
        totalQty += item.quantity;
        totalAmt +=
          item["discount_percentage"] && item["rate"] > 0
            ? item["quantity"] *
              (
                item["rate"] -
                (item["discount_percentage"] / 100) * item["rate"]
              ).toFixed(2)
            : item.quantity * item["rate"];
        totalSavings += Math.abs(
          item["discount_percentage"] && item["rate"] > 0
            ? item["quantity"] *
                (item["rate"] -
                  (item["discount_percentage"] / 100) * item["rate"]) -
                item["rate"] * item.quantity
            : 0
        );
        item["qty"] = item["quantity"];
        item[isNewUser ? "universal_sku_id" : "master_sku_id"] =
          item.id === index ? null : item.id;
      });
    setTotalValue({
      totalQuantity: totalQty,
      totalAmount: totalAmt,
      totalSavings: totalSavings
    });
  }, [summaryData]);

  const changeHandler = (item, e) => {
    let updatedValue;
    let newQuantity;
    if (parseInt(e.target.value) >= 1) {
      updatedValue = parseInt(e.target.value);
    }

    const newSummaryData = summaryData.map(object =>
      object.id === item.id
        ? { ...object, qty: updatedValue, quantity: updatedValue }
        : object
    );
    let newTotal =
      totalValue.totalAmount -
      parseInt(item["quantity"]) * parseInt(item["rate"]) +
      updatedValue * parseInt(item["rate"]);
    newQuantity =
      totalValue.totalQuantity - parseInt(item["quantity"]) + updatedValue;

    setTotalValue({
      ...totalValue,
      totalAmount: isNaN(newTotal) ? 0 : newTotal,
      totalQuantity: isNaN(newQuantity) ? 0 : newQuantity
    });
    localStorage.setItem("summary", JSON.stringify(newSummaryData));
    setSummaryData(newSummaryData);
  };

  const removeLineItem = (item, index) => {
    const array = JSON.parse(localData);
    array.splice(index, 1);
    localStorage.setItem("summary", JSON.stringify(array));
    if (summaryData.length > 1) {
      const newSummaryData = [...summaryData];
      newSummaryData.splice(index, 1);
      setSummaryData(newSummaryData);
      let deductedTotal =
        totalValue.totalAmount - parseInt(item["rate"]) * parseInt(item["qty"]);
      let deductedQuantity = totalValue.totalQuantity - parseInt(item["qty"]);
      setTotalValue({
        ...totalValue,
        totalAmount: isNaN(deductedTotal) ? 0 : deductedTotal,
        totalQuantity: isNaN(deductedQuantity) ? 0 : deductedQuantity
      });
    } else {
      setSummaryData([]);
      setTotalValue({ ...totalValue, totalAmount: 0, totalQuantity: 0 });
    }
  };

  const deleteDraft = async () => {
    setLoadedFile({ ...loadedFile, deleteLoaded: true });
    const orderId = JSON.parse(localStorage.getItem("sales_order_id"));
    const res = await PurchaseOrderService.deleteDraft(orderId);

    if (res["status"] === "success") {
      setLoadedFile({ ...loadedFile, deleteLoaded: false });
      const token = localStorage.getItem("token");
      localStorage.setItem("token", token);
      history.push({
        pathname: `/hospital/purchase_orders`
      });
      history.go(0);
    }
  };

  const handleCheckoutBack = () => {
    history.push({
      pathname: `/hospital/purchase_orders`
    });
  };

  const postData = async (url, data, reqType) => {
    reqType === "draft"
      ? setLoadedFile({ ...loadedFile, loaded: true })
      : setLoadedFile({ ...loadedFile, draftLoaded: true });
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    !isNewUser && myHeaders.append("Content-Type", "application/json");
    const formData = Object.keys(data).reduce((formData, key) => {
      if (key === "sb_sales_order_items_attributes") {
        data[key].map((item, i) => {
          Object.keys(item).map(item_key => {
            formData.append(`${key}[${i}][${item_key}]`, item[item_key]);
          });
        });
      } else if (key === "supporting_files[]") {
        if (data[key] && Array.from(data[key]).length > 0) {
          Array.from(data[key]).forEach(element => {
            formData.append(key, element);
          });
        } else {
          data[key] && formData.append(key, data[key]);
        }
      } else {
        formData.append(key, data[key]);
      }
      return formData;
    }, new FormData());
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: isNewUser ? "PUT" : "POST",
      headers: myHeaders,
      body: isNewUser ? formData : raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(url, requestOptions);
      const json = await response.json();

      if (json["status"] === "success" || json["success"] === true) {
        const keysToRemove = ["hospital", "po_number"];
        localStorage.getItem("newUser") === "true" && keysToRemove.splice(0, 1);
        keysToRemove.map(item => localStorage.removeItem(item));
        localStorage.setItem("summary", "[]");
        const isNewUser = localStorage.getItem("newUser") === "true";
        window.location.href = isNewUser
          ? `/hospital/thank_you/${json.po_number}`
          : "/hospital/orders";
      }
      setLoadedFile({ ...loadedFile, loaded: false });
    } catch (error) {
      console.log(error);
    }
  };

  const getReq = (reqType, orderId) => {
    if (reqType === "put") {
      return `${BASE_URL}/sb_sales_orders#update`;
    } else if (reqType === "post") {
      return `${BASE_URL}/purchase_orders/create_draft`;
    } else {
      return `${BASE_URL}/purchase_orders/update_sales_order?id=${orderId}`;
    }
  };
  const getObject = (isNewUser, tenantId, status, sales_order_items) => {
    if (isNewUser) {
      return {
        id: localStorage.getItem("id"),
        organisation_name: deliveryData.orgName,
        full_name: deliveryData.fullName,
        delivery_phone_number: deliveryData.phoneNumber,
        delivery_address_line_1: deliveryData.addressLine1,
        delivery_address_line_2: deliveryData.addressLine2,
        universal_city_id: deliveryData.cityId,
        dl_file: deliveryData.dl_file,
        delivery_dl_number: deliveryData.dlNumber,
        ...(deliveryData.gst_file && { gst_file: deliveryData.gst_file }),
        ...(deliveryData.gstNumber && {
          delivery_gst_number: deliveryData.gstNumber
        }),
        ...(deliveryData["supporting_files[]"] && {
          "supporting_files[]": deliveryData["supporting_files[]"]
        }),
        pincode: deliveryData.pincode,
        sb_sales_order_items_attributes: sales_order_items
      };
    }
    return {
      hospital_id: hospitalId,
      tenant_id: tenantId,
      sales_order: {
        buyer_party_id: hospitalId,
        buyer_party_type: "Hospital",
        po_number: po,
        status: status,
        sales_order_items_attributes: sales_order_items
      }
    };
  };

  const handlePlaceOrder = async (status, reqType) => {
    const orderId = JSON.parse(localStorage.getItem("sales_order_id"));
    const tenantId = JSON.parse(localStorage.getItem("distributor"))[1];
    const isNewUser = localStorage.getItem("newUser") === "true";

    let sales_order_items = {};

    if (isNewUser) {
      sales_order_items = summaryData
        .filter(item => item.quantity !== null)
        .map(item => {
          return {
            rate: item.rate,
            mrp: item.mrp,
            quantity: item.quantity,
            universal_sku_id: item.universal_sku_id
          };
        });
    } else {
      summaryData
        .filter(item => item.quantity !== null)
        .map((item, index) => {
          sales_order_items[item["id"]] = summaryData[index];
          delete sales_order_items[item["id"]]["id"];
        });
    }
    const req = getReq(!isNewUser ? reqType : "put", orderId);
    const object = getObject(isNewUser, tenantId, status, sales_order_items);
    if (uploadedFile instanceof File) {
      status === "draft"
        ? setLoadedFile({ ...loadedFile, loaded: true })
        : setLoadedFile({ ...loadedFile, draftLoaded: false });

      var formdata = new FormData();
      formdata.append("file", uploadedFile, uploadedFile.name);
      formdata.append("body", JSON.stringify(object));

      const res = await PurchaseOrderService.postDataWithFile(formdata);
      if (res["status"] === "success") {
        setLoadedFile({ ...loadedFile, loaded: false });
        setLoadedFile({ ...loadedFile, draftLoaded: false });
        const keysToRemove = ["hospital", "po_number"];
        keysToRemove.map(item => localStorage.removeItem(item));
        localStorage.setItem("summary", "[]");
        history.push("/hospital/orders");
      }
    } else {
      postData(req, object, status);
    }
  };
  return (
    <>
      <AddDeliveryCenter
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        setData={setDeliveryData}
        setModal={setShowModal}
        isValid={isDeliveryDataValid}
        setIsValid={setIsDeliveryDataValid}
        setDlFile={setDlFile}
        dlFile={dlFile}
      />
      <Navigation selected={1} />
      <Primitives.Flex flexDirection="column">
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flex={1}
          flexDirection="column"
          px={5}
          py={3}
        >
          <Primitives.Flex>
            <Button
              active={{ backgroundColor: "none!important", color: "inherit" }}
              backgroundColor="none!important"
              clickHandler={handleCheckoutBack}
              glyph={<Back fill={colors.text[0]} />}
              py="3px"
            />
            <Primitives.Text fontSize={5} fontWeight={2} px={1}>
              Purchase Order Summary
            </Primitives.Text>
          </Primitives.Flex>

          <Primitives.Flex px={4} py={4}>
            <SectionHeader
              header="PO No."
              data={
                <Primitives.Text color="#1E2B51" fontSize={1}>
                  {po.split("-").join("/")}
                </Primitives.Text>
              }
              flex="0.15"
            />
            <SectionHeader
              header="Dated"
              lineHeight={2}
              data={
                localStorage.getItem("dated") ? (
                  <Primitives.Text color="#1E2B51" fontSize={1}>
                    {JSON.parse(localStorage.getItem("dated"))
                      .split("/")
                      .join("-")}
                  </Primitives.Text>
                ) : (
                  <Primitives.Text color="#1E2B51" fontSize={1}>
                    {new Date().toISOString().split("T")[0]}
                  </Primitives.Text>
                )
              }
              flex="0.1"
            />
            {!isNewUser && (
              <SectionHeader
                header="For Hospital"
                data={<Input py={1} px={1} disabled value={hospital[0]} />}
                flex="0.15"
              />
            )}
            {((!isNewUser && data !== []) || isDeliveryDataValid) && (
              <SectionHeader
                header="Shipping Address"
                data={
                  !isNewUser && data && data["address"] ? (
                    <Primitives.Text color="#1E2B51" fontSize={1}>
                      {data["address"]}
                    </Primitives.Text>
                  ) : (
                    <>
                      <Primitives.Text color="#1E2B51" fontSize={1}>
                        {`${deliveryData.addressLine1}, ${deliveryData.addressLine2}, ${deliveryData.city}, ${deliveryData.state}`}
                      </Primitives.Text>
                      <Button
                        active={{
                          color: colors.primary[3]
                        }}
                        backgroundColor="none!important"
                        clickHandler={() => setShowModal(true)}
                        color={colors.primary[3]}
                        fontWeight={2}
                        hover={{
                          color: colors.primary[3]
                        }}
                        label="Change"
                        lineHeight={4}
                      />
                    </>
                  )
                }
                flex="0.22"
              />
            )}
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex flexDirection="column" px={5} py={3}>
          {summaryData && summaryData.length ? (
            <Table
              color={{ head: colors.text[1], row: colors.text[0] }}
              column={column(isNewUser)}
              data={modifyData(
                summaryData,
                changeHandler,
                removeLineItem,
                isNewUser
              )}
            />
          ) : (
            <NoResults />
          )}

          <Primitives.Flex
            alignItems="center"
            justifyContent="space-between"
            py={4}
          >
            <Button
              active={{
                backgroundColor: "white",
                borderColor: colors.primary[3],
                color: colors.primary[3]
              }}
              backgroundColor="white"
              border="1px solid"
              clickHandler={handleCheckoutBack}
              color={colors.primary[3]}
              fontWeight={2}
              glyph={<Plus fill={colors.primary[3]} />}
              hover={{ backgroundColor: colors.primary[3], color: "white" }}
              label={<Primitives.Text px={2}>Add Item</Primitives.Text>}
              lineHeight={3}
              px={2}
              py={1}
            />
            {summaryData && summaryData.length > 0 && (
              <Primitives.Flex flex={1} justifyContent="center">
                {totalItem(
                  "Total Products: ",
                  summaryData.length && indianNumberFormat(summaryData.length)
                )}
                {totalItem(
                  "Total Qty: ",
                  !isNaN(totalValue.totalQuantity) &&
                    indianNumberFormat(totalValue.totalQuantity)
                )}
                {totalItem(
                  "Total Amount: ",
                  totalValue?.totalAmount
                    ? ` ₹${indianNumberFormat(
                        totalValue.totalAmount.toFixed(2)
                      )}`
                    : ""
                )}
                {!isNewUser &&
                  totalItem(
                    "Total Savings: ",
                    `₹${indianNumberFormat(totalValue.totalSavings.toFixed(2))}`
                  )}
              </Primitives.Flex>
            )}
            {summaryData && summaryData.length > 0 && (
              <Primitives.Flex>
                <Primitives.Flex mx={4} flex={1}>
                  {!isNewUser && (
                    <Button
                      backgroundColor="background-light"
                      clickHandler={
                        !localStorage.getItem("sales_order_id")
                          ? () => handlePlaceOrder("pre_draft", "post")
                          : deleteDraft
                      }
                      color={colors.text[1]}
                      hover={{
                        backgroundColor: colors.text[1],
                        color: colors.text[0],
                        stroke: colors.text[0]
                      }}
                      label={
                        loadedFile.draftLoaded || loadedFile.deleteLoaded
                          ? loaderFlex()
                          : !localStorage.getItem("sales_order_id")
                          ? "Save as Draft"
                          : "Delete Draft"
                      }
                      {...buttonProps}
                    />
                  )}
                </Primitives.Flex>
                <Button
                  backgroundColor={colors.primary[3]}
                  clickHandler={() => {
                    isNewUser && !isDeliveryDataValid
                      ? setShowModal(true)
                      : !localStorage.getItem("sales_order_id")
                      ? handlePlaceOrder("draft", "post")
                      : handlePlaceOrder("draft", "update");
                  }}
                  color="white"
                  hover={{ backgroundColor: colors.primary[6], color: "white" }}
                  label={
                    loadedFile.loaded
                      ? loaderFlex()
                      : isNewUser && !isDeliveryDataValid
                      ? "Add Shipping Address"
                      : "Confirm and Place Order"
                  }
                  {...buttonProps}
                />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default PurchaseOrderCheckout;
