import React, { useEffect, useState } from "react";
import { bool, func, number, string } from "prop-types";
import Input from "../../../components/input";
import Primitives from "../../../components/primitives";
import ThrottleSearch from "../throttle-search";

const propTypes = {
  clearFilter: bool,
  config: string,
  getClickEvent: func,
  getConfig: func,
  getSearchValue: func,
  margin: bool,
  throttle: bool,
  width: string,
  mr: number,
  heading: string
};

const defaultProps = {
  margin: true,
  mr: 20
};

const GenericSearch = ({
  clearFilter,
  config,
  getClickEvent,
  getConfig,
  getSearchValue,
  mr,
  throttle,
  heading
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    getSearchValue(search);
    getConfig(config);
  }, [search]);

  useEffect(() => {
    !clearFilter && setSearch("");
  }, [clearFilter]);

  const placeHolderObject = {
    hospitalSearch: "Hospital Name",
    invoiceSearch: "Invoice No.",
    moleculeSearch: "Molecule",
    skuNameSearch: "SKU Name",
    poNumberSearch: "PO Number",
    productSearch: "Product Name",
    orderSearch: "Order No.",
    manufacturerSearch: "Manufacturer Name",
    orderListingSearch: "Search by PO",
    orderListingProductSearch: "Search by product name",
    manufacturerThrottleSearch: "Search by Manufacturer"
  };

  return (
    <Primitives.Flex mr={mr} flexDirection="column">
      {heading && (
        <Primitives.Text color="#333333" fontSize="12px" mb={1}>
          {heading}
        </Primitives.Text>
      )}
      {throttle ? (
        <ThrottleSearch
          clearFilter={clearFilter}
          config={config}
          selectedValues={data => setSearch(data)}
          placeholder={placeHolderObject[config]}
        />
      ) : (
        <Input
          active={search.length > 0}
          changeHandler={e => setSearch(e.target.value)}
          clickHandler={() => getClickEvent()}
          glyph="search"
          placeholder={placeHolderObject[config]}
          type="text"
          value={search}
        />
      )}
    </Primitives.Flex>
  );
};

GenericSearch.propTypes = propTypes;
GenericSearch.defaultProps = defaultProps;
export default GenericSearch;
