import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import LineChart from "../../../../components/charts/line";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import Loading from "../../../../components/loading";
import DownloadButton from "../download-button";
import { string } from "prop-types";

const propTypes = {
  quickSearch: string,
  hospital: string
};

const FulFillmentRate = ({ quickSearch, hospital }) => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(`${BASE_URL}/overview/otif?`);
  const { isLoaded, data } = useApiRequest(request);

  useEffect(() => {
    quickSearch || hospital
      ? updateRequest(
          `${BASE_URL}/overview/otif?${quickSearch}&hospital_entity_id=${hospital}`
        )
      : updateRequest(`${BASE_URL}/overview/otif?`);
  }, [quickSearch, hospital]);

  return (
    <Primitives.Flex flexDirection="column" pl={4}>
      {isLoaded ? (
        <Primitives.Flex>
          {data && data["values"] && data["values"].length > 0 && (
            <Primitives.Flex
              flex={1}
              flexDirection="column"
              height="50%"
              py={3}
            >
              <Primitives.Flex
                justifyContent="space-between"
                my={2}
                width="100%"
              >
                <Primitives.Text fontSize={4} fontWeight={2}>
                  Fulfillment Rate
                </Primitives.Text>
                <DownloadButton
                  downloadCtaProps={request
                    .split("?")
                    .join(".xlsx?is_download=true&")}
                />
              </Primitives.Flex>
              <Primitives.Flex>
                <LineChart
                  backgroundColor={colors.accent[1]}
                  borderColor={colors.accent[3]}
                  chartData={data && data["values"]}
                  height={320}
                  label="Fullfillment %"
                  labels={data && data["dates"]}
                  legend={true}
                  max={100}
                  width={660}
                  yAxesLabels={true}
                />
              </Primitives.Flex>
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      ) : (
        <Primitives.Flex
          justifyContent="center"
          height="50vh"
          mt="25%"
          width="47vw"
        >
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};

FulFillmentRate.propTypes = propTypes;

export default FulFillmentRate;
