import React, { useState, useContext } from "react";
import Button from "../../../components/button";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two/TableComponent";
import { colors } from "../../../components/utils/theme";
import { array, object, func } from "prop-types";
import AlertNotification from "../../../components/alert-notification";
import { AppContext } from "../../../utils/context";
import Loading from "../../../components/loading";
import InvoiceDownload from "./invoice-download";
import Cta from "../../../components/cta";
import downloadHandlerFn from "../../../components/utils/download-handler";
import { HorizontalScroll } from "../../../lib/horizontal-scroll";

const propTypes = {
  data: object,
  filteredList: func,
  newRequest: func,
  tableData: array
};
const invoiceFilters = [
  "product_name",
  "hsn_code",
  "batch_number",
  "batch_expiry",
  "rate",
  "mrp",
  "discount_rate",
  "discount_amount",
  "quantity",
  "free_quantity",
  "net_amount",
  "gst_rate",
  "cgst_rate",
  "total_tax_amount",
  "total_amount"
];
var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const arrSum = (arr, key) =>
  arr
    .map(item => item[key])
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

const modifyData = (filteredList, tableData) => [
  ...tableData.map(o => filteredList(o, invoiceFilters)),
  {
    product_name: `Total products ${tableData.length}`,
    hsn_code: <Primitives.Text />,
    batch_number: <Primitives.Text />,
    batch_expiry: <Primitives.Text />,
    rate: <Primitives.Text />,
    mrp: <Primitives.Text />,
    discount_rate: <Primitives.Text />,
    discount_amount: "₹".concat(arrSum(tableData, "discount_amount")),
    quantity: arrSum(tableData, "quantity"),
    free_quantity: arrSum(tableData, "free_quantity"),
    net_amount: "₹".concat(arrSum(tableData, "net_amount")),
    gst_rate: <Primitives.Text />,
    cgst_rate: <Primitives.Text />,
    total_tax_amount: "₹".concat(arrSum(tableData, "total_tax_amount")),
    total_amount: "₹".concat(arrSum(tableData, "total_amount"))
  }
];

const column = [
  { heading: "PRODUCT", type: "string", width: 3 },
  { heading: "HSN CODE", type: "string" },
  { heading: "BATCH", type: "string", width: 1.5 },
  { heading: "EXPIRY", type: "string" },
  { heading: "RATE", type: "number", align: "flex-end" },
  { heading: "MRP", type: "string", align: "flex-end" },
  { heading: "DISC RATE", type: "number" },
  { heading: "DISC AMT", type: "string", align: "flex-end" },
  { heading: "QUANTITY", type: "number", align: "flex-end" },
  { heading: "FREE QTY", type: "number", align: "flex-end" },
  { heading: "NET AMT", type: "string", align: "flex-end" },
  { heading: "SGST", type: "string", width: "0.5" },
  { heading: "CGST", type: "string", width: "0.5" },
  { heading: "TOTAL TAX", type: "string", align: "flex-end" },
  { heading: "TOTAL AMT", type: "string", align: "flex-end" }
];

const Invoice = ({ data, filteredList, newRequest, tableData }) => {
  const [grnShow, toggleGrnShow] = useState(false);
  const { BASE_URL, user } = useContext(AppContext);
  const isProcurementRole = user?.user?.role === "hospital_warehouse";
  const [isDownload, setIsDownload] = useState(false);
  const createGrn = id => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
    fetch(`${BASE_URL}/invoices/${id}/create_grn`, requestOptions)
      .then(() => {
        //response.json();
      })
      .then(() => {
        toggleGrnShow(true);
        setTimeout(function() {
          newRequest();
          toggleGrnShow(false);
        }, 3000);
      });
  };
  const downloadHandler = async (path, format) =>
    downloadHandlerFn(path, setIsDownload, format);

  const selectHandler = option => {
    switch (option.text) {
      case "Download Invoice (PDF)":
        downloadHandler(
          `${BASE_URL}/invoices/${data.id}.pdf?is_download=true`,
          "pdf"
        );
        break;
      case "Download Invoice (XLS)":
        downloadHandler(
          `${BASE_URL}/invoices/${data.id}.xlsx?is_download=true`,
          "xlsx"
        );
        break;
    }
  };

  return (
    <>
      <Primitives.Flex>
        <Primitives.Flex flex={3} flexDirection="column">
          <Primitives.Flex fontSize={2} mt={1}>
            <Primitives.Flex flexDirection="column" mb={3} mr={5}>
              <Primitives.Text color={colors.text[1]} mr="5px" mb={1}>
                Date Issued:
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} fontWeight={2}>
                {days[new Date(data.invoice_date).getDay()].concat(", ") +
                  new Date(data.invoice_date).getDate() +
                  " " +
                  months[new Date(data.invoice_date).getMonth()].concat(" ") +
                  new Date(data.invoice_date).getFullYear()}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mr={4}>
              <Primitives.Text color={colors.text[1]} mr="5px" mb={1}>
                Invoice Value:
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} fontWeight={2}>
                {`₹ ${data.total_amount.toFixed(2)}`}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mr={5}>
              <Primitives.Text color={colors.text[1]} mr="5px" mb={1}>
                Total Qty:
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} fontWeight={2}>
                {data.total_quantity}
              </Primitives.Text>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column" mr={5}>
              <Primitives.Text color={colors.text[1]} mr="5px" mb={1}>
                Total Products:
              </Primitives.Text>
              <Primitives.Text color={colors.text[0]} fontWeight={2}>
                {data.items_count}
              </Primitives.Text>
            </Primitives.Flex>
            {data.pod && data.pod.length > 0 && (
              <Primitives.Flex flexDirection="column" mr={6}>
                <Cta
                  color="inherit"
                  label={
                    <Button
                      active={{
                        backgroundColor: colors.primary[3],
                        color: "white",
                        borderColor: "inherit"
                      }}
                      borderColor={colors.primary[3]}
                      border="1px solid"
                      color={colors.primary[3]}
                      hover={{
                        backgroundColor: colors.primary[3],
                        color: "white"
                      }}
                      label="View POD"
                      lineHeight={3}
                      px="8px"
                      py="4px"
                    />
                  }
                  link={data.pod[0].url}
                  hover={{ color: "inherit", textdecorationline: false }}
                  target="_blank"
                  textdecorationline={false}
                  type="anchor"
                />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
        {!isProcurementRole && (
          <Primitives.Flex
            alignItems="flex-start"
            flex={1}
            justifyContent="flex-end"
          >
            <Primitives.Flex>
              {isDownload ? (
                <Loading onlySpinner />
              ) : (
                <InvoiceDownload selectHandler={selectHandler} />
              )}
            </Primitives.Flex>
            {data.status !== "cancelled" && (
              <Primitives.Flex ml={2}>
                <Button
                  active={{
                    backgroundColor: colors.primary[6],
                    color: "white",
                    borderColor: "inherit"
                  }}
                  backgroundColor="white"
                  border="1px solid"
                  borderColor={colors.primary[3]}
                  clickHandler={() => createGrn(data.id)}
                  color={colors.primary[3]}
                  fontWeight={1}
                  hover={{
                    backgroundColor: colors.primary[3],
                    color: "white"
                  }}
                  label="Create GRN"
                  lineHeight={4}
                  px={2}
                  py="1px"
                />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        )}
      </Primitives.Flex>
      <Primitives.Flex
        flexDirection="column"
        width="100%"
        overflowX="auto"
        pb={3}
      >
        <HorizontalScroll>
          <Primitives.Flex width="110%">
            <Table
              color={{ head: colors.text[1], row: colors.text[0] }}
              boldRow={true}
              data={modifyData(filteredList, tableData)}
              column={column}
              page="detail"
            />
          </Primitives.Flex>
        </HorizontalScroll>
      </Primitives.Flex>
      {!isProcurementRole && (
        <Primitives.Flex
          alignItems="flex-start"
          flex={1}
          justifyContent="flex-end"
          mt={2}
        >
          <Primitives.Flex>
            {isDownload ? (
              <Loading onlySpinner />
            ) : (
              <InvoiceDownload selectHandler={selectHandler} />
            )}
          </Primitives.Flex>
          {data.status !== "cancelled" && (
            <Primitives.Flex ml={2}>
              <Button
                active={{
                  backgroundColor: colors.primary[6],
                  color: "white",
                  borderColor: "inherit"
                }}
                backgroundColor="white"
                border="1px solid"
                borderColor={colors.primary[3]}
                clickHandler={() => createGrn(data.id)}
                color={colors.primary[3]}
                fontWeight={1}
                hover={{
                  backgroundColor: colors.primary[3],
                  color: "white"
                }}
                label="Create GRN"
                lineHeight={4}
                px={2}
                py="1px"
              />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      )}
      {grnShow && (
        <Primitives.Box position="fixed" width={1} left={0} top={0} zIndex={1}>
          <AlertNotification
            backgroundColor={colors.success[1]}
            label="GRN created successfully"
          />
        </Primitives.Box>
      )}
    </>
  );
};
Invoice.propTypes = propTypes;
export default Invoice;
