/** It renders Top Manufacturers Report. Last Modified on 18/01/23 for testing */

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import { colors } from "../../../components/utils/theme";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two/TableComponent";
import Loading from "../../../components/loading";
import { reportStyles } from "../../../lib/report-styles";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import paginationHandler from "../../../components/utils/pagination-handler";
import { indianNumberFormat } from "../../../components/utils";

const modifyData = data =>
  data &&
  data["top_manufacturers"] &&
  data["top_manufacturers"].map(item => ({
    Company: item.company_name,
    CompanyGroup: item.group_name,
    TotalPurchaseValue: `₹ ${item.total_amount}`,
    TotalPurchaseQuantity: item.total_purchase_qty,
    UniqueProductsPurchased: item.uniq_skus
  }));

const modayModifyData = modalData =>
  modalData &&
  modalData["skus_stats"] &&
  modalData["skus_stats"].map(item => ({
    ProductName: item.name,
    PurchaseValue: `₹ ${item.total_amount}`,
    PurchaseQuantity: item.total_quantity
  }));

const column = [
  {
    heading: "Company",
    type: "string",
    width: 2
  },
  {
    heading: "Company Group",
    type: "string",
    width: 1.5
  },
  {
    heading: "Total Purchase Value",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    align: "flex-end",
    heading: "Total Purchase Quantity",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    heading: "Unique Products Purchased",
    type: "number",
    width: 1
  }
];

const modalColumn = modalData => [
  {
    heading: "Product Name",
    type: "string",
    width: 2
  },
  {
    heading:
      modalData &&
      modalData["total_amount_sum"] !== undefined &&
      `Purchase Value (₹ ${indianNumberFormat(
        roundOffTwoDecimal(modalData["total_amount_sum"])
      )})`,
    type: "string",
    width: 1,
    align: "right"
  },
  {
    heading:
      modalData &&
      modalData["total_qty_sum"] &&
      `Purchase Quantity (${indianNumberFormat(modalData["total_qty_sum"])})`,
    type: "number",
    width: 1,
    align: "right"
  }
];

const TopManufacturers = () => {
  const { BASE_URL } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(1);
  const [modal, toggleModal] = useState(false);
  const [hospital, setHospital] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [modalData, setModalData] = useState({});
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/top_manufacturers?`
  );
  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    setQuery(`${queryObj.category}`);
    updateRequest(`${BASE_URL}/reports/top_manufacturers?${queryObj.category}`);
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/reports/top_manufacturers?page=${currentPage}&${query}`
    );
  }, [currentPage]);
  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );
  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const headerProps = {
    filterUpdate: filterUpdate,
    config: { category: true },
    label: "Top Manufacturers"
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const fetchData = async (item, index) => {
    setLoaded(false);
    toggleModal(true);
    setHospital(item[0]);

    try {
      const response = await fetch(
        `${BASE_URL}/reports/individual_skus?id=${data["top_manufacturers"][index].id}&skus=[${data["top_manufacturers"][index].skus}]`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      const json = await response.json();
      setLoaded(true);
      setModalData(json);
    } catch (error) {
      console.log(error);
    }
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    cursor: "pointer",
    clickHandler: (item, index) => fetchData(item, index),
    data: modifyData(data),
    column: column,
    isLakhsCroresNeeded: true,
    ...reportStyles["allReports"]
  };
  const modalTableProps = {
    data: modayModifyData(modalData),
    column: modalColumn(modalData),
    ...reportStyles["allReports"]
  };

  return (
    <Primitives.Flex flexDirection="column">
      {modal && (
        <Modal
          border={`1px solid ${colors.text[3]}`}
          borderRadius={8}
          closeHandler={() => {
            toggleModal(false);
          }}
          content={
            <Primitives.Flex flex={1} flexDirection="column">
              <Primitives.Flex flexDirection="column">
                <Primitives.Text fontSize={5} fontWeight={3}>
                  {hospital} - Unique SKU Purchased
                </Primitives.Text>
                <Primitives.Text fontSize="12px" mt={1}>
                  Total {modalData["total_items"]} Products
                </Primitives.Text>
                {loaded ? (
                  <Table {...modalTableProps} />
                ) : (
                  <Primitives.Flex m="auto" mt="20vh">
                    <Loading onlySpinner />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </Primitives.Flex>
          }
          open={true}
          py={3}
          px={4}
          width={"60vw"}
          height={"60vh"}
        />
      )}
      <GenericReport
        displayItems={displayItems()}
        downloadCtaProps={downloadCtaProps}
        subNavIndex={[3, 7]}
        isLoaded={isLoaded}
        headerProps={headerProps}
        paginationProps={paginationProps}
        tableProps={tableProps}
      />
    </Primitives.Flex>
  );
};
export default TopManufacturers;
