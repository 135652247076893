import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Back from "../../../../components/glyphs/elements/back";
import Primitives from "../../../../components/primitives";
import ConvertMoneyToLakhsCrores from "../../../../components/utils/convert-money-to-lakh-crores";
import { colors } from "../../../../components/utils/theme";
import useApiRequest from "../../../../hooks/useApiRequest";
import { reportStyles } from "../../../../lib/report-styles";
import SubHeader from "../../../../lib/sub-header";
import { AppContext } from "../../../../utils/context";
import { FilterContext } from "../../../../utils/filter-context";
import DisplayAllItems from "../../lib/display-all-items";
import GenericReport from "../../lib/generic-report";
import { indianNumberFormat } from "../../../../components/utils";

const modifyData = data =>
  data &&
  data.map((item, index) => ({
    "Sr. No.": index + 1,
    "Product Name": item["sku_name"],
    "Manufacturer Name": item["manufacturer_name"],
    "Product Category": item["sub_category"],
    "Total Purchase Value": `₹${item["total_value"].toFixed(2)}`,
    "Total Purchase Quantity": item["quantity"],
    "Contribution Percentage": `${item["contribution_percentage"]}%`,
    "Average Margin": `${item["average_margin_percentage"]}%`
  }));

const column = data => [
  { heading: "Sr. No.", type: "number", width: 0.5 },
  { heading: "Product Name", type: "string", width: 2 },
  {
    heading: "Manufacturer Name",
    type: "string",
    width: 1.5
  },
  { heading: "Product Category", type: "string", width: 1 },
  {
    heading: `Total Purchase Value (₹${data &&
      ConvertMoneyToLakhsCrores(data.value)})`,
    type: "string",
    width: 0.8,
    align: "flex-end"
  },
  {
    heading: `Total Purchase Quantity (${data && data.qty})`,
    type: "number",
    width: 0.8,
    align: "flex-end"
  },
  {
    heading: "Contribution Percentage",
    type: "string",
    width: 0.5
  },
  { heading: "Average Margin", type: "string", width: 0.5 }
];

const headerValue = data => [
  {
    header: "Total Purchase Value",
    value:
      data.total_skus_details &&
      `₹${ConvertMoneyToLakhsCrores(data.total_skus_details.value)}`
  },
  {
    header: "Total Purchase Quantity",
    value:
      data.total_skus_details && indianNumberFormat(data.total_skus_details.qty)
  },
  {
    header: "Unique Products",
    value:
      data["total_items_count"] && indianNumberFormat(data["total_items_count"])
  },
  {
    header: "Avg Margin %",
    value:
      data.total_skus_details && `${data.total_skus_details.margin_percentage}%`
  }
];

const TopMolecule = () => {
  const history = useHistory();
  const filterContext = useContext(FilterContext);
  const { BASE_URL } = useContext(AppContext);
  const ids = useParams().ids;
  const moleculeName = useParams().moleculeName;
  const [query, setQuery] = useState("");
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/individual_sku_purchased?sku_ids=${ids}`
  );
  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    let filterState = filterContext.filterState;
    let strength =
      queryObj.moleculeStrength > 0 &&
      "&strength=" +
        data.total_skus_details.molecule_strengths[
          queryObj.moleculeStrength - 1
        ].strength;
    let unit =
      queryObj.moleculeStrength &&
      "&unit=" +
        data.total_skus_details.molecule_strengths[
          queryObj.moleculeStrength - 1
        ].unit;
    setQuery(
      `&${filterState.dateRange || ""}&${queryObj.subcategory}&${
        queryObj.moleculeStrength
      }&${strength}&${unit}`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/reports/individual_sku_purchased?sku_ids=${ids}&${query}`
    );
  }, [query]);

  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");

  const headerProps = filterUpdate => ({
    filterUpdate: filterUpdate,
    config: {
      moleculeStrength: true,
      subcategory: true
    },
    label: (
      <Primitives.Flex alignItems="flex-end">
        <Primitives.Flex mr={2}>
          <Link
            exact="true"
            to=""
            onClick={() => {
              filterContext.updateFilterState("");
              history.go(-1);
            }}
          >
            <Back fill={colors.text[0]} />
          </Link>
        </Primitives.Flex>
        <Primitives.Flex color={colors.text[0]} fontSize={4} fontWeight={2}>
          <Primitives.Text>{moleculeName}</Primitives.Text>
        </Primitives.Flex>
      </Primitives.Flex>
    ),
    subHeader: data["total_skus_details"] && (
      <Primitives.Flex ml={4}>
        <SubHeader data={headerValue(data)} />
      </Primitives.Flex>
    )
  });
  const tableProps = {
    cursor: "pointer",
    data: modifyData(data["sku_details"]),
    column: column(data["total_skus_details"]),
    isLakhsCroresNeeded: true,
    ...reportStyles["allReports"],
    sorting: false
  };

  const displayItems = () => (
    <DisplayAllItems currentPage={1} totalItems={data["total_items_count"]} />
  );
  return (
    <Primitives.Flex flexDirection="column">
      <GenericReport
        displayItems={displayItems()}
        downloadCtaProps={downloadCtaProps}
        subNavIndex={[3, 9]}
        isLoaded={isLoaded}
        headerProps={headerProps(filterUpdate)}
        tableProps={tableProps}
      />
    </Primitives.Flex>
  );
};
export default TopMolecule;
