import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "regenerator-runtime/runtime";

const useApiRequest = url => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const userlogout = async () => {
    if (window.location.pathname.includes("thank_you")) return;
    !window.location.pathname.includes("/reset_password") &&
      !window.location.pathname.includes("/otp_login") &&
      history.push("/");
  };
  const fetchData = async url => {
    if (!url) return;
    setIsLoaded(false);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const json = await response.json();
      if (response.status === 401) {
        const keysToRemove = [
          "distributor",
          "hospital",
          "po_number",
          "summary",
          "token",
          "newUser",
          "redirected",
          "delivery_data",
          "file_name"
        ];
        keysToRemove.map(item => localStorage.removeItem(item));
        userlogout();
      }
      setIsLoaded(true);
      setData(json);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };
  useEffect(() => {
    fetchData(url);
  }, [url]);

  return { error, isLoaded, data };
};

export default useApiRequest;
