import React, { useState } from "react";
import Edit from "../../../../components/glyphs/elements/edit";
import Primitives from "../../../../components/primitives";
import Input from "../../../../components/input";
import Tooltip from "../../../../components/tool-tip";
import { colors } from "../../../../components/utils/theme";

const PoPanel = poNumber => {
  const [editPO, setEditPO] = useState(false);
  const [po, setPo] = useState(localStorage.getItem("po_number"));
  const isNewUser = localStorage.getItem("newUser") === "true";

  return (
    <>
      <Primitives.Flex flexDirection="row">
        <Primitives.Flex fontWeight={2} fontSize={5}>
          Purchase Order&nbsp;&nbsp;
          {editPO ? (
            <Input
              changeHandler={e => setPo(`"${e.target.value}"`)}
              lineHeight={1}
              px={1}
              value={po ? JSON.parse(po) : poNumber}
              width="10vw"
            />
          ) : localStorage.getItem("po_number") ? (
            JSON.parse(localStorage.getItem("po_number"))
          ) : (
            poNumber
          )}
        </Primitives.Flex>
        {!isNewUser && (
          <>
            {!editPO ? (
              <Primitives.Flex
                alignItems="flex-end"
                cursor="pointer"
                pl={1}
                onClick={() => setEditPO(!editPO)}
              >
                <Tooltip
                  content={
                    <Primitives.Text>
                      You can use your system generated PO <br></br> number here
                    </Primitives.Text>
                  }
                  contentTextAlign="center"
                  fontSize="12px"
                  value={<Edit />}
                  contentWidth="max-content"
                />
              </Primitives.Flex>
            ) : (
              <>
                <Primitives.Flex
                  alignItems="center"
                  color={colors["highlight-blue"]}
                  cursor="pointer"
                  fontSize="12px"
                  fontWeight={2}
                  onClick={() => {
                    localStorage.setItem("po_number", po);
                    setEditPO(!editPO);
                  }}
                  ml={2}
                >
                  Save
                </Primitives.Flex>
                <Primitives.Flex
                  alignItems="center"
                  cursor="pointer"
                  color={colors.text[1]}
                  fontSize="12px"
                  onClick={() => setEditPO(!editPO)}
                  ml={2}
                >
                  Cancel
                </Primitives.Flex>
              </>
            )}
          </>
        )}
      </Primitives.Flex>
    </>
  );
};

export default PoPanel;
