import React from "react";
import { array, object, oneOfType } from "prop-types";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";

const propTypes = {
  data: oneOfType([array, object])
};

const SubHeader = ({ data }) => (
  <>
    {data.map((item, index) => (
      <Primitives.Flex
        borderRight={index !== data.length - 1 && "1px solid #c6cdd7"}
        flexDirection="column"
        key={index}
        mt={3}
        pl={index !== 0 && 3}
        pr={3}
      >
        <Primitives.Flex color={colors.text[1]} fontSize={1} mb={1}>
          <Primitives.Text>{item.header}</Primitives.Text>
        </Primitives.Flex>
        <Primitives.Flex color={colors.text[0]} fontSize={3}>
          <Primitives.Text>{item.value}</Primitives.Text>
        </Primitives.Flex>
      </Primitives.Flex>
    ))}
  </>
);

export default SubHeader;
SubHeader.propTypes = propTypes;
