import React from "react";

const Warning = ({ ...props }) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8259 10.4704L7.93992 0.874977C7.5482 0.235517 6.90734 0.244517 6.51562 0.883962L0.629621 10.4662C0.237901 11.1061 0.530481 11.6001 1.27986 11.6001H13.1755C13.9253 11.6001 14.2178 11.1092 13.8261 10.4702L13.8259 10.4704ZM7.22272 2.99898C7.59968 2.99898 7.89132 3.41538 7.87202 3.9243L7.75265 6.9909C7.73335 7.49934 7.49421 7.91574 7.22133 7.91574C6.94851 7.91574 6.70891 7.49934 6.68961 6.9909L6.57164 3.9243C6.55234 3.4154 6.84492 2.99898 7.22274 2.99898H7.22272ZM7.22272 10.2258C6.7471 10.2258 6.42084 9.87804 6.43068 9.40282C6.43068 8.91728 6.75778 8.57986 7.22272 8.57986C7.70732 8.57986 8.01382 8.91728 8.02412 9.40282C8.02412 9.87806 7.70732 10.2258 7.22272 10.2258Z"
      fill="#F4B513"
    />
  </svg>
);
export default Warning;
