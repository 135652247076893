import React from "react";
import Cta from "../cta";
import ForwardAngular from "../glyphs/elements/forwardAngular";
import Primitives from "../primitives";
import { node, object, string, number, oneOfType } from "prop-types";
import { colors } from "../utils/theme";
const propTypes = {
  /** heading text*/
  heading: node,
  /** cta details passed */
  ctaDetails: object,
  /** data node*/
  data: node,
  /** orderStats passed */
  orderStats: oneOfType([number, string])
};
const Panel = ({ heading, ctaDetails, data, orderStats }) => (
  <Primitives.Flex
    background="white"
    border="1px solid"
    borderColor={colors["athens-gray"]}
    borderRadius={8}
    boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.1)"
    flexDirection="column"
  >
    <Primitives.Flex
      alignItems="center"
      backgroundColor={colors.accent[0]}
      borderBottom="1px solid"
      borderColor={colors["athens-gray"]}
      flex={1}
      pl={3}
      pr={4}
      py={20}
    >
      <Primitives.Flex mr={4}>{heading}</Primitives.Flex>
      {orderStats && (
        <Primitives.Flex
          alignItems="center"
          backgroundColor={colors.primary[0]}
          borderRadius={8}
          px={1}
          py="6px"
        >
          <Primitives.Flex
            backgroundColor={colors.primary[5]}
            borderRadius={25}
            height={7}
            mr={1}
            width={7}
          />
          <Primitives.Text
            fontSize={1}
            fontWeight={1}
            color={colors.primary[5]}
          >
            {orderStats}
          </Primitives.Text>
        </Primitives.Flex>
      )}
      {ctaDetails && (
        <Primitives.Flex flex={1} justifyContent="flex-end">
          <Cta
            color={colors.primary[3]}
            fontSize={1}
            fontWeight={2}
            label={
              <Primitives.Flex alignItems="flex-end">
                <Primitives.Text mr={1}>{ctaDetails.label}</Primitives.Text>
                <ForwardAngular fill={colors.primary[3]} />
              </Primitives.Flex>
            }
            type="hyperlink"
            link={ctaDetails.link}
            hover={{ color: colors.primary[3], textDecorationLine: true }}
            textdecorationline={false}
          />
        </Primitives.Flex>
      )}
    </Primitives.Flex>
    <Primitives.Flex>{data}</Primitives.Flex>
  </Primitives.Flex>
);

Panel.propTypes = propTypes;

export default Panel;
