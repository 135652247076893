import React from "react";
import { array, func, number, string } from "prop-types";
import GenericSavingsMarginsReport from "../../../../../lib/generic-savings-margins-report";

const propTypes = {
  avgMarginPercentage: number,
  date: string,
  data: array,
  download: string,
  modifyData: func
};

const title = "Category Wise Margins";

const column = avgMarginPercentage => [
  { heading: "Category", type: "string" },
  { heading: "Total Manufacturers ", type: "number", align: "flex-end" },
  { heading: "MRP Value", type: "number", align: "flex-end" },
  { heading: "Purchase Value", type: "number", align: "flex-end" },
  {
    heading: `Margin % (Avg ${avgMarginPercentage}%)`,
    type: "string",
    align: "flex-end"
  },
  { heading: "Last Month Margin %", type: "number", align: "flex-end" }
];

const CategoryWiseMargin = ({
  avgMarginPercentage,
  data,
  download,
  modifyData
}) => (
  <GenericSavingsMarginsReport
    cursor="pointer"
    data={data && modifyData(data, true)}
    downloadLink={download}
    column={column(avgMarginPercentage)}
    report="margin"
    title={title}
    width="96.5%"
  />
);

CategoryWiseMargin.propTypes = propTypes;
export default CategoryWiseMargin;
