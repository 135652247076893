import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import { colors } from "../../../components/utils/theme";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import paginationHandler from "../../../components/utils/pagination-handler";

const modifyData = (data, currentPage) =>
  data &&
  data.items &&
  data.items.map((item, index) => ({
    no: index + (currentPage - 1) * 20 + 1,
    productName: item.master_sku_name,
    quantityPurchase: item.quantity_sum,
    purchaseValue: `₹ ${item.total_amount_sum}`
  }));

const column = [
  {
    heading: "No",
    type: "number",
    width: 0.5,
    sort: false
  },
  { heading: "Product Name", type: "string", width: 2, sort: false },
  { heading: "Qty Purchase", type: "number", width: 1, align: "flex-end" },
  { heading: "Purchase value", type: "string", width: 1, align: "flex-end" }
];

const index = {
  2: "quantity_sum",
  3: "total_amount_sum"
};

const TopProducts = () => {
  const { BASE_URL } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, updateSort] = useState("");
  const [order, toggleOrder] = useState("asc");
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/top_products?`
  );
  const { isLoaded, data } = useApiRequest(request);

  const filterUpdate = queryObj => {
    queryObj.quickSearch !== "" && setCurrentPage(1);
    setQuery(
      `${queryObj.hospitalEntity || ""}${queryObj.category ||
        ""}&${queryObj.quickSearch || ""}`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/reports/top_products?page=${currentPage}${query}&${sort}`
    );
  }, [currentPage, sort, query]);

  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );
  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const headerProps = {
    config: { hospitalEntity: true, category: true },
    filterUpdate: filterUpdate,
    label: "Top Products",
    tagProps: {
      backgroundColor: "background-light-blue",
      color: colors.text[1],
      items: [
        { label: "Sales Till Date", key: 8 },
        { label: "Last Month", key: 31 },
        { label: "Last Quater", key: 61 }
      ],
      mr: 1
    },
    tagSearchHeading: "Quick Search"
  };

  const getHeaderId = id => {
    toggleOrder(order === "asc" ? "desc" : "asc");
    id in index
      ? updateSort(`sort_by=${index[id]}&sort_type=${order}`)
      : updateSort(null);
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    data: modifyData(data, currentPage),
    column: column,
    isLakhsCroresNeeded: true,
    sorting: true,
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      getHeaderId={getHeaderId}
      headerProps={headerProps}
      subNavIndex={[3, 8]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
export default TopProducts;
