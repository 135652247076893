import React, { useState } from "react";
import Primitives from "../../../../components/primitives";
import DownloadBtn from "../../../../lib/download";
import { string } from "prop-types";
import Loading from "../../../../components/loading";
import downloadHandlerFn from "../../../../components/utils/download-handler";

const propTypes = {
  downloadCtaProps: string
};

const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);

const DownloadButton = ({ downloadCtaProps }) => {
  const [isDownload, setIsDownload] = useState(false);
  return (
    <Primitives.Flex flex={1} justifyContent="flex-end">
      {isDownload ? (
        <Loading onlySpinner />
      ) : (
        <DownloadBtn
          clickHandler={() => {
            downloadHandler(setIsDownload, downloadCtaProps);
          }}
        />
      )}
    </Primitives.Flex>
  );
};

DownloadButton.propTypes = propTypes;

export default DownloadButton;
