/** It renders Order Fulfillment Report. Last modified on 27/01/23 for testing */
import React from "react";
import Primitives from "../../../../components/primitives";
import Table from "../../../../components/table-two/TableComponent";
import { colors } from "../../../../components/utils/theme";
import { array, bool, object, oneOfType, string } from "prop-types";
import Panel from "../../lib/panel";
import Loading from "../../../../components/loading";
import { useHistory } from "react-router";

const propTypes = {
  data: oneOfType([array, object]),
  /** Url path*/
  isLoaded: bool,
  /** Updated parameters */
  query: string
};

const dualHeader = (mainHeading, subHeading) => (
  <Primitives.Flex flexDirection="column" key={1} width={1} p={2}>
    <Primitives.Flex
      color="black"
      fontSize="12px"
      fontWeight={2}
      justifyContent="start"
      mb={2}
    >
      {mainHeading}
    </Primitives.Flex>
    <Primitives.Flex>
      {subHeading &&
        subHeading.map((item, index) => (
          <Primitives.Flex key={index} width={1 / subHeading.length}>
            {item}
          </Primitives.Flex>
        ))}
    </Primitives.Flex>
  </Primitives.Flex>
);

const headerText = (heading, color) => (
  <Primitives.Flex color={color} fontWeight={2} ml={2} p={2}>
    {heading}
  </Primitives.Flex>
);

const fulfilmentHeader = value => (
  <Primitives.Flex
    color={colors.text[1]}
    fontWeight={2}
    textAlign="center"
    p={2}
  >
    {value}
  </Primitives.Flex>
);

const orderValues = values => (
  <Primitives.Flex flex={1} p={2}>
    {values &&
      values.map((item, index) => (
        <Primitives.Text
          key={index}
          fontWeight={index === 3 ? 2 : 1}
          width={1 / values.length}
        >
          {item}
        </Primitives.Text>
      ))}
  </Primitives.Flex>
);

const fulfillmentValues = value => (
  <Primitives.Flex justifyContent="center" p={2} width={1}>
    {value}
  </Primitives.Flex>
);

const subHeader = (first, second) => (
  <Primitives.Flex>
    {first}
    <br />
    {second}
  </Primitives.Flex>
);

const column = [
  { heading: headerText("Period", "black"), width: 1.2 },
  {
    heading: dualHeader("Purchase Orders", [
      subHeader("POs", "PLACED"),
      subHeader("TOTAL", "ITEMS"),
      subHeader("TOTAL", "QTY"),
      "VALUE"
    ]),
    width: 2.6
  },
  {
    heading: dualHeader("Delivered Orders", [
      subHeader("POs", "CLOSED"),
      subHeader("TOTAL", "ITEMS"),
      subHeader("TOTAL", "QTY"),
      "VALUE"
    ]),
    width: 2.6
  },
  {
    heading: dualHeader("In Progress", [
      subHeader("POs", "PENDING"),
      subHeader("TOTAL", "ITEMS"),
      subHeader("TOTAL", "QTY"),
      "VALUE"
    ]),
    width: 2.6
  },
  { heading: fulfilmentHeader("QUANTITY FULFILLMENT"), width: 1 },
  { heading: fulfilmentHeader("UNIQUE SKU FULFILLMENT"), width: 1 },
  { heading: headerText("", colors.text[1]), width: 0.5 }
];

const modifyReport = (data, history) =>
  data &&
  data["response"] &&
  data["response"].map(item => ({
    period: fulfillmentValues([item.display_date]),
    purchaseOrder: orderValues(
      [
        item.ordered_pos,
        item.ordered_products,
        item.ordered_quantity,
        item.ordered_value
      ],
      3
    ),
    deliveredOrder: orderValues(
      [
        item.delivered_pos,
        item.delivered_products,
        item.delivered_quantity,
        item.delivered_value
      ],
      3
    ),
    pendingOrder: orderValues(
      [
        item.pending_pos,
        item.pending_products,
        item.pending_quantity,
        item.pending_value
      ],
      1
    ),
    quantityFulfillment: fulfillmentValues(item.quantity_fulfillment),
    uniqueSkuFulfillment: fulfillmentValues(item.sku_fulfillment),
    viewDeatils: (
      <Primitives.Flex
        color={colors.primary[3]}
        cursor="pointer"
        onClick={() =>
          history.push(
            `/hospital/order_fulfilment_details/${item.start_date}/${
              item.end_date ? item.end_date : item.start_date
            }`
          )
        }
        pt={1}
        justifyContent="center"
        width={1}
      >
        View
      </Primitives.Flex>
    )
  }));

const OrderFulfillmentReport = ({ data, isLoaded, query }) => {
  const history = useHistory();
  return (
    <>
      {isLoaded ? (
        data.response &&
        data.response.length && (
          <Primitives.Flex flexDirection="column" mx={4} mt={3}>
            <Panel
              title="Order Fulfillment Report"
              date={data.date_range}
              downloadLink={`${data.download_link}?${query}`}
            />
            <Primitives.Flex
              backgroundColor="white"
              borderRadius={10}
              border="1px solid"
              borderColor={colors["athens-gray"]}
              boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.1)"
              flexDirection="column"
              mt={2}
            >
              {data && data.response && (
                <Table
                  borderBottom={{
                    head: `1px solid ${colors["pattens-blue"]}`,
                    row: "0px"
                  }}
                  color={{ head: colors.text[1], row: colors.text[0] }}
                  data={modifyReport(data, history)}
                  column={column}
                  stripedColumns="odd"
                  p={{ headContainer: 0, row: 0 }}
                />
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        )
      ) : (
        <Primitives.Flex minHeight={200} mt={7} mx="auto">
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </>
  );
};
OrderFulfillmentReport.propTypes = propTypes;
export default OrderFulfillmentReport;
