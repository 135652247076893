import React from "react";
import Primitives from "../primitives";
import { array, bool, func, object, oneOfType, string } from "prop-types";
import { colors } from "../utils/theme";

const propTypes = {
  /** Array of data */
  data: oneOfType([array, object]),
  /** clickHandler function for viewDetails*/
  clickHandler: func,
  /**  centerWidth as a string*/
  centerWidth: string,
  /** Bool to render viewDetail */
  viewDeatils: bool
};

const defaultProps = {
  centerWidth: "74%",
  clickHandler: () => {},
  data: [
    {
      backgroundColor: "#036516",
      bottomLabel: "15 PO'S",
      color: "#036516",
      width: "20%"
    },
    {
      backgroundColor: colors.success[1],
      bottomLabel: "70 PO'S",
      color: colors.success[1],
      width: "40%"
    },
    {
      bottomLabel: "8 PO'S",
      backgroundColor: "#F4B513",
      color: "#F4B513",
      width: "30%"
    },
    {
      bottomLabel: "8 PO'S",
      backgroundColor: "#F34040",
      color: "#F34040",
      width: "10%"
    }
  ],
  viewDeatils: false
};

const borderStyle = {
  borderRightStyle: "solid",
  borderRightColor: "white",
  borderRightWidth: "3px"
};

const lableStyle = {
  fontSize: "12px",
  fontWeight: 2,
  minWidth: "fit-content"
};

const justifyContentObj = {
  0: "start",
  1: "center",
  2: "end"
};

const labelFn = (data, margin, top, viewDeatils, clickHandler, centerWidth) => (
  <Primitives.Flex>
    {data.map((item, index) => {
      return (item.textAlign === "center" && item.width === "0.0%") ||
        item.width === "0%" ? (
        <Primitives.Text
          color={item.color}
          key={index}
          position="absolute"
          textAlign={item.textAlign}
          width={centerWidth}
          {...lableStyle}
          {...margin}
        >
          {top ? item.topLabel : item.bottomLabel}
        </Primitives.Text>
      ) : (
        <Primitives.Text
          color={item.color}
          key={index}
          textAlign={item.textAlign}
          width={item.width}
          {...lableStyle}
          {...margin}
        >
          {top ? item.topLabel : item.bottomLabel}
          {!top && viewDeatils && (
            <Primitives.Flex
              color={colors.primary[3]}
              cursor="pointer"
              flex={1}
              fontSize="13px"
              onClick={() => clickHandler(index)}
              textAlign={item.textAlign}
              justifyContent={justifyContentObj[index]}
              my={1}
            >
              View Details
            </Primitives.Flex>
          )}
        </Primitives.Text>
      );
    })}
  </Primitives.Flex>
);

const PillChart = ({ data, viewDeatils, clickHandler, centerWidth }) => {
  const filterData = data.filter(item => item.width.split("%")[0] > 0);
  return (
    <Primitives.Flex flexDirection="column" width={1}>
      {labelFn(data, { mb: 1 }, true, viewDeatils, clickHandler, centerWidth)}
      <Primitives.Flex width={1}>
        {filterData.map((item, index) => {
          return (
            <Primitives.Flex
              flexDirection="column"
              key={index}
              width={item.width.split(".")[0] < 3 ? "3%" : item.width}
            >
              <Primitives.Flex
                backgroundColor={item.backgroundColor}
                borderBottomLeftRadius={index === 0 ? "32px" : 0}
                borderBottomRightRadius={
                  index === filterData.length - 1 ? "32px" : 0
                }
                borderTopLeftRadius={index === 0 ? "32px" : 0}
                borderTopRightRadius={
                  index === filterData.length - 1 ? "32px" : 0
                }
                height="16px"
                {...borderStyle}
              ></Primitives.Flex>
            </Primitives.Flex>
          );
        })}
      </Primitives.Flex>
      {labelFn(data, { mt: 1 }, false, viewDeatils, clickHandler, centerWidth)}
    </Primitives.Flex>
  );
};

PillChart.propTypes = propTypes;
PillChart.defaultProps = defaultProps;
export default PillChart;
