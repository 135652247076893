import React from "react";
import Button from "../../../../../components/button";
import { colors } from "../../../../../components/utils/theme";
import Download from "../../../../../components/glyphs/elements/download";

const CtaForDownload = props => {
  return (
    <>
      <Button
        active={{
          backgroundColor: "white"
        }}
        backgroundColor="white"
        fontWeight={2}
        glyph={<Download height={20} width={20} />}
        hover={{
          backgroundColor: "white",
          color: colors.primary[3]
        }}
        lineHeight={3}
        px={2}
        {...props}
      />
    </>
  );
};

export default CtaForDownload;
