import React, { useRef } from "react";
import { colors } from "../../../../../components/utils/theme";
import Primitives from "../../../../../components/primitives";
import UploadedFileButton from "../../uploaded-file-tag";
import { array, func } from "prop-types";

const propTypes = {
  arrayOfSupportDocs: array,
  setArrayOfSupportDocs: func
};

const SupportingDocuments = ({ arrayOfSupportDocs, setArrayOfSupportDocs }) => {
  const addMoreDocs = useRef();
  const clickButton = useRef();
  const additionalBtn = useRef();
  const handleClick = () => {
    additionalBtn.current.click();
    additionalBtn.current.addEventListener("change", () => {
      if (additionalBtn.current.value) {
        clickButton.current.style.display = "none";
        const newArrayOfSupportDocs = [
          ...arrayOfSupportDocs,
          {
            id: Math.floor(Math.random() * 10000),
            value: additionalBtn.current.files[0].name,
            file: additionalBtn.current.files[0]
          }
        ];
        setArrayOfSupportDocs(newArrayOfSupportDocs);
        addMoreDocs.current.style.display = "flex";
      }
    });
  };
  const addAnotherDoc = () => {
    additionalBtn.current.click();
    additionalBtn.current.addEventListener("change", () => {
      if (additionalBtn.current.value) {
        const newArrayOfSupportDocs = [
          ...arrayOfSupportDocs,
          {
            id: Math.floor(Math.random() * 10000),
            value: additionalBtn.current.files[0].name,
            file: additionalBtn.current.files[0]
          }
        ];
        setArrayOfSupportDocs(newArrayOfSupportDocs);
      }
    });
  };
  const removeDocs = id => {
    let removedArray = [...arrayOfSupportDocs].filter(item => item.id !== id);
    setArrayOfSupportDocs(removedArray);
    if (arrayOfSupportDocs.length === 1) {
      clickButton.current.style.display = "flex";
      addMoreDocs.current.style.display = "none";
    }
  };
  return (
    <>
      <Primitives.Flex
        backgroundColor="background-blue"
        flexDirection="column"
        padding={3}
        width={317}
      >
        <Primitives.Text fontSize={3} fontWeight={2}>
          Do you have supporting documents?
        </Primitives.Text>
        <Primitives.Text color={colors.text[1]} mb={1} mt={1}>
          Upload if you have. It’s not mandatory.
        </Primitives.Text>
        <Primitives.Input display="none" ref={additionalBtn} type="file" />
        <Primitives.Flex
          alignItems="center"
          border={`1px solid ${colors.primary[3]}`}
          borderRadius={4}
          color={colors.primary[3]}
          cursor="pointer"
          fontSize={1}
          fontWeight={2}
          height={36}
          justifyContent="center"
          mt={2}
          onClick={handleClick}
          ref={clickButton}
          width={200}
        >
          Upload Supporting Documents
        </Primitives.Flex>
        {arrayOfSupportDocs.map(file => (
          <Primitives.Flex key={file.id} mt={1}>
            <UploadedFileButton value={file.value} />
            <Primitives.Flex
              color={colors.primary[3]}
              cursor="pointer"
              fontSize={1}
              ml={2}
              mt={1}
              onClick={() => removeDocs(file.id)}
              textDecoration="underline"
            >
              Remove
            </Primitives.Flex>
          </Primitives.Flex>
        ))}
        <Primitives.Flex
          alignItems="center"
          cursor="pointer"
          display="none"
          ml={1}
          onClick={addAnotherDoc}
          ref={addMoreDocs}
        >
          <Primitives.Flex
            alignItems="center"
            backgroundColor={colors.primary[3]}
            color="white"
            borderRadius="50%"
            fontSize={3}
            fontWeight={2}
            height={24}
            justifyContent="center"
            width={24}
          >
            +
          </Primitives.Flex>
          <Primitives.Text color={colors.primary[3]} fontSize={2} ml={1}>
            Add More Files
          </Primitives.Text>
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

SupportingDocuments.propTypes = propTypes;
export default SupportingDocuments;
