import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import Navigation from "../../../../lib/navigation";
import Filters from "../../../../lib/filters";
import Back from "../../../../components/glyphs/elements/back";
import Loading from "../../../../components/loading";
import DownloadBtn from "../../../../lib/download";
import Table from "../../../../components/table-two";
import NoResults from "../../../../components/no-results";
import SubHeader from "../../../../lib/sub-header";
import DisplayAllItems from "../../lib/display-all-items";
import CircleCrossed from "../../../../components/glyphs/elements/circle-crossed";
import Warning from "../../../../components/glyphs/elements/warning";
import Tooltip from "../../../../components/tool-tip";
import TooltipOnClick from "../../../../lib/tooltip-onclick";
import { FilterContext } from "../../../../utils/filter-context";
import checkEmptyNull from "../../../../components/utils/check-empty-null";
import Pagination from "../../../../components/pagination";
import downloadHandlerFn from "../../../../components/utils/download-handler";
import { indianNumberFormat } from "../../../../components/utils";
import splitDateRange from "../../../../utils/split-date-range.js";

/** Invokes the downloadHandlerFn */
const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);

/** Column detils of product specific compliance report */
const column = [
  { heading: "Product Name", type: "string", width: 1 },
  { heading: "Manufacturer Name", type: "string", width: 1 },
  {
    heading: "Quantity Ordered",
    type: "number",
    width: 0.5,
    align: "flex-end"
  },
  { heading: "RC Rate", type: "number", width: 0.5, align: "flex-end" },
  { heading: "PO Rate", type: "number", width: 0.5, align: "flex-end" },
  { heading: "Invoice Rate", type: "number", width: 0.5, align: "flex-end" },
  { heading: "RC Status", type: "object", width: 0.5 }
];

/** columne details of product specific RC compliance header */
const headerValue = data => [
  {
    header: "Total Products Ordered",
    value: checkEmptyNull(data["total_prdoucts"])
  },
  {
    header: "Products with RC",
    value: checkEmptyNull(data["total_rc_products"])
  },
  { header: "Total Quantity", value: checkEmptyNull(data["total_quantity"]) },
  { header: "Value Ordered", value: checkEmptyNull("₹" + data["total_value"]) },
  {
    header: "RC Compliance",
    value: checkEmptyNull(data["compliance_percentage"] + "%")
  }
];

/** define the possible RC statuses */
const rcStatusObj = {
  true: {
    text: "RC Followed",
    color: "#199B46"
  },
  false: {
    text: "RC Not Followed",
    color: "#FF2F2F"
  },
  null: {
    text: "NO RC",
    color: "#9d9d9d"
  }
};

/** Returns tale data */
const modifyData = (data, history) =>
  data.map(item => ({
    "Product Name": item["product_name"],
    "Manufacturer Name": item["manufacturer"],
    "Quantity Ordered": item["quantity"],
    "RC Rate": (
      <Primitives.Text color="#4C49D6" opacity={item["rc_rate"] ? 1 : 0.4}>
        {item["rc_rate"] != null
          ? checkEmptyNull(`₹${item["rc_rate"]}`)
          : "No RC"}
      </Primitives.Text>
    ),
    "PO Rate": (
      <Primitives.Flex alignItems="center">
        {item["po_rc_rate_match"] === false && (
          <Primitives.Flex mr={2}>
            <Tooltip
              content="Rate in PO did not match RC Rate"
              contentWidth="120px"
              left="85px"
              top="-18px"
              toolTipTriangle={{
                left: "-4%",
                top: "50%",
                transform: "rotate(270deg)"
              }}
              value={<Warning />}
            />
          </Primitives.Flex>
        )}
        <Primitives.Flex>
          <TooltipOnClick
            backgroundColor="white"
            boxShadow={9}
            content={
              <Primitives.Flex flexDirection="column">
                <Primitives.Text color="#333333" fontSize="12px">
                  PO Number
                </Primitives.Text>
                <Primitives.Text
                  color="#4C49D6"
                  cursor="pointer"
                  fontSize="12px"
                  onClick={() =>
                    history.push(`/hospital/orders/${item["po_id"]}`)
                  }
                >
                  {item["po_number"]}
                </Primitives.Text>
                <Primitives.Text color="#333333" fontSize="10px" opacity="0.5">
                  created on {item["po_created_at"]}
                </Primitives.Text>
              </Primitives.Flex>
            }
            contentWidth="max-content"
            left="-78px"
            top="-33px"
            toolTipTriangle={{
              right: "-9%",
              top: "50%",
              transform: "rotate(90deg)"
            }}
            value={
              <Primitives.Text color="#4C49D6" cursor="pointer">
                ₹{item["po_rate"] || 0}
              </Primitives.Text>
            }
          />
        </Primitives.Flex>
      </Primitives.Flex>
    ),
    "Invoice Rate": <>₹{indianNumberFormat(item["invoice_rate"])}</>,
    "RC Status": (
      <Primitives.Flex alignItems="center">
        <Primitives.Text color={rcStatusObj[item["rc_followed"]].color} mr={1}>
          {rcStatusObj[item["rc_followed"]].text}
        </Primitives.Text>
        {item["rc_followed"] === false && (
          <CircleCrossed fill="#FF2F2F" height="14px" width="14px" />
        )}
      </Primitives.Flex>
    )
  }));

/** This is component function */
const ProductSpecificComplianceReport = () => {
  const [isDownload, setIsDownload] = useState(false);
  const { BASE_URL } = useContext(AppContext);
  const [pageNumber, setPageNumber] = useState(1);
  const filterContext = useContext(FilterContext);
  const filterStateHospitals = filterContext.filterState.hospitalEntity || "";
  const filterStateTenants = filterContext.filterState.distributor || "";
  const filterStateRc = filterContext.filterState.rcAvailability
    ? filterContext.filterState.rcAvailability[1]
    : "";
  const filterStateCategory = filterContext.filterState.crossProcurementCategory
    ? `&q[master_sku_universal_sku_universal_category_id_in][]=${filterContext.filterState.crossProcurementCategory}`
    : "";
  const filterStateDate = filterContext.filterState.dateRange
    ? `&start_date=${filterContext.filterState.dateRange
        .split("&")[0]
        .split("=")[1]
        .split("/")
        .join("-")}&end_date=${filterContext.filterState.dateRange
        .split("&")[1]
        .split("=")[1]
        .split("/")
        .join("-")}`
    : "";
  const [query, setQuery] = useState(
    `${filterStateCategory &&
      filterStateCategory}&universal_hospital_ids=${filterStateHospitals}&tenant_ids=${filterStateTenants}&${filterStateRc ||
      ""}&${filterStateDate}`
  );
  let history = useHistory();
  const [request, updateRequest] = useState(
    `${BASE_URL}/rc_adherence_reports/product_wise_rc_compliance?${query}`
  );
  const { data, isLoaded } = useApiRequest(request);

  /** Updates the filter state */
  const filterUpdate = queryObj => {
    setPageNumber(1);
    const { startDate, endDate } = splitDateRange(queryObj.date);
    const categoriesMapper = data =>
      data
        ? `&q[master_sku_universal_sku_universal_category_id_in][]=${data
            .split(",")
            .join("&q[master_sku_universal_sku_universal_category_id_in][]=")}`
        : "";

    /** Build the date range string */
    const date = queryObj.date
      ? `&start_date=${startDate}&end_date=${endDate}`
      : filterStateDate;
    /** Extract the hospitalEntity, distributor, and crossProcurementCategory from queryObj */
    const hospitals =
      queryObj?.hospitalEntity?.split("=")[1] ?? filterStateHospitals;

    const distributors = queryObj?.distributor?.split("=")?.[1] ?? "";

    /** Map 'crossProcurementCategory' value and assign to 'mappedCategory' */
    const mappedCategory =
      categoriesMapper(queryObj?.crossProcurementCategory?.split("=")[1]) || "";

    /** Set the updated query string with the filter parameters */
    setQuery(
      `universal_hospital_ids=${hospitals || ""}&${mappedCategory ||
        filterStateCategory}&tenant_ids=${distributors ||
        filterStateTenants}&q[master_sku_name_cont]=${queryObj.skuNameSearch ||
        ""}&${date}&${queryObj.rcAvailability || filterStateRc}&${
        queryObj.rcFollowed
      }`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/rc_adherence_reports/product_wise_rc_compliance?${query}`
    );
  }, [query]);

  /** handles the page no. changes */
  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setPageNumber(1);
    }
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/rc_adherence_reports/product_wise_rc_compliance?page=${pageNumber}&${query}`
    );
  }, [pageNumber]);

  /** Responsible for monitoring changes in the filterContext object. */
  useEffect(() => {
    if (filterContext.filterState === "") {
      updateRequest(
        `${BASE_URL}/rc_adherence_reports/product_wise_rc_compliance?`
      );
    }
  }, [filterContext]);

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 14]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
        mb={5}
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          pb={3}
          pt={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex mr={2}>
              <Link
                exact="true"
                to=""
                onClick={() => {
                  history.go(-1);
                  filterContext.updateFilterState("");
                }}
              >
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Flex color={colors.text[0]} fontSize={5} fontWeight={2}>
              <Primitives.Text>Product specific RC compliance</Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex ml={35}>
            {data["header_details"] && (
              <SubHeader data={headerValue(data["header_details"])} />
            )}
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Box mt={2} mx={2}>
          <Filters
            config={{
              skuNameSearch: true,
              hospitalEntity: true,
              crossProcurementCategory: true,
              distributor: true,

              rcAvailability: true,
              rcFollowed: true,
              date: true
            }}
            headers={[
              "Search Product",
              "Select Hospital",
              "Select Category",
              "Select Distributor",

              "RC Availability",
              "Other Filters",
              "Invoice Date"
            ]}
            filterUpdate={filterUpdate}
            flexWrap="wrap"
          />
        </Primitives.Box>
        {isLoaded ? (
          <Primitives.Flex px={5} flexDirection="column">
            <Primitives.Flex alignItems="center">
              {data?.product_details?.length > 0 && (
                <Primitives.Text color="#C2C4D2" fontSize="12px" my={3}>
                  <DisplayAllItems
                    currentPage={pageNumber}
                    title="results"
                    totalItems={data["total_items_count"]}
                  />
                </Primitives.Text>
              )}
              <Primitives.Flex flex={1} justifyContent="flex-end">
                {data?.product_details?.length > 0 && (
                  <Primitives.Flex flex={1} justifyContent="flex-end">
                    {isDownload ? (
                      <Loading onlySpinner />
                    ) : (
                      <DownloadBtn
                        clickHandler={() => {
                          downloadHandler(
                            setIsDownload,
                            `${BASE_URL}/rc_adherence_reports/product_wise_rc_compliance?${query}&is_download=true`
                          );
                        }}
                      />
                    )}
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex flexDirection="column">
              {data?.product_details?.length > 0 ? (
                <Table
                  alternateRowColor="#F8F8F8"
                  border={`1px solid #00000008`}
                  backgroundColor={{ head: colors["light-blue"] }}
                  borderBottom="0px"
                  color={{ head: colors.text[0], row: colors.text[0] }}
                  column={column}
                  data={modifyData(data.product_details, history)}
                  striped={true}
                />
              ) : (
                <Primitives.Flex mt="15vh" mx="auto">
                  <NoResults />
                </Primitives.Flex>
              )}
              {data?.total_items_count > 20 && (
                <Primitives.Flex bottom={10} ml="auto" right={40}>
                  <Pagination
                    changeHandler={paginationChangeHandler}
                    forcePage={pageNumber - 1}
                    marginPagesDisplayed={1}
                    pageCount={parseInt(data["total_items_count"]) / 20}
                  />
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
      </Primitives.Flex>
    </>
  );
};

export default ProductSpecificComplianceReport;
