import React, { useContext, useEffect, useRef, useState } from "react";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import SummaryBadge from "../../components/transaction-summary";
import WalletLogo from "../../components/glyphs/elements/wallet";
import DaysSince from "../../components/glyphs/elements/credit-card";
import { AppContext } from "../../utils/context";
import useApiRequest from "../../hooks/useApiRequest";
import Loading from "../../components/loading";
import Table from "../../components/table-two/TableComponent";
import OrderStatus from "../../components/order-status";
import NoResults from "../../components/no-results";
import { useHistory } from "react-router";
import Dropdown from "../../components/dropdown";
import Button from "../../components/primitives/button";
import Input from "../../components/input";
import Checkbox from "../../components/checkbox";
import styled from "styled-components";
import Pagination from "../../components/pagination";
import paymentStatus from "../../lib/payment-status";
import Navigation from "../../lib/navigation";
import paginationHandler from "../../components/utils/pagination-handler";

const StyledText = styled(Primitives.Text)`
  text-decoration: underline;
`;

const modifyTableData = data =>
  data.map(item => ({
    STATUS: (
      <Primitives.Flex>
        <OrderStatus
          status={
            item["status"] in paymentStatus
              ? paymentStatus[item["status"]]
              : "partially completed"
          }
        />
        <Primitives.Text ml={1}>{item["status"]}</Primitives.Text>
      </Primitives.Flex>
    ),
    Centre: item["centre"],
    "Pending Amount": item["pending_amount"],
    "Payment Due On": item["pending_due_on"]
  }));

const column = [
  {
    heading: "Status",
    type: "string"
  },
  { heading: "Centre", type: "string" },
  { heading: "Pending Amount", type: "string", align: "flex-end" },
  {
    heading: "Payment Due On ",
    type: "number",
    sort: false,
    align: "flex-end"
  }
];

const SummaryBadgeData = data => [
  {
    backgroundColor: "dull-green",
    content: "Amount Outstanding Since Last Payment",
    glyph: <WalletLogo fill={colors["background-dark-green"]} />,
    id: "outStandingAmount",
    title: data["outstanding_amount"] && data["outstanding_amount"]
  },
  {
    backgroundColor: "dull-green",
    content: "Days since Last Payment",
    glyph: <DaysSince fill={colors["background-dark-green"]} />,
    id: "daysSinceLastPayment",
    title: data["days_since_last_payment"] && data["days_since_last_payment"]
  },
  {
    backgroundColor: colors.accent[1],
    content: "On-Account Payment",
    glyph: <WalletLogo fill={colors.accent[4]} />,
    id: "onAccountPayment",
    title: data["on_account_pay"] && data["on_account_pay"]
  },
  {
    backgroundColor: "#FFDEDE",
    content: "Total Credit Note Amount",
    glyph: <WalletLogo fill="red" />,
    id: "overDueAmount",
    title: data["overdue_amount"] && data["overdue_amount"]
  }
];
const Payments = () => {
  const { BASE_URL, cities } = useContext(AppContext);
  const [centre, setCentre] = useState("");
  const [inputActive, setInputActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [pill, togglePill] = useState(false);
  const [cityCollapse, setCityCollapse] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sort, updateSort] = useState("");
  const [order, toggleOrder] = useState("asc");
  const [sortingIndex, updateSortingIndex] = useState();
  const [request, updateRequest] = useState(`${BASE_URL}/payments/${centre}`);
  const { data, isLoaded } = useApiRequest(request);

  const isClearFilter = centre !== "" || selectedCities.length;
  let history = useHistory();
  const node = useRef();
  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    if (!e.target.closest(".dropdown-filter")) {
      setCityCollapse(false);
    }
  };

  const cityMapper = cities => {
    var mapString = "";
    cities.forEach(item => {
      mapString += `&q[universal_city_id_in][]=${item}`;
    });
    return mapString;
  };

  const applyFilter = () => {
    updateRequest(
      `${BASE_URL}/payments?q[legal_name_cont]=${centre}${cityMapper(
        selectedCities
      )}`
    );
    selectedCities.length && setFilterActive(true);
  };

  /** clear all the filters */
  const clearFilter = () => {
    setCentre("");
    setSelectedCities([]);
    togglePill(true);
  };

  /** function to change the pagination values */
  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setPageNumber(page);
  };

  /** city dropdown select handler */
  const citySelectHandler = option => {
    const id = cities.cities.filter(item => item.name === option.text)[0].id;
    !selectedCities.includes(id)
      ? setSelectedCities([...selectedCities, id])
      : setSelectedCities(selectedCities.filter(data => data !== id));
  };

  const filterObject = {
    0: "payment_status+",
    1: "legal_name+",
    2: "pending_amount+",
    3: "pending_due_on+"
  };

  const headClickHandler = id => {
    updateSortingIndex(id);
    toggleOrder(order === "asc" ? "desc" : "asc");
    updateSort(filterObject[id] + order);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (pill) {
      applyFilter();
      togglePill(false);
    }
  }, [selectedCities, centre]);

  useEffect(() => {
    updateRequest(`${BASE_URL}/payments?page=${pageNumber}&q[s]=${sort}`);
  }, [pageNumber, sort]);

  return (
    <>
      <Navigation selected={3} />
      <Primitives.Flex flexDirection="column" mx={2}>
        <Primitives.Flex
          backgroundColor="background-blue"
          flexDirection="column"
          py={3}
        >
          <Primitives.Flex flexDirection="column" px={3}>
            <Primitives.Text color={colors.text[0]} fontSize={5} fontWeight={2}>
              Payments
            </Primitives.Text>
            <Primitives.Text
              color={colors.text[1]}
              fontSize={2}
              lineHeight={3}
              pt={1}
            >
              Summary Till Date.
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex pt={4}>
            {SummaryBadgeData(data).map((item, index) => (
              <Primitives.Flex flex={1} key={index} px={2}>
                <SummaryBadge
                  backgroundColor={item.backgroundColor}
                  content={item.content}
                  glyph={item.glyph}
                  key={index}
                  title={
                    item.title || item.title === 0 ? (
                      item.title
                    ) : (
                      <Primitives.Flex height={25} width={40} mb={1}>
                        <Loading onlySpinner />
                      </Primitives.Flex>
                    )
                  }
                />
              </Primitives.Flex>
            ))}
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex
          backgroundColor="white"
          borderBottom="1px solid"
          borderTop="1px solid"
          borderColor="athens-gray"
          px={5}
        />
        <Primitives.Flex flexDirection="column" px={3}>
          <Primitives.Flex
            flexDirection="column"
            pl="4px"
            pb={3}
            justifyContent="center"
          >
            <Primitives.Text fontSize={4} fontWeight={2} py={1} pt={1}>
              Payment List
            </Primitives.Text>
            <Primitives.Flex pb="12px" width="50vw">
              <Input
                active={inputActive}
                blurHandler={() => setInputActive(false)}
                changeHandler={e => {
                  setCentre(e.target.value);
                }}
                clickHandler={applyFilter}
                flex={1.5}
                glyph="search"
                placeholder="Search by Centre Name"
                type="text"
                value={centre}
              />

              <Primitives.Flex flex={1} mx={1} ref={node}>
                <Dropdown
                  className="dropdown-filter"
                  collapsed={cityCollapse}
                  defaultValue={
                    selectedCities.length
                      ? `${selectedCities.length} selected`
                      : "Select City"
                  }
                  focusHandler={() => {
                    setCityCollapse(true);
                  }}
                  id="cities"
                  listMaxHeight={195}
                  listWidth={320}
                  name="cities"
                  options={
                    cities &&
                    cities.cities &&
                    cities.cities.map(city => {
                      return {
                        glyph: (
                          <Checkbox
                            checked={
                              selectedCities.includes(city.id) ? true : false
                            }
                          />
                        ),
                        text: city.name
                      };
                    })
                  }
                  searchable={true}
                  selectHandler={citySelectHandler}
                  selectType="multiSelect"
                />
              </Primitives.Flex>

              <Primitives.Flex flex={1}>
                <Primitives.Flex
                  alignItems="center"
                  backgroundColor={filterActive ? colors.primary[3] : "white"}
                  border={`1px solid ${colors.text[2]}`}
                  borderRadius={4}
                  cursor="pointer"
                  justifyContent="center"
                  onClick={() => {
                    applyFilter();
                  }}
                  width={2 / 3}
                >
                  <Button
                    backgroundColor={filterActive ? colors.primary[3] : "white"}
                    border="0px"
                    borderColor={colors.text[3]}
                    opacity="0.69"
                    py={1}
                  />
                  <Primitives.Text
                    color={filterActive ? "white" : colors.primary[3]}
                    fontSize={1}
                    fontWeight={1}
                  >
                    Apply Filter
                  </Primitives.Text>
                </Primitives.Flex>
              </Primitives.Flex>

              <StyledText
                color={colors.text[1]}
                cursor={isClearFilter ? "pointer" : "default"}
                fontSize={1}
                lineHeight={4}
                onClick={() => {
                  if (isClearFilter) {
                    clearFilter();
                  }
                }}
                width={60}
              >
                {isClearFilter ? "Clear Filter" : ""}
              </StyledText>
            </Primitives.Flex>

            {data["hospital_entities"] && data["hospital_entities"].length > 0 && (
              <Primitives.Text
                color={colors.text[1]}
                fontSize={1}
                fontWeight={1}
              >
                Displaying all {data["hospital_entities"].length} Centres
              </Primitives.Text>
            )}
          </Primitives.Flex>
          {isLoaded ? (
            data &&
            data["hospital_entities"] &&
            data["hospital_entities"].length > 0 ? (
              <Table
                clickHandler={(e, index) => {
                  history.push(
                    `/hospital/payments/${data["hospital_entities"][index]["id"]}`
                  );
                }}
                color={{ head: colors.text[1], row: colors.text[0] }}
                cursor="pointer"
                data={modifyTableData(data["hospital_entities"])}
                headClickHandler={headClickHandler}
                column={column}
                sorting
                sortingIndex={sortingIndex}
                sortingType={order === "asc" ? "desc" : "asc"}
              />
            ) : (
              <NoResults />
            )
          ) : (
            <Loading />
          )}
        </Primitives.Flex>
        <Primitives.Flex position="fixed" bottom={10} right={40}>
          {data && data["total_items"] && data["total_items"] > 20 && (
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              marginPagesDisplayed={1}
              pageCount={parseInt(data["total_items"]) / 20}
            />
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};
export default Payments;
