import getMonthName from "./get-month-name";
import monthValues from "./month-values";

const defaultProps = {
  typeOfMonth: "currentMonth"
};

/** here for every months we are appending year to distinguish between two
 same months of different years, for Ex: January-2021 and January-2022 */

const isEmpty = str => {
  return !str || str.length === 0;
};
const previousMonth = () =>
  getMonthName(new Date().getMonth() - 1) + "-" + new Date().getFullYear();

const currentMonth = () =>
  getMonthName(new Date().getMonth()) + "-" + new Date().getFullYear();

const previousFilterMonth = columnMonth =>
  getMonthName(monthValues[columnMonth.split("-")[0]] - 1) +
  "-" +
  columnMonth.split("-")[1];

const selectedMonth = (columnMonth, typeOfMonth) => {
  if (typeOfMonth === "previousMonth") {
    return isEmpty(columnMonth)
      ? previousMonth()
      : previousFilterMonth(columnMonth);
  } else {
    return isEmpty(columnMonth) ? currentMonth() : columnMonth;
  }
};
selectedMonth.defaultProps = defaultProps;
export default selectedMonth;
