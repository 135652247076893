import React, { useEffect, useContext } from "react";
import Navigation from "../../../../lib/navigation";
import Primitives from "../../../../components/primitives";
import { useParams } from "react-router-dom";
import Button from "../../../../components/button";
import { AppContext } from "../../../../utils/context";
import logoutUser from "../../../../lib/logout-user";

const ThankYouPage = () => {
  const { so, date, id } = useParams();
  const { BASE_URL } = useContext(AppContext);

  useEffect(() => {
    logoutUser(BASE_URL);
  }, []);

  return (
    <>
      <Navigation hideCart={true} hideNavData={true} />
      <Primitives.Flex
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Primitives.Flex flexDirection="column">
          <Primitives.Text
            fontSize={5}
            fontWeight="bold"
            textAlign="center"
            mb={2}
          >
            Thank You. We have received your order. Kindly check your email to
            complete the payment process.
          </Primitives.Text>
          <Primitives.Text fontSize={3} textAlign="center">
            Your Order Number is{" "}
            <b>
              {so}/{date}/{id}
            </b>
          </Primitives.Text>
          <Button
            color="#F58158"
            hover={{ color: "#E34B26" }}
            fontSize={5}
            justifyContent="center"
            py={5}
            clickHandler={() => {
              window.location.href = "https://www.aknamed.com";
            }}
            label="Go back to Login"
          ></Button>
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default ThankYouPage;
