import React from "react";
import { node, number, object, oneOfType, string } from "prop-types";
import Primitives from "../primitives";
const propTypes = {
  /** Background color of component */
  backgroundColor: string,
  /** To set the content for the component */
  content: oneOfType([object, string]),
  /** To add the glyph icon */
  glyph: node,
  /** To set the title for the component */
  title: oneOfType([number, object, string]),
  toolTip: node
};

const defaultProps = {
  backgroundColor: "dull-green"
};

const TransactionComponent = ({
  backgroundColor,
  content,
  glyph,
  title,
  toolTip
}) => (
  <Primitives.Flex>
    {glyph && (
      <Primitives.Flex
        alignItems="center"
        backgroundColor={backgroundColor}
        borderRadius={50}
        height={50}
        justifyContent="center"
        width={50}
      >
        {glyph}
      </Primitives.Flex>
    )}
    <Primitives.Flex flexDirection="column" ml={2} justifyContent="center">
      <Primitives.Text fontSize={5} fontWeight={2} lineHeight={3}>
        {title}
      </Primitives.Text>
      <Primitives.Text fontSize={2} fontWeight={1} lineHeight={2}>
        {content}
      </Primitives.Text>
    </Primitives.Flex>
    {toolTip && toolTip}
  </Primitives.Flex>
);

TransactionComponent.propTypes = propTypes;
TransactionComponent.defaultProps = defaultProps;

export default TransactionComponent;
