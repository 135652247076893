import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { AppContext } from "../../../utils/context";
import DisplayAllItems from "../../reports/lib/display-all-items";
import paginationHandler from "../../../components/utils/pagination-handler";
import useApiRequest from "../../../hooks/useApiRequest";
import GenericReport from "../../reports/lib/generic-report";
import ViewPO from "./viewPO/viewPO";
import { FilterContext } from "../../../utils/filter-context";

/** sorting related request param  */
const index = {
  0: "product_name",
  1: "manufacturer_name",
  2: "quantity_ordered",
  3: "avg_quantity_ordered",
  4: "order_count"
};

/** This array defines a set of status tabs with corresponding labels and IDs. */
const statusTabs = [
  {
    label: "1-2 Times",
    id: "one_to_two"
  },
  { label: "3-4 Times", id: "three_to_four" },
  { label: "5+ Times", id: "five_plus" }
];

/** Stores the response key, whose value denote the count of total no. of item present in each tab */
const totalCountName = [
  "one_to_two_total_items",
  "three_to_four_total_items",
  "five_plus_total_items"
];

/** Return: product order frequency table data of selected tab */
const modifyData = (data, currentTab) =>
  data &&
  data[statusTabs[currentTab].id] &&
  data[statusTabs[currentTab].id].map(item => ({
    product_name: item["product_name"],
    manufacturer_name: item["manufacturer_name"],
    quantity_ordered: item["quantity_ordered"],
    avg_quantity_ordered: item["avg_quantity_ordered"],
    order_count: item["order_count"],
    linked_sos: (
      <Primitives.Flex
        borderBottomLeftRadius="10px"
        borderBottomRightRadius="10px"
        color={colors.primary[3]}
        fontSize="12px"
        fontWeight={2}
        justifyContent="center"
        py={1}
        marginTop="auto"
      >
        <ViewPO data={item["linked_pos"]} header="Purchase Orders" />
      </Primitives.Flex>
    )
  }));

/** Columns details of product order frequency table */
const column = [
  {
    heading: "Product Name",
    type: "object",
    width: 1.5
  },
  {
    heading: "Manufacturer Name",
    type: "string",
    width: 1.2
  },
  {
    align: "flex-end",
    heading: "Total Qty Ordered",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "Average Qty Ordered",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "Total Orders",
    type: "string",
    width: 1
  },
  {
    align: "center",
    heading: "Action",
    type: "string",
    width: 1,
    sort: false
  }
];

const ProductOrderFrequency = () => {
  const { BASE_URL } = useContext(AppContext);
  const filterContext = useContext(FilterContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, updateCurrentTab] = useState(
    filterContext?.filterState?.tab || 0
  );
  const [sort, updateSort] = useState("");
  const [orderFrequency, toggleOrderFrequency] = useState("asc");
  const [months, setMonths] = useState("");
  const [orderListingProductSearch, setOrderListingProductSearch] = useState(
    ""
  );
  const [hospitalEntity, setHospitalEntity] = useState("");
  const [manufacturerThrottleSearch, setManufacturerThrottleSearch] = useState(
    ""
  );
  const [request, updateRequest] = useState(
    `${BASE_URL}/product_order_frequency_reports?tab=${statusTabs[currentTab].id}&page=1&q[s]=order_count desc`
  );
  const { isLoaded, data } = useApiRequest(request);

  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const hospitalMapper = data =>
    data.split(",").map(item => `&q[buyer_party_id_in][]=${item}`);

  /* Apply filter */
  const filterUpdate = ({
    orderListingProductSearch,
    months,
    hospitalEntity,
    manufacturerThrottleSearch
  }) => {
    setMonths(months ? months.split("=")[1].split(",")[0] : "");
    setOrderListingProductSearch(orderListingProductSearch?.id);
    setHospitalEntity(hospitalEntity);
    setManufacturerThrottleSearch(manufacturerThrottleSearch);
  };

  /** Generates a URL based on the current state values */
  const generateURL = () => {
    const month = months || "";
    const statusTabId = statusTabs?.[currentTab]?.id || "";
    const mappedHospital =
      (hospitalEntity &&
        hospitalEntity.split("=")[1] &&
        hospitalMapper(hospitalEntity.split("=")[1])) ||
      "";
    const manufacturer = manufacturerThrottleSearch || "";
    const filteredOrderListingProductSearch = orderListingProductSearch || "";
    return `${BASE_URL}/product_order_frequency_reports?${month &&
      `&allowed_months=${month}`}&tab=${statusTabId}${filteredOrderListingProductSearch &&
      `&universal_sku_id=${filteredOrderListingProductSearch}`}${mappedHospital}&page=${currentPage}&q[s]=${sort}${manufacturer &&
      `&universal_company_id=${manufacturer}`}`;
  };

  /* update request  on basis of filter applied*/
  useEffect(() => {
    updateRequest(generateURL());
  }, [
    sort,
    currentTab,
    currentPage,
    months,
    orderListingProductSearch,
    hospitalEntity,
    manufacturerThrottleSearch
  ]);

  /* shows visible rows count out of the total no of rows in table  */
  const displayItems = () => (
    <DisplayAllItems
      currentPage={currentPage}
      itemsPerPage={20}
      totalItems={data[totalCountName[currentTab]]}
      title=""
    />
  );

  /* sorting the table */
  const getHeaderId = id => {
    id in index
      ? updateSort(`${index[id]}+${orderFrequency}`)
      : updateSort(null);
    toggleOrderFrequency(orderFrequency === "asc" ? "desc" : "asc");
  };

  const totalTabData = (data, totalCountName) =>
    totalCountName && totalCountName.map(name => data[name]);

  const headerProps = {
    backgroundColor: "#ffffff",
    config: {
      orderListingProductSearch: true,
      manufacturerThrottleSearch: true,
      months: true,
      hospitalEntity: true
    },
    filterUpdate: filterUpdate,
    headers: [
      "Search By Product",
      "Search By Manufacturer",
      "Select Month",
      "Hospital Branch"
    ],
    label: "Product Order Frequency",
    tabData: {
      currentTab: currentTab,
      data: totalTabData(data, totalCountName),
      setCurrentPage: setCurrentPage,
      statusTabs: statusTabs,
      updateCurrentTab: updateCurrentTab
    }
  };

  /** Handles page number  changes */
  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount:
      data && Math.ceil(parseInt(data[totalCountName[currentTab]]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    border: `1px solid ${colors.text[3]}`,
    column: column,
    color: { head: "#1E2B51", row: colors.text[0] },
    data: modifyData(data, currentTab),
    p: { headContainer: 2, row: 1 },
    striped: true,
    sorting: true
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      getHeaderId={getHeaderId}
      headerProps={headerProps}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      selected={2}
      subNavIndex={[2, 4]}
      tableProps={tableProps}
    />
  );
};

export default ProductOrderFrequency;
