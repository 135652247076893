import React from "react";
import Primitives from "../../../../../../components/primitives";
import { colors } from "../../../../../../components/utils/theme";
import { object } from "prop-types";
import HeaderComponent from "./header-component";
import { indianNumberFormat } from "../../../../../../components/utils";
const propTypes = {
  data: object
};

const styles = {
  borderLeft: "1px solid",
  borderColor: colors.text[1],
  pl: 4
};
const poObject = (key, value) => ({
  [key]: indianNumberFormat(Math.round(value))
});
const headerDataFn = data => {
  return {
    orderedPOs: [
      poObject("POs Placed", data && data.ordered_pos),
      poObject("Total Items", data && data.ordered_products),
      poObject("Total Qty", data && data.ordered_quantity),
      { Value: data && data.ordered_value }
    ],
    pendingPOs: [
      poObject("POs Pending", data && data.pending_pos),
      poObject("Total Items", data && data.pending_products),
      poObject("Total Qty", data && data.pending_quantity),
      { Value: data && data.pending_value }
    ],
    deliveredPOs: [
      poObject("POs Delivered", data && data.delivered_pos),
      poObject("Total Items", data && data.delivered_products),
      poObject("Total Qty", data && data.delivered_quantity),
      { Value: data && data.delivered_value }
    ],
    fulfilment: [
      {
        "Qty Fulfillment": data?.quantity_fulfillment
          ? `${data.quantity_fulfillment.toFixed(2)}%`
          : "0%"
      },
      {
        "Unique SKU Fulfillment": data?.sku_fulfillment
          ? `${data.sku_fulfillment.toFixed(2)}%`
          : "0%"
      }
    ]
  };
};

const Header = ({ data }) => (
  <Primitives.Flex pt={4}>
    <Primitives.Flex flex={1.5}>
      {headerDataFn(data).orderedPOs.map((item, index) => (
        <HeaderComponent item={item} key={index} />
      ))}
    </Primitives.Flex>
    <Primitives.Flex {...styles} flex={1.5}>
      {headerDataFn(data).pendingPOs.map((item, index) => (
        <HeaderComponent item={item} key={index} />
      ))}
    </Primitives.Flex>
    <Primitives.Flex flex={1.5} {...styles}>
      {headerDataFn(data).deliveredPOs.map((item, index) => (
        <HeaderComponent item={item} key={index} />
      ))}
    </Primitives.Flex>
    <Primitives.Flex flex={1} {...styles}>
      {headerDataFn(data).fulfilment.map((item, index) => (
        <HeaderComponent item={item} key={index} />
      ))}
    </Primitives.Flex>
  </Primitives.Flex>
);

Header.propTypes = propTypes;
export default Header;
