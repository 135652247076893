import React, { useState } from "react";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import { string } from "prop-types";
import DownloadBtn from "../../../../lib/download";
import Loading from "../../../../components/loading";
import downloadHandlerFn from "../../../../components/utils/download-handler";

const propTypes = {
  /** Date to be displayed on panel */
  date: string,
  /** Donwload link for download button */
  downloadLink: string,
  /** Title of report to display */
  title: string
};

const downloadHandler = async (setIsDownload, download) =>
  downloadHandlerFn(download, setIsDownload);

const Panel = ({ date, downloadLink, title }) => {
  const [isDownload, setIsDownload] = useState(false);
  return (
    <Primitives.Flex>
      <Primitives.Flex alignItems="center">
        <Primitives.Flex
          color={colors.text[0]}
          fontSize={3}
          fontWeight={2}
          mr={1}
        >
          <Primitives.Text>{title}</Primitives.Text>
        </Primitives.Flex>
        <Primitives.Flex color={colors.text[1]} fontSize={1} fontWeight={1}>
          <Primitives.Text>{date}</Primitives.Text>
        </Primitives.Flex>
      </Primitives.Flex>
      {downloadLink && (
        <Primitives.Flex flex={1} justifyContent="flex-end">
          {isDownload ? (
            <Loading onlySpinner />
          ) : (
            <DownloadBtn
              clickHandler={() => {
                downloadHandler(setIsDownload, downloadLink);
              }}
            />
          )}
        </Primitives.Flex>
      )}
    </Primitives.Flex>
  );
};
Panel.propTypes = propTypes;
export default Panel;
