import React from "react";
import { number, object, oneOfType, string } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";

const propTypes = {
  header: string,
  data: oneOfType([string, object]),
  flex: oneOfType([string, number])
};

const SectionHeader = ({ header, data, flex }) => (
  <Primitives.Flex flex={flex} flexDirection="column">
    <Primitives.Text color={colors.text[1]} fontSize={1}>
      {header}
    </Primitives.Text>
    <Primitives.Text py={1}>{data}</Primitives.Text>
  </Primitives.Flex>
);

SectionHeader.propTypes = propTypes;
export default SectionHeader;
