import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import ToggleContent from "../toggle-content";
import { array } from "prop-types";
import Button from "../../../components/button";
import { AppContext } from "../../../utils/context";

const propTypes = {
  dispatchMail: array,
  hospitalSettings: array,
  orderMail: array,
  kpiMailer: array,
  deliverMail: array
};

const CommunicationSettings = ({
  dispatchMail,
  hospitalSettings,
  kpiMailer,
  orderMail,
  deliverMail
}) => {
  const [communicationSettings, setCommunicationSettings] = useState([]);
  const [orderedMail, setOrderedMail] = useState([]);
  const [dispatchedMail, setDispatchedMail] = useState([]);
  const [kpiMail, setKpiMail] = useState([]);
  const [deliveredMail, setDeliveredMail] = useState([]);
  const [allHospitals, setAllHospitals] = useState(false);
  const [allCommunications, setAllCommunications] = useState(false);
  const [pill, togglePill] = useState(false);
  const { BASE_URL, user } = useContext(AppContext);
  const hospitalArray = [];

  const toggleFn = data => {
    data[1] = data[1] ? false : true;
    togglePill(true);
  };

  const settingsObj = {
    "Order Confirmation Mail": data => {
      setOrderedMail(data);
      toggleFn(data);
    },
    "Ready for Dispatch Mail": data => {
      setDispatchedMail(data);
      toggleFn(data);
    },
    "KPI Mail": data => {
      setKpiMail(data);
      toggleFn(data);
    },
    "Dispatched/ Delivered Mail": data => {
      setDeliveredMail(data);
      toggleFn(data);
    },
    "All Hospitals": data => {
      communicationSettings.map((item, index) => {
        communicationSettings[index][1] = !data[1];
      });
      setAllHospitals(!data[1]);
      setCommunicationSettings(communicationSettings);
      togglePill(true);
    },
    "All Coummunications": data => {
      communicationSettings.map((item, index) => {
        communicationSettings[index][1] = !data[1];
      });
      setAllHospitals(!data[1]);
      setCommunicationSettings(communicationSettings);
      setAllCommunications(!allCommunications);
      allCommunications
        ? (dispatchedMail[1] = orderedMail[1] = kpiMail[1] = deliveredMail[1] = false)
        : (dispatchedMail[1] = orderedMail[1] = kpiMail[1] = deliveredMail[1] = true);
      togglePill(true);
    }
  };

  const toggledData = data => {
    if (data.length === 4) {
      data[1] = data[1] ? false : true;
      communicationSettings[communicationSettings.indexOf(data)] = data;
      setCommunicationSettings(communicationSettings);
      togglePill(true);
    } else {
      settingsObj[data[0]](data);
    }
  };

  useEffect(() => {
    if (pill) {
      setCommunicationSettings(communicationSettings);
      togglePill(false);
    }
  }, [pill]);

  const toggleSection = (heading, title, cardInfo) => (
    <Primitives.Flex flexDirection="column" mr={7}>
      <Primitives.Text
        color={colors.text[0]}
        fontWeight={2}
        fontSize={2}
        my={4}
      >
        {heading}
      </Primitives.Text>

      {cardInfo[title].map((item, index) => (
        <Primitives.Box key={index}>
          <ToggleContent data={item} toggledData={toggledData} />
        </Primitives.Box>
      ))}
    </Primitives.Flex>
  );

  const toggleObj = [
    {
      state: orderMail,
      setState: () => setOrderedMail(orderMail)
    },
    {
      state: dispatchMail,
      setState: () => setDispatchedMail(dispatchMail)
    },
    {
      state: kpiMailer,
      setState: () => setKpiMail(kpiMailer)
    },
    {
      state: deliverMail,
      setState: () => setDeliveredMail(deliverMail)
    },
    {
      state: allHospitals,
      setState: allHospitalsBool => {
        allHospitalsBool.every(val => val === true)
          ? setAllHospitals(true)
          : setAllHospitals(false);
      }
    },
    {
      state:
        kpiMailer !== undefined &&
        orderMail !== undefined &&
        dispatchMail !== undefined,
      setState: () =>
        allHospitals &&
        kpiMail[1] &&
        orderedMail[1] &&
        dispatchedMail[1] &&
        deliveredMail[1]
          ? setAllCommunications(true)
          : setAllCommunications(false)
    }
  ];

  useEffect(() => {
    let allHospitalsBool = [];
    hospitalSettings !== undefined &&
      hospitalSettings &&
      hospitalSettings.map(item => {
        allHospitalsBool.push(item.email_enabled);
        hospitalArray.push([
          item.universal_hospital_entity_name,
          item.email_enabled,
          item.id,
          item.universal_hospital_entity_id
        ]);
        setCommunicationSettings(hospitalArray);
      });

    toggleObj.map(
      obj => obj["state"] !== undefined && obj["setState"](allHospitalsBool)
    );
    //console.log(deliveredMail);
  }, [hospitalSettings, orderMail, dispatchMail, kpiMailer, deliverMail]);

  const cardInfo = {
    CommunicationSettings: [["All Coummunications", allCommunications]],
    "Communication Type": [
      ["Order Confirmation Mail", orderedMail[1]],
      ["Ready for Dispatch Mail", dispatchedMail[1]],
      ...(user?.user?.role !== "hospital_warehouse"
        ? [["KPI Mail", kpiMail[1]]]
        : []),
      ["Dispatched/ Delivered Mail", deliveredMail[1]]
    ],
    "Hospital Settings": [
      ["All Hospitals", allHospitals],
      ...communicationSettings
    ]
  };

  const submitSettings = async () => {
    let settingsObject = communicationSettings.map(data => ({
      id: data[2],
      email_enabled: data[1],
      universal_hospital_entity_id: data[3],
      universal_hospital_entity_name: data[0]
    }));
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user: {
        group_order_confirmation_email_enabled: orderedMail[1],
        group_order_dispatch_ready_email_enabled: dispatchedMail[1],
        send_kpi_mail: kpiMail[1],
        user_hospital_communications_attributes: settingsObject,
        group_order_shipping_email_enabled: deliveredMail[1]
      }
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(
        `${BASE_URL}/users/communication_settings`,
        requestOptions
      );
      const json = await response.json();
      if (json["status"] === "success") {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Primitives.Flex
      borderTop="1px solid"
      borderColor="background-light-blue"
      flexDirection="column"
      mx={5}
    >
      <Primitives.Text
        color={colors.text[0]}
        fontWeight={3}
        fontSize={5}
        my={3}
      >
        Communication Settings
      </Primitives.Text>
      {cardInfo.CommunicationSettings.map((item, index) => (
        <Primitives.Flex
          alignItems="center"
          backgroundColor="background-blue"
          height={60}
          key={index}
          mb={4}
          pl={1}
        >
          <ToggleContent data={item} toggledData={toggledData} />
        </Primitives.Flex>
      ))}
      <Primitives.Flex>
        {toggleSection("COMMUNICATION TYPE", "Communication Type", cardInfo)}
        {toggleSection("HOSPITAL SETTINGS", "Hospital Settings", cardInfo)}
        <Primitives.Flex alignSelf="flex-start" mt={4}>
          <Button
            active={{
              backgroundColor: colors.primary[6],
              color: "white"
            }}
            backgroundColor={colors.primary[3]}
            clickHandler={submitSettings}
            color="white"
            fontWeight={2}
            hover={{
              backgroundColor: colors.primary[6],
              color: "white"
            }}
            label="Submit"
            lineHeight={4}
            px={5}
          />
        </Primitives.Flex>
      </Primitives.Flex>
      <Primitives.Flex />
    </Primitives.Flex>
  );
};

CommunicationSettings.propTypes = propTypes;
export default CommunicationSettings;
