import React, { useContext, useEffect, useState } from "react";
import { string } from "prop-types";
import Primitives from "../../../components/primitives";
import SectionHeader from "../section-header";
import OperationalStatus from "./operational-status";
import HistoricalTrend from "./historical-trend";
import { AppContext } from "../../../utils/context";

const propTypes = {
  header: string,
  dateRangeStart: string,
  dateRangeEnd: string,
  query: string
};

const statusStyle = {
  background: "white",
  borderRadius: "10px",
  boxShadow: "rgb(0 0 0 / 10%) 0px 0px 4px",
  flex: 1
};

const OperationalSummary = ({ query }) => {
  const { BASE_URL } = useContext(AppContext);
  const [hospitals, setHospitals] = useState("");

  useEffect(() => {
    let mappedQuery =
      query.split("&hospital_entity_id=")[1] &&
      query
        .split("&hospital_entity_id=")[1]
        .split(",")
        .map(item => "&hospital_ids[]=" + item);
    setHospitals(mappedQuery);
  }, [query]);

  return (
    <Primitives.Flex flex={1} flexDirection="column">
      <SectionHeader text="Operational Summary" />
      <Primitives.Flex>
        <Primitives.Flex flex={1} flexDirection="column" mr={1}>
          <Primitives.Flex mb={1} {...statusStyle}>
            <OperationalStatus
              buttonText="Track All Orders"
              url={`${BASE_URL}/dashboards/purchase_order_status?`}
              header="Purchase Order"
              hospitals={hospitals}
            />
          </Primitives.Flex>
          <Primitives.Flex mt={1} {...statusStyle}>
            <OperationalStatus
              buttonText="View All Invoices"
              url={`${BASE_URL}/dashboards/invoice_status?`}
              header="Invoices"
              hospitals={hospitals}
            />
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex background="white" flex={1} ml={1} {...statusStyle}>
          <HistoricalTrend query={query} />
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
OperationalSummary.propTypes = propTypes;
export default OperationalSummary;
