import React from "react";

const Closed = ({ ...props }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#FDF1F1" />
    <path
      d="M18 10L10 18"
      stroke="#F34040"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10L18 18"
      stroke="#F34040"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Closed;
