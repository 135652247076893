import React from "react";

const Plus = ({ ...props }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    title="plus-icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66659 6.33337H13.6666V7.66671H7.66659V13.6667H6.33325V7.66671H0.333252V6.33337H6.33325V0.333374H7.66659V6.33337Z"
      fill="inherit"
    />
  </svg>
);

export default Plus;
