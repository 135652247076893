import React, { useState, useContext } from "react";
import { number } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import TooltipOnClick from "../../../../lib/tooltip-onclick";
import Table from "../../../../components/table-two";
import Close from "../../../../components/glyphs/elements/close";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";

const propTypes = {
  noOfCenters: number,
  skuId: number
};

const column = [
  {
    heading: "Center Name",
    type: "string",
    align: "flex-start",
    width: 2
  },
  {
    heading: "RC Available",
    type: "string",
    width: 1
  }
];

const rcAvailable = {
  true: (
    <Primitives.Text color={colors["background-green"]}>Yes</Primitives.Text>
  ),
  false: <Primitives.Text color={colors.error[0]}>No</Primitives.Text>
};

const modifyData = data =>
  data &&
  data.map(item => ({
    name: item.name,
    rc_status: rcAvailable[item.rc_status]
  }));
const ViewCenter = ({ noOfCenters, skuId }) => {
  const { BASE_URL } = useContext(AppContext);
  const [active, setActive] = useState(false);
  const [url, setUrl] = useState("");
  const { data } = useApiRequest(url);

  return (
    <TooltipOnClick
      backgroundColor="white"
      boxShadow={9}
      close={active}
      padding="16px"
      position="absolute"
      content={
        <Primitives.Flex
          flexDirection="column"
          maxHeight="35vh"
          overflow="scroll"
          width={1}
        >
          <Primitives.Flex pb="16px" justifyContent="space-between">
            <Primitives.Text color="#1E2B51" fontSize={2} fontWeight={2}>
              CENTER STATUS
            </Primitives.Text>
            <Close
              fill={colors.text[0]}
              onClick={() => setActive(!active)}
              cursor="pointer"
              height="10px"
              width="10px"
            />
          </Primitives.Flex>
          <Primitives.Flex>
            <Table
              alternateRowColor="whitesmoke"
              backgroundColor={{ head: colors["background-light-blue"] }}
              border={`0.5px solid ${colors.text[3]}`}
              column={column}
              color={{
                head: colors.text[0],
                row: "#525983",
                footer: colors.text[0],
                selected: "#EDF4FF"
              }}
              data={modifyData(data?.hospital_hash)}
              striped={true}
              borderBottom={`0.5px solid ${colors.text[3]}`}
            />
          </Primitives.Flex>
        </Primitives.Flex>
      }
      contentWidth="450px"
      containerTextAlign="center"
      left="-1800%"
      toolTipTriangle={{
        left: "78%",
        transform: "translateX(-50%)"
      }}
      borderRadius="9px"
      value={
        <Primitives.Text
          cursor="pointer"
          color={colors["highlight-blue"]}
          textDecoration="underline"
          onClick={() =>
            setUrl(
              `${BASE_URL}/cross_procurement_reports/rc_status_with_centres?sku_id=${skuId}`
            )
          }
        >
          {noOfCenters}
        </Primitives.Text>
      }
    />
  );
};

ViewCenter.propTypes = propTypes;
export default ViewCenter;
