import React, { createContext, useState } from "react";
import { node } from "prop-types";
import { subDays } from "date-fns";
import formatDate from "./format-date";

const propTypes = {
  children: node
};
export const FulfillmentContext = createContext();

const FulfillmentContextProvider = ({ children }) => {
  const [startDate, updateStartDate] = useState(
    formatDate(subDays(subDays(new Date(), 3), 29))
  );
  const [endDate, updateEndDate] = useState(formatDate(subDays(new Date(), 3)));
  const [otifStartDate, updateOtifStartDate] = useState(
    formatDate(subDays(subDays(new Date(), 3), 29))
  );
  const [otifEndDate, updateOtifEndDate] = useState(
    formatDate(subDays(new Date(), 3))
  );
  return (
    <FulfillmentContext.Provider
      value={{
        startDate,
        endDate,
        otifStartDate,
        otifEndDate,
        updateEndDate,
        updateStartDate,
        updateOtifStartDate,
        updateOtifEndDate
      }}
    >
      {children}
    </FulfillmentContext.Provider>
  );
};
FulfillmentContextProvider.propTypes = propTypes;

export default FulfillmentContextProvider;
