// It renders category wise margin improvement report.
import React from "react";
import { array, string } from "prop-types";
import GenericSavingsMarginsReport from "../../../../lib/generic-savings-margins-report";

const propTypes = {
  data: array,
  download: string,
  column: array
};

const modifyData = data =>
  data.map(item => ({
    hospitalName: item[0],
    previousMonth: `${item["1"].last_month_avg_margin_perc} %`,
    currentMonth: `${item["1"].current_month_avg_margin_perc} %`,
    marginDifference: `${item["1"].avg_margin_perc} %`
  }));
const title = "Category Margin Improvements Report";

const CategoryMarginReport = ({ data, download, column }) => (
  <GenericSavingsMarginsReport
    data={data && modifyData(data)}
    downloadLink={`${download}?category_margin_download=true&`}
    column={column}
    title={title}
    report="savings"
  />
);

CategoryMarginReport.propTypes = propTypes;
export default CategoryMarginReport;
