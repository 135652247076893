import getMonthName from "../../components/utils/get-month-name";

const MonthRange = (startDate, endDate) => {
  let start = startDate.split("-");
  let end = endDate.split("-");
  let dates = [];
  let startYearMonth = start[1];
  let endYearMonth = end[1];

  for (let i = endYearMonth; i >= 0; i--) {
    let month = getMonthName(i) + "-" + end[0];
    dates.push(month);
  }
  for (let j = 11; j >= startYearMonth; j--) {
    let month = getMonthName(j) + "-" + start[0];
    dates[dates.length] = month;
  }
  return dates;
};

export default MonthRange;
