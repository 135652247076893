import React from "react";
import Primitives from "../../../components/primitives";

import { func, string, number } from "prop-types";
import Input from "../../../components/input";
import { colors } from "../../../components/utils/theme";

const propTypes = {
  changeHandler: func,
  heading: string,
  keys: number,
  value: string
};
const InputSection = ({ changeHandler, heading, keys, value }) => (
  <>
    <Primitives.Flex flexDirection="column" mr={4} key={keys}>
      <Primitives.Text
        color={colors.text[1]}
        fontSize={1}
        fontWeight={2}
        my={2}
        px={1}
      >
        {heading}
      </Primitives.Text>
      <Primitives.Box width={430}>
        <Input changeHandler={changeHandler} type="text" value={value} />
      </Primitives.Box>
    </Primitives.Flex>
  </>
);

InputSection.propTypes = propTypes;
export default InputSection;
