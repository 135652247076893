import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import Alert from "../../components/alert";
import Button from "../../components/button";
import Error from "../../components/glyphs/elements/error";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import useApiRequest from "../../hooks/useApiRequest";
import Navigation from "../../lib/navigation";
import { AppContext } from "../../utils/context";
import ChangePassword from "./change-password";
import CommunicationSettings from "./communication-settings";
import InputSection from "./input-section";

const headings = {
  first_name: "Name",
  last_name: "Last Name",
  email: "Email",
  designation: "Designation",
  mobile: "Mobile"
};

const UserProfile = () => {
  const { BASE_URL } = useContext(AppContext);
  const params = useParams();
  const request = `${BASE_URL}/users/${params.id}`;
  const { data, isLoaded } = useApiRequest(request);
  const [userData, setUserData] = useState({});
  const [password, setPassword] = useState("");
  const [communicationSettings, setCommunicationSettings] = useState([]);
  const [modal, toggleModal] = useState(false);
  const [alert, toggleAlert] = useState(false);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        toggleAlert(!alert);
      }, 1000);
    }
  }, [alert]);

  const updateProfile = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user: {
        ...userData,
        current_password: password
      }
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(
        `${BASE_URL}/users/${params.id}`,
        requestOptions
      );
      const json = await response.json();
      if (json["status"] === "success") {
        window.location.reload();
      } else {
        toggleAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = e => {
    e.preventDefault();
    toggleModal(true);
  };

  useEffect(() => {
    let responseObj = {};
    Object.keys(headings).map(
      key =>
        (responseObj[key] = data && data["response"] && data["response"][key])
    );
    setUserData(responseObj);
    setCommunicationSettings(
      data &&
        data.response && [
          data.response.group_order_confirmation_email_enabled,
          data.response.group_order_dispatch_ready_email_enabled,
          data.response.hospital_communications,
          data.response.send_kpi_mail,
          data.response.group_order_shipping_email_enabled
        ]
    );
  }, [data && data.response]);
  return (
    <>
      <Navigation />
      {isLoaded ? (
        <Primitives.Flex flexDirection="column">
          <Primitives.Flex flexDirection="column" mx={5} my={5}>
            <Primitives.Text
              color={colors.text[0]}
              fontSize={5}
              fontWeight={2}
              mb={3}
            >
              My Profile
            </Primitives.Text>
            <Primitives.Text
              color={colors["oxford-blue"]}
              fontWeight={1}
              fontSize={4}
            >
              Personal Info
            </Primitives.Text>
            <Primitives.Flex mt={1}>
              <form onSubmit={submitHandler}>
                <Primitives.Flex flexWrap="wrap">
                  {Object.keys(headings).map((item, index) => (
                    <InputSection
                      changeHandler={e =>
                        setUserData({
                          ...userData,
                          [item]: e.target.value
                        })
                      }
                      heading={headings[item]}
                      key={index}
                      value={userData[item]}
                    />
                  ))}
                </Primitives.Flex>
                <Primitives.Flex mt={2}>
                  <Button
                    active={{
                      backgroundColor: colors.primary[6],
                      color: "white"
                    }}
                    backgroundColor={colors.primary[3]}
                    color="white"
                    fontWeight={2}
                    hover={{
                      backgroundColor: colors.primary[6],
                      color: "white"
                    }}
                    label="Update"
                    lineHeight={4}
                    px={5}
                  />
                </Primitives.Flex>
              </form>
            </Primitives.Flex>
          </Primitives.Flex>
          {modal && (
            <Modal
              border={`1px solid ${colors.text[3]}`}
              borderRadius={8}
              closeHandler={() => {
                toggleModal(false);
              }}
              content={
                <Primitives.Flex flex={1} flexDirection="column">
                  <Primitives.Flex flexDirection="column">
                    <Primitives.Flex
                      justifyContent="center"
                      left={0}
                      position="absolute"
                      top={14}
                      width={1}
                    >
                      {alert && (
                        <Alert
                          backgroundColor="white"
                          borderColor={colors.error[1]}
                          color={colors.error[1]}
                          fontSize={1}
                          glyph={<Error fill={colors.error[1]} />}
                          label={
                            <Primitives.Text
                              alignItems="center"
                              display="inline-flex"
                            >
                              Incorrect Password
                            </Primitives.Text>
                          }
                          lineHeight={3}
                          mx={15}
                          py={1}
                        />
                      )}
                    </Primitives.Flex>
                    <Primitives.Text fontSize={4} fontWeight={2}>
                      Account Verification
                    </Primitives.Text>
                    <Primitives.Text fontSize={2} mt={4}>
                      Please enter you SmartBuy password to verify account and
                      update your profile
                    </Primitives.Text>

                    <Primitives.Input
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      mt={3}
                      height={33}
                      border={1}
                      borderStyle="solid"
                      borderColor={colors.text[2]}
                      borderRadius={4}
                      placeholder="Enter Password"
                      pl={10}
                      type="password"
                    />

                    <Primitives.Flex justifyContent="flex-end" mt={3} mb={2}>
                      <Button
                        active={{
                          backgroundColor: colors.primary[6],
                          color: "white"
                        }}
                        border="1px solid"
                        borderColor={colors.primary[3]}
                        backgroundColor="white"
                        clickHandler={() => {
                          toggleModal(false);
                        }}
                        color={colors.primary[3]}
                        fontWeight={2}
                        hover={{
                          backgroundColor: colors.primary[6],
                          color: "white"
                        }}
                        label="Cancel"
                        lineHeight={4}
                        px={5}
                        mx={1}
                      />
                      <Primitives.Flex ml={2}>
                        <Button
                          active={{
                            backgroundColor: colors.primary[6],
                            color: "white"
                          }}
                          backgroundColor={colors.primary[3]}
                          clickHandler={updateProfile}
                          color="white"
                          fontWeight={2}
                          hover={{
                            backgroundColor: colors.primary[6],
                            color: "white"
                          }}
                          label="Update"
                          lineHeight={4}
                          px={5}
                        />
                      </Primitives.Flex>
                    </Primitives.Flex>
                  </Primitives.Flex>
                </Primitives.Flex>
              }
              open={true}
              py={3}
              px={4}
              width="30vw"
              height="30vh"
            />
          )}
          <CommunicationSettings
            dispatchMail={
              communicationSettings && [
                "group_order_dispatch_ready_email_enabled",
                communicationSettings[1]
              ]
            }
            hospitalSettings={communicationSettings && communicationSettings[2]}
            kpiMailer={
              communicationSettings && [
                "send_kpi_mail",
                communicationSettings[3]
              ]
            }
            orderMail={
              communicationSettings && [
                "group_order_confirmation_email_enabled",
                communicationSettings[0]
              ]
            }
            deliverMail={
              communicationSettings && [
                "group_order_shipping_email_enabled",
                communicationSettings[4]
              ]
            }
          />
          <ChangePassword />
        </Primitives.Flex>
      ) : (
        <Loading />
      )}
      <Primitives.Flex />
    </>
  );
};

export default UserProfile;
