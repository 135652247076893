/**
  It renders invoices report.
  Last modified on 27/01/23 for testing 
*/
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../utils/context";
import GenericReport from "../lib/generic-report";
import useApiRequest from "../../../hooks/useApiRequest";
import DisplayALlItems from "../lib/display-all-items";
import { reportStyles } from "../../../lib/report-styles";
import { useHistory } from "react-router";
import Cta from "../../../components/cta";
import { colors } from "../../../components/utils/theme";
import Button from "../../../components/button";
import Primitives from "../../../components/primitives";
import paginationHandler from "../../../components/utils/pagination-handler";

const modifyData = data =>
  data &&
  data["sales_invoices"] &&
  data["sales_invoices"].map(item => ({
    invoiceNumber: item.invoice_number,
    invoiceDate: item.invoice_date,
    refPO: item.ref_po,
    refPODate: item.ref_po_date,
    city: item.city,
    noOfProducts: item.no_of_products,
    totalQty: item.total_qty,
    totalFreeQty: item.total_free_qty,
    totalNetAmt: `₹ ${item.total_net_amt.toFixed(2)}`,
    totalTax: `₹ ${item.total_tax_amt.toFixed(2)}`,
    totalAmount: `₹ ${item.total_amt.toFixed(2)}`,
    POD: (
      <Primitives.Flex
        position="absolute"
        top={0}
        onClick={e => e.stopPropagation()}
      >
        {item.pod && item.pod.length > 0 ? (
          <Cta
            color="inherit"
            label={
              <Button
                color={colors.primary[3]}
                fontWeight={1}
                hover={{
                  backgroundColor: colors.text[2],
                  color: colors.text[0]
                }}
                label="View"
                lineHeight={3}
                px="8px"
                py="4px"
              />
            }
            link={item.pod[0].url}
            hover={{ color: "inherit", textdecorationline: false }}
            target="_blank"
            textdecorationline={false}
            type="anchor"
          />
        ) : (
          <Primitives.Flex mt={1}>NA</Primitives.Flex>
        )}
      </Primitives.Flex>
    )
  }));

const column = [
  {
    heading: "Invoice Number",
    type: "string"
  },
  {
    heading: "Invoice Date",
    type: "string"
  },
  {
    heading: "Ref. PO",
    type: "string",
    width: 1.5
  },
  {
    heading: "Ref. PO Date",
    type: "string"
  },
  {
    heading: "City",
    type: "string",
    width: 1.5
  },
  {
    heading: "No.of Products",
    type: "number",
    align: "flex-end",
    width: 0.8
  },
  {
    heading: "Total Qty",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Total Free Qty",
    type: "number",
    align: "flex-end"
  },
  {
    heading: "Total Net Amt",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "Total Tax",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "Total Amount",
    type: "string",
    align: "flex-end"
  },
  {
    heading: "POD",
    type: "object"
  }
];
const AllInvoices = () => {
  let history = useHistory();
  const { BASE_URL } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(1);
  const [request, updateRequest] = useState(
    `${BASE_URL}/reports/all_invoice_report?`
  );
  const { isLoaded, data } = useApiRequest(request);
  const filterUpdate = queryObj => {
    setQuery(`${queryObj.hospitals}&${queryObj.date}`);
    updateRequest(
      `${BASE_URL}/reports/all_invoice_report?page=${currentPage}&${queryObj.hospitals}&${queryObj.date}`
    );
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/reports/all_invoice_report?page=${currentPage}&${query}`
    );
  }, [currentPage]);
  const displayItems = () => (
    <DisplayALlItems
      currentPage={currentPage}
      totalItems={data["total_items"]}
    />
  );
  const downloadCtaProps = request.split("?").join(".xlsx?is_download=true&");
  const headerProps = {
    filterUpdate: filterUpdate,
    config: { hospitals: true, date: true },
    label: "All Invoices Report"
  };

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  const paginationProps = {
    changeHandler: paginationChangeHandler,
    forcePage: currentPage - 1,
    marginPagesDisplayed: 1,
    pageCount: data && Math.ceil(parseInt(data["total_items"]) / 20),
    pageRangeDisplayed: 5
  };

  const tableProps = {
    clickHandler: (e, index) =>
      history.push({
        pathname: `/hospital/orders/${data["sales_invoices"][index]["order_id"]}?invoice=1`
      }),
    cursor: "pointer",
    data: modifyData(data),
    column: column,
    ...reportStyles["allReports"]
  };

  return (
    <GenericReport
      displayItems={displayItems()}
      downloadCtaProps={downloadCtaProps}
      headerProps={headerProps}
      subNavIndex={[3, 16]}
      isLoaded={isLoaded}
      paginationProps={paginationProps}
      tableProps={tableProps}
    />
  );
};
export default AllInvoices;
