import React from "react";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import Filters from "../../../../lib/filters";
import { array, bool, func, object, oneOfType, string } from "prop-types";
import { Link } from "react-router-dom";
import Back from "../../../../components/glyphs/elements/back";
import Tabs from "../../../product-masters/product-order-frequency/tabs";

const propTypes = {
  backgroundColor: string,
  flexDirection: string,
  headers: array,
  label: oneOfType([object, string]),
  subHeader: oneOfType([bool, object]),
  filterUpdate: func,
  backLink: string,
  config: object,
  tagProps: object,
  tabData: object,
  tagSearchHeading: string
};

const defaultProps = {
  backgroundColor: colors["background-blue"],
  subHeader: false
};

const Header = ({
  backgroundColor,
  flexDirection = "column",
  headers,
  label,
  subHeader,
  filterUpdate,
  backLink,
  config,
  tagProps,
  tabData,
  tagSearchHeading = "Date Range"
}) => {
  return (
    <Primitives.Flex
      alignItems={flexDirection === "row" && "center"}
      backgroundColor={backgroundColor}
      flexDirection={flexDirection}
    >
      <Primitives.Flex mb={flexDirection === "column" && 2} mr={3}>
        {backLink && (
          <Primitives.Flex ml={2} pt={2} alignItems="center">
            <Link to={backLink}>
              <Back fill={colors.text[0]} />
            </Link>
          </Primitives.Flex>
        )}
        <Primitives.Text
          color={colors.text[0]}
          fontSize={4}
          fontWeight={2}
          pl={4}
          pt={2}
        >
          {label}
        </Primitives.Text>
      </Primitives.Flex>

      <Primitives.Box backgroundColor={colors["background-blue"]} pl={4} py={2}>
        <Filters
          filterUpdate={filterUpdate}
          headers={headers}
          config={config}
          tagProps={tagProps}
          tagSearchHeading={tagSearchHeading}
        />
      </Primitives.Box>
      <Primitives.Text>
        {tabData && (
          <Tabs
            currentTab={tabData.currentTab}
            data={tabData.data}
            setCurrentPage={tabData.setCurrentPage}
            statusTabs={tabData.statusTabs}
            updateCurrentTab={tabData.updateCurrentTab}
          />
        )}
      </Primitives.Text>
      {subHeader && <Primitives.Flex>{subHeader}</Primitives.Flex>}
    </Primitives.Flex>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
