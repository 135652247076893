import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Main from "./pages/order-listing";
import Dashboard from "./pages/dashboard";
import Detail from "./pages/order-detail";
import CreateNewPurchaseOrder from "./pages/create-new-purchase-order";
import DraftPurchaseOrder from "./pages/draft-purchase-order";
import ProductMaster from "./pages/product-masters/product-master";
import RateContract from "./pages/product-masters/rate-contract";
import ProductCodes from "./pages/product-masters/product-codes";
import Payments from "./pages/payments";
import PurchaseMarginCategory from "./pages/reports/purchase-margin-report/purchase-margin-category";
import PurchaseMarginCompany from "./pages/reports/purchase-margin-report/purchase-margin-company";
import Overview from "./pages/reports/overview";
import PurchaseMarginReport from "./pages/reports/purchase-margin-report/purchase-margin-listing";
import FulFillmentReport from "./pages/reports/fulfillment-report";
import SavingsReport from "./pages/reports/savings-report";
import AppContextProvider from "./utils/context";
import TopMolecule from "./pages/reports/top-molecule";
import ProcurementSummary from "./pages/reports/procurement-summary";
import TopProducts from "./pages/reports/top-products";
import TopManufacturers from "./pages/reports/top-manufacturers";
import FastGrnCompliance from "./pages/reports/fast-grn-compliance-report";
import AllInvoices from "./pages/reports/invoices-report";
import AllGrn from "./pages/reports/grn-report";
import ProductReturns from "./pages/reports/product-returns-report";
import CrossProductProcurementReport from "./pages/reports/cross-product-procurement-report";
import OrderedSku from "./pages/reports/cross-product-procurement-report/ordered-skus";
import PurchaseOrderCheckout from "./pages/create-new-purchase-order/purchase-order-checkout";
import Login from "./pages/login";
import CategoryProductTable from "./pages/product-masters/product-master/category-product-table";
import NewProducts from "./pages/product-masters/product-master/new_products";
import Bugsnag from "@bugsnag/browser";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import CentrePayments from "./pages/payments/centre-payment";
import ExpiryCheckReport from "./pages/reports/expiry-check-report";
import UserProfile from "./pages/user-profile";
import Products from "./pages/product-masters/products";
import ResetPassword from "./pages/login/forgot-password/reset-password";
import ProtectedRoute from "./lib/protected-route";
import ReactGA from "react-ga4";
import MoleculeDetails from "./pages/reports/top-molecule/molecule-details";
import OrderFulfilmentDetail from "./pages/reports/fulfillment-report/order-fulfillment-report/order-fulfilment-detail";
import ProductWiseInvoiceDetail from "./pages/reports/product-wise-invoice-report";
import PurchaseOrderDetails from "./pages/reports/fulfillment-report/purchase-order-fulfilment-report/purchase-order-details";
import CategoryOrderDetails from "./pages/reports/fulfillment-report/otif-report/category-details-report";
import RcComplianceReport from "./pages/reports/rc-compliance-report";
import ProductSpecificComplianceReport from "./pages/reports/rc-compliance-report/product-specific-compliance-report";
import FilterContextProvider from "./utils/filter-context";
import ProductOrderFrequency from "./pages/product-masters/product-order-frequency";
import ThemeProvider from "./components/utils/theme/index";
import FulfillmentContextProvider from "./utils/fulfillment-report-context";
import ThankYouPage from "./pages/create-new-purchase-order/purchase-order-checkout/thank-you";

/** BugSnag */
Bugsnag.start({
  apiKey: "16ead7805e5734e368f4bea49c4c8f97",
  plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const newRelicInit = () => {
  if (process.env.NODE_ENV !== "development") {
    const script = document.createElement("script");
    script.src = "/new-relic-production.js";
    script.async = true;
    document.body.appendChild(script);
  }
};

const App = () => {
  useEffect(() => {
    newRelicInit();
  }, []);
  ReactGA.initialize("G-P8H54LBB91");
  ReactGA.send("pageview");

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ThemeProvider>
          <AppContextProvider>
            <div className="sans-serif">
              <Route exact path="/" component={Login} />
              <Route exact path="/otp_login" component={Login} />
              <Route
                exact
                path="/reset_password/:token/:email"
                component={ResetPassword}
              />
              <Route exact path="/hospital/orders/" component={Main} />
              <Route
                exact
                path="/hospital/filtered_orders/:status?"
                component={Main}
              />
              <Route path="/hospital/orders/:orderId" component={Detail} />
              <FilterContextProvider>
                <Route
                  exact
                  path="/hospital/purchase_margin_report/:month?/:category?"
                  component={PurchaseMarginReport}
                />
                <Route
                  path="/hospital/purchase_margin_category/:purchaseMarginCategoryId"
                  component={PurchaseMarginCategory}
                />
              </FilterContextProvider>
              <Route
                path="/hospital/purchase_margin_company/:id/:companyId"
                component={PurchaseMarginCompany}
              />
              <Route exact path="/hospital/payments" component={Payments} />
              <Route path="/hospital/payments/:id" component={CentrePayments} />
              <Route
                exact
                path="/hospital/purchase_orders"
                component={CreateNewPurchaseOrder}
              />
              <Route
                exact
                path="/hospital/thank_you/:so/:date/:id"
                component={ThankYouPage}
              />
              <Route
                exact
                path="/hospital/purchase_orders/draft_orders"
                component={DraftPurchaseOrder}
              />
              <Route
                path="/hospital/purchase_orders/purchase_order_summary/:hospitalId/:poNumber"
                component={PurchaseOrderCheckout}
              />
              <Route
                path="/hospital/procurement_analytics"
                component={ProcurementSummary}
              />
              <Route exact path="/hospital/analytics" component={Overview} />
              <FulfillmentContextProvider>
                <FilterContextProvider>
                  <Route
                    exact
                    path="/hospital/fulfillment_reports"
                    component={FulFillmentReport}
                  />
                  <Route
                    exact
                    path="/hospital/category_order_details/:otifCategory"
                    component={CategoryOrderDetails}
                  />
                  <Route
                    path="/hospital/purchase_order_details/:otifStatus"
                    component={PurchaseOrderDetails}
                  />
                  <Route
                    exact
                    path="/hospital/order_fulfilment_details/:startDate/:endDate"
                    component={OrderFulfilmentDetail}
                  />
                </FilterContextProvider>
              </FulfillmentContextProvider>

              <Route
                path="/hospital/hospital_rate_contracts"
                component={RateContract}
              />
              <Route
                exact
                path="/hospital/product_masters"
                component={ProductMaster}
              />
              <Route
                exact
                path="/hospital/product_masters/products/:productMasterId"
                component={Products}
              />
              <Route
                path="/hospital/product_masters_category/:categoryId/:category"
                component={CategoryProductTable}
              />
              <Route
                path="/hospital/new_product_masters_category/:categoryId/:category"
                component={NewProducts}
              />
              <Route
                path="/hospital/product_masters/product_codes"
                component={ProductCodes}
              />
              <FilterContextProvider>
                <Route
                  exact
                  path="/hospital/top_molecules"
                  component={TopMolecule}
                />
                <Route
                  exact
                  path="/hospital/top_molecules/:ids/:moleculeName"
                  component={MoleculeDetails}
                />
              </FilterContextProvider>
              <Route
                exact
                path="/hospital/top_products"
                component={TopProducts}
              />
              <Route
                exact
                path="/hospital/top_manufacturers"
                component={TopManufacturers}
              />
              <Route
                exact
                path="/hospital/analytics/new_grn_report"
                component={FastGrnCompliance}
              />
              <Route
                exact
                path="/hospital/analytics/invoice_reports"
                component={AllInvoices}
              />
              <Route
                exact
                path="/hospital/analytics/grn_reports"
                component={AllGrn}
              />
              <Route
                exact
                path="/hospital/analytics/expiry_check"
                component={ExpiryCheckReport}
              />
              <Route
                exact
                path="/hospital/analytics/savings_report"
                component={SavingsReport}
              />
              <FilterContextProvider>
                <Route
                  exact
                  path="/hospital/cross_center_procurement_report"
                  component={CrossProductProcurementReport}
                />
              </FilterContextProvider>
              <Route
                exact
                path="/hospital/ordered_skus/:orderedSkusId"
                component={OrderedSku}
              />
              <Route
                exact
                path="/hospital/ordered_skus"
                component={OrderedSku}
              />
              <Route
                exact
                path="/hospital/users/:id/my_profile"
                component={UserProfile}
              />
              <Route
                path="/hospital/product_wise_invoices/:status?"
                component={ProductWiseInvoiceDetail}
              />
              <FilterContextProvider>
                <Route
                  path="/hospital/rc_compliance"
                  component={RcComplianceReport}
                />
                <Route
                  path="/hospital/product_order_frequency"
                  component={ProductOrderFrequency}
                />
                <Route
                  path="/hospital/product_specific_compliance"
                  component={ProductSpecificComplianceReport}
                />
                <Route
                  exact
                  path="/hospital/product_returns"
                  component={ProductReturns}
                />
                <Route path="/hospital/dashboards" component={Dashboard} />
              </FilterContextProvider>
              <ProtectedRoute />
            </div>
          </AppContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
export default App;
