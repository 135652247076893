import React from "react";

const SurgicalProducts = ({ ...props }) => (
  <svg
    width="15"
    height="24"
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.24828 17.808H6.00027C5.70352 17.808 5.46302 17.5703 5.46302 17.2771V16.2594H4.43325C4.13649 16.2594 3.896 16.0217 3.896 15.7284V13.5068C3.896 13.2135 4.13649 12.9759 4.43325 12.9759H5.46302V11.9584C5.46302 11.6652 5.70352 11.4275 6.00027 11.4275H8.24828C8.54504 11.4275 8.78553 11.6652 8.78553 11.9584V12.9759H9.81503C10.1118 12.9759 10.3523 13.2135 10.3523 13.5068V15.7284C10.3523 16.0217 10.1118 16.2594 9.81503 16.2594H8.78553V17.2771C8.78582 17.5703 8.54504 17.808 8.24828 17.808ZM6.53752 16.7461H7.71103V15.7284C7.71103 15.4352 7.95153 15.1975 8.24828 15.1975H9.27778V14.0377H8.24828C7.95153 14.0377 7.71103 13.8001 7.71103 13.5068V12.4894H6.53752V13.5068C6.53752 13.8001 6.29703 14.0377 6.00027 14.0377H4.9705V15.1975H6.00027C6.29703 15.1975 6.53752 15.4352 6.53752 15.7284V16.7461Z"
      fill="inherit"
    />
    <path d="M10.6539 2.36328H3.59425C3.57541 2.36328 3.55656 2.36328 3.54053 2.36599H10.7076C10.6916 2.36328 10.6727 2.36328 10.6539 2.36328Z" />
    <path
      d="M11.1917 4.59838V3.42752H11.4254C11.7236 3.42752 11.9627 3.18846 11.9627 2.89657V0.530946C11.9627 0.236285 11.7236 0 11.4254 0H2.82352C2.52536 0 2.28627 0.236285 2.28627 0.530946V2.89657C2.28627 3.18873 2.52536 3.42752 2.82352 3.42752H3.05726V4.59838C1.2143 5.3153 0 6.88423 0 8.60188V19.208C0 19.4893 0.034879 19.7787 0.104637 20.0603C0.104637 20.0631 0.104637 20.0631 0.104637 20.0631C0.109982 20.0842 0.112794 20.1056 0.118138 20.1267C0.628667 22.1549 2.80186 23.6285 5.28952 23.6285H8.95914C11.444 23.6285 13.6175 22.1549 14.1331 20.1267V20.124C14.1331 20.1212 14.1331 20.1187 14.1359 20.1159C14.1412 20.0973 14.144 20.0762 14.1466 20.0575C14.2163 19.7681 14.2487 19.4893 14.2487 19.208V8.60188C14.2487 6.88673 13.0344 5.3178 11.1917 4.59838ZM3.36077 1.06189H10.8879V2.36535H3.36077V1.06189ZM1.0745 8.60188C1.0745 7.23727 2.15181 5.98162 3.75569 5.47708C3.97874 5.40814 4.13176 5.20355 4.13176 4.97255V3.42474H10.1172V4.97255C10.1172 5.20355 10.2677 5.40787 10.4904 5.47708C12.0968 5.98162 13.1742 7.23727 13.1742 8.60188V8.70529H1.0745V8.60188ZM1.0745 9.76718H13.1742V19.208C13.1742 19.2931 13.1688 19.3806 13.1607 19.4682H1.088C1.07984 19.3806 1.0745 19.2956 1.0745 19.208V9.76718ZM8.95914 22.5666H5.28952C3.58354 22.5666 2.07109 21.741 1.41851 20.5304H12.833C12.1776 21.741 10.6623 22.5666 8.95914 22.5666Z"
      fill="inherit"
    />
    <path d="M4.13184 3.4248V3.42752H10.1173V3.4248H4.13184Z" />
  </svg>
);

export default SurgicalProducts;
