import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../../../../../components/glyphs/elements/back";
import Loading from "../../../../../components/loading";
import NoResults from "../../../../../components/no-results";
import Pagination from "../../../../../components/pagination";
import PillChart from "../../../../../components/pill-chart";
import Primitives from "../../../../../components/primitives";
import Table from "../../../../../components/table-two/TableComponent";
import { colors } from "../../../../../components/utils/theme";
import DownloadBtn from "../../../../../lib/download";
import Filters from "../../../../../lib/filters";
import Navigation from "../../../../../lib/navigation";
import OtifService from "../../../../../services/reports/order-fulfilment/index";
import DisplayAllItems from "../../../lib/display-all-items";
import PillChartHeader from "../../pillchart-header";
import downloadHandlerFn from "../../../../../components/utils/download-handler";
import { BASE_URL } from "../../../../../services/config";
import splitDateRange from "../../../../../utils/split-date-range";
import { FulfillmentContext } from "../../../../../utils/fulfillment-report-context";
import { subDays } from "date-fns";
import formatDate from "../../../../../utils/format-date";

const downloadHandler = async (setIsDownload, query) =>
  downloadHandlerFn(
    `${BASE_URL}/reports/sku_wise_otif_report.xlsx?${query}&is_download=true`,
    setIsDownload
  );

const cellFn = data => (
  <Primitives.Flex color={colors.text[0]} pl={2} py={1}>
    {data}
  </Primitives.Flex>
);

const column = [
  { heading: cellFn("SKU Name"), type: "string", width: 1 },
  { heading: cellFn("Manufacturer"), type: "string", width: 1 },
  { heading: cellFn("Quantity Ordered"), type: "number", width: 0.5 },
  {
    heading: (
      <PillChartHeader
        mainHeading="OTIF BREAKDOWN"
        subHeading={["EARLY(24 HRS)", "ON TIME(1-2 DAYS)", "LATE(2+ DAYS)"]}
        padding={2}
      />
    ),
    type: "string",
    width: 1
  }
];

const chartData = (data, tableData) => [
  {
    backgroundColor: "#036516",
    bottomLabel: data["early"],
    color: "#036516",
    textAlign: "start",
    topLabel: data.header && "EARLY(24 HRS)",
    width: data["early"]
  },
  {
    backgroundColor: colors.success[1],
    bottomLabel: data["on_time"],
    color: colors.success[1],
    textAlign: "center",
    topLabel: data.header && "ON TIME(1-2 DAYS)",
    width: data["on_time"]
  },
  {
    backgroundColor: tableData ? "#F4B513" : "#F4B513",
    bottomLabel: data["late"],
    color: tableData ? "#F4B513" : "#F4B513",
    textAlign: "end",
    topLabel: data.header && "LATE(2+ DAYS)",
    width: data["late"]
  }
];

const headerFn = (heading, value) => (
  <Primitives.Flex flexDirection="column">
    <Primitives.Text color={colors.text[1]}>{heading}</Primitives.Text>
    <Primitives.Text pt={1}>{value}</Primitives.Text>
  </Primitives.Flex>
);

const modifyData = data =>
  data &&
  data.map(item => ({
    sku: cellFn(item.sku_name),
    comapny: cellFn(item.company_name),
    quantity: cellFn(item.total_quantity),
    breakdown: (
      <Primitives.Flex flex={1} flexDirection="column" p={2} pt={0}>
        <PillChart centerWidth="92%" data={chartData(item, true)} />
      </Primitives.Flex>
    )
  }));

const CategoryOrderDetails = () => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const fulfillmentContext = useContext(FulfillmentContext);
  const categoryId = useParams().otifCategory;
  const startDate = fulfillmentContext.otifStartDate,
    endDate = fulfillmentContext.otifEndDate;
  const [pageNumber, setPageNumber] = useState(1);
  const [query, updateQuery] = useState(
    `category_id=${categoryId}&order_date_gteq=${startDate}&order_date_lteq=${endDate}`
  );
  const [isDownload, setIsDownload] = useState(false);

  const fetchPurchaseOrderOtif = async query => {
    try {
      setIsLoaded(false);
      const response = await OtifService.categoryOrderDeatils(query);
      if (response) {
        setIsLoaded(true);
        setData(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setPageNumber(1);
    }
  };

  const filterUpdate = queryObj => {
    const dateRange = splitDateRange(queryObj.date);
    const start_date =
      (dateRange && dateRange.startDate) ||
      formatDate(subDays(subDays(new Date(), 3), 29));
    const end_date =
      (dateRange && dateRange.endDate) ||
      formatDate(subDays(new Date(), 3), 29);
    fulfillmentContext.updateOtifStartDate(start_date);
    fulfillmentContext.updateOtifEndDate(end_date);
    updateQuery(
      `category_id=${categoryId}&${`&order_date_gteq=${start_date}&order_date_lteq=${end_date}`}&q[master_sku_name_cont]=${queryObj.skuNameSearch ||
        ""}&q[master_sku_universal_sku_universal_company_name_cont]=${queryObj.manufacturerSearch ||
        ""}`
    );
  };

  useEffect(() => {
    query && query.length > 0 && fetchPurchaseOrderOtif(query);
  }, [query]);

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 3]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Primitives.Flex
          backgroundColor={colors["background-blue"]}
          flexDirection="column"
          pb={2}
          pt={4}
          px={5}
        >
          <Primitives.Flex alignItems="flex-end">
            <Primitives.Flex mr={2}>
              <Link exact="true" to="/hospital/fulfillment_reports">
                <Back fill={colors.text[0]} />
              </Link>
            </Primitives.Flex>
            <Primitives.Text color={colors.text[0]} fontSize={5} fontWeight={2}>
              {data.header_details &&
                data.header_details[0] &&
                data.header_details[0].category}
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex height="91px">
            {data && data.header_details && data.header_details[0].category && (
              <Primitives.Flex flex={1} flexDirection="row" pt={3}>
                <Primitives.Flex flex={3} justifyContent="space-between" px={4}>
                  {headerFn(
                    "Unique Skus Ordered",
                    data.header_details[0].total_products
                  )}
                  {headerFn(
                    "Total Quantity Ordered",
                    data.header_details[0].total_quantity
                  )}
                </Primitives.Flex>
                <Primitives.Flex flex={9}>
                  {data.header_details[0].total_products > 0 && (
                    <Primitives.Flex
                      borderLeft="1px solid"
                      borderColor={colors.text[1]}
                      flexDirection="row"
                      pl={4}
                    >
                      <Primitives.Text>OTIF Breakdown:</Primitives.Text>
                      <Primitives.Flex ml={3} width="53vw">
                        <PillChart
                          data={chartData(
                            {
                              early: data.header_details[0].early,
                              on_time: data.header_details[0].on_time,
                              late: data.header_details[0].late,
                              header: true
                            },
                            false
                          )}
                          centerWidth="30%"
                        />
                      </Primitives.Flex>
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Box mt={2} pl={5}>
          <Filters
            config={{
              skuNameSearch: true,
              manufacturerSearch: true,
              otifCategoryMargin: true,
              date: { buffer: 3 },
              defaultDateRange: { startDate: startDate, endDate: endDate }
            }}
            filterUpdate={filterUpdate}
          />
        </Primitives.Box>
        {(data && data.total_sku_count && data.total_sku_count > 0 && (
          <Primitives.Flex alignItems="center" mb={20} mt={3} px={5}>
            <Primitives.Flex fontSize={1} color={colors.text[1]}>
              <DisplayAllItems
                currentPage={pageNumber}
                itemsPerPage={20}
                totalItems={data.total_sku_count}
                title="Orders"
              />
            </Primitives.Flex>
            <Primitives.Flex flex={1} justifyContent="flex-end">
              <Primitives.Flex flex={1} justifyContent="flex-end">
                {isDownload ? (
                  <Loading onlySpinner />
                ) : (
                  <DownloadBtn
                    clickHandler={() => {
                      downloadHandler(setIsDownload, query);
                    }}
                  />
                )}
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
        )) ||
          ""}
        {isLoaded && data && data.sku_otifs ? (
          <Primitives.Flex flexDirection="column" overflow="scroll" px={5}>
            <Primitives.Flex
              backgroundColor="white"
              borderRadius={10}
              border="1px solid"
              borderColor={colors["athens-gray"]}
              boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.1)"
              flexDirection="column"
              my={2}
            >
              {data && data.sku_otifs.length > 0 ? (
                <Table
                  borderBottom={{
                    head: `1px solid ${colors["pattens-blue"]}`,
                    row: "0px"
                  }}
                  color={{ head: colors.text[1], row: colors.text[0] }}
                  data={modifyData(data.sku_otifs)}
                  column={column}
                  p={{ headContainer: 0, row: 0 }}
                  stripedColumns="even"
                />
              ) : (
                <Primitives.Flex m="auto" my={7}>
                  <NoResults />
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <Primitives.Box mt={100}>
            <Loading />
          </Primitives.Box>
        )}
        {data && data.total_sku_count > 20 && (
          <Primitives.Flex
            backgroundColor={colors.accent[0]}
            bottom={0}
            right={0}
            width="100vw"
            justifyContent="flex-end"
            zIndex={2}
          >
            <Pagination
              changeHandler={paginationChangeHandler}
              forcePage={pageNumber - 1}
              marginPagesDisplayed={1}
              pageCount={data.total_sku_count / 20}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

export default CategoryOrderDetails;
