import React, { useContext, useEffect, useState } from "react";
import Table from "../../components/table-two/TableComponent";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import OrderStatus from "../../components/order-status";
import Pagination from "../../components/pagination";
import OrdersListingService from "../../services/order-listing/index";
import Detail from "./detail/";
import { AppContext } from "../../utils/context";
import Loading from "../../components/loading";
import NoResults from "../../components/no-results";
import Navigation from "../../lib/navigation";
import Filters from "../../lib/filters";
import Tabs from "./tabs";
import DisplayAllItems from "../reports/lib/display-all-items";
import { useParams } from "react-router";
import paginationHandler from "../../components/utils/pagination-handler";
import { convertDate } from "../../components/utils";
import downloadHandlerFn from "../../components/utils/download-handler";
import { BASE_URL } from "../../services/config";
import Frozen from "../../components/glyphs/elements/frozen";

const index = {
  0: "status",
  2: "created_at",
  4: "total_amount",
  5: "total_pending_amount"
};

const statusTabs = ["ongoing", "delivered", "all"];

// is_cold_storage
const modifyData = data =>
  data &&
  data.map(item => ({
    status: (
      <Primitives.Flex alignItems="center">
        <Primitives.Flex>
          <OrderStatus status={item.status.replace(/_/g, " ")} />
        </Primitives.Flex>
        <Primitives.Flex ml={1}>
          <Primitives.Text>
            {item.status.includes("_")
              ? item.status.split("_")[0][0].toUpperCase() +
                item.status.split("_")[0].substring(1) +
                " " +
                item.status.split("_")[1][0].toUpperCase() +
                item.status.split("_")[1].substring(1)
              : item.status[0].toUpperCase() + item.status.substring(1)}
          </Primitives.Text>
        </Primitives.Flex>
      </Primitives.Flex>
    ),
    po_number: (
      <Primitives.Flex>
        <Primitives.Text>{item.po_number}</Primitives.Text>
        {item.is_cold_storage && (
          <Primitives.Flex pr={1}>
            <Frozen height="16px" />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    ),
    order_date: item.order_date
      .replace(/-/g, "/")
      .split("/")
      .reverse()
      .join("/"),
    buyer_name: item.buyer_name,
    total_amount: "₹".concat(item.total_amount.toFixed(2)),
    total_pending_amount: "₹".concat(item.total_pending_amount.toFixed(2))
  }));

const column = [
  {
    heading: "Status",
    type: "object",
    width: 1.5
  },
  {
    heading: "PO Number",
    type: "string",
    width: 1.2,
    sort: false
  },
  {
    heading: "Order Date",
    type: "string",
    width: 0.8
  },
  {
    heading: "Hospital",
    type: "string",
    width: 2,
    sort: false
  },
  {
    heading: "Total Amt.",
    type: "string",
    align: "flex-end",
    width: 1
  },
  {
    heading: "Pending Amt.",
    type: "string",
    align: "flex-end",
    width: 1
  }
];

const OrderListing = () => {
  const status = useParams().status;
  const [orderDetail, updateOrderDetail] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(0);
  const [sort, updateSort] = useState("");
  const [sortingIndex, updateSortingIndex] = useState(null);
  const [order, toggleOrder] = useState("desc");
  const { defaultHospital, tenants } = useContext(AppContext);
  const localHospital = localStorage.getItem("hospital");
  const localDistributor = localStorage.getItem("distributor");
  const [data, setData] = useState([]);
  const [currentTab, updateCurrentTab] = useState(0);
  const [orderStatus, updateOrderStatus] = useState([]);
  const [filterZindex, toggleZindex] = useState(false);
  const date = new Date();
  const defaultDate = `&q[order_date_gteq]=${convertDate(
    new Date(date.getFullYear(), date.getMonth(), 1),
    "-"
  )}&q[order_date_lteq]=${convertDate(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    "-"
  )}`;
  const [query, updateQuery] = useState(`${status || defaultDate}&tab=ongoing`);

  const cancelRequest = () => {
    fetchOrders(`&cancel=${new Date().getTime()}`);
  };
  const [clearFilter, setClearFilter] = useState(false);
  const fetchOrders = async query => {
    try {
      const response = await OrdersListingService.orders(query);
      if (response) {
        setData(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStatus = async tab => {
    try {
      const response = await OrdersListingService.statusData(tab);
      if (response && response["status"]) {
        updateOrderStatus(response["status"]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchOrders(
      `page=${currentPage}&q[s]=${sort}&${status ||
        ""}&${defaultDate}&tab=ongoing`
    );
  }, []);

  useEffect(() => {
    fetchStatus(`tab=${statusTabs[currentTab]}`);
    setData([]);
    fetchOrders(`tab=${statusTabs[currentTab]}`);
    setClearFilter(true);
  }, [currentTab]);

  useEffect(() => {
    fetchStatus(`tab=${statusTabs[currentTab]}`);
    setData([]);
    fetchOrders(`&page=${currentPage}&q[s]=${sort}&${query}`);
  }, [sort, currentPage]);

  useEffect(() => {
    updateOrderDetail({});
    data.response && updateOrderDetail(data.response[0]);
    setSelectedRow(0);
  }, [data]);
  useEffect(() => {
    if (localHospital === null) {
      defaultHospital &&
        localStorage.setItem("hospital", JSON.stringify(defaultHospital));
    }
  }, [defaultHospital]);

  useEffect(() => {
    if (localDistributor === null) {
      tenants &&
        tenants[0] &&
        localStorage.setItem(
          "distributor",
          JSON.stringify([tenants[0].name, tenants[0].id])
        );
    }
  }, [tenants]);

  /** pagination */
  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setCurrentPage(page);
  };

  /*sorting */
  const headClickHandler = id => {
    updateSortingIndex(id);
    toggleOrder(order === "asc" ? "desc" : "asc");
    id in index ? updateSort(`${index[id]}+${order}`) : updateSort(null);
  };

  /** right panel */
  const tableClickHandler = (row, index) => {
    updateOrderDetail(data.response[index]);
    setSelectedRow(index);
  };

  const hospitalMapper = data =>
    data.split(",").map(item => `&q[buyer_party_id_in][]=${item}`);

  const filterUpdate = queryObj => {
    const hospitals =
      queryObj.hospitals && queryObj.hospitals.split("=")[1]
        ? hospitalMapper(queryObj.hospitals.split("=")[1])
        : "";
    const universalHospitalIds = queryObj?.hospitalEntity?.split(
      "&hospital_entity_id="
    )[1]
      ? `&universal_hospital_ids=${
          queryObj?.hospitalEntity?.split("&hospital_entity_id=")[1]
        }`
      : "";
    const products = queryObj.orderListingProductSearch
      ? `"&q[sales_order_items_master_sku_universal_sku_id_in][]=${queryObj
          .orderListingProductSearch.id ||
          ""}&q[sales_order_items_product_name_cont][]=${queryObj
          .orderListingProductSearch.name || ""}`
      : "";
    const centers = queryObj.hospitalCenter || "";
    const date = queryObj.date
      ? `&q[order_date_lteq]=${queryObj.date
          .split("&")[1]
          .split("=")[1]
          .split("/")
          .join("-")}&q[order_date_gteq]=${queryObj.date
          .split("&")[0]
          .split("=")[1]
          .split("/")
          .join("-")}`
      : defaultDate;

    updateQuery(
      `q[po_number_cont]=${queryObj.orderListingSearch || ""}&tab=${
        statusTabs[currentTab]
      }&${date}${hospitals}${universalHospitalIds}${products}${centers}${
        queryObj.status && queryObj.status.split("=")[1] ? queryObj.status : ""
      }`
    );
  };
  const downloadHandler = setIsDownload => {
    downloadHandlerFn(
      `${BASE_URL}/orders?page=${currentPage}&button=${`&tab=${statusTabs[currentTab]}` ||
        ""}&page=${currentPage}&q[s]=${sort}&${query}&is_download=true`,
      setIsDownload
    );
  };

  useEffect(() => {
    setData([]);
    fetchOrders(query);
  }, [query]);
  return (
    <>
      <Navigation selected={1} subNavIndex={[1, 0]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="row"
        minHeight="100vh"
      >
        <Primitives.Flex flexDirection="column" flex={7} pt={2}>
          <Primitives.Flex flexDirection="column" mb={3}>
            <Primitives.Text fontSize={5} fontWeight={2} pl={30}>
              Order List
            </Primitives.Text>
            <Tabs
              clickHandler={downloadHandler}
              currentTab={currentTab}
              data={data}
              disableTab={data && data.response ? false : true}
              setCurrentPage={setCurrentPage}
              statusTabs={statusTabs}
              updateCurrentTab={updateCurrentTab}
            />
            <Primitives.Flex
              backgroundColor={colors["background-blue"]}
              borderTop={`1px solid ${colors.text[2]}`}
              pl={30}
              py={2}
              zIndex={filterZindex && 0}
            >
              <Filters
                config={{
                  orderListingSearch: true,
                  hospitalCenter: true,
                  hospitalEntity: true,
                  date: true,
                  defaultDateRange: true,
                  ...(currentTab !== 1 && { status: true }),
                  orderListingProductSearch: true,
                  currentTab: currentTab,
                  tabData: orderStatus
                }}
                filterUpdate={filterUpdate}
                flexWrap="wrap"
                clearAllFilters={clearFilter}
                setClearAllFilters={flag => setClearFilter(flag)}
              />
            </Primitives.Flex>
            {data.response && data.response.length > 0 && (
              <Primitives.Flex
                fontSize={1}
                color={colors.text[1]}
                pl="30px"
                mb={3}
              >
                <DisplayAllItems
                  currentPage={currentPage}
                  itemsPerPage={20}
                  totalItems={data.total_count}
                  title="Orders"
                />
              </Primitives.Flex>
            )}
            {data && data.response ? (
              <Primitives.Flex pl={30}>
                {data.response.length > 0 ? (
                  <Table
                    column={column}
                    data={modifyData(data.response)}
                    clickHandler={tableClickHandler}
                    cursor="pointer"
                    headClick={true}
                    headClickHandler={headClickHandler}
                    page="listing"
                    pl={12}
                    sorting={true}
                    selected={selectedRow}
                    sortingType={order === "asc" ? "desc" : "asc"}
                    sortingIndex={sortingIndex}
                  />
                ) : (
                  <Primitives.Flex margin="auto" mt={7}>
                    <NoResults />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            ) : (
              <Primitives.Flex justifyContent="center" pb={5}>
                <Loading />
              </Primitives.Flex>
            )}

            {data && data.total_count > 20 && (
              <Primitives.Flex justifyContent="flex-end">
                <Pagination
                  changeHandler={paginationChangeHandler}
                  forcePage={currentPage - 1}
                  marginPagesDisplayed={1}
                  pageCount={data.total_pages}
                  pageRangeDisplayed={5}
                />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex
          flex={3}
          position="relative"
          backgroundColor="background-blue"
        >
          {data?.response?.length > 0 ? (
            <Detail
              orderDetail={orderDetail}
              newRequest={cancelRequest}
              toggleZindex={data => toggleZindex(data)}
            />
          ) : (
            <Primitives.Flex flex={1} position="relative">
              <Primitives.Box mx="auto" alignSelf="center">
                <NoResults small={true} />
              </Primitives.Box>
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default OrderListing;
