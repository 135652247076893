import React, { useContext, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Tabs from "../tabs";
import InvoiceReport from "../invoice-report";
import PaymentLedger from "../payment-ledger";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import Loading from "../../../components/loading";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Back from "../../../components/glyphs/elements/back";
import Navigation from "../../../lib/navigation";

const tabsData = [
  {
    label: "Invoice Report",
    component: <InvoiceReport />
  },
  {
    label: "Payment Ledger",
    component: <PaymentLedger />
  }
];

const titleData = data => [
  {
    name: "Amount Due",
    value: data["header"] && data["header"]["pending_amount"]
  },
  {
    name: "Payment Due On",
    value: data["header"] && data["header"]["payment_due_on"]
  },
  {
    name: "Last Payment Made",
    value: data["header"] && data["header"]["last_paid_date"]
  },
  {
    name: "Hospital",
    value: data["header"] && data["header"]["hospital"]
  }
];

const title = (isLoaded, name, value) => (
  <Primitives.Flex flex="auto" flexDirection="column" key={name}>
    <Primitives.Text color={colors.text[1]}>{name}</Primitives.Text>
    {isLoaded ? (
      <Primitives.Text fontSize={2}>{value}</Primitives.Text>
    ) : (
      <Primitives.Flex height={20} width={20}>
        <Loading onlySpinner />
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);

const CentrePayments = () => {
  const [selected, setSelected] = useState(0);
  const hospitalId = useParams().id;
  const { BASE_URL } = useContext(AppContext);
  const request = `${BASE_URL}/payments/${hospitalId}`;
  const { data, isLoaded } = useApiRequest(request);

  const tabsHandler = index => setSelected(index);
  return (
    <>
      <Navigation selected={3} />
      <Primitives.Box backgroundColor="background-blue" flex={1}>
        <Primitives.Flex flexDirection="column" py={3} px={4}>
          <Primitives.Flex alignItems="flex-end" flexDirection="row">
            <Primitives.Flex mr={10}>
              <Link exact="true" to="/hospital/payments/">
                <Back fill="black" />
              </Link>
            </Primitives.Flex>
            {isLoaded ? (
              <Primitives.Text fontSize={5}>
                {data["header"] && data["header"]["hospital"]}
              </Primitives.Text>
            ) : (
              <Primitives.Flex height={20} width={30}>
                <Loading onlySpinner />
              </Primitives.Flex>
            )}
          </Primitives.Flex>

          <Primitives.Flex
            flexDirection="row"
            fontSize={2}
            ml={4}
            py={2}
            width={2 / 3}
          >
            {titleData(data).map(item =>
              title(isLoaded, item.name, item.value)
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Box>

      <Primitives.Flex flexDirection="column">
        <Primitives.Flex
          backgroundColor="white"
          borderBottom="1px solid"
          borderColor="athens-gray"
          borderTop="1px solid"
          px={5}
        >
          <Tabs
            selected={selected}
            tabsHandler={tabsHandler}
            tabsData={tabsData}
          />
        </Primitives.Flex>
        <Primitives.Flex backgroundColor="white" px={5} py={4}>
          {tabsData[selected].component}
        </Primitives.Flex>
      </Primitives.Flex>
    </>
  );
};

export default CentrePayments;
