import React, { useEffect, useState } from "react";
import Checkbox from "../../../components/checkbox";
import Dropdown from "../../../components/dropdown";
import { array, bool, func, object, oneOfType, string } from "prop-types";
import Primitives from "../../../components/primitives";

const propTypes = {
  clearFilter: bool,
  config: string,
  dropdownData: oneOfType([array, object, string]),
  filterContextState: oneOfType([array, object, string]),
  getConfig: func,
  getSelectedItems: func,
  heading: string,
  selectType: string
};

const filterObject = {
  hospitalEntity: {
    id: "id",
    name: "display_name",
    placeholder: "All Hospitals Selected"
  },
  crossProcurementHospital: {
    id: "id",
    name: "display_name",
    placeholder: "Select Hospital"
  },
  category: {
    id: "category_id",
    name: "category_name",
    placeholder: " Select Category"
  },
  crossProcurementCategory: {
    id: "category_id",
    name: "category_name",
    placeholder: " Select Category"
  },
  months: {
    id: "id",
    name: "text",
    placeholder: "Select Month",
    preSelected: true
  },
  procurementsMonths: {
    id: "id",
    name: "text",
    placeholder: "Select Month"
  },
  hospitals: {
    id: "id",
    name: "display_name",
    placeholder: "Hospital"
  },
  entity: {
    id: "id",
    name: "legal_name",
    placeholder: "Select Hospital"
  },
  hospitalCenter: {
    id: "id",
    name: "legal_name",
    placeholder: "Select Center"
  },
  city: {
    id: "id",
    name: "name",
    placeholder: "Select City"
  },
  hospitalEntitySingleSelect: {
    id: "id",
    name: "display_name",
    placeholder: "Select Hospital",
    preSelected: true
  },
  skus: {
    id: "id",
    name: "name",
    placeholder: "Select SKU"
  },
  invoices: {
    id: "id",
    name: "number",
    placeholder: "Select Invoices"
  },
  invoiceStatus: {
    id: "id",
    name: "display_name",
    placeholder: "Select Invoice Status"
  },
  poNumbers: {
    id: "id",
    name: "number",
    placeholder: "Select PO Number"
  },
  moleculeGroup: {
    id: "molecules_id",
    name: "molecule_group_name",
    placeholder: "Select Molecule Groups"
  },
  molecules: {
    id: "molecules_id",
    name: "molecule_name",
    placeholder: "Select Molecules"
  },
  universalMolecules: {
    id: "id",
    name: "name",
    placeholder: "Select Molecules"
  },
  subcategory: {
    id: "id",
    name: "name",
    placeholder: "Select Product Category"
  },
  fulfilmentStatus: {
    id: "id",
    name: "name",
    placeholder: "Select Fulfillment"
  },
  fulfilmentManufacturer: {
    id: "id",
    name: "name",
    placeholder: "Select Manufacturer"
  },
  hospitalSingleSelect: {
    id: "id",
    name: "display_name",
    placeholder: "Select Hospital"
  },
  marginCategoryManufacturer: {
    id: "company_id",
    name: "company_name",
    placeholder: "Select Manufacturer"
  },
  distributor: {
    id: "id",
    name: "name",
    placeholder: "Select Distributor"
  },
  distributorSingleSelect: {
    id: "id",
    name: "name",
    placeholder: "Select Distributor",
    preSelected: true
  },
  rcAvailability: {
    id: "id",
    name: "name",
    placeholder: "Select RC Availabilty",
    preSelected: true
  },
  rcFollowed: {
    id: "id",
    name: "name",
    placeholder: "Select Filters"
  },
  moleculeStrength: {
    id: "id",
    name: "name",
    placeholder: "Molecule Strength"
  },
  status: {
    id: "name",
    name: "display_name",
    placeholder: "Select Status"
  }
};

const GenericDropdown = ({
  clearFilter,
  config,
  dropdownData,
  filterContextState,
  getConfig,
  getSelectedItems,
  heading,
  selectType
}) => {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [collapse, setCollpase] = useState(false);
  const [searchText, updateSearchText] = useState("");

  const multiSelectHandler = option => {
    const id =
      dropdownData &&
      dropdownData.filter(
        item => item[filterObject[config].name] === option.text
      )[0][filterObject[config].id];
    !selectedEntities.includes(id)
      ? setSelectedEntities([...selectedEntities, id])
      : setSelectedEntities(selectedEntities.filter(data => data !== id));
    updateSearchText("");
  };

  const singleSelectHandler = option => {
    !dropdownData.includes(option.text)
      ? setSelectedEntities([option.text, option.id])
      : setSelectedEntities(
          selectedEntities.filter(data => data !== option.text),
          option.id
        );
    setCollpase(false);
    updateSearchText("");
  };

  const handleClick = e => {
    if (
      !e.target.closest(".dropdown-filter") &&
      !e.target.closest(".rdrDateRangeWrapper")
    ) {
      setCollpase(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    getSelectedItems(selectedEntities);
    getConfig(config);
  }, [selectedEntities]);

  useEffect(() => {
    !clearFilter && setSelectedEntities([]);
  }, [clearFilter]);

  useEffect(() => {
    if (filterContextState) {
      const entityArray = filterObject[config].preSelected
        ? filterContextState
        : filterContextState.split(",").map(Number);
      setSelectedEntities(entityArray);
    }
  }, [filterContextState]);
  return (
    <Primitives.Flex flexDirection="column" zIndex={3}>
      {heading && (
        <Primitives.Text color="#333333" fontSize="12px" mb={1}>
          {heading}
        </Primitives.Text>
      )}
      <Dropdown
        className="dropdown-filter"
        changeHandler={e => updateSearchText(e.target.value)}
        collapsed={collapse}
        defaultValue={
          config === "months"
            ? selectedEntities.length > 0
              ? selectedEntities[0]
              : dropdownData[0].text
            : null
        }
        placeholder={
          selectedEntities.length > 0
            ? selectType === "singleSelect"
              ? selectedEntities[0]
              : `${selectedEntities.length} selected`
            : filterObject[config].placeholder
        }
        flex={1}
        focusHandler={() => setCollpase(true)}
        id={config}
        name={config}
        listMaxHeight={195}
        listMaxWidth={30}
        listWidth={320}
        options={
          selectType === "singleSelect"
            ? dropdownData &&
              dropdownData.map(month => {
                return {
                  text: month[filterObject[config].name],
                  id: month[filterObject[config].id]
                };
              })
            : dropdownData &&
              dropdownData.map(hospital => {
                return {
                  glyph: (
                    <Checkbox
                      checked={selectedEntities.includes(
                        hospital[filterObject[config].id]
                      )}
                    />
                  ),
                  text: hospital[filterObject[config].name]
                };
              })
        }
        search={searchText}
        searchable={true}
        selectHandler={
          selectType === "singleSelect"
            ? singleSelectHandler
            : multiSelectHandler
        }
        selectType={selectType}
      />
    </Primitives.Flex>
  );
};

GenericDropdown.propTypes = propTypes;
export default GenericDropdown;
