import React from "react";

const Delete = ({ ...props }) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    xmlns="http://www.w3.org/2000/svg"
    title="delete-icon"
    {...props}
  >
    <path
      d="M17.7173 2.59949H12.5262V0.564354C12.5262 0.252845 12.2737 0.000112311 11.962 0.000112311H6.33076C6.17912 -0.00291044 6.03268 0.0551909 5.92454 0.161324C5.81623 0.267458 5.75527 0.412716 5.75527 0.564354V2.59949H0.564242C0.252565 2.59949 0 2.85206 0 3.16374C0 3.47524 0.252565 3.72798 0.564242 3.72798H1.06838L1.73034 19.019C1.7604 19.7465 2.07107 20.434 2.59702 20.9376C3.12296 21.4411 3.82339 21.7213 4.55155 21.7198H13.7299C14.458 21.7213 15.1584 21.4411 15.6844 20.9376C16.2104 20.434 16.521 19.7465 16.5511 19.019L17.2131 3.72798H17.7172C18.0289 3.72798 18.2814 3.47524 18.2814 3.16374C18.2814 2.85206 18.0289 2.59949 17.7172 2.59949H17.7173ZM6.88382 1.1286H11.3978V2.59949H6.88382V1.1286ZM15.4225 18.9698C15.404 19.4063 15.2176 19.8187 14.9021 20.1208C14.5867 20.4231 14.1666 20.5915 13.7297 20.5911H4.55141C4.11463 20.5915 3.69445 20.423 3.37908 20.1208C3.06354 19.8187 2.87713 19.4063 2.85868 18.9698L2.19673 3.72776H16.0846L15.4225 18.9698ZM6.72949 7.20349L7.06048 17.074C7.06552 17.2249 7.00993 17.3715 6.90598 17.4812C6.80203 17.5907 6.65845 17.654 6.50749 17.657C6.20303 17.6572 5.9533 17.4159 5.94325 17.1116L5.60101 7.22991C5.59631 6.92814 5.83006 6.67624 6.13133 6.6581C6.28381 6.64786 6.43394 6.70025 6.54679 6.80319C6.65964 6.90613 6.72563 7.05072 6.7295 7.20354L6.72949 7.20349ZM12.1501 6.65804C12.4513 6.67618 12.6851 6.92807 12.6804 7.22985L12.3494 17.1003C12.3474 17.2506 12.2856 17.3939 12.1776 17.4983C12.0695 17.6029 11.9242 17.66 11.7739 17.657C11.6243 17.6521 11.4827 17.5878 11.3803 17.4785C11.278 17.3692 11.2235 17.2236 11.2285 17.074L11.5595 7.20348C11.5623 7.05184 11.627 6.90776 11.7385 6.80482C11.8498 6.70188 11.9986 6.64881 12.1501 6.65805L12.1501 6.65804Z"
      fill="#F58158"
    />
  </svg>
);
export default Delete;
