import React, { useState, useContext, useEffect } from "react";
import { colors } from "../../../components/utils/theme";
import Button from "../../../components/button";
import Cta from "../../../components/cta";
import Primitives from "../../../components/primitives";
import Download from "../../../components/glyphs/elements/download";
import Table from "../../../components/table-two/TableComponent";
import OrderStatus from "../../../components/order-status";
import NoResult from "../../../components/no-results";
import Loading from "../../../components/loading";
import TableDownloadButton from "./download";
import UploadNewModal from "./upload-modal";
import useApiRequest from "../../../hooks/useApiRequest";
import { AppContext } from "../../../utils/context";
import Pagination from "../../../components/pagination";
import paymentStatus from "../../../lib/payment-status";
import Navigation from "../../../lib/navigation";
import paginationHandler from "../../../components/utils/pagination-handler";

const column = [
  {
    heading: "STATUS",
    type: "string"
  },
  {
    heading: "UPLOAD DATE",
    type: "string"
  },
  {
    heading: "RATE CONTRACT END DATE",
    type: "string"
  },
  {
    heading: "RATE CONTRACT NAME",
    type: "string"
  },
  {
    heading: "UNIQUE ITEMS IN RATE CONTRACT",
    type: "number",
    align: "flex-end"
  },
  {
    heading: " ",
    type: "object",
    align: "flex-end",
    width: 0.5
  }
];

const modifyTableData = data =>
  data.map(item => ({
    STATUS: (
      <Primitives.Flex alignItems="center">
        <OrderStatus
          status={
            item["status"] in paymentStatus
              ? paymentStatus[item["status"]]
              : "partially completed"
          }
        />
        <Primitives.Text ml={1}>
          {item["status"]?.charAt(0).toUpperCase() + item["status"]?.slice(1)}
        </Primitives.Text>
      </Primitives.Flex>
    ),
    "UPLOAD DATE": item["upload_date"],
    "RATE CONTRACT END DATE": !item["rc_end_date"] ? "--" : item["rc_end_date"],
    "RATE CONTRACT NAME": item["rc_name"],
    "UNIQUE ITEMS IN RATE CONTRACT": item["uniq_items"],
    "": (
      <TableDownloadButton
        rateContract={item["rc_name"]}
        supportingDocs={item["supporting_docs"]}
        url={item["download_url"]}
      />
    )
  }));

const RateContract = () => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/hospital_rate_contracts`
  );
  const { data, isLoaded } = useApiRequest(request);
  const [pageNumber, setPageNumber] = useState(1);

  const [showUploadModal, toggleUploadModal] = useState(false);

  const paginationChangeHandler = pageNumber => {
    const page = paginationHandler(pageNumber);
    page && setPageNumber(page);
  };

  useEffect(() => {
    updateRequest(`${BASE_URL}/hospital_rate_contracts?page=${pageNumber}`);
  }, [pageNumber, BASE_URL]);
  return (
    <>
      <Navigation selected={2} subNavIndex={[2, 1]} />
      <Primitives.Flex flexDirection="column" mx={5} mt={4}>
        <Primitives.Flex backgroundColor="white">
          <Primitives.Flex flexDirection="column">
            <Primitives.Text color={colors.text[0]} fontSize={5} fontWeight={2}>
              Rate Contract
            </Primitives.Text>
            {data &&
              data["hospital_rate_contracts"] &&
              data["hospital_rate_contracts"].length > 0 && (
                <Primitives.Text
                  color={colors.text[1]}
                  fontSize={1}
                  fontWeight={1}
                  mt={2}
                >
                  Showing all {data["total_items"]} entries
                </Primitives.Text>
              )}
          </Primitives.Flex>
          <Primitives.Flex
            alignItems="center"
            justifyContent="center"
            ml="auto"
          >
            {data && data["sample_file"] && (
              <Primitives.Flex mr={3}>
                <Cta
                  color="inherit"
                  label={
                    <Button
                      active={{
                        backgroundColor: "background-light",
                        color: colors.text[1],
                        borderColor: "inherit"
                      }}
                      backgroundColor="background-light"
                      color={colors.text[1]}
                      fontWeight={1}
                      glyph={<Download height={20} width={20} />}
                      hover={{
                        backgroundColor: colors.text[2],
                        color: colors.text[0]
                      }}
                      label="Download Sample File"
                      lineHeight={3}
                      px={2}
                      py={1}
                    />
                  }
                  link={data["sample_file"]}
                  hover={{ color: "inherit", textdecorationline: false }}
                  target="_blank"
                  textdecorationline={false}
                  type="anchor"
                />
              </Primitives.Flex>
            )}

            {data && data["sample_file"] && (
              <Button
                active={{
                  backgroundColor: "white",
                  borderColor: colors.primary[3],
                  color: colors.primary[3]
                }}
                backgroundColor="white"
                border="1px solid"
                clickHandler={() => {
                  toggleUploadModal(true);
                }}
                color={colors.primary[3]}
                fontWeight={2}
                hover={{ backgroundColor: colors.primary[3], color: "white" }}
                label="Upload New Rate Contract"
                lineHeight={3}
                px={2}
                py={1}
              />
            )}
            {showUploadModal && (
              <UploadNewModal toggleUploadModal={toggleUploadModal} />
            )}
          </Primitives.Flex>
        </Primitives.Flex>
        {isLoaded ? (
          <Primitives.Flex flexDirection="column" mt={4} pb={6}>
            {data &&
            data["hospital_rate_contracts"] &&
            data["hospital_rate_contracts"].length > 0 ? (
              <Primitives.Flex>
                <Table
                  color={{ head: colors.text[1], row: colors.text[0] }}
                  data={modifyTableData(data["hospital_rate_contracts"])}
                  column={column}
                />
                {data && data["total_items"] && data["total_items"] > 20 && (
                  <Primitives.Flex
                    backgroundColor={colors.accent[0]}
                    bottom={0}
                    position="fixed"
                    pr={3}
                    right={0}
                    width="100vw"
                    justifyContent="flex-end"
                  >
                    <Pagination
                      changeHandler={paginationChangeHandler}
                      forcePage={pageNumber - 1}
                      marginPagesDisplayed={1}
                      pageCount={parseInt(data["total_items"]) / 20}
                    />
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            ) : (
              <Primitives.Flex
                alignItems="center"
                flex={1}
                flexDirection="column"
                mt={7}
              >
                <NoResult />
              </Primitives.Flex>
            )}
          </Primitives.Flex>
        ) : (
          <Primitives.Flex m="auto" mt="20vh">
            <Loading />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

export default RateContract;
