import React, { createContext, useEffect, useState } from "react";
import { node } from "prop-types";
import useApiRequest from "../hooks/useApiRequest";

const propTypes = {
  children: node
};

export const AppContext = createContext("");

const AppContextProvider = ({ children }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [branches, updateBranch] = useState([]);
  const [hospitals, updateHospitals] = useState([]);
  const [categories, updateCategories] = useState([]);
  const [cities, updateCities] = useState([]);
  const [hospitalsEntities, updateHospitalsEntities] = useState([]);
  const [invoiceNumbers, updateInvoiceNumbers] = useState([]);
  const [poNumbers, updatePoNumbers] = useState([]);
  const [defaultHospital, updateDefaultHospital] = useState([]);
  const [getHospitalEntities, updateGetHospitalEntities] = useState([]);
  const [molecules, updateMolecules] = useState([]);
  const [moleculeGroups, updateMoleculeGroups] = useState([]);
  const [drafts, updateDrafts] = useState([]);
  const [fulfilmentStatus, updateFulfilmentStatus] = useState([]);
  const [tenants, updateTenants] = useState([]);
  const [universalSubCategories, updateUniversalSubCategories] = useState([]);

  /* load dropdown data */
  const loadData = () => {
    const loadFn = async (id, updateState) => {
      const response = await fetch(`${BASE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const json = await response.json();
      updateState(json);
    };
    loadFn("branches", updateBranch);
    loadFn(
      `hospitals?tenant_id=${
        localStorage.getItem("distributor")
          ? JSON.parse(localStorage.getItem("distributor"))[1]
          : 1
      }`,
      updateHospitals
    );
    loadFn("category", updateCategories);
    loadFn("cities", updateCities);
    loadFn("hospitals/universal_hospitals", updateHospitalsEntities);
    loadFn("invoice_numbers", updateInvoiceNumbers);
    loadFn("po_numbers", updatePoNumbers);
    loadFn(
      `default_hospital?tenant_id=${
        localStorage.getItem("distributor")
          ? JSON.parse(localStorage.getItem("distributor"))[1]
          : 1
      }`,
      updateDefaultHospital
    );
    loadFn("hospitals/get_hospital_entities", updateGetHospitalEntities);
    loadFn("molecules", updateMolecules);
    loadFn("molecule_groups", updateMoleculeGroups);
    loadFn("drafts_count", updateDrafts);
    loadFn("fulfillment_status", updateFulfilmentStatus);
    loadFn("tenants", updateTenants);
    loadFn("sub_categories", updateUniversalSubCategories);
  };

  useEffect(() => {
    loadData();
  }, []);

  /* handle login and logout */
  const { data: user } = useApiRequest(`${BASE_URL}/is_logged_in`);
  const [currentUser, updateUser] = useState(user);

  useEffect(() => {
    updateUser(user);
  }, [user]);

  const loadUser = data => {
    updateUser(data);
    loadData();
  };

  return (
    <AppContext.Provider
      value={{
        branches: branches,
        categories: categories.categories,
        cities: cities,
        defaultHospital: defaultHospital.response,
        draftsCount: drafts.count,
        fulfilmentStatus: fulfilmentStatus,
        hospitals: hospitals,
        getHospitalEntities: getHospitalEntities,
        hospitalsEntities: hospitalsEntities,
        invoiceNumbers: invoiceNumbers,
        loadUser: loadUser,
        moleculeGroups: moleculeGroups.molecule_groups,
        molecules: molecules.molecules,
        poNumbers: poNumbers,
        tenants: tenants.tenants,
        universalSubCategories: universalSubCategories,
        user: currentUser,
        BASE_URL: BASE_URL
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
AppContextProvider.propTypes = propTypes;

export default AppContextProvider;
