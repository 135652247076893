import { colors } from "../../components/utils/theme";

/** padding common to most of the pages */
let padding = {
  pb: { headContainer: 2, row: 2 },
  pt: { headContainer: 2, row: 2 }
};

/** props common in all the reports */
let reportSpecificStyles = {
  backgroundColor: { head: colors.accent[1] },
  border: `1px solid ${colors.text[3]}`,
  color: { head: colors.text[0], row: colors.text[0] }
};

export const reportStyles = {
  /** props common in all the pages */
  allReports: {
    color: { head: colors.text[1], row: colors.text[0] },
    ...padding
  },

  /** props common in margin reports */
  marginReports: {
    noSortingColumns: [0],
    sorting: true,
    ...reportSpecificStyles,
    ...padding
  },

  /** props common in saving reports */
  savingsReports: {
    ...reportSpecificStyles,
    ...padding
  }
};
