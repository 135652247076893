import React, { useState, useRef, useEffect } from "react";
import { array, number, oneOfType } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import UploadedFileTag from "../../../product-masters/rate-contract/uploaded-file-tag";
import Alert from "../../../../components/alert";
import FunctionButton from "../../../product-masters/rate-contract/upload-modal/upload-rate-contract/function-button";
import Error from "../../../../components/glyphs/elements/error";
import PurchaseOrderService from "../../../../services/create-new-purchase-order";
import { useHistory } from "react-router";
import Loading from "../../../../components/loading";
import Cta from "../../../../components/cta";
import Button from "../../../../components/button";
import Download from "../../../../components/glyphs/elements/download";

const propTypes = {
  distributorId: oneOfType([array, number]),
  hospitalId: number
};

const UploadButton = ({ distributorId, hospitalId }) => {
  let history = useHistory();
  const defaultBtn = useRef();
  const fileContainer = useRef();
  const customBtn = useRef();
  const wrongFormat = useRef();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [buttonName, setButtonName] = useState("Upload XLSX File");
  const [isLoaded, setIsLoaded] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");

  const handleChange = async () => {
    const defaultBtnFunc = () => {
      if (defaultBtn.current.value) {
        fileContainer.current.style.display = "flex";
        setButtonName("Confirm & Proceed");
      }
    };
    if (buttonName === "Upload XLSX File") {
      defaultBtn.current.click();
      defaultBtn.current.addEventListener("change", () => {
        defaultBtnFunc();
        setUploadedFileName(defaultBtn.current.files[0].name);
      });
    } else if (buttonName === "Confirm & Proceed") {
      var filePath = defaultBtn.current.files[0].name;
      var allowedExtensions = /(\.xlsx|\.dmg)$/i;
      if (!allowedExtensions.exec(filePath)) {
        fileContainer.current.style.display = "none";
        wrongFormat.current.style.display = "flex";
        setButtonName("Upload Again");
        defaultBtn.current.value = null;
      } else {
        setIsLoaded(true);
        var formdata = new FormData();
        formdata.append(
          "file",
          defaultBtn.current.files[0],
          defaultBtn.current.files[0].name
        );

        const res = await PurchaseOrderService.uploadOrderFile(
          distributorId,
          formdata,
          hospitalId
        );
        if (res && res["po_number"]) {
          localStorage.setItem("po_number", "");
          localStorage.setItem(
            "summary",
            JSON.stringify(res["master_sku_list"])
          );
          history.push({
            pathname: `/hospital/purchase_orders/purchase_order_summary/${hospitalId}/${res[
              "po_number"
            ]
              .split("/")
              .join("-")}`,
            state: {
              file: defaultBtn.current.files[0]
            }
          });
        }
        setIsLoaded(false);
      }
    } else if (buttonName === "Upload Again") {
      defaultBtn.current.click();
      defaultBtn.current.addEventListener("change", () => {
        defaultBtnFunc();
        wrongFormat.current.style.display = "none";
      });
    }
  };

  const handleRemove = () => {
    defaultBtn.current.value = null;
    fileContainer.current.style.display = "none";
    setButtonName("Upload XLSX File");
  };
  const fetchSample = async () => {
    try {
      const response = await PurchaseOrderService.downloadSampleFile();
      if (response && response.download_link) {
        setDownloadLink(response.download_link);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchSample();
  }, []);

  return (
    <Primitives.Flex
      backgroundColor="white"
      flexDirection="column"
      padding={0}
      width={317}
    >
      <Primitives.Flex flexDirection="column" mt={1}>
        <Primitives.Flex flexDirection="column" mt={3}>
          <Primitives.Flex display="none" ref={fileContainer}>
            <UploadedFileTag value={uploadedFileName} />
            <Primitives.Text
              color={colors.primary[3]}
              cursor="pointer"
              fontSize={1}
              ml={2}
              mt={1}
              onClick={handleRemove}
              textDecoration="underline"
            >
              Remove
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex
            display="none"
            height={48}
            ref={wrongFormat}
            width={235}
            mb={2}
          >
            <Alert
              backgroundColor="background-transparent-red"
              borderColor={colors.error[1]}
              color={colors.error[1]}
              glyph={<Error fill={colors.error[1]} />}
              label={
                <Primitives.Text
                  display="inline-flex"
                  fontSize={2}
                  fontWeight={2}
                  alignItems="center"
                  ml={3}
                  py={1}
                >
                  Wrong format uploaded.
                </Primitives.Text>
              }
            />
          </Primitives.Flex>
          <Primitives.Input display="none" ref={defaultBtn} type="file" />
          <Primitives.Flex>
            <Primitives.Flex onClick={handleChange} ref={customBtn}>
              <FunctionButton>
                {!isLoaded ? (
                  <Primitives.Text>{buttonName}</Primitives.Text>
                ) : (
                  <Primitives.Flex height="4vh">
                    <Loading onlySpinner />
                  </Primitives.Flex>
                )}
              </FunctionButton>
            </Primitives.Flex>
            <Primitives.Flex ml={2}>
              <Cta
                color="inherit"
                label={
                  <Button
                    active={{
                      backgroundColor: "background-light",
                      color: colors.text[1],
                      borderColor: "inherit"
                    }}
                    backgroundColor="background-light"
                    color={colors.text[1]}
                    fontWeight={2}
                    glyph={<Download height={20} width={20} />}
                    hover={{
                      backgroundColor: colors.text[2],
                      color: colors.text[0]
                    }}
                    label="Sample"
                    lineHeight={3}
                    px={2}
                    py={1}
                  />
                }
                link={downloadLink}
                hover={{ color: "inherit", textdecorationline: false }}
                target="_blank"
                textdecorationline={false}
                type="anchor"
              />
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

UploadButton.propTypes = propTypes;
export default UploadButton;
