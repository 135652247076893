import React from "react";

const Minus = ({ ...props }) => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6666 0.333374V1.66671H0.333252V0.333374H13.6666Z"
      fill="inherit"
    />
  </svg>
);
export default Minus;
