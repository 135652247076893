import React, { useState, useEffect, useContext } from "react";
import Loading from "../../../../components/loading";
import NoResults from "../../../../components/no-results";
import Primitives from "../../../../components/primitives";
import Table from "../../../../components/table-two/TableComponent";
import { colors } from "../../../../components/utils/theme";
import useApiRequest from "../../../../hooks/useApiRequest";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../utils/context";
import ViewDetailsBtn from "../../../reports/lib/view-details-btn";
import Pagination from "../../../../components/pagination";
import { string } from "prop-types";
import DownloadBtn from "../../../../lib/download";
import { reportStyles } from "../../../../lib/report-styles";
import downloadHandlerFn from "../../../../components/utils/download-handler";

const propTypes = {
  hospitalEntity: string,
  date: string
};

const column = data =>
  data &&
  data["column_sum"] && [
    {
      heading: "Category",
      type: "string",
      width: 1
    },
    {
      heading: `Total Manufacturers (${Math.round(
        data["column_sum"]["manufacturers"]
      ).toLocaleString()}) `,
      type: "number",
      width: 1,
      align: "flex-end"
    },
    {
      heading: `Total Products (${data["column_sum"][
        "products"
      ].toLocaleString()}) `,
      type: "number",
      width: 1,
      align: "flex-end"
    },
    {
      heading: `Total Purchase Qty (${data["column_sum"][
        "purchase_qty"
      ].toLocaleString()})`,
      type: "number",
      width: 1,
      align: "flex-end"
    },
    {
      _heading: `Total Purchase Value (₹${Math.round(
        data["column_sum"]["purchase_value"] || 0
      ).toLocaleString()})`,
      get heading() {
        return this._heading;
      },
      set heading(value) {
        this._heading = value;
      },
      type: "number",
      width: 1,
      align: "flex-end"
    },
    {
      heading: `% Purchase Value (${
        data["column_sum"]["purchase_qty"] ? "100" : "-"
      }) `,
      type: "number",
      width: 1,
      align: "flex-end"
    },
    {
      heading: " ",
      type: "object",
      width: 0.5,
      sort: false,
      align: "flex-end"
    }
  ];

const modifyData = data =>
  data.map(item => ({
    Category: item["category"],
    "Total Manufacturers": item["total_manufacturers"],
    "Total Products": item["total_products"],
    "Total Purchase Qty": item["purchase_qty"],
    "Total Purchase value": `₹ ${Math.round(item["purchase_value"])}`,
    "% Purchase Value": item["purchase_value_percent"],
    "": (
      <ViewDetailsBtn
        link={`/hospital/product_masters_category/${item["category_id"]}/${item["category"]}`}
      />
    )
  }));

const ProductTable = ({ hospitalEntity, date }) => {
  const history = useHistory();
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/product_masters/${date}`
  );
  const { data, isLoaded } = useApiRequest(request);
  const [pageNumber, setPageNumber] = useState(1);
  const [sort, updateSort] = useState("");
  const [order, toggleOrder] = useState("asc");
  const [sortingIndex, updateSortingIndex] = useState();
  const [isDownload, setIsDownload] = useState(false);

  /*sorting */
  const filterObject = {
    0: "category+",
    1: "manufacturer+",
    2: "product+",
    3: "quantity+",
    4: "value+",
    5: "percent+"
  };

  const headClickHandler = id => {
    updateSortingIndex(id);
    toggleOrder(order === "asc" ? "desc" : "asc");
    updateSort(filterObject[id] + order);
  };

  const getCategoryId = index =>
    data &&
    data["product_masters"] &&
    data["product_masters"][index]["category_id"];

  const getCategory = index =>
    data &&
    data["product_masters"] &&
    data["product_masters"][index]["category"];

  const clickHandler = (row, index) =>
    history.push(
      `/hospital/product_masters_category/${getCategoryId(index)}/${getCategory(
        index
      )}`
    );

  const downloadHandler = async () =>
    downloadHandlerFn(
      `${BASE_URL}/product_masters.xlsx?&is_download=true${hospitalEntity}&${date}`,
      setIsDownload
    );

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected) {
      setPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      setPageNumber(1);
    }
  };

  useEffect(() => {
    updateRequest(
      `${BASE_URL}/product_masters?q[s]=${sort}${hospitalEntity}&${date}`
    );
  }, [pageNumber, sort, hospitalEntity, date]);

  return (
    <>
      {isLoaded ? (
        <Primitives.Flex flexDirection="column" mt={3}>
          {data && data["total_items"] > 0 && (
            <Primitives.Flex alignItems="center" justifyContent="space-between">
              <Primitives.Text color={colors.text[1]} fontSize={2}>
                Showing {(pageNumber - 1) * 20 + 1}-
                {data["total_items"] > 20
                  ? pageNumber * 20 > data["total_items"]
                    ? data["total_items"]
                    : pageNumber * 20
                  : data["total_items"]}{" "}
                of {data && data["total_items"]} {"categories"}
              </Primitives.Text>
              <Primitives.Flex flex={1} my={1} justifyContent="flex-end">
                {isDownload ? (
                  <Loading onlySpinner />
                ) : (
                  <DownloadBtn clickHandler={downloadHandler} />
                )}
              </Primitives.Flex>
            </Primitives.Flex>
          )}
          {data && data["total_items"] ? (
            <Table
              pr={0}
              borderBottom={{
                head: `1px solid ${colors.text[3]}`,
                row: `1px solid ${colors.text[3]}`
              }}
              border={`1px solid ${colors.text[3]}`}
              clickHandler={clickHandler}
              cursor="pointer"
              data={modifyData(data["product_masters"])}
              column={column(data)}
              headClickHandler={headClickHandler}
              sorting={true}
              sortingIndex={sortingIndex}
              sortingType={order === "asc" ? "desc" : "asc"}
              {...reportStyles["allReports"]}
            />
          ) : (
            <NoResults />
          )}
        </Primitives.Flex>
      ) : (
        <Loading />
      )}
      {data && data["total_items"] > 20 && (
        <Primitives.Flex position="fixed" bottom={10} right={40}>
          <Pagination
            changeHandler={paginationChangeHandler}
            forcePage={pageNumber - 1}
            marginPagesDisplayed={1}
            pageCount={parseInt(data["total_items"]) / 20}
          />
        </Primitives.Flex>
      )}
    </>
  );
};

ProductTable.propTypes = propTypes;
export default ProductTable;
