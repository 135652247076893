import React from "react";

const ErrorWithBackground = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="15" fill="#FFEFCF" />
    <circle cx="15" cy="15" r="8" stroke="#F6A609" strokeWidth="2" />
    <path
      d="M15.847 10.5295V14.2475C15.847 14.6462 15.8232 15.0427 15.7755 15.437C15.7278 15.827 15.665 16.2257 15.587 16.633H14.222C14.144 16.2257 14.0812 15.827 14.0335 15.437C13.9858 15.0427 13.962 14.6462 13.962 14.2475V10.5295H15.847ZM13.741 18.9795C13.741 18.8235 13.7692 18.6783 13.8255 18.544C13.8862 18.4097 13.9663 18.2927 14.066 18.193C14.17 18.0933 14.2913 18.0153 14.43 17.959C14.5687 17.8983 14.7203 17.868 14.885 17.868C15.0453 17.868 15.1948 17.8983 15.3335 17.959C15.4722 18.0153 15.5935 18.0933 15.6975 18.193C15.8015 18.2927 15.8817 18.4097 15.938 18.544C15.9987 18.6783 16.029 18.8235 16.029 18.9795C16.029 19.1355 15.9987 19.2828 15.938 19.4215C15.8817 19.5558 15.8015 19.6728 15.6975 19.7725C15.5935 19.8722 15.4722 19.9502 15.3335 20.0065C15.1948 20.0628 15.0453 20.091 14.885 20.091C14.7203 20.091 14.5687 20.0628 14.43 20.0065C14.2913 19.9502 14.17 19.8722 14.066 19.7725C13.9663 19.6728 13.8862 19.5558 13.8255 19.4215C13.7692 19.2828 13.741 19.1355 13.741 18.9795Z"
      fill="#F6A609"
    />
  </svg>
);

export default ErrorWithBackground;
