import React from "react";

const Tick = ({ ...props }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#E9FFF2" />
    <path
      d="M19.3334 10L12.0001 17.3333L8.66675 14"
      stroke="#219653"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Tick;
