import logoutUser from "../../lib/logout-user";
import { BASE_URL, REQ_OPT } from "../config";

export default {
  userLogin: async body => {
    var json = {};
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      headers: myHeaders,
      body: body,
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL.replace("/smartbuy", "")}/api/login`,
        requestOptions
      );
      json = await response.json();
      if (json.status === 401) {
        localStorage.removeItem("token");
        BASE_URL && logoutUser(BASE_URL.replace("/smartbuy", ""));
      }
      if (json.success) {
        localStorage.setItem("token", json.token);
      }
    } catch (error) {
      console.log(error);
    }
    return json;
  },
  sendOtpLoginRequest: async (url, body) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("error occured :", response.status);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  },
  sentApiRequestWithBody: async (url, body) => {
    try {
      const response = await fetch(`${BASE_URL}/sb_sales_orders#create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(body)
      });
      if (response.ok) {
        return response;
      } else {
        console.error("error occured :", response.status);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  }
};
