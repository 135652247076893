import React from "react";

const Cube = props => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#F4F6FF" />
    <path
      d="M20 16.6679V11.3346C19.9998 11.1008 19.938 10.8711 19.821 10.6687C19.704 10.4662 19.5358 10.2981 19.3333 10.1812L14.6667 7.51457C14.464 7.39755 14.234 7.33594 14 7.33594C13.766 7.33594 13.536 7.39755 13.3333 7.51457L8.66667 10.1812C8.46418 10.2981 8.29599 10.4662 8.17897 10.6687C8.06196 10.8711 8.00024 11.1008 8 11.3346V16.6679C8.00024 16.9017 8.06196 17.1314 8.17897 17.3338C8.29599 17.5362 8.46418 17.7043 8.66667 17.8212L13.3333 20.4879C13.536 20.6049 13.766 20.6665 14 20.6665C14.234 20.6665 14.464 20.6049 14.6667 20.4879L19.3333 17.8212C19.5358 17.7043 19.704 17.5362 19.821 17.3338C19.938 17.1314 19.9998 16.9017 20 16.6679Z"
      stroke="#5C6FCE"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.18018 10.6406L14.0002 14.0073L19.8202 10.6406"
      stroke="#5C6FCE"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 20.72V14"
      stroke="#5C6FCE"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Cube;
