import React from "react";
import Panel from "../../../components/panel";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Table from "../../../components/table-two/TableComponent";
import Doughnut from "../../../components/charts/doughnut";
import useApiRequest from "../../../hooks/useApiRequest";
import { string } from "prop-types";
import Loading from "../../../components/loading";

const propTypes = {
  path: string,
  query: string
};

const tableStyles = { width: 1, justifyContent: "space-between", pr: 20 };
const keyStyles = { width: 12, height: 12, borderRadius: "100%" };

const SkuDistribution = ({ path, query }) => {
  const { data, isLoaded } = useApiRequest(
    `${path}/unique_sku_distribution?${query}`
  );
  return (
    <Panel
      heading={
        <Primitives.Flex flexDirection="column">
          <Primitives.Flex
            color={colors.text[0]}
            fontSize={3}
            fontWeight={3}
            mb="4px"
          >
            <Primitives.Text>Cross Product Procurement</Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex color={colors.text[1]} fontSize={1} fontWeight={0}>
            {data.hospitals && (
              <Primitives.Text>
                {data.hospital_group} Hospitals ({data.hospitals} Centers)
              </Primitives.Text>
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      }
      ctaDetails={{
        label: "Show more details",
        link: "/hospital/cross_center_procurement_report"
      }}
      data={
        <Primitives.Flex pb={4} pt={3} flex={1}>
          {isLoaded ? (
            <>
              <Primitives.Flex flex={1} pl={3}>
                <Table
                  color={{ head: colors.text[1], row: colors.text[0] }}
                  data={[
                    {
                      "Unique Products": data.ordered_in_all_centers,
                      CENTERS: (
                        <Primitives.Flex {...tableStyles}>
                          All ({data.hospitals})
                          <Primitives.Box
                            backgroundColor={colors.accent[5]}
                            {...keyStyles}
                          />
                        </Primitives.Flex>
                      )
                    },
                    {
                      "NUMBER OF PRODUCTS": data.ordered_in_some_centers,
                      CENTERS: (
                        <Primitives.Flex {...tableStyles}>
                          Some (2-{data.hospitals})
                          <Primitives.Box
                            backgroundColor={colors.accent[3]}
                            {...keyStyles}
                          />
                        </Primitives.Flex>
                      )
                    },
                    {
                      "NUMBER OF PRODUCTS": data.ordered_only_at_one,
                      CENTERS: (
                        <Primitives.Flex {...tableStyles}>
                          Just One (1)
                          <Primitives.Box
                            backgroundColor={colors.accent[1]}
                            {...keyStyles}
                          />
                        </Primitives.Flex>
                      )
                    }
                  ]}
                  column={[
                    { heading: "Unique Products" },
                    { heading: "CENTRES" }
                  ]}
                />
              </Primitives.Flex>
              <Primitives.Flex height={200}>
                <Doughnut
                  chartData={[
                    data.ordered_in_all_centers,
                    data.ordered_in_some_centers,
                    data.ordered_only_at_one
                  ]}
                  labels={[
                    "Ordered In All Centers",
                    "Ordered In Some Centers",
                    "Ordered Only At One Center"
                  ]}
                />
              </Primitives.Flex>
            </>
          ) : (
            <Primitives.Flex m="auto" alignItems="center" minHeight={200}>
              <Loading onlySpinner={true} />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
    />
  );
};
SkuDistribution.propTypes = propTypes;
export default SkuDistribution;
