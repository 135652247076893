const splitDateRange = dateRange =>
  (dateRange && {
    startDate: dateRange
      .split("&")[0]
      .split("=")[1]
      .split("/")
      .join("-"),
    endDate: dateRange
      .split("&")[1]
      .split("=")[1]
      .split("/")
      .join("-")
  }) ||
  {};
export default splitDateRange;
