import React from "react";
import { colors } from "../utils/theme";
import { string } from "prop-types";
import Primitives from "../primitives";

const propTypes = {
  /** order status string */
  status: string
};

const defaultProps = {
  status: "pending"
};
const colorObj = {
  "In Progress": "background-error",
  completed: "background-green",
  "partially completed": "background-yellow",
  "short closed": "#E5A1A1",
  cancelled: colors.error[0],
  "pending confirmation": "#F2ADE7",
  placed: colors["background-error"],
  "order confirmed": "#A91692",
  invoiced: "#F37D10",
  "partially invoiced": "#FFBB7C",
  "partially dispatched": "#00B8E0",
  dispatched: "#0077FF",
  "partially delivered": colors["light-green"],
  delivered: colors["background-green"]
};

const OrderStatus = ({ status }) => (
  <Primitives.Flex
    backgroundColor={colorObj[status]}
    borderRadius={15}
    flex="0 0 auto"
    height={12}
    width={12}
  ></Primitives.Flex>
);

OrderStatus.propTypes = propTypes;
OrderStatus.defaultProps = defaultProps;
export default OrderStatus;
