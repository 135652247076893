import React, { useEffect, useState } from "react";
import { number } from "prop-types";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Tooltip from "../../../components/tool-tip";
import AddToCart from "../../../components/glyphs/elements/add-to-cart";

const propTypes = {
  count: number
};

const CartCount = ({ count }) => {
  const [counter, setCounter] = useState([false, count]);

  useEffect(() => {
    if (count > counter[1]) {
      setCounter([true, count]);
      setTimeout(() => {
        setCounter([false, count]);
      }, 1000);
    } else {
      setCounter([false, count]);
    }
  }, [count]);

  useEffect(() => {
    setCounter([false, 0]);
  }, []);

  return (
    <>
      {count > 0 && (
        <Primitives.Text
          background={colors.primary[3]}
          borderRadius="50%"
          color="white"
          fontSize="13px"
          fontWeight={2}
          height="17px"
          marginLeft="20px"
          position="absolute"
          textAlign="center"
          top="7px"
          width="18px"
        >
          {counter[0] && (
            <Tooltip
              content="Items Added to Cart"
              left="-9px"
              triggerTooltop={counter[0]}
              value=" "
            />
          )}
          {count}
        </Primitives.Text>
      )}
      <AddToCart />
    </>
  );
};

CartCount.propTypes = propTypes;
export default CartCount;
