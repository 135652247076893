import React from "react";
import Primitives from "../../../../../../components/primitives";
import { colors } from "../../../../../../components/utils/theme";
import { node } from "prop-types";

const propTypes = {
  children: node
};

const FunctionButton = props => {
  return (
    <>
      <Primitives.Flex
        alignItems="center"
        backgroundColor={colors.primary[3]}
        borderRadius={3}
        color="white"
        cursor="pointer"
        fontSize={1}
        fontWeight={2}
        height={36}
        justifyContent="center"
        width={200}
      >
        {props.children}
      </Primitives.Flex>
    </>
  );
};

FunctionButton.propTypes = propTypes;
export default FunctionButton;
