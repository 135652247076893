import React from "react";

const AddToCart = ({ ...props }) => (
  <>
    <svg
      title="add-to-cart"
      width="24"
      height="25"
      viewBox="0 0 22 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.718175 0.402515C0.321608 0.402515 0 0.72411 0 1.12069C0 1.51727 0.321428 1.83886 0.718175 1.83886H3.10443C4.29298 4.78265 5.46362 7.73182 6.64264 10.6809L5.55796 13.2915C5.46713 13.5093 5.49267 13.7716 5.62375 13.9676C5.75499 14.1636 5.98776 14.2873 6.22369 14.2863H18.1926C18.5719 14.2917 18.9209 13.9477 18.9209 13.5682C18.9209 13.1888 18.5719 12.8447 18.1926 12.85H7.30089L7.92169 11.3688L19.4494 10.4487C19.7512 10.425 20.0243 10.1899 20.0928 9.89523L21.5291 3.67142C21.6232 3.25215 21.2556 2.79479 20.826 2.79612H5.03437L4.25643 0.851176C4.1499 0.587852 3.87473 0.402344 3.59071 0.402344L0.718175 0.402515ZM5.61045 4.23263H19.9205L18.806 9.06506L7.89206 9.93285L5.61045 4.23263ZM8.61772 15.244C7.30413 15.244 6.22394 16.3242 6.22394 17.6378C6.22394 18.9513 7.30413 20.0315 8.61772 20.0315C9.9313 20.0315 11.0115 18.9513 11.0115 17.6378C11.0115 16.3242 9.9313 15.244 8.61772 15.244ZM15.799 15.244C14.4855 15.244 13.4053 16.3242 13.4053 17.6378C13.4053 18.9513 14.4855 20.0315 15.799 20.0315C17.1126 20.0315 18.1928 18.9513 18.1928 17.6378C18.1928 16.3242 17.1126 15.244 15.799 15.244ZM8.61772 16.6803C9.15503 16.6803 9.57514 17.1004 9.57514 17.6378C9.57514 18.1751 9.15503 18.5952 8.61772 18.5952C8.0804 18.5952 7.66029 18.1749 7.66029 17.6378C7.66029 17.1004 8.08057 16.6803 8.61772 16.6803ZM15.799 16.6803C16.3364 16.6803 16.7565 17.1006 16.7565 17.6378C16.7565 18.1751 16.3362 18.5952 15.799 18.5952C15.2617 18.5952 14.8416 18.1751 14.8416 17.6378C14.8416 17.1004 15.2617 16.6803 15.799 16.6803Z"
        fill="#F58158"
      />
    </svg>
  </>
);

export default AddToCart;
