import React, { useContext } from "react";
import { array, bool, object, oneOfType, string } from "prop-types";
import Primitives from "../../../../components/primitives";
import { colors } from "../../../../components/utils/theme";
import Loading from "../../../../components/loading";
import { useHistory } from "react-router";
import { FilterContext } from "../../../../utils/filter-context";

const propTypes = {
  header: string,
  loader: bool,
  values: oneOfType([array, object])
};

const valueMapper = data =>
  data.map((item, index) => (
    <React.Fragment key={index}>
      <Primitives.Flex flexDirection="column" key={index}>
        <Primitives.Text fontSize="13px" textAlign="center">
          {item.heading}
        </Primitives.Text>
        <Primitives.Text
          fontSize="18px"
          fontWeight={2}
          py={1}
          textAlign="center"
        >
          {item.value}
        </Primitives.Text>
      </Primitives.Flex>
      {data.length > 1 && index < data.length - 1 && (
        <Primitives.Flex borderLeft="1px solid #E5E5E5"></Primitives.Flex>
      )}
    </React.Fragment>
  ));

const ProductsOrdered = ({ header, loader, values }) => {
  const history = useHistory();
  const filterContext = useContext(FilterContext);
  return (
    <Primitives.Flex
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      flex={1}
      flexDirection="column"
      mb={3}
      mr={2}
    >
      <Primitives.Flex
        background="white"
        borderBottom="1px solid #E5E5E5"
        borderTopRightRadius="10px"
        borderTopLeftRadius="10px"
        color={colors.text[0]}
        fontSize="14px"
        fontWeight={2}
        px="17px"
        py="12px"
      >
        {header}
      </Primitives.Flex>
      <Primitives.Flex
        background="white"
        flexDirection="column"
        px="17px"
        py="17px"
      >
        {loader ? (
          <Primitives.Flex justifyContent="space-around">
            {valueMapper(values)}
          </Primitives.Flex>
        ) : (
          <Primitives.Flex margin="auto" py={1}>
            <Loading onlySpinner />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
      <Primitives.Flex
        background="#fff9f7"
        borderBottomLeftRadius="10px"
        borderBottomRightRadius="10px"
        color={colors.primary[3]}
        fontSize="12px"
        fontWeight={2}
        justifyContent="center"
        py={1}
      >
        <Primitives.Text
          cursor="pointer"
          onClick={() => {
            var paramObj = {};
            if (header.includes("Non RC")) {
              paramObj = {
                rcAvailability: [
                  "Product Without RC",
                  "product_without_rc=true"
                ]
              };
            } else {
              paramObj = {
                rcAvailability: ["Product With RC", "product_with_rc=true"]
              };
            }
            const obj = {
              ...filterContext.filterState,
              ...paramObj
            };
            filterContext.updateFilterState(obj);
            history.push("/hospital/product_specific_compliance");
          }}
        >
          View Products
        </Primitives.Text>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

ProductsOrdered.propTypes = propTypes;
export default ProductsOrdered;
