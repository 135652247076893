import React, { useState } from "react";
import { object } from "prop-types";
import DownloadBtn from "../../../../lib/download";
import downloadHandlerFn from "../../../../components/utils/download-handler";
import { BASE_URL } from "../../../../services/config";
import Loading from "../../../../components/loading";

const propTypes = {
  item: object
};
const DownloadCreditNote = ({ item }) => {
  const [isDownload, setIsDownload] = useState(false);

  const downloadHandler = async (setIsDownload, download) =>
    downloadHandlerFn(download, setIsDownload, "pdf");

  return !isDownload ? (
    <DownloadBtn
      clickHandler={() => {
        downloadHandler(
          setIsDownload,
          `${BASE_URL}/analytics/credit_note_download?id=${item["id"]}`,
          "pdf"
        );
      }}
      isGlyph={false}
    />
  ) : (
    <Loading onlySpinner />
  );
};

DownloadCreditNote.propTypes = propTypes;
export default DownloadCreditNote;
