// It renders four different savings reports
import React, { useState, useContext } from "react";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import Primitives from "../../../components/primitives";
import Loading from "../../../components/loading";
import Header from "../lib/header";
import GrnTimeSavings from "./grn-time-savings-report";
import HospitalManagementReport from "./hospital-management-report";
import CategoryMarginReport from "./category-margin-report";
import DiscountReport from "./discount-report";
import selectedMonth from "../../../components/utils/selected-month";
import Navigation from "../../../lib/navigation";

const categoryDiscountColumn = [
  { heading: "Category Name", type: "string" },
  { heading: "Total Discount Value", type: "string", align: "flex-end" },
  { heading: "Average Discount %", type: "string" }
];

const categoryMarginColumn = columnMonth => [
  { heading: "Hospital Name", type: "string" },
  {
    heading: `${
      selectedMonth(columnMonth, "previousMonth").split("-")[0]
    } Margin`,
    type: "string"
  },
  {
    heading: `${selectedMonth(columnMonth).split("-")[0]} Margin`,
    type: "string"
  },
  { heading: "Margin Diff", type: "string" }
];

const hospitalManagementColumn = columnMonth => [
  { heading: "Hospital Name", type: "string" },
  {
    heading: `${
      selectedMonth(columnMonth, "previousMonth").split("-")[0]
    } Margin`,
    type: "string"
  },
  {
    heading: `${selectedMonth(columnMonth).split("-")[0]} Margin`,
    type: "string"
  },
  { heading: "Margin Diff", type: "string" }
];

const grnTimeSavingsColumn = [
  { heading: "Hospital Name", type: "string" },
  { heading: "No. of Invoices Confirmed", type: "number", align: "flex-end" },
  { heading: "Total Products", type: "number", align: "flex-end" },
  { heading: "Estimate time for GRN", type: "string", align: "flex-end" }
];

const SavingsReport = () => {
  const { BASE_URL } = useContext(AppContext);
  const [request, updateRequest] = useState(
    `${BASE_URL}/analytics/savings_report`
  );

  const [columnMonth, setColumnMonth] = useState("");

  const { data, isLoaded } = useApiRequest(request);
  const filterUpdate = queryObj => {
    queryObj.months
      ? setColumnMonth(queryObj.months.split("=")[1])
      : setColumnMonth("");
    let hospital_ids = "";
    queryObj.hospitalEntity &&
      queryObj.hospitalEntity
        .split("=")[1]
        .split(",")
        .map(item => (hospital_ids += `hospital_ids[]=${item}&`));
    updateRequest(
      `${BASE_URL}/analytics/savings_report?${queryObj.months}&${hospital_ids}`
    );
  };

  return (
    <>
      <Navigation selected={3} subNavIndex={[3, 6]} />
      <Primitives.Flex
        backgroundColor="white"
        flexDirection="column"
        fontFamily="Lato"
      >
        <Header
          filterUpdate={filterUpdate}
          label="Savings Report"
          config={{ hospitalEntity: true, months: true, savings: true }}
        />

        {isLoaded ? (
          <Primitives.Flex flexWrap="wrap" px={5} flex={1} width="100%">
            <Primitives.Flex py={4} width={0.45} pr={1}>
              <DiscountReport
                data={data.category_discount_stats}
                download={request}
                column={categoryDiscountColumn}
              />
            </Primitives.Flex>
            <Primitives.Flex py={4} width={0.45} pl={1}>
              <CategoryMarginReport
                data={data.category_margin_improvement_stats}
                download={request}
                column={categoryMarginColumn(columnMonth)}
              />
            </Primitives.Flex>
            <Primitives.Flex py={4} width={0.45} pr={1}>
              <HospitalManagementReport
                data={data.hospital_margin_improvement_stats}
                download={request}
                column={hospitalManagementColumn(columnMonth)}
              />
            </Primitives.Flex>
            <Primitives.Flex py={4} width={0.45} pl={1}>
              <GrnTimeSavings
                data={data.grn_time_savings_stats}
                download={request}
                column={grnTimeSavingsColumn}
              />
            </Primitives.Flex>
          </Primitives.Flex>
        ) : (
          <Loading />
        )}
      </Primitives.Flex>
    </>
  );
};
export default SavingsReport;
