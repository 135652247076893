import React, { useContext } from "react";
import Table from "../../../../components/table-two/TableComponent";
import { colors } from "../../../../components/utils/theme";
import { array, string } from "prop-types";
import Primitives from "../../../../components/primitives";
import Panel from "../../lib/panel";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";
import PillChart from "../../../../components/pill-chart/index";
import { useHistory } from "react-router";
import { FulfillmentContext } from "../../../../utils/fulfillment-report-context";
import { FilterContext } from "../../../../utils/filter-context";
import reverseDate from "../../../../utils/reverse-date";
const propTypes = {
  /** data Types */
  column: array,
  /** Url path*/
  path: string,
  /** Updated parameters */
  query: string,
  /** Title for Otif report */
  title: string,
  /**start date and end date */
  startDate: string,
  endDate: string
};

const rowFn = data => (
  <Primitives.Flex alignItems="center" p={1} pl={2}>
    {data}
  </Primitives.Flex>
);
const chartFn = (
  data,
  history,
  title,
  startDate,
  endDate,
  filterContext,
  hospitalId
) => {
  const chartData = [
    {
      backgroundColor: "#036516",
      bottomLabel: data["early"],
      color: "#036516",
      textAlign: "start",
      width: data["early"]
    },
    {
      backgroundColor: colors.success[1],
      bottomLabel: data["on_time"],
      color: colors.success[1],
      textAlign: "center",
      width: data["on_time"]
    },
    {
      backgroundColor: "#F4B513",
      bottomLabel: data["late"],
      color: "#F4B513",
      textAlign: "end",
      width: data["late"]
    }
  ];

  return (
    <Primitives.Flex py="5px" px={2} width={1}>
      <Primitives.Flex flex={9.5}>
        <PillChart centerWidth="73%" data={chartData} />
      </Primitives.Flex>
      {
        <Primitives.Text
          color={colors.primary[3]}
          cursor="pointer"
          flex={2.5}
          onClick={() => {
            if (title === "Category") {
              history.push({
                pathname: `/hospital/category_order_details/${data.category_id}`
              });
            } else {
              filterContext.updateFilterState({
                ...filterContext.filterState,
                hospitalEntity: String(hospitalId)
              });
              history.push(
                `/hospital/order_fulfilment_details/${startDate}/${
                  endDate ? endDate : startDate
                }`
              );
            }
          }}
          pt={1}
          textAlign="end"
          width={1}
        >
          View Details
        </Primitives.Text>
      }
    </Primitives.Flex>
  );
};

const modifyData = (
  data,
  history,
  title,
  startDate,
  endDate,
  filterContext,
  hospitalData
) =>
  data &&
  data.response.map((item, index) => ({
    [title.toUpperCase()]:
      title.toLowerCase() === "hospital"
        ? rowFn(item.hospital_name)
        : rowFn(item.category),
    "UNIQUE SKUs ORDERED": rowFn(item.total_products),
    "QUANTITY ORDERED": rowFn(item.total_quantity),
    "OTIF BREAKDOWN": chartFn(
      item,
      history,
      title,
      startDate,
      endDate,
      filterContext,
      hospitalData[index]?.hospital_id
    )
  }));

const Otif = ({ column, title, path, query, startDate, endDate }) => {
  const history = useHistory();
  const fulfillmentContext = useContext(FulfillmentContext);
  fulfillmentContext.updateOtifStartDate(startDate);
  fulfillmentContext.updateOtifEndDate(endDate);
  const filterContext = useContext(FilterContext);

  const { data, isLoaded } = useApiRequest(
    `${path}/${title.toLowerCase()}_otif_report?${query}`
  );
  return (
    <>
      {isLoaded ? (
        data.response &&
        data.response.length > 0 && (
          <Primitives.Flex flexDirection="column" mx={4} mt={4} minHeight={40}>
            <Panel
              title={`${title} OTIF Report`}
              date={data.date_range}
              downloadLink={`${data.download_link}?${query}`}
            />
            <Primitives.Flex
              backgroundColor="white"
              borderRadius={10}
              border="1px solid"
              borderColor={colors["athens-gray"]}
              boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.1)"
              flexDirection="column"
              mt={2}
            >
              <Primitives.Flex flex={1}>
                {data && data.response && (
                  <Table
                    borderBottom={{
                      head: `1px solid ${colors["pattens-blue"]}`,
                      row: "0px"
                    }}
                    color={{ head: colors.text[1], row: colors.text[0] }}
                    data={modifyData(
                      data,
                      history,
                      title,
                      reverseDate(startDate.replaceAll("/", "-"), "-"),
                      reverseDate(endDate.replaceAll("/", "-"), "-"),
                      filterContext,
                      data.response
                    )}
                    column={column}
                    fontWeight={{ head: 3, row: 1 }}
                    stripedColumns="even"
                    page="otifReport"
                    p={{ headContainer: 0, row: 0 }}
                  />
                )}
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
        )
      ) : (
        <Primitives.Flex minHeight={300} mt={7} mx="auto">
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </>
  );
};
Otif.propTypes = propTypes;
export default Otif;
