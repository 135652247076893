import React, { useEffect, useState, useRef } from "react";
import { bool, number, object, oneOfType, string } from "prop-types";
import Primitives from "../../components/primitives";
import ToolTipComponent from "../../components/tool-tip/ToolTipComponent";
import ToolTipIcon from "../../components/glyphs/elements/tool-tip";

const propTypes = {
  backgroundColor: string,
  boxShadow: oneOfType([number, string]),
  content: oneOfType([object, string]),
  borderRadius: string,
  close: bool,
  contentTextAlign: string,
  containerTextAlign: string,
  contentWidth: oneOfType([number, string]),
  containerWidth: string,
  contentHeight: oneOfType([number, string]),
  delay: number,
  fontSize: oneOfType([number, string]),
  height: oneOfType([number, string]),
  left: oneOfType([number, string]),
  padding: oneOfType([number, string]),
  toolTipTriangle: object,
  top: oneOfType([number, string]),
  value: object,
  width: oneOfType([number, string])
};
const defaultProps = {
  delay: 400,
  top: 30,
  fontSize: "10px",
  height: 12,
  width: 12
};

const TooltipOnClick = ({
  backgroundColor,
  boxShadow,
  borderRadius,
  close,
  content,
  contentTextAlign,
  containerTextAlign,
  contentWidth,
  containerWidth,
  contentHeight,
  fontSize,
  height,
  left,
  padding,
  toolTipTriangle,
  top,
  value,
  width
}) => {
  const [active, setActive] = useState(false);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return true;
    }
    if (!e.target.closest(".sc-dIsUp")) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setActive(false);
  }, [close]);

  return (
    <Primitives.Flex
      display="inline-block"
      onClick={() => {
        setActive(true);
      }}
      position="relative"
      ref={node}
      textAlign={containerTextAlign}
      width={containerWidth}
    >
      {value || <ToolTipIcon height={height} width={width} />}
      {active && (
        <ToolTipComponent
          backgroundColor={backgroundColor}
          boxShadow={boxShadow}
          left={left}
          top={top}
          toolTipTriangle={toolTipTriangle}
          width={contentWidth}
          height={contentHeight}
          padding={padding}
          borderRadius={borderRadius}
        >
          <Primitives.Flex
            fontSize={fontSize}
            textAlign={contentTextAlign}
            width={1}
          >
            {content}
          </Primitives.Flex>
        </ToolTipComponent>
      )}
    </Primitives.Flex>
  );
};
TooltipOnClick.propTypes = propTypes;
TooltipOnClick.defaultProps = defaultProps;
export default TooltipOnClick;
